import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import general from './modules/general.js'
import filtering from './modules/filtering'
import users from './modules/users'
import organizations from './modules/organizations'
import themes from './modules/themes'
import routes from './modules/routes'
import casualUpload from './modules/casualUpload'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    general,
    auth,
    filtering,
    users,
    organizations,
    themes,
    routes,
    casualUpload,
  },
})

export default store
