<template>
  <div class="d-flex justify-space-between align-center">
    <!-- TITLE -->
    <v-card-title class="d-flex justify-space-between fill-width mx-0 pa-0"
      ><span>{{ generateTitle }}</span>
      <span
        v-if="isShowIfUserHostIsBlocked"
        class="host-account-is-blocked-message"
        >The account's host is blocked</span
      >
      <span></span>
    </v-card-title>

    <!-- BUTTONS FOR SAVE AND CANCEL -->
    <div class="d-flex ml-auto mr-4">
      <transition name="slide-fade">
        <span
          class="error-save-button-message fs-1r mr-8 red--text"
          v-if="showMessage"
          >{{ message }}</span
        >
      </transition>
      <div @mouseover="showMessage = true" @mouseleave="showMessage = false">
        <Button
          :clickAction="checkBeforeSaveRequireAlert"
          :loading="loading"
          :disabled="isSaveButtonDisabled"
          :text="actionType === 'edit' ? 'Save' : 'Create'"
          class="mr-4"
          btnType="blue"
        />
      </div>
      <Button
        btnType="grey"
        text="Cancel"
        :clickAction="() => $router.go(-1)"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'HeaderAndButtons',
  data() {
    return {
      showMessage: false,
    }
  },
  props: {
    freezedProfile: Object,
    actionType: String,
    isSaveButtonDisabled: Boolean,
    checkBeforeSaveRequireAlert: Function,
    loading: Boolean,
    isFromUsers: Boolean,
    message: String,
  },
  components: { Button },
  computed: {
    ...mapGetters(['currentOrganization']),
    generateTitle() {
      if (this.freezedProfile.status === 2) return 'ACCOUNT IS ARCHIVED'
      else if (this.freezedProfile.status === 1) return 'ACCOUNT IS BLOCKED'
      else if (this.actionType === 'add') return 'CREATE ACCOUNT'
      else if (this.actionType === 'edit') return 'EDIT ACCOUNT'
    },

    isShowIfUserHostIsBlocked() {
      if (
        this.currentOrganization.status !== 0 &&
        this.actionType === 'edit' &&
        this.isFromUsers
      )
        return true
      return false
    },
  },
}
</script>

<style scoped src="./HeaderAndButtons.css"></style>
