var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.type === 'desktop' && 'directory-tree-wrapper-desktop fill-width pt-2',
    _vm.type === 'tablet' && 'ma-2',
  ],attrs:{"tabindex":"0","aria-label":"Directories tree"}},[(_vm.type === 'tablet')?_c('Button',{staticClass:"mb-3",attrs:{"clickAction":_vm.changeTreeAllFilesClicked,"text":`Show Only ${_vm.isShowAllFiles ? 'Folders' : 'Files'}`,"width":"150","btnType":"grey"}}):_vm._e(),_c('div',{staticClass:"wrapper-1 mx-2 v-treeview-wrapper"},[_c('v-treeview',{staticClass:"dir-tree bg-white",class:[_vm.type === 'desktop' && 'dir-tree-desktop'],attrs:{"items":_vm.directoriesTree.children,"transition":"","dense":_vm.type === 'desktop',"hoverable":""},on:{"update:open":_vm.treeViewOpened},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [_c('img',{staticClass:"h-1-25r",attrs:{"src":require("@/assets/folder.svg")}})]}},{key:"label",fn:function({ item }){return [_c('button',{staticClass:"directory-tree-item fs-1-12r",class:[
            _vm.shownDirectory.serverName === item.serverName &&
              'font-weight-regular',
          ],attrs:{"aria-label":item.name,"tabindex":"0"},on:{"click":function($event){return _vm.changeShownDirClicked(item.path, 'dirTree')}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }