import { api, selectedHost } from '@/config'

export default {
  state: {
    organizations: [],
    currentOrganization: {},
  },

  mutations: {
    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations
    },
    SET_SINGLE_ORGANIZATION(state, organization) {
      state.currentOrganization = organization
    },
  },
  actions: {
    async GET_ORGANIZATIONS({ commit }) {
      try {
        commit('SET_PROCESSING', true)
        let res = await api.get('organizations')
        if (res.status !== 200) {
          throw { res }
        }
        commit('SET_ORGANIZATIONS', [...res.data])
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },
    async ADD_ORGANIZATION({ commit, dispatch }, organization) {
      let organizationId
      try {
        commit('SET_PROCESSING', true)

        const res = await api.post(`organizations`, organization)
        if (res.status !== 200) throw { response: res }
        organizationId = res.data.organizationId

        await dispatch('GET_ORGANIZATIONS')
        commit('SET_NOTIFICATION', {
          type: 'success',
          text: 'Host was created successfully',
        })
      } catch (error) {
        console.log(error)
        return null
      } finally {
        commit('SET_PROCESSING', false)
        return organizationId
      }
    },
    async EDIT_ORGANIZATION({ commit, state }, payload) {
      try {
        const { loggedUserOrgId, ...organization } = payload
        commit('SET_PROCESSING', true)

        const res = await api.put(
          `organizations/${organization.organizationId}`,
          organization
        )
        if (res.status !== 200) throw { response: res }

        const updatedList = state.organizations.map(el =>
          el.organizationId === res.data.organizationId ? res.data : el
        )
        delete res.data.logo
        delete res.data.background
        commit('SET_ORGANIZATIONS', updatedList)
        commit('SET_SINGLE_ORGANIZATION', {
          ...state.currentOrganization,
          ...res.data,
        })

        if (loggedUserOrgId === organization.organizationId) {
          const theme = {
            ...organization.theme,
            name: organization.name,
            backgroundUrl: `${selectedHost}/api/images/${loggedUserOrgId}/background`,
            logoUrl: `${selectedHost}/api/images/${loggedUserOrgId}/logo`,
            organizationId: loggedUserOrgId,
          }

          commit('SET_THEME', theme)
        }
        commit('SET_NOTIFICATION', {
          type: 'success',
          text: 'Host was edited successfully',
        })
        return res
      } catch (error) {
        console.log(error)
        return error
      } finally {
        commit('SET_PROCESSING', false)
      }
    },
    async GET_SINGLE_ORGANIZATION({ commit, state }, id = 0) {
      try {
        commit('SET_PROCESSING', true)
        let res = await api.get(`organizations/${id}`)
        if (res.status !== 200) {
          throw { response: res }
        }
        commit('SET_SINGLE_ORGANIZATION', res.data)
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },
    async REMOVE_ORGANIZATION({ commit, dispatch }, payload) {
      try {
        commit('SET_PROCESSING', true)
        let res = await api.delete(`organizations/${payload.organizationId}`)
        if (res.status == 200) {
          await dispatch('GET_ORGANIZATIONS')
          commit('SET_NOTIFICATION', {
            type: 'success',
            text: 'Host was deleted',
          })
        } else {
          throw { response: res }
        }
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },
  },
  getters: {
    organizations: state => state.organizations,
    currentOrganization: state => state.currentOrganization,
  },
}
