<template>
  <div class="d-flex table-header fill-width rtl">
    <!--LOOP ON EACH HEAD-->
    <div
      v-for="head of uploadedFilesTableHeaders"
      :class="[head.class, head.sortable && 'pointer']"
      class="table-head-cell fs-1-25r"
      :style="{ width: head.width }"
      @click="sortByClicked(head, true)"
      role="columnheader"
      tabindex="0"
    >
      <!--TEXT-->
      {{ head.text }}

      <!--SORT BY-->
      <v-icon dense v-if="head.sortBy === sortBy"
        >mdi-arrow-{{ sortType === 'inc' ? 'down' : 'up' }}</v-icon
      >
    </div>
  </div>
</template>

<script>
import { getUploadedFilesTableHeaders } from './TableHeader'
import { mapGetters } from 'vuex'
export default {
  name: 'TableHeader',
  data() {
    return {
      sortType: 'dec',
      sortBy: 'lastModifiedDate',
    }
  },
  props: {
    sortByChanged: Function,
    session: Object,
  },
  watch: {
    'session.filesData': {
      handler: function (n) {
        this.sortByClicked(
          { sortable: true, sortBy: 'lastModifiedDate' },
          false
        )
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['language']),
    uploadedFilesTableHeaders() {
      return getUploadedFilesTableHeaders(this)
    },
  },
  methods: {
    sortByClicked(head, isChangeSortBy) {
      //if the head is not sortable
      if (!head.sortable) return

      //if the change is from clicking on header
      if (isChangeSortBy) {
        if (this.sortType === 'inc') this.sortType = 'dec'
        else if (this.sortType === 'dec') this.sortType = 'inc'
      }

      //set sort by and how
      this.sortBy = head.sortBy

      this.sortByChanged(head.sortBy, this.sortType)
    },
  },
}
</script>

<style scoped src="./TableHeader.css"></style>
