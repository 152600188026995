export const checkboxChanged = (arr) => {
  if (arr.includes('Email') && arr.includes('Website')) {
    return 3
  } else if (arr.includes('Email') && !arr.includes('Website')) {
    return 2
  } else if (!arr.includes('Email') && arr.includes('Website')) {
    return 1
  } else {
    return 0
  }
}
