<template>
  <div class="d-flex flex-column edit-details" :class="['edit-details-org']">
    <!--CREATED BY AT-->
    <span
      v-if="
        parentObj.createUpdateDetails.createdByName &&
        parentObj.createUpdateDetails.dateCreatedByMs
      "
      class="label f-14"
      >Created by {{ computedCreatedBy }} at {{ computedCreatedAt }}</span
    >

    <!--UPDATED BY AT-->
    <span
      v-if="
        parentObj.createUpdateDetails.updatedByName &&
        parentObj.createUpdateDetails.dateUpdatedByMs
      "
      class="label f-14"
      >Updated by {{ computedUpdatedBy }}
      {{ computedUpdatedAt }}
      ago
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRelativeTimeFromUTC0, generateLocalDateFromUTC0 } from '@/utils'

export default {
  name: 'EditDetails',
  props: {
    parentObj: Object,
    editOf: String,
  },
  filters: {
    getRelativeTimeFromUTC0,
    generateLocalDateFromUTC0,
  },
  computed: {
    ...mapGetters(['users']),
    computedCreatedBy() {
      return this.parentObj.createUpdateDetails.createdByName
    },

    computedCreatedAt() {
      const res = generateLocalDateFromUTC0(
        this.parentObj.createUpdateDetails.dateCreatedByMs
      )

      if (res === '') return '1 second'
      return res
    },
    computedUpdatedBy() {
      return this.parentObj.createUpdateDetails.updatedByName
    },

    computedUpdatedAt() {
      const res = getRelativeTimeFromUTC0(
        this.parentObj.createUpdateDetails.dateUpdatedByMs
      )
      if (res === '') return '1 second'
      return res
    },
  },
}
</script>

<style scoped src="./Editdetails.css"></style>
