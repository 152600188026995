<template>
  <div class="pa-2">
    <h4 class="settings-h4" :class="[actionType === 'add' && 'required-field']">
      Licenses
    </h4>
    <div class="wrapper-2 pa-3 mb-4 pos-relative">
      <v-data-table
        :headers="orgDefinitionsHeaders"
        hide-default-footer
        fixed-header
        class="wrapper-1 mw-220"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <!-- FIRST LINE OF THE TABLE FOR CASUAL AND REGISTERED-->
            <WebsiteLicenses
              :protocolNumberRules="protocolNumberRules"
              :getProtocols="getProtocols"
              :numOfAllowedChange="numOfAllowedChange"
              :organization="organization"
              :actionType="actionType"
            />
            <Licenses
              v-for="(interFace, i) in licenses"
              :key="i"
              :interFace="interFace"
              :protocolNumberRules="protocolNumberRules"
              :getProtocols="getProtocols"
              :organization="organization"
              :numOfAllowedChange="numOfAllowedChange"
            />
          </tbody>
        </template>
      </v-data-table>
      <OnlineHelp
        page="settings"
        section="licenses"
        title="Licenses"
      />
    </div>
  </div>
</template>

<script>
//Base componentns
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

//Childrens
import WebsiteLicenses from './Childrens/WebsiteLicenses/WebsiteLicenses.vue'
import Licenses from './Childrens/Licenses/Licenses.vue'

//Component file
import { langs, orgDefinitionsHeaders, licenses } from './OrgDefinitons'

export default {
  name: 'OrgDefinitions',
  data() {
    return {
      langs,
      orgDefinitionsHeaders,
      licenses,
      protocolNumberRules: {
        required: value => !!value || value === 0 || 'Required.',
        isPositive: value =>
          (value > -1 && value < 100) || 'Only positive Numbers',
        isInt: value => (value % 1 === 0 && value !== 'e') || 'Only Integers',
        isMoreThanUsed: type => {
          if (!this.organization.allowedLicenses) return true
          const license = this.organization.allowedLicenses.find(
            e => e.type === type
          )
          if (!license || license.numOfInUse > license.numOfAllowed) {
            return false
          }
          return true
        },
      },
    }
  },
  props: {
    organization: Object,
    actionType: String,
  },
  components: { WebsiteLicenses, Licenses, OnlineHelp },
  methods: {
    getProtocols(type, allowed) {
      if (!this.organization.allowedLicenses) return 0
      if (this.organization.allowedLicenses.length) {
        const proto = this.organization.allowedLicenses.find(
          e => e.type === type
        )
        if (proto && allowed) return proto.numOfAllowed
        else if (proto) return proto.numOfInUse
        return 0
      }
      return 0
    },
    numOfAllowedChange(val, type) {
      // if the the type is casual and it is more than 0
      if (type === 'Casual' && +val > 0) {
        this.organization.casualUsersDefinitions.isActive = true
      }

      // if the type is casual and it is 0
      else if (type === 'Casual' && +val === 0) {
        this.organization.casualUsersDefinitions.isActive = false
      }

      const value = parseInt(val)
      //if the value is more than 0
      if (value > 0) {
        if (
          //checks if there is already an object with the same type
          this.organization.allowedLicenses.length &&
          this.organization.allowedLicenses.some(e => e.type === type)
        ) {
          this.organization.allowedLicenses =
            this.organization.allowedLicenses.map(e => {
              return e.type === type ? { ...e, numOfAllowed: value } : e
            })
          return
        } else {
          const newProtocol = {
            type,
            numOfAllowed: value,
            numOfInUse: 0,
          }
          this.organization.allowedLicenses.push(newProtocol)
        }
      }

      // if the number is 0
      else {
        this.organization.allowedLicenses = this.organization.allowedLicenses
          .map(lic => {
            if (lic.type === type) {
              //if there are used users retun this to cause an error
              if (lic.numOfInUse > 0) return { ...lic, numOfAllowed: 0 }
              else return undefined
            }
            return lic
          })
          .filter(el => el !== undefined)
      }
    },
  },
}
</script>

<style scoped src="./OrgDefinitions.css"></style>
