<template>
  <div class="licenses-table-wrapper">
    <Button
      btnType="grey"
      :clickAction="() => (isShowTable = !isShowTable)"
      :text="isShowTable ? 'Hide Allocations Table' : 'Show Allocations Table'"
      :icon="isShowTable ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      class="mx-auto my-2"
    />

    <div v-if="isShowTable">
      <v-text-field
        v-if="computedUsers.length > 10"
        v-model="registerSearch"
        class="mw-200 ma-0 pa-0 mb-2"
        placeholder="Search registered account"
        hide-details
        clearable
      ></v-text-field>

      <v-data-table
        class="wrapper-1"
        :headers="licensesTableHeaders"
        :items="computedUsers"
        :search="registerSearch"
        :options="{ sortBy: ['email'] }"
        must-sort
        dense
      >
        <template v-slot:item="{ item }">
          <tr :class="[currentOrganization.status !== 0 && 'low-op-disabeld']">
            <!--CASUAL EMAIL-->
            <td>{{ item.email }}</td>

            <!--ACTIVE-->
            <td class="d-flex">
              <v-switch
                @change="allowCasualsChanged($event, item.userId)"
                v-model="item.isAllowCasuals"
                dense
                class="pa-0 ma-0 mx-auto my-1"
                hide-details
              ></v-switch>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
/*NEED REBUILD!!!*/
import { mapGetters, mapMutations } from 'vuex'
import Button from '@/components/BaseComponents/Button/Button.vue'
//Childrens

//Component files
import { licensesTableHeaders } from './LicensesTable'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'LicensesTable',
  data() {
    return {
      licensesTableHeaders,
      errorItem: [],
      newCasualParent: null,
      registerSearch: '',
      isShowTable: false,
    }
  },
  props: {
    casualsLicense: Object,
    allowCasualsChanged: Function,
  },
  components: { OnlineHelp, Button },
  computed: {
    ...mapGetters(['users', 'currentOrganization']),
    computedUsers() {
      //return only the active users
      return this.users.filter(
        el =>
          el.status === 0 && (el.license === 'Website' || el.license === 'SFTP')
      )
    },
  },
  methods: {
    ...mapMutations(['SET_USERS']),
  },
}
</script>

<style scoped src="./LicensesTable.css"></style>
