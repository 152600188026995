<template>
  <div class="casual-upload-header bg-grey">
    <!--TITLE-->
    <h1>{{$t('Send Files Securely To') + ' ' + recipientName + $t(' From ') + themeSettings.name }}</h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Header',
  props: {
    recipientName: String,
  },
computed:{
  ...mapGetters(['themeSettings']),
}
}
</script>

<style lang="scss" scoped>
.casual-upload-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 8px;
  border-radius: 10px 10px 0 0;

  h1 {
    font-size: 1.25rem;
  }
}
</style>
