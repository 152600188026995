<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeUserRoutesDialog"
    width="1000"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <!--ROUTE INFORMATION-->
    <v-card>
      <!--TITLE-->
      <v-card-title class="bg-grey">
        <span class="f-22">Route information (read only)</span>
      </v-card-title>

      <!--SINGLE ROUTE IN READ ONLY-->
      <div class="pa-2 my-2 mx-3">
        <SingleRoute
          :routeId="routeId"
          :isFromUser="true"
          class="wrapper-2 pa-3 my-2"
        />
      </div>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <Button
          :clickAction="closeUserRoutesDialog"
          width="120"
          text="Close Route"
          btnType="grey"
          class="my-2"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import SingleRoute from '@/components/SingleOrganization/Routes/SingleRoute/SingleRoute.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UserRouteDialog',
  data() {
    return {
      dialog: true,
    }
  },
  props: {
    routeId: Number,
    closeUserRoutesDialog: Function,
  },
  components: { SingleRoute, Button },
  computed: {
    ...mapGetters(['isDarkMode']),
  },
}
</script>
