export const packagesUsage = [
  { name: 'vue.js', url: 'https://vuejs.org' },
  { name: 'vuetify', url: 'https://vuetifyjs.com/en/' },
  { name: 'axios', url: 'https://www.npmjs.com/package/axios' },
  { name: 'core-js', url: 'https://www.npmjs.com/package/core-js' },
  { name: 'bowser', url: 'https://www.npmjs.com/package/bowser' },
  { name: 'dayjs', url: 'https://www.npmjs.com/package/dayjs' },
  { name: 'file-icons-js', url: 'https://www.npmjs.com/package/file-icons-js' },
  { name: 'i18next', url: 'https://www.npmjs.com/package/i18next' },
  { name: 'idle-vue', url: 'https://www.npmjs.com/package/idle-vue' },
  { name: 'path', url: 'https://www.npmjs.com/package/path' },
  {
    name: 'roboto-fontface',
    url: 'https://www.npmjs.com/package/roboto-fontface',
  },
  { name: 'sass', url: 'https://www.npmjs.com/package/sass' },
  { name: 'sass-loader', url: 'https://www.npmjs.com/package/sass-loader' },
  { name: 'tus-js-client', url: 'https://www.npmjs.com/package/tus-js-client' },
  { name: 'vue-date-pick', url: 'https://www.npmjs.com/package/vue-date-pick' },
  { name: 'vue-router', url: 'https://www.npmjs.com/package/vue-router' },
  { name: 'vue-tel-input', url: 'https://www.npmjs.com/package/vue-tel-input' },
  { name: 'vuejs-dialog', url: 'https://www.npmjs.com/package/vuejs-dialog' },
  { name: 'vuex', url: 'https://www.npmjs.com/package/vuex' },
  { name: 'eslint', url: 'https://www.npmjs.com/package/eslint' },
  { name: 'babel-eslint', url: 'https://www.npmjs.com/package/babel-eslint' },
  { name: 'babel', url: 'https://www.npmjs.com/package/babel' },
]
