<template>
  <div class="mx-4 d-flex align-center my-4">
    <span class="mx-1 mt-1"><b>Slogan: </b></span>
    <v-text-field
      v-model="kioskSettings.design.slogan"
      class="mw-400 mx-1"
      dense
      height="20px"
      hide-details
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'Slogan',
  props: {
    kioskSettings: Object,
  },
}
</script>
