<template>
  <div v-if="kioskSettings.filtering.isAutoSelectFiles" class="ml-4 mr-2 my-2">
    <div class="wrapper-1 pa-2 mt-1 w-250p">
      <span class="fs-1-12r my-1"> Files and extentions names: </span>
      <div class="py-2 list-wrapper">
        <div
          v-for="(f, i) of this.kioskSettings.filtering.autoFileNames"
          :key="i"
          class="d-flex px-2"
        >
          <v-text-field
            v-model="f.name"
            hide-details
            dense
            height="22px"
            class="ma-0 pa-0 mx-2"
          >
          </v-text-field>
          <span>.</span>
          <v-text-field
            v-model="f.extension"
            hide-details
            dense
            height="22px"
            class="mw-60 ma-0 pa-0 mx-2"
            maxlength="6"
          >
          </v-text-field>

          <v-icon @click="deleteFileExt(i)" small class="mt-06"
            >mdi-delete</v-icon
          >
        </div>
      </div>

      <div class="d-flex justify-end mt-2">
        <v-icon
          @click="
            kioskSettings.filtering.autoFileNames.push({
              name: '',
              extention: '',
            })
          "
          >mdi-plus</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoSelectFiles',
  data() {
    return {
      filenamesArr: [],
    }
  },
  props: {
    kioskSettings: Object,
  },

  methods: {
    deleteFileExt(idx) {
      this.kioskSettings.filtering.autoFileNames.splice(idx, 1)
    },
  },
}
</script>

<style scoped>
.mt-06 {
  margin-top: 6px;
}

.w-130 {
  width: 130px;
}

.list-wrapper {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
