<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :content-class="isDarkMode ? 'dark-mode' : ''"
    style="z-index: 5001"
  >
    <v-card class="bg-white">
      <v-card-title class="text-h5 bg-grey">
        {{ $t('Inactivity') }}
        <v-spacer></v-spacer>
        <v-icon large>mdi-alert</v-icon>
      </v-card-title>

      <v-card-text class="fs-1-25r my-3 font-weight-regular" tabindex="0">
        {{ $t('The system will disconnect in') }}: {{ counterDown || 60 }}
        {{ $t('seconds') }}.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="ma-0">
        <v-spacer></v-spacer>
        <Button :text="$t('Cancel')" :clickAction="closeDialog" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

//Base Components
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'InactiveDialog',
  data() {
    return {
      dialog: false,
      lastTimeMouseMoved: 0,
      inactivityStartedDate: null,
      intervalCheckForInactivity: null,
      counterDown: null,
      countingDownInterval: null,
    }
  },
  components: { Button },

  watch: {
    isLoggedIn(n) {
      //if the user logged in start runnning the check
      if (n === true) this.checkDateStatus()
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isInMiddleOfFiltering', 'isDarkMode']),
  },
  methods: {
    ...mapActions(['LOG_OUT']),
    closeDialog() {
      clearInterval(this.countingDownInterval)
      this.countingDownInterval = null
      this.inactivityStartedDate = null
      this.counterDown = null
      this.dialog = false
    },

    startCountingDown() {
      //this will run when start counting down from 60
      this.countingDownInterval = setInterval(() => {
        const currentTime = Date.now()
        const time = currentTime - this.inactivityStartedDate - 1000

        this.counterDown = Math.floor(60 - time / 1000)

        if (this.counterDown < 1) this.runLogOut()
      }, 1000)
    },

    runLogOut() {
      clearInterval(this.intervalCheckForInactivity)
      clearInterval(this.countingDownInterval)
      this.intervalCheckForInactivity = null
      this.countingDownInterval = null
      this.inactivityStartedDate = null
      this.counterDown = null
      this.dialog = false
      this.LOG_OUT()
    },

    mouseMoved() {
      this.lastTimeMouseMoved = Date.now()
    },

    checkDateStatus() {
      this.intervalCheckForInactivity = setInterval(() => {
        if (!this.isLoggedIn) return
        if (this.isInMiddleOfFiltering) return
        if (this.$route.fullPath === '/management/analytics') return
        const currentTime = Date.now()

        if (currentTime >= this.lastTimeMouseMoved + 600000) {
          this.dialog = true
          if (!this.inactivityStartedDate) {
            this.inactivityStartedDate = Date.now()
            this.startCountingDown()
          }
        }
      }, 1000) // Check every second (1000 milliseconds)
    },
  },

  mounted() {
    document.addEventListener('click', this.mouseMoved)
    document.addEventListener('mousemove', this.mouseMoved)
    document.addEventListener('keydown', this.mouseMoved)
    this.mouseMoved()
  },
  beforeUnmount() {
    document.removeEventListener('click', this.mouseMoved)
    document.removeEventListener('mousemove', this.mouseMoved)
    document.removeEventListener('keydown', this.mouseMoved)
  },
}
</script>
