import store from '@/store'
import { api } from '@/config'

export const newPolicyRules = v => {
  return [
    v => {
      if (!v.trim()) return 'Required'
      return true
    },
    v => {
      if (store.getters.policies.includes(v)) return 'Policy name already exist'
      return true
    },
  ]
}

export async function renamePolicy(orgId = 0, data) {
  const res = await api.patch(`/organizations/${orgId}/renamepolicy`, data)
  if (res.status !== 200) {
    throw new Error('Cant fetch')
  }
  return res
}



