<template>
  <div
    class="pos-relative"
    :class="[type === 'tablet' && 'fill-width fill-height']"
  >
    <div
      :class="[
        isDrag && 'drag-files',
        type === 'tablet' && 'drop-files-tablet',
      ]"
      @click.prevent="openSelectFiles"
      class="drop-files-wrapper pointer d-flex flex-column justify-center align-center"
    >
      <img
        class="my-1"
        height="60px"
        src="@/assets/upload-files-blue.svg"
        alt="Drop files here"
      />

      <h2 class="my-2 fs-1-5r">{{ $t('Drag & Drop Files Here') }}</h2>
    </div>

    <input
      @change="inputChanged"
      type="file"
      class="d-none"
      multiple
      ref="fileInput"
    />
  </div>
</template>

<script>
import { fileAddedFromInput } from '@/components/WebsiteInterface/uploadingFunctions'

export default {
  name: 'DropFiles',
  props: {
    session: Object,
    type: String,
    isDrag: Boolean,
  },
  methods: {
    openSelectFiles() {
      this.$refs.fileInput.click()
    },

    inputChanged(e) {
      fileAddedFromInput(this, e)
    },
  },
}
</script>

<style scoped src="./DropFiles.css"></style>
