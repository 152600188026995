<template>
  <div class="my-4">
    <h4 class="settings-h4">Licenses</h4>
    <div class="wrapper-1 pa-2">
      <!--KIOSK USAGE-->
      <div class="d-flex">
        <div class="mx-2" style="width: 150px">
          <b>Usage:</b>

          {{ numOfActiveKiosks }} /
          {{ kioskLicense.numOfAllowed }}
        </div>

        <!--SHOW / HIDE -->
        <Button
          :text="isShowKiosks ? 'Hide Kiosks List' : 'Show Kiosks List'"
          :icon="isShowKiosks ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          btnType="grey"
          class="mx-2"
          :clickAction="() => (isShowKiosks = !isShowKiosks)"
        />
      </div>

      <!--KIOSKS-->
      <div v-show="isShowKiosks" class="my-2">
        <!--KIOSKS LIST-->
        <KioskLicense
          v-for="(kiosk, i) of kioskSettings.licenses"
          :i="i"
          :key="i"
          :kiosk="kiosk"
          :computedUsers="computedUsers"
          :deleteKioskClicked="deleteKioskClicked"
        />

        <!--ADD KISOK BUTTON-->
        <div class="d-flex justify-end fill-width">
          <Button :clickAction="addKiosk" text="Add Kiosk" icon="mdi-plus" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Children
import KioskLicense from './Children/KioskLicense/KioskLicense.vue'

export default {
  name: 'Licenses',
  data() {
    return {
      isShowKiosks: false,
    }
  },
  props: {
    kioskSettings: Object,
    kioskLicense: Object,
    numOfActiveKiosks: Number,
  },

  components: { KioskLicense, Button },

  computed: {
    ...mapGetters(['users']),
    computedUsers() {
      return this.users
        .filter(user => user.status === 0 && user.license === 'SFTP')
        .sort((a, b) => {
          if (a.email.toLowerCase() < b.email.toLowerCase()) return -1
          if (a.email.toLowerCase() > b.email.toLowerCase()) return 1
          return 0
        })
    },
  },
  methods: {
    addKiosk() {
      this.kioskSettings.licenses.push({
        kioskId: '',
        nickname: '',
        recipients: [],
      })

      this.kioskLicense.numOfInUse++
    },
    deleteKioskClicked(kiosk, i) {
      //if the fields are empty
      if (!kiosk.kioskId && !kiosk.nickname) return this.deleteKiosk(i)
      //if not
      this.$dialog
        .confirm(
          `<p class="message-class">Are you sure you want to delete this kiosk?</p>`,
          {
            okText: 'Delete',
            cancelText: "Cancel",
            animation: 'fade',
            customClass: 'message-class',
            html: true,
          }
        )
        .then(() => {
          this.deleteKiosk(i)
        })
        .catch(e => e)
    },
    deleteKiosk(i) {
      this.kioskSettings.licenses = this.kioskSettings.licenses
        .map((el, num) => {
          if (num === i) return
          return el
        })
        .filter(el => el !== undefined)
    },
  },
}
</script>

<style scoped src="./Licenses.css"></style>
