<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4" :class="[actionType === 'add' && 'required-field']">
      Volumes
    </h4>
    <div class="wrapper-2 pa-2 pos-relative light-text">
      <!--PER FILE-->
      <div class="volume-row">
        <v-checkbox
          v-model="checkboxes.isFileSizeActive"
          :disabled="isInputDisabled"
          hide-details
          class="ma-0 pa-0"
        ></v-checkbox>
        <span
          >File size restriction (Per file){{
            checkboxes.isFileSizeActive ? ':' : ''
          }}</span
        >
        <div v-if="checkboxes.isFileSizeActive" class="d-flex">
          <v-text-field
            v-model.number="localLimits.localMaxSingleFileSize"
            @input="inputChanged('maxSingleFileSize', $event)"
            :disabled="isInputDisabled"
            hide-details
            height="20px"
            type="number"
            dense
            class="input-field centered-input"
          ></v-text-field>
          <span class="mx-2">GB</span>
        </div>
      </div>

      <!--DAILY-->
      <div class="volume-row">
        <v-checkbox
          v-model="checkboxes.isDayFileSizeActive"
          :disabled="isInputDisabled"
          hide-details
          class="ma-0 pa-0"
        ></v-checkbox>
        <span
          >File(s) size restriction (Daily){{
            checkboxes.isDayFileSizeActive ? ':' : ''
          }}</span
        >

        <div v-if="checkboxes.isDayFileSizeActive" class="d-flex">
          <v-text-field
            v-model.number="localLimits.localMaxSizePerDay"
            @input="inputChanged('maxSizePerDay', $event)"
            :disabled="isInputDisabled"
            hide-details
            type="number"
            height="20px"
            dense
            class="input-field centered-input"
          ></v-text-field>
          <span class="mx-2">GB</span>
        </div>
      </div>

      <!--MONTHLY-->
      <div class="volume-row">
        <v-checkbox
          v-model="checkboxes.isMonthFileSizeActive"
          :disabled="isInputDisabled"
          hide-details
          class="ma-0 pa-0"
        ></v-checkbox>

        <span
          >File(s) size restriction (Monthly){{
            checkboxes.isMonthFileSizeActive ? ':' : ''
          }}</span
        >
        <div v-if="checkboxes.isMonthFileSizeActive" class="d-flex">
          <v-text-field
            v-model.number="localLimits.localMaxSizePerMonth"
            @input="inputChanged('maxSizePerMonth', $event)"
            :disabled="isInputDisabled"
            hide-details
            height="20px"
            dense
            type="number"
            class="input-field centered-input"
          ></v-text-field>
          <span class="mx-2">GB</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Volumes',
  data() {
    return {
      // localMaxSingleFileSize: 0,
      // localMaxSizePerDay: 0,
      // localMaxSizePerMonth: 0,
      // isFileSizeActive: null,
      // isDayFileSizeActive: null,
      // isMonthFileSizeActive: null,
    }
  },
  props: {
    organization: Object,
    actionType: String,
    checkboxes:Object,
    localLimits: Object,
  },

  // watch: {
    

  //   //restart values
  //   localMaxSingleFileSize(n) {
  //     // if (n === 0) this.isFileSizeActive = false
  //     if (n === '') this.organization.sizeLimits.maxSingleFileSize = 0
  //   },

  //   localMaxSizePerDay(n) {
  //     // if (n === 0) this.isDayFileSizeActive = false
  //     if (n === '') this.organization.sizeLimits.maxSizePerDay = 0
  //   },

  //   localMaxSizePerMonth(n) {
  //     // if (n === 0) this.isMonthFileSizeActive = false
  //     if (n === '') this.organization.sizeLimits.maxSizePerMonth = 0
  //   },
  // },

  computed: {
    ...mapGetters(['loggedUser']),
    isInputDisabled() {
      if (this.organization.status !== 0) return true
      if (this.loggedUser.role !== 'SuperAdmin') return true
      return false
    },
  },

  methods: {
    inputChanged(inputType, val) {
      //make val as gb
      let nVal = val * 1024 * 1024 * 1024
      this.organization.sizeLimits[inputType] = nVal
    },
  },

  mounted() {
    //single file size
    if (this.organization.sizeLimits.maxSingleFileSize)
      this.checkboxes.isFileSizeActive = true
    else this.checkboxes.isFileSizeActive = false

    this.localLimits.localMaxSingleFileSize =
      Math.round(
        (this.organization.sizeLimits.maxSingleFileSize /
          (1024 * 1024 * 1024)) *
          1000
      ) / 1000
    //daily files sizes
    if (this.organization.sizeLimits.maxSizePerDay)
      this.checkboxes.isDayFileSizeActive = true
    else this.checkboxes.isDayFileSizeActive = false

    this.localLimits.localMaxSizePerDay =
      Math.round(
        (this.organization.sizeLimits.maxSizePerDay / (1024 * 1024 * 1024)) *
          1000
      ) / 1000

    //monthly files sizes
    if (this.organization.sizeLimits.maxSizePerMonth)
      this.checkboxes.isMonthFileSizeActive = true
    else this.checkboxes.isMonthFileSizeActive = false

    this.localLimits.localMaxSizePerMonth =
      Math.round(
        (this.organization.sizeLimits.maxSizePerMonth / (1024 * 1024 * 1024)) *
          1000
      ) / 1000
  },
}
</script>

<style scoped lang="scss">
.volume-row {
  display: flex;
  align-items: center;
  margin: 12px 8px;
  span {
    width: 235px;
  }

  .input-field {
    max-width: 120px;
    margin: 0;
    padding: 0;
  }
}
</style>
