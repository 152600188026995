<template>
  <div class="wrapper-2 bg-white py-1 px-2 mt-2 d-flex justify-space-between">
    <div class="d-flex">
      <!--CHANGE THE ROUTE STATUS-->
      <h3 class="light-text my-auto mr-3">Route Status:</h3>
      <v-radio-group
        v-model="route.status"
        :disabled="
          freezedRoute.status === 2 || currentOrganization.status !== 0
        "
        dense
        hide-details
        class="ma-0"
        row
      >
        <v-radio class="ma-0 mr-4" label="Active" :value="0"> </v-radio>
        <v-radio class="ma-0 mr-4" label="Blocked (Temporary)" :value="1">
        </v-radio>
      </v-radio-group>
    </div>

    <!--DELETE ROUTE -->
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          class="my-auto mr-8"
          v-on="on"
          @click="removeRoute"
          x-small
          rounded
          depressed
          outlined
          fab
          color="error"
        >
          <v-icon dark>mdi-delete</v-icon>
        </v-btn>
      </template>
      <span>Delete route</span>
    </v-tooltip>
    <OnlineHelp page="routes" section="routeStatus" title="Route Status" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { confirmDialog } from '@/utils'

//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'RouteStatus',
  props: {
    freezedRoute: Object,
    route: Object,
    setSaveButtonClicked: Function,
  },
  computed: {
    ...mapGetters(['currentOrganization']),
  },
  methods: {
    ...mapActions(['REMOVE_ROUTE', 'GET_ROUTES']),
    ...mapMutations(['SET_PROCESSING']),
    removeRoute() {
      const text = 'Are you sure you want to delete this route?'

      const thenFunc = async () => {
        this.SET_PROCESSING(true)
        await this.REMOVE_ROUTE(this.route.routeId)
        await this.GET_ROUTES({ id: this.currentOrganization.organizationId })
        this.setSaveButtonClicked(true)
        this.SET_PROCESSING(false)
        this.$router.go(-1)
      }

      const catchFunc = () => this.SET_PROCESSING(false)
      confirmDialog(this, text, 'Delete', 'Cancel', thenFunc, catchFunc)
    },
  },
  components: { OnlineHelp },
}
</script>
