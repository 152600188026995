export const usersListHeaders = [
  {
    text: 'Username',
    class: 'bg-table-heading table-header-first',
    value: 'email',
    width: '20%',
  },
  {
    text: 'Name',
    value: 'name',
    class: 'bg-table-heading',
    width: '15%',
  },
  {
    text: 'Account Type',
    value: 'role',
    align: 'center',
    class: 'bg-table-heading',
    width: '10%',
    sort: (a, b) => {
      //HostAdmin not admin
      //Application
      //Approver
      //Person - not user
      //Person (Guest) - not guestuer
      //Individual
      //'SuperAdmin'

      let nA = 0
      let nB = 0

      if (a === 'Application') nA = 0
      else if (a === 'Approver') nA = 1
      else if (a === 'Host Admin') nA = 2
      else if (a === 'Individual') nA = 3
      else if (a === 'User') nA = 4
      else if (a === 'GuestUser') nA = 5
      else if (a === 'Super Admin') nA = 6

      if (b === 'Application') nB = 0
      else if (b === 'Approver') nB = 1
      else if (b === 'Host Admin') nB = 2
      else if (b === 'Individual') nB = 3
      else if (b === 'User') nB = 4
      else if (b === 'Guest User') nB = 5
      else if (b === 'Super Admin') nB = 6

      return nA - nB
    },
  },

  {
    text: 'Status',
    value: 'status',
    align: 'center',
    width: '10%',
    class: 'bg-table-heading',
  },
  {
    text: 'License',
    value: 'license',
    align: 'center',
    width: '10%',
    class: 'bg-table-heading',
  },
  {
    text: 'Host',
    value: 'organizationId',
    width: '10%',
    class: 'bg-table-heading',
  },
  {
    text: 'Guests',
    value: 'isGuests',
    align: 'center',
    width: '4%',
    class: 'bg-table-heading',
  },
  {
    text: 'S/N',
    value: 'fakeID',
    align: 'center',
    width: '8%',
    class: 'bg-table-heading',
  },
  {
    text: '',
    value: 'mdi-pencil-circle-outline',
    icon: 'mdi-pencil-circle-outline',
    sortable: false,
    width: '6%',
    class: 'bg-table-heading table-header-last',
  },
]
