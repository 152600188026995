<template>
  <div class="fill-height fill-width" id="filtering-summary">
    <div v-if="idx" class="d-flex justify-space-between">
      <!--TITLE-->
      <h2>{{ idx }}. Files Summary</h2>

      <!--EXPAND-->
      <v-icon @click="expandClicked('FilteringSummary')"
        >mdi-arrow-expand</v-icon
      >
    </div>

    <div
      class="d-flex justify-center align-center pa-2"
      style="height: calc(100% - 20px)"
    >
      <!--NUMBERS-->
      <Summary
        class="left-side-statistics"
        :statusResults="analyticsData.files"
      />
      <div class="squares-wrapper">
        <div class="square" style="background-color: #41b883"></div>

        <div class="square" style="background-color: #ffa500"></div>

        <div class="square" style="background-color: #ff7b52"></div>

        <div class="square" style="background-color: #ff0000"></div>
      </div>

      <div class="mx-4"></div>
      <!--PIE CHART-->
      <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
        class="fill-height min-w-200"
      />
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import Summary from '@/components/BaseComponents/Summary/Summary.vue'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'FilteringSummary',
  data() {
    return {
      chartData: {
        labels: ['Intact', 'Modified', 'Partial', 'Rejected'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#FFA500', '#FF7B52', '#FF0000'],
            data: [],
          },
        ],
      },
      chartOptions: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              afterLabel: function (context) {
                let sum = 0
                const val = context.dataset.data[+context.dataIndex]
                context.dataset.data.forEach(el => (sum += el))

                return `${((val * 100) / sum).toFixed(2)}%`
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  components: {
    Pie,
    Summary,
  },
  props: {
    analyticsData: Object,
    idx: Number,
    expandClicked: Function,
  },
  created() {
    const labelsArr = ['intact', 'modified', 'partial', 'rejected']
    const reportsData = []
    for (let label of labelsArr) {
      reportsData.push(this.analyticsData.files[label])
    }
    this.chartData.datasets[0].data = reportsData
  },
}
</script>

<style scoped>
.squares-wrapper {
  margin-top: 22px;
}
.square {
  width: 50px;
  height: 16px;
  margin: 9px 0;
  border: 2px solid #ffffff;
}
</style>
