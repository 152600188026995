export const hostTableHeaders =  [
    {
      text: 'Logo',
      value: 'logoUrl',
      sortable: false,
      class: 'bg-table-heading table-header-first',
      width: '25.6%',
      align: 'center',
    },
    {
      text: 'Name',
      value: 'name',
      class: 'bg-table-heading ',
      width: '38.5%',
    },
    {
      text: 'Status',
      value: 'status',
      class: 'bg-table-heading ',
      width: '20%',
    },
    {
      text: 'S/N',
      value: 'fakeID',
      class: 'bg-table-heading ',
      width: '10.3%',
    },
    {
      text: 'Edit',
      value: '',
      sortable: false,
      class: 'bg-table-heading table-header-last',
      width: '20px',
    },
  ]