<template>
  <!--WHICH FILES-->
  <div v-if="kioskSettings.filtering.isAutoSelectFiles" class="ml-4 mr-2 my-2">
    <div class="wrapper-1 pa-2 mt-1 w-130">
      <span class="fs-1-12r my-1"> Extensions: </span>
      <div class="py-2 list-wrapper">
        <div v-for="(f, i) of filesArr" :key="i" class="d-flex px-2">
          <span>*.</span>
          <v-text-field
            v-model="f.name"
            hide-details
            dense
            height="22px"
            class="mw-60 ma-0 pa-0 mx-2 centered-input"
            maxlength="6"
          >
          </v-text-field>

          <v-icon @click="deleteFileExt(i)" small class="mt-06"
            >mdi-delete</v-icon
          >
        </div>
      </div>

      <div class="d-flex justify-end mt-2">
        <v-icon @click="filesArr.push({ name: '' })">mdi-plus</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoSelectFiles',
  data() {
    return {
      filesArr: [],
    }
  },
  props: {
    kioskSettings: Object,
  },

  watch: {
    filesArr: {
      handler: function (n) {
        this.kioskSettings.filtering.autoFileTypes = this.filesArr.map(f =>
          f.name.replace('.', '')
        )
      },
      deep: true,
    },
  },

  methods: {
    deleteFileExt(idx) {
      this.filesArr.splice(idx, 1)
    },
  },

  created() {
    if (this.kioskSettings.filtering.autoFileTypes)
      this.filesArr = this.kioskSettings.filtering.autoFileTypes.map(el => ({
        name: el,
      }))
    else this.filesArr = []
    // this.filesArr = [{ name: 'png' }, { name: 'abc' }, { name: 'ai' }]
  },
}
</script>

<style scoped>
.mt-06 {
  margin-top: 6px;
}

.w-130 {
  width: 130px;
}

.list-wrapper {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
