var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column edit-details",class:['edit-details-org']},[(
      _vm.parentObj.createUpdateDetails.createdByName &&
      _vm.parentObj.createUpdateDetails.dateCreatedByMs
    )?_c('span',{staticClass:"label f-14"},[_vm._v("Created by "+_vm._s(_vm.computedCreatedBy)+" at "+_vm._s(_vm.computedCreatedAt))]):_vm._e(),(
      _vm.parentObj.createUpdateDetails.updatedByName &&
      _vm.parentObj.createUpdateDetails.dateUpdatedByMs
    )?_c('span',{staticClass:"label f-14"},[_vm._v("Updated by "+_vm._s(_vm.computedUpdatedBy)+" "+_vm._s(_vm.computedUpdatedAt)+" ago ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }