<template>
  <div class="pos-relative">
    <v-icon
      @click="isShowSearchBar = !isShowSearchBar"
      class="mx-2"
      x-large
      :aria-label="`${isShowSearchBar ? 'close' : 'open'} Directory search`"
      tabindex="0"
      >mdi-magnify</v-icon
    >

    <div v-if="isShowSearchBar" class="search-input">
      <v-text-field
        @input="setDirSearch"
        clearable
        hide-details
        class="wrapper-1 bg-white pa-2"
        placeholder="Table search"
        autofocus
        style="font-size: 22px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FolderSearch',
  data() {
    return {
      isShowSearchBar: false,
    }
  },
  props: {
    setDirSearch: Function,
    shownDirectory: Object,
  },
  watch: {
    shownDirectory: {
      handler: function (n) {
        this.isShowSearchBar = false
        this.setDirSearch(null)
      },
      deep: true,
    },
  },
}
</script>

<style scoped src="./FolderSearch.css"></style>
