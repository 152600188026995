<template>
  <tr>
    <td>
      <span :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']">{{
        interFace.shown
      }}</span>
    </td>
    <!--INPUT TO CHOOSE LICENSES -->
    <td>
      <span class="d-flex justify-center align-center my-auto ">
        <p
          class="mr-2 my-0"
          :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']"
        >
          {{ getProtocols(interFace.value) }}
        </p>
        <span :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']"
          >/</span
        >
        <v-text-field
          :disabled="
            loggedUser.role !== 'SuperAdmin' || organization.status !== 0
          "
          class="numOfAllowedInput mb-3"
          hide-details
          @change="numOfAllowedChange($event, interFace.value)"
          height="16px"
          type="number"
          :rules="[
            protocolNumberRules.isPositive,
            protocolNumberRules.required,
            protocolNumberRules.isInt,
            protocolNumberRules.isMoreThanUsed(interFace.value),
          ]"
          validate-on-blur
          :value="getProtocols(interFace.value, true)"
        ></v-text-field>
      </span>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Licenses',
  props: {
    interFace: Object,
    protocolNumberRules: Object,
    getProtocols: Function,
    numOfAllowedChange: Function,
    organization: Object,
  },
  computed: {
    ...mapGetters(['loggedUser']),
  },
}
</script>

<style scoped src="../OrgDefinitionsChildrens.css"></style>
