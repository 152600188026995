<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Host Admins Notifications By Email</h4>
    <div class="wrapper-2 pa-2 pos-relative">
      <!--NOTIFICATIONS FOR HOST -->
      <div
        class="d-flex my-1 mx-3"
        v-for="(item, index) in computedAdminAlerts"
        :key="index"
      >
        <v-checkbox
          v-model="notificationsSet[item.model]"
          :label="item.description"
          :disabled="status !== 0"
          class="pa-0 ma-0"
          hide-details
        ></v-checkbox>
      </div>
      <OnlineHelp
        page="settings"
        section="accountNotifications"
        title="Account Notifications"
      />
    </div>
  </div>
</template>

<script>
//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

//Component files
import { adminAlerts } from './NotificationsSetDescriptions'
import { mapGetters } from 'vuex'

export default {
  name: 'AdminAlertSettings',
  data() {
    return {
      adminAlerts,
    }
  },
  props: {
    notificationsSet: Object,
    status: Number,
  },
  components: { OnlineHelp },
  computed: {
    ...mapGetters(['currentOrganization']),
    computedAdminAlerts() {
      const newArr = this.adminAlerts.map(el => {
        if (el.model === 'hostDeleted') {
          const description = `The host: "${this.currentOrganization.name}" was deleted`
          return {
            ...el,
            description,
          }
        }
        return el
      })

      return newArr
    },
  },
}
</script>
