<template>
  <div class="pa-2">
    <h4 class="settings-h4">
      {{ isBackupSettings ? 'Backup ' : '' }}Mail Gateway
    </h4>

    <div class="wrapper-2 pa-4 mb-4 pos-relative">
      <div class="d-flex align-center">
        <!--DISABLE / ENABLE -->
        <v-tooltip right :open-on-hover="organization.status === 0">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-simple-checkbox
                class="d-inline mr-2"
                v-model="isSettingsEnabled"
                :disabled="organization.status !== 0"
              ></v-simple-checkbox>
            </span>
          </template>
          <span>{{ isSettingsEnabled ? 'Disable' : 'Enable' }} updating</span>
        </v-tooltip>

        <InputsSenderVendor
          :organization="organization"
          :isSettingsEnabled="isSettingsEnabled"
          :isBackupSettings="isBackupSettings"
          :actionType="actionType"
          :isGlobalSettings="isGlobalSettings"
        />
      </div>

      <!--IF THE CONNECTION IS SMTP-->
      <SMTP
        v-if="computedIsShowSMTP"
        :organization="organization"
        :isBackupSettings="isBackupSettings"
        :isSettingsEnabled="isSettingsEnabled"
      />

      <!--IF THE CONNEECTION IS GRAPH-->
      <MsGraph
        v-if="computedIsShowMsGraph"
        :organization="organization"
        :isSettingsEnabled="isSettingsEnabled"
        :isBackupSettings="isBackupSettings"
        class="mb-3"
      />

      <!--IF THIS IS SUPER ADMIN-->
      <div v-if="isShowSuperAdminEmailMesssage" class="mb-2">
        Super admin cannot see the host's custom email settings.
      </div>

      <!-- EMAIL TESTING -->
      <TestEmail
        v-if="computedIsShowTestEmail"
        :organization="organization"
        :isSettingsEnabled="isSettingsEnabled"
        :isBackupSettings="isBackupSettings"
        :setIsCheckSmtpChange="setIsCheckSmtpChange"
        :setIsCheckBackupSmtpChange="setIsCheckBackupSmtpChange"
        class="mb-6"
      />
      <OnlineHelp
        :page="isGlobalSettings ? 'globalSettings' : 'settings'"
        :section="isBackupSettings ? 'backupSMTPSettings' : 'SMTPSettings'"
        :title="isBackupSettings ? 'Backup Mail Gateway' : 'Mail Gateway'"
      />
    </div>
  </div>
</template>

<script>
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'
import { mapGetters } from 'vuex'
import TestEmail from '../TestEmail/TestEmail.vue'

//Childrens
import InputsSenderVendor from './Childrens/InputsSenderVendor/InputsSenderVendor.vue'
import MsGraph from './Childrens/MsGraph/MsGraph.vue'
import SMTP from './Childrens/SMTP/SMTP.vue'

export default {
  name: 'EmailSettings',
  data() {
    return {
      isSettingsEnabled: false,
    }
  },
  props: {
    organization: Object,
    actionType: String,
    isBackupSettings: Boolean,
    setIsCheckSmtpChange: Function,
    setIsCheckBackupSmtpChange: Function,
    isGlobalSettings: {
      type: Boolean,
      default: false,
    },
  },

  components: { InputsSenderVendor, SMTP, MsGraph, TestEmail, OnlineHelp },

  computed: {
    ...mapGetters(['loggedUser']),
    isShowSuperAdminEmailMesssage() {
      //host admins
      if (this.loggedUser.role === 'Admin') return false

      //super admin
      if (this.loggedUser.role === 'SuperAdmin' && this.isGlobalSettings)
        return false
      else if (
        this.loggedUser.role === 'SuperAdmin' &&
        !this.isGlobalSettings
      ) {
        //regular - not backup
        if (
          !this.isBackupSettings &&
          this.organization.emailDefinitions.emailProtocol === 'Default'
        )
          return false
        //backup
        if (
          this.isBackupSettings &&
          this.organization.backupEmailDefinitions.emailProtocol === 'Default'
        )
          return false
      }
      return true
    },
    computedIsShowSMTP() {
      //if the user is super admin in regular host
      if (this.loggedUser.role === 'SuperAdmin' && !this.isGlobalSettings)
        return false

      //if show the backup email settings
      if (this.isBackupSettings) {
        //if the protocol is smtp
        if (this.organization.backupEmailDefinitions.emailProtocol === 'SMTP')
          return true
        //if it is microsoft graph
        return false
      }

      //is show the main email settings
      else {
        //if the email protocol is smtp
        if (this.organization.emailDefinitions.emailProtocol === 'SMTP')
          return true
        //if it is microsoft graph
        return false
      }
    },

    computedIsShowMsGraph() {
      //if the user is super admin in regular host
      if (this.loggedUser.role === 'SuperAdmin' && !this.isGlobalSettings)
        return false

      //if show the backup email settings
      if (this.isBackupSettings) {
        //if the protocol is smtp
        if (
          this.organization.backupEmailDefinitions.emailProtocol ===
          'Microsoft Graph'
        )
          return true
        //if it is microsoft graph
        return false
      }

      //is show the main email settings
      else {
        //if the email protocol is smtp
        if (
          this.organization.emailDefinitions.emailProtocol === 'Microsoft Graph'
        )
          return true
        //if it is microsoft graph
        return false
      }
    },

    computedIsShowTestEmail() {
      //for backup email
      if (this.isBackupSettings) {
        return (
          this.organization.backupEmailDefinitions.emailProtocol !== 'Default'
        )
      }

      //for regular email
      return this.organization.emailDefinitions.emailProtocol !== 'Default'
    },
  },
}
</script>
