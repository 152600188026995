var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"my-2",class:[_vm.loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']},[_vm._v("Website")]),_c('span',{staticClass:"ml-4 my-2",class:[_vm.loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']},[_vm._v("Registered")]),_c('span',{staticClass:"ml-4 my-2",class:[_vm.loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']},[_vm._v("Guest")])])]),_c('td',[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"my-5"}),_c('span',{staticClass:"d-flex justify-center align-center my-auto"},[_c('p',{staticClass:"mr-2 my-0",class:[_vm.loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']},[_vm._v(" "+_vm._s(_vm.getProtocols('Website'))+" ")]),_c('span',{class:[_vm.loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']},[_vm._v("/")]),_c('v-text-field',{staticClass:"numOfAllowedInput mb-3",attrs:{"disabled":_vm.loggedUser.role !== 'SuperAdmin' || _vm.organization.status !== 0,"hide-details":"","height":"16px","type":"number","rules":[
            _vm.protocolNumberRules.isPositive,
            _vm.protocolNumberRules.required,
            _vm.protocolNumberRules.isInt,
            _vm.protocolNumberRules.isMoreThanUsed('Website'),
          ],"validate-on-blur":"","value":_vm.getProtocols('Website', true)},on:{"change":function($event){return _vm.numOfAllowedChange($event, 'Website')}}})],1),_c('span',{staticClass:"d-flex justify-center align-center my-auto"},[_c('p',{staticClass:"mr-2 my-0",class:[_vm.loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']},[_vm._v(" "+_vm._s(_vm.getProtocols('Casual'))+" ")]),_c('span',{class:[_vm.loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']},[_vm._v("/")]),_c('v-text-field',{staticClass:"numOfAllowedInput mb-3",attrs:{"disabled":_vm.loggedUser.role !== 'SuperAdmin' || _vm.organization.status !== 0,"hide-details":"","height":"16px","type":"number","rules":[
            _vm.protocolNumberRules.isPositive,
            _vm.protocolNumberRules.required,
            _vm.protocolNumberRules.isInt,
            _vm.protocolNumberRules.isMoreThanUsed('Casual'),
          ],"validate-on-blur":"","value":_vm.getProtocols('Casual', true)},on:{"change":function($event){return _vm.numOfAllowedChange($event, 'Casual')}}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }