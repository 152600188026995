<template>
  <div class="d-flex flex-column fill-height">
    <div v-if="idx" class="d-flex justify-space-between">
      <!--TITLE-->
      <h2>
        {{ idx }}. File Types <span class="caption">{{ parsedAxisTitle }}</span>
      </h2>

      <!--EXPAND-->
      <v-icon @click="expandClicked('FileTypes')">mdi-arrow-expand</v-icon>
    </div>
    <!--RADIO TO SELECT TYPE OF CHART-->
    <v-radio-group v-model="chartType" row hide-details class="mt-0 mb-2 mx-2">
      <v-radio value="files" label="Files counts" hide-details></v-radio>
      <v-radio value="bytes" label="Volumes" hide-details></v-radio>
    </v-radio-group>

    <v-checkbox
      v-model="sortByAbc"
      label="Sort alphabetically"
      class="mt-0 pt-0 mx-2"
      hide-details
      dense
    ></v-checkbox>

    <!--ON BIG SCREEN SHOW THE AXIS TITLES-->
    <span v-if="!idx" class="fs-1-12r my-1 mx-2 label">{{
      parsedAxisTitle
    }}</span>

    <div style="width: 100%; overflow: auto; height: calc(100% - 80px)">
      <Bar
        class="fill-height"
        :chart-options="computedChartOptions"
        :chart-data="computedData"
        :style="{ width: computeWidth }"
      />
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'
import { bytesToSize, megabytesToSize } from '@/utils'
import { mapGetters } from 'vuex'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'FileTypes',
  data() {
    return {
      sortByAbc: true,
      chartType: 'files',
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Total files',
            backgroundColor: '#a2d5ff',
            data: [],
          },
        ],
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: {},
        },
      },
    }
  },
  components: {
    Bar,
  },

  props: {
    analyticsData: Object,
    idx: Number,
    expandClicked: Function,
  },
  computed: {
    ...mapGetters(['isDarkMode']),
    parsedAxisTitle() {
      if (this.chartType === 'files') return '(Types / Files counts)'
      if (this.chartType === 'bytes') return '(Types / Volumes [MB])'
    },

    computedChartOptions() {
      //this will add colors to grids
      return {
        ...this.chartOptions,
        scales: {
          y: {
            ...this.chartOptions?.scales?.y,
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },

          x: {
            ...this.chartOptions?.scales?.x,
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
        },
      }
    },
    computedData() {
      const labelsArr = []
      const originalSizeArr = []
      const filteredSizeArr = []
      const filesCountArr = []
      let copyArr = []

      //if sort by abc
      if (this.sortByAbc) {
        copyArr = this.getABCSortedArray(
          JSON.parse(JSON.stringify(this.analyticsData.files.segmentation))
        )
      }

      //if sort by size
      else {
        //if showing size
        if (this.chartType === 'bytes')
          copyArr = this.getSizeSortedArray(
            JSON.parse(JSON.stringify(this.analyticsData.files.segmentation))
          )
        //if showing numbers
        else
          copyArr = this.getNumberSortedArray(
            JSON.parse(JSON.stringify(this.analyticsData.files.segmentation))
          )
      }

      copyArr.forEach(file => {
        originalSizeArr.push(file.totalOriginalSize / 1024 / 1024)
        filteredSizeArr.push(file.totalFilteredSize / 1024 / 1024)
        filesCountArr.push(file.totalFiles)
        labelsArr.push(file.type.toUpperCase())
      })

      this.chartData.labels = [...labelsArr]

      //if the user wants to see files count
      if (this.chartType === 'files') {
        this.chartOptions.plugins.tooltip.callbacks = {}
        // this.chartOptions.scales = {
        //   y: {
        //     title: { display: true, text: 'Files count' },
        //   },
        // }
        return {
          ...this.chartData,
          datasets: [
            {
              data: [...filesCountArr],
              label: 'Total files',
              backgroundColor: '#a2d5ff',
            },
          ],
        }
      }

      //if the user want to see files sizes
      else {
        this.chartOptions.plugins.tooltip.callbacks = {
          label: function (context) {
            const foundObj = context.dataset.data[+context.dataIndex]
            return `${context.dataset.label}: ${megabytesToSize(foundObj)}`
          },
        }

        // this.chartOptions.scales = {
        //   y: {
        //     title: { display: true, text: 'Megabytes' },
        //   },
        // }

        return {
          ...this.chartData,
          datasets: [
            {
              data: [...originalSizeArr],
              label: 'Original size',
              backgroundColor: '#a2d5ff',
            },
            {
              data: [...filteredSizeArr],
              label: 'Filtered size',
              backgroundColor: '#e3e3e3',
            },
          ],
        }
      }
    },
    computeWidth() {
      const { length } = this.computedData.datasets[0].data

      if (length < 10) return '100%'
      else if (length < 15) return '150%'
      else if (length < 20) return '200%'
      else return '300%'
    },
  },

  methods: {
    getABCSortedArray(arr) {
      return arr.sort((a, b) => {
        {
          if (a.type.toLowerCase() < b.type.toLowerCase()) return -1
          else if (a.type.toLowerCase() > b.type.toLowerCase()) return 1
          else return 0
        }
      })
    },
    getSizeSortedArray(arr) {
      return arr.sort((a, b) => {
        {
          if (a.totalOriginalSize > b.totalOriginalSize) return -1
          else if (a.totalOriginalSize < b.totalOriginalSize) return 1
          else return 0
        }
      })
    },

    getNumberSortedArray(arr) {
      return arr.sort((a, b) => {
        {
          if (a.totalFiles > b.totalFiles) return -1
          else if (a.totalFiles < b.totalFiles) return 1
          else return 0
        }
      })
    },
  },
}
</script>
