export const analyticsResFake = {
  files: {
    //FOR FILES COUNT
    total: 207,
    intact: 103,
    modified: 64,
    partial: 0,
    rejected: 40,

    //FOR FILE TYPES DATA
    segmentation: [
      {
        totalOriginalSize: 199520,
        totalFilteredSize: 199728,
        totalFiles: 16,
        type: 'html',
      },
      {
        totalOriginalSize: 542394,
        totalFilteredSize: 0,
        totalFiles: 3,
        type: 'webp',
      },
      {
        totalOriginalSize: 1245803,
        totalFilteredSize: 528464,
        totalFiles: 9,
        type: 'docx',
      },
      {
        totalOriginalSize: 6961905,
        totalFilteredSize: 4214576,
        totalFiles: 5,
        type: 'zip',
      },
      {
        totalOriginalSize: 40444795,
        totalFilteredSize: 2331776,
        totalFiles: 35,
        type: 'exe',
      },
      {
        totalOriginalSize: 224479,
        totalFilteredSize: 225056,
        totalFiles: 84,
        type: 'txt',
      },
      {
        totalOriginalSize: 15845600,
        totalFilteredSize: 8299840,
        totalFiles: 20,
        type: 'pdf',
      },
      {
        totalOriginalSize: 2005568,
        totalFilteredSize: 0,
        totalFiles: 5,
        type: 'json',
      },
      {
        totalOriginalSize: 16045,
        totalFilteredSize: 9488,
        totalFiles: 1,
        type: 'xlsm',
      },
      {
        totalOriginalSize: 33282356,
        totalFilteredSize: 9983104,
        totalFiles: 22,
        type: 'jpg',
      },
      {
        totalOriginalSize: 54454533,
        totalFilteredSize: 0,
        totalFiles: 1,
        type: 'mov',
      },
      {
        totalOriginalSize: 694154,
        totalFilteredSize: 0,
        totalFiles: 2,
        type: 'skp',
      },
      {
        totalOriginalSize: 7090704,
        totalFilteredSize: 6073184,
        totalFiles: 4,
        type: 'png',
      },
    ],

    //REMOVE FROM BACKEND
    // totalOriginalSize: 163007856,
    // averageOriginalSize: 787477.5652173914,
    // medianOriginalSize: 5037,
    // totalFilteredSize: 31865216,
    // averageFilteredSize: 153938.24154589372,
    // medianFilteredSize: 4064,
    // smallestOriginalFile: {
    //   name: '_amit Report 2022-04-20 16-53-02.html',
    //   originalSize: 0,
    //   filteredSize: 16,
    // },
    // biggestOriginalFile: {
    //   name: 'באג תצוגה.mov',
    //   originalSize: 54454533,
    //   filteredSize: 0,
    // },
    // smallestFilteredFile: {
    //   name: '1236193001.0.webp',
    //   originalSize: 180798,
    //   filteredSize: 0,
    // },
    // biggestFilteredFile: {
    //   name: 'dotEngines2022-05-19 12-20-11.zip',
    //   originalSize: 4780869,
    //   filteredSize: 2898480,
    // },
  },

  //FOR SESSIONS DATA
  trafficPerDays: [
    {
      date: 1690837200000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1690848000000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1690934400000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1691020800000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1691107200000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1691193600000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1691280000000,
      sessionsCount: 2,
      filesCount: 5,
      bytesCount: 4996758,
    },
    {
      date: 1691366400000,
      sessionsCount: 2,
      filesCount: 4,
      bytesCount: 180798,
    },
    {
      date: 1691452800000,
      sessionsCount: 4,
      filesCount: 37,
      bytesCount: 9445222,
    },
    {
      date: 1691539200000,
      sessionsCount: 25,
      filesCount: 140,
      bytesCount: 122008847,
    },
    {
      date: 1691625600000,
      sessionsCount: 4,
      filesCount: 5,
      bytesCount: 745260,
    },
    {
      date: 1691712000000,
      sessionsCount: 2,
      filesCount: 3,
      bytesCount: 615623,
    },
    {
      date: 1691798400000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1691884800000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1691971200000,
      sessionsCount: 3,
      filesCount: 4,
      bytesCount: 14280432,
    },
    {
      date: 1692057600000,
      sessionsCount: 3,
      filesCount: 7,
      bytesCount: 3594700,
    },
    {
      date: 1692144000000,
      sessionsCount: 1,
      filesCount: 2,
      bytesCount: 7140216,
    },
    {
      date: 1692230400000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1692316800000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1692403200000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1692489600000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1692576000000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
    {
      date: 1692662400000,
      sessionsCount: 0,
      filesCount: 0,
      bytesCount: 0,
    },
  ],

  //FOR ROUTES DATA
  routes: [
    {
      routeId: 1234,
      name: 'route 1',
      volume: 12312341, // bytes
      filesCount: 234,
      sessionsCount: 12,
    },
    {
      name: 'route 2',
      volume: 5321467, // bytes
      sessionsCount: 5,
      filesCount: 87,

      routeId: 5678,
    },
    {
      routeId: 91011,
      name: 'route 3',
      volume: 98765432, // bytes
      filesCount: 451,
      sessionsCount: 22,
    },
    {
      routeId: 121314,
      name: 'route 4',
      volume: 2156789, // bytes
      filesCount: 72,
      sessionsCount: 8,
    },
    {
      routeId: 151617,
      name: 'route 5',
      volume: 3444412, // bytes
      filesCount: 193,
      sessionsCount: 17,
    },
  ],

  //FOR USERS DATA
  users: [
    {
      email: 'abcdd@asd.com',
      name: 'Ninet Tayeb',
      filesCount: 123,
      sessionsCount: 12,
      volume: 12313123, // bytes
      role: 'SFTP', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'john.doe@example.com',
      name: 'John Doe',
      filesCount: 87,
      sessionsCount: 5,
      volume: 5321467, // bytes
      role: 'User', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'jane.smith@example.com',
      name: 'Jane Smith',
      filesCount: 11,
      sessionsCount: 22,
      volume: 98743, // bytes
      role: 'API', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user123@example.com',
      name: 'User123',
      filesCount: 72,
      sessionsCount: 8,
      volume: 2156789, // bytes
      role: 'Casual', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'admin@example.com',
      name: 'Admin User',
      filesCount: 193,
      sessionsCount: 17,
      volume: 3444412, // bytes
      role: 'Admin', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'jerry@example.com',
      name: 'Jerry Brown',
      filesCount: 30,
      sessionsCount: 3,
      volume: 127893, // bytes
      role: 'Kiosk', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'linda@example.com',
      name: 'Linda Williams',
      filesCount: 256,
      sessionsCount: 15,
      volume: 5123678, // bytes
      role: 'User', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'sam@example.com',
      name: 'Sam Johnson',
      filesCount: 13,
      sessionsCount: 42,
      volume: 123985, // bytes
      role: 'API', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'testuser@example.com',
      name: 'Test User',
      filesCount: 5,
      sessionsCount: 1,
      volume: 9823, // bytes
      role: 'User', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'manager@example.com',
      name: 'Manager Smith',
      filesCount: 389,
      sessionsCount: 24,
      volume: 7654321, // bytes
      role: 'Admin', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user1@example.com',
      name: 'Alice Johnson',
      filesCount: 64,
      sessionsCount: 7,
      volume: 864219, // bytes
      role: 'User', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user2@example.com',
      name: 'Bob Smith',
      filesCount: 128,
      sessionsCount: 12,
      volume: 231987, // bytes
      role: 'Casual', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user3@example.com',
      name: 'Charlie Brown',
      filesCount: 24,
      sessionsCount: 3,
      volume: 1247865, // bytes
      role: 'Kiosk', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user4@example.com',
      name: 'David Miller',
      filesCount: 51,
      sessionsCount: 18,
      volume: 9876324, // bytes
      role: 'API', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user5@example.com',
      name: 'Ella Davis',
      filesCount: 10,
      sessionsCount: 2,
      volume: 54127, // bytes
      role: 'User', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user6@example.com',
      name: 'Frank Wilson',
      filesCount: 3,
      sessionsCount: 14,
      volume: 8765432, // bytes
      role: 'User', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user7@example.com',
      name: 'Grace Anderson',
      filesCount: 78,
      sessionsCount: 9,
      volume: 5432189, // bytes
      role: 'SFTP', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user8@example.com',
      name: 'Henry Martinez',
      filesCount: 20,
      sessionsCount: 23,
      volume: 13245678, // bytes
      role: 'Admin', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user9@example.com',
      name: 'Isabella Clark',
      filesCount: 42,
      sessionsCount: 6,
      volume: 523146, // bytes
      role: 'Kiosk', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user10@example.com',
      name: 'Jack Harris',
      filesCount: 16,
      sessionsCount: 11,
      volume: 986754, // bytes
      role: 'API', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user11@example.com',
      name: 'Olivia Green',
      filesCount: 92,
      sessionsCount: 10,
      volume: 725198, // bytes
      role: 'User', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user12@example.com',
      name: 'Noah Adams',
      filesCount: 42,
      sessionsCount: 4,
      volume: 421953, // bytes
      role: 'Casual', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user13@example.com',
      name: 'Sophia Lee',
      filesCount: 15,
      sessionsCount: 15,
      volume: 153467, // bytes
      role: 'Kiosk', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user14@example.com',
      name: 'Liam Brown',
      filesCount: 32,
      sessionsCount: 20,
      volume: 23764, // bytes
      role: 'API', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user15@example.com',
      name: 'Ava Johnson',
      filesCount: 8,
      sessionsCount: 2,
      volume: 86234, // bytes
      role: 'User', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user16@example.com',
      name: 'Mason Smith',
      filesCount: 29,
      sessionsCount: 13,
      volume: 7643215, // bytes
      role: 'User', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user17@example.com',
      name: 'Emma Davis',
      filesCount: 56,
      sessionsCount: 7,
      volume: 456783, // bytes
      role: 'SFTP', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user18@example.com',
      name: 'Jackson Martinez',
      filesCount: 17,
      sessionsCount: 19,
      volume: 9845623, // bytes
      role: 'Admin', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user19@example.com',
      name: 'Charlotte Clark',
      filesCount: 30,
      sessionsCount: 5,
      volume: 312987, // bytes
      role: 'Kiosk', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
    {
      email: 'user20@example.com',
      name: 'Liam Harris',
      filesCount: 13,
      sessionsCount: 9,
      volume: 574896, // bytes
      role: 'API', // User/Casual/API/SFTP/Kiosk/User/Admin/
    },
  ],

  //FOR ALERTS (?)
}

export const defaultProfile = 
  {
    analyticsProfileId: 0,
    userId: null,
    profileName: 'Default Profile',
    isDefault: true,
    isAutoUpdate: false,
    //NEW FROM BE
    dateFormat: 'UTC',
    askedPeriodType: 'recent', // or custom
    duration: 604800000,
    startDate: null,
    endDate: null,
    orgId: null,
    //
    mode: '2x3',
    order: {
      p1: 'Users',
      p2: 'FileTypes',
      p3: 'Routes',
      p4: 'FilteringSummary',
      p5: 'Transference',
      p6: 'Alerts',
    },
    users: {
      sortBy: 'files',
      isSortAlphabetically: false,
      numberOfUsersShown: 5,
    },
    fileTypes: {
      sortBy: 'number',
      isSortAlphabetically: false,
    },
    routes: {
      sortBy: 'filesCount',
      isSortAlphabetically: false,
      numberOfRoutesShown: 5,
    },
    transference: {
      sortBy: 'volumes',
    },
  }

