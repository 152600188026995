<template>
  <div
    class="px-2 d-flex align-center bg-white light-border-bottom fs-1r"
    :aria-label="`Currently uploaded file: ${curFileUploaded.file.name}`"
    tabindex="0"
  >
    <!--FILE NAME & ICON-->
    <div class="d-flex name-icon-cell">
      <b :class="curFileUploaded.file.icon"></b>
      <div class="mx-1 big-text-ellipsis font-weight-bold">
        {{ curFileUploaded.file.name }}
      </div>
    </div>

    <!--FILE SIZE-->
    <div class="file-size-cell">
      <b>{{ curFileUploaded.file.numbSize | bytesToSize }}</b>
    </div>

    <!--LAST MODIFIED-->
    <div class="text-center last-modi-cell">
      <b>{{ curFileUploaded.file.lastModifiedDate }}</b>
    </div>

    <!--PROGRESS-->
    <div class="d-flex align-center justify-center progress-cell">
      <v-progress-circular
        v-if="curFileUploaded.file.progressPrecent"
        :value="curFileUploaded.file.progressPrecent"
        :size="36"
        color="primary"
        class="my-auto mx-2"
        >{{ curFileUploaded.file.progressPrecent }}</v-progress-circular
      >
    </div>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils'

export default {
  name: 'CurUploadedFileRow',
  props: {
    curFileUploaded: Object,
  },
  filters: {
    bytesToSize,
  },
}
</script>

<style scoped src="./CurUploadedFileRow.css"></style>
