export const defaultSettings = {
  profileName: 'New Profile',
  isDefault: false,
  isAutoUpdate: false, // will this profile update by itself
  isShowAlerts: false,
  analyticsProfileId: 0,
  userId: 0,
  dateFormat: 'UTC',
  askedPeriodType: 'recent',
  duration: 604800000,
  startDate: null,
  endDate: null,
  duration: 604800000,
  mode: '2x3',
  order: {
    p1: 'Users',
    p2: 'FileTypes',
    p3: 'Routes',
    p4: 'FilteringSummary',
    p5: 'Transference',
    p6: 'Alerts',
  },
  users: {
    sortBy: 'files', //files or volume
    isSortAlphabetically: false,
    numberOfUsersShown: 5,
  },

  //FILE TYPES CARD
  fileTypes: {
    isSortAlphabetically: false,
    sortBy: 'number', //number or volume
  },

  //ROUTE
  route: {
    isSortAlphabetically: false,
    sortBy: 'filesCount', // filesCount, sessionsCount , volumeSize
    numberOfRoutesShown: 5, //
  },

  //TRANSFERNCE
  transference: {
    sortBy: 'volumes', //volumes, files and sessions
  },
}

export const datesItems = [
  {
    text: '1 Day',
    value: 86400000,
  },

  {
    text: '1 Week',
    value: 604800000,
  },

  {
    text: '1 Month',
    value: 2629800000,
  },
]

export const arrangeItems = [
  { text: 'Users', value: 'Users' },
  { text: 'File Types', value: 'FileTypes' },
  { text: 'Routes', value: 'Routes' },
  { text: 'Filtering summary', value: 'FilteringSummary' },
  { text: 'Transference', value: 'Transference' },
  { text: 'Alerts', value: 'Alerts' },
]

export const screenPositionsItems = [
  {
    text: '3 X 2',
    value: '3x2',
  },
  {
    text: '2 X 3',
    value: '2x3',
  },

  {
    text: '2 X 2',
    value: '2x2',
  },

  {
    text: '3 X 1',
    value: '3x1',
  },

  {
    text: '2 X 1',
    value: '2x1',
  },
  {
    text: '1 X 1',
    value: '1x1',
  },
  {
    text: '1 X 6',
    value: '1x6',
  },
]

export function checkAnalysticsProfiles(vue) {
  vue.isSaveButtonDisabled = true
  vue.msgWhyBtnIsDisabled = ''

  //if nothing changed
  const freezed = JSON.stringify(vue.clonedData)
  const clone = JSON.stringify(vue.profiles)

  if (freezed === clone) return

  //if there is no default profile
  // if (vue.clonedData.every(el => !el.isDefault)) {
  //   return (vue.msgWhyBtnIsDisabled = 'Default profile is required')
  // }

  if (vue.clonedData.some(el => !el.profileName))
    return (vue.msgWhyBtnIsDisabled = `Profile name is required in all profiles`)

  //if duplicated names
  const profileNames = new Set()

  for (const obj of vue.clonedData) {
    if (profileNames.has(obj.profileName)) {
      return (vue.msgWhyBtnIsDisabled = 'Duplicated names was found') // Found a duplicate profileName
    }
    profileNames.add(obj.profileName)
  }

  let noTimeIn = null

  vue.clonedData.some(pr => {
    if (!pr.duration && pr.askedPeriodType === 'recent') {
      noTimeIn = pr
      return false
    }

    if (pr.askedPeriodType === 'custom') {
      if (!pr.startDate || !pr.endDate) {
        noTimeIn = pr
        return false
      }
    }
    return true
  })
  //if there is no time
  if (noTimeIn) {
    return (vue.msgWhyBtnIsDisabled = `Recents periods fields are required in the profile "${noTimeIn.profileName}"`)
  }

  //if everything is okay
  vue.msgWhyBtnIsDisabled = ''
  vue.isSaveButtonDisabled = false
}
