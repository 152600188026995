<template>
  <div>
    <!--SENDING ACCOUNT-->
    <v-text-field
      v-model.sync="modelSendingAccount"
      :disabled="!isSettingsEnabled"
      label="Sending Email Account"
      class="mw-400"
      type="text"
      hide-details
    >
    </v-text-field>

    <!--SERVER-->
    <v-text-field
      v-model.sync="modelServer"
      :disabled="!isSettingsEnabled"
      label="Server"
      class="mw-400"
      hide-details
    >
    </v-text-field>

    <!--PORT-->
    <v-text-field
      v-model.sync="modelPort"
      :disabled="!isSettingsEnabled"
      class="mw-400"
      maxLength="5"
      label="Port"
      hide-details
    >
    </v-text-field>

    <!--PASSWORD-->
    <v-text-field
      v-model.sync="modelPassword"
      :disabled="!isSettingsEnabled"
      :type="showPassword ? 'text' : 'password'"
      autocomplete="new-password"
      class="mw-400"
      label="Password"
      hide-details
    >
      <template v-slot:append>
        <v-icon
          @mousedown="showPassword = true"
          @mouseup="showPassword = false"
          class="pointer"
          >mdi-eye{{ showPassword ? '-off' : '' }}</v-icon
        >
      </template>
    </v-text-field>

    <!--ENCRYPT-->
    <v-switch
      v-model.sync="modelEncrypt"
      :disabled="!isSettingsEnabled"
      hide-details
      label="Encrypted"
      class="mb-2"
    ></v-switch>
  </div>
</template>

<script>
export default {
  name: 'SMTP',
  data() {
    return {
      showPassword: false,
    }
  },
  props: {
    isSettingsEnabled: Boolean,
    isBackupSettings: Boolean,
    organization: Object,
  },

  computed: {
    modelServer: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.smtpDefinitions.server
        return this.organization.emailDefinitions.smtpDefinitions.server
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.smtpDefinitions.server =
            n)
        return (this.organization.emailDefinitions.smtpDefinitions.server = n)
      },
    },
    modelPort: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.smtpDefinitions.port
        return this.organization.emailDefinitions.smtpDefinitions.port
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.smtpDefinitions.port =
            n)
        return (this.organization.emailDefinitions.smtpDefinitions.port = n)
      },
    },
    modelEncrypt: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.smtpDefinitions
            .encrypt
        return this.organization.emailDefinitions.smtpDefinitions.encrypt
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.smtpDefinitions.encrypt =
            n)
        return (this.organization.emailDefinitions.smtpDefinitions.encrypt = n)
      },
    },
    modelSendingAccount: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.smtpDefinitions
            .senderEmail
        return this.organization.emailDefinitions.smtpDefinitions.senderEmail
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.smtpDefinitions.senderEmail =
            n)
        return (this.organization.emailDefinitions.smtpDefinitions.senderEmail =
          n)
      },
    },
    modelPassword: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.smtpDefinitions
            .password
        return this.organization.emailDefinitions.smtpDefinitions.password
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.smtpDefinitions.password =
            n)
        return (this.organization.emailDefinitions.smtpDefinitions.password = n)
      },
    },
  },
}
</script>
