import { render, staticRenderFns } from "./SavaCancelButtons.vue?vue&type=template&id=2978bf81&scoped=true&"
import script from "./SavaCancelButtons.vue?vue&type=script&lang=js&"
export * from "./SavaCancelButtons.vue?vue&type=script&lang=js&"
import style0 from "./SaveCancelButtons.css?vue&type=style&index=0&id=2978bf81&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2978bf81",
  null
  
)

export default component.exports