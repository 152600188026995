<template>
  <div
    class="d-flex align-center download-table-row fs-1-12r"
    :class="computedTableRowClass"
    role="row"
    tabindex="0"
  >
    <div
      v-for="field of fields"
      :style="{ width: `${field.width}%` }"
      :class="field.class"
      :key="field.name"
    >
      <!--CHECKBOX ON ALL TYPES-->
      <v-checkbox
        v-if="field.name === 'checkbox'"
        :class="[type !== 'desktop' && 'touch-screen-checkbox']"
        :value="computedValue"
        @change="selectFilesCheckboxClicked"
        hide-details
        class="ma-0 pa-0"
        :false-value="false"
        :true-value="true"
        color="primary"
        tabindex="0"
        :aria-label="`select ${item.name}`"
      />
      <!--ICON AND NAME ON ALL TYPES-->
      <div
        v-if="field.name === 'name'"
        @click="
          item.status
            ? setShownMoreDataDirFile(item)
            : changeShownDirClicked(item.path, 'tableRow')
        "
        class="d-flex align-center fill-width"
        ref="myDiv"
      >
        <!--IF THE ITEM IS FILE-->
        <b
          v-if="item.status"
          class="img-file"
          aria-label="file"
          :class="parseIcon(item.name)"
        />

        <!--IF THE ITEM IS FOLDER-->
        <img
          v-else
          src="@/assets/folder.svg"
          height="22"
          alt="Directory image"
        />

        <!--FILE / DIR NAME-->
        <v-tooltip v-if="isShowTooltipVer" top>
          <template v-slot:activator="{ on }">
            <button
              class="mx-1 big-text-ellipsis name-dir-file pointer dir-ltr"
              :aria-label="
                item.status
                  ? `More info about ${item.name}`
                  : `Go to directory ${item.name}`
              "
              v-on="on"
            >
              <span :class="[!item.status && 'link-text']">
                {{ item.name | parseName }}</span
              >
              <span
                v-if="!item.name.includes('(UTC') && item.serverName && item.serverName.includes('Ⓢ')"
                class="black--text mx-1 fs-0-75r"
                >(UTC)</span
              >
            </button>
          </template>
          <span>
            {{ item.name | parseName }}
            <span
              v-if="!item.name.includes('(UTC') && item.serverName && item.serverName.includes('Ⓢ')"
              class="mx-1 mb-1 fs-0-75r"
              >(UTC)</span
            ></span
          >
        </v-tooltip>

        <button
          v-else
          class="mx-1 big-text-ellipsis name-dir-file pointer dir-ltr"
          :aria-label="
            item.status
              ? `More info about ${item.name}`
              : `Go to directory ${item.name}`
          "
        >
          <span :class="[!item.status && 'link-text']">
            {{ item.name | parseName }}</span
          >
          <span
            v-if="!item.name.includes('(UTC') && item.serverName && item.serverName.includes('Ⓢ')"
            class="black--text mx-1 fs-0-75r"
            >(UTC)</span
          >
        </button>
      </div>

      <!--FILE STATUS ON DESKTOP AND TABLET-->
      <img
        v-if="field.name === 'status' && item.status !== 'Unknown'"
        :src="getFileStatusImage(item.status)"
        class="file-status"
        :alt="item.status"
      />

      <!--SIZE ON ALL TYPES-->
      <div v-if="field.name === 'size'" class="text-end px-2">
        <span v-if="item.status !== 'Blocked'">{{
          item.size | bytesToSize
        }}</span>
      </div>

      <!--SUBJECT & MESSAGE ON DESKTOP-->
      <div
        v-if="field.name === 'dynamicFields'"
        class="d-flex justify-space-between align-center px-2"
      >
        <v-tooltip top v-if="item.interfaceDynamicFields">
          <template v-slot:activator="{ on }">
            <v-icon
              @click="setDynamicFields(item)"
              v-on="on"
              class="pointer"
              tabindex="0"
              aria-label="message and subject"
              >mdi-dots-horizontal-circle-outline</v-icon
            >
          </template>
          <span>{{ $t('More') }}</span>
        </v-tooltip>
      </div>

      <!--DATE CREATED ON DESKTOP-->
      <span
        v-if="field.name === 'dateCreated'"
        class="text-center"
        style="line-height: 1"
      >
        {{ item.dateCreated | generateLocalDate }}
      </span>

      <!--DELETION IN ON DESKTOP-->
      <div
        v-if="field.name === 'deletionIn'"
        :class="[
          'dir-ltr',
          item.deletionDate < twoDaysMsFromNow && 'red--text',
          item.deletionDate < nowDate && 'op-0',
        ]"
        class="d-flex justify-center align-center"
      >
        <span v-if="item.deletionDate">
          {{ item | generateDeletionDate }}
        </span>
      </div>

      <!--MORE ICON ON MOBILE AND TABLET-->
      <button
        v-if="field.name === 'more' && isShowMore"
        @click="setShownMoreDataDirFile(item)"
        class="pointer"
        tabindex="0"
        aria-label="More information"
      >
        <v-icon large>mdi-dots-horizontal-circle-outline</v-icon>
      </button>
    </div>
  </div>
</template>

<script>
import {
  parseIcon,
  bytesToSize,
  generateLocalDate,
  generateDeletionDate,
} from '@/utils'

import {
  desktopTableHeaders,
  tabletTableHeaders,
  mobileTableHeaders,
} from '../../DirectoryDownloadTable'

//Icons for file statuss
import intact from '@/assets/intact.svg'
import modified from '@/assets/modified.svg'
import partial from '@/assets/partial.svg'
import blocked from '@/assets/blocked.svg'
import { mapGetters } from 'vuex'

export default {
  name: 'TableRow',
  data() {
    return {
      computedValue: false,
      fields: [],
    }
  },
  props: {
    computedFileList: Array, //this is a bandage
    shownDirectory: Object, //this is a bandage
    item: Object,
    changeShownDirClicked: Function,
    setMessage: Function,
    setDynamicFields: Function,
    twoDaysMsFromNow: Number,
    nowDate: Number,
    isAllDirFilesSelected: Boolean,
    selectedDirFiles: Array,
    setSelectedDirFiles: Function,
    setShownMoreDataDirFile: Function,
    type: String,
    nameTitleWidth: Number,
  },
  filters: {
    bytesToSize,
    generateLocalDate,
    generateDeletionDate,
    parseName(v) {
      return v.replace('.blocked', '').replace('~', '')
    },
  },

  watch: {
    isAllDirFilesSelected(n) {
      //when all files are selected put the value of the checkbox as true
      if (n) this.computedValue = true
      //if all files canceled and the dirFile is not in the array put the value as false
      else if (!this.selectedDirFiles.includes(this.item))
        this.computedValue = false
    },

    //THIS WATCHERS ARE BANDAGES -- WHEN CHANGING THE SORT OR THE SHOWN DIR THE CHECKBOXES ARE STAYING THE SAME
    shownDirectory: {
      handler: function () {
        this.computedValue = false
        this.setSelectedDirFiles([])
      },
      deep: true,
    },
    computedFileList() {
      this.computedValue = false
      this.setSelectedDirFiles([])
    },
  },

  computed: {
    ...mapGetters([
      'accessibility',
      'loggedUser',
      'interactiveInterfaceFields',
    ]),
    showFieldsArr() {
      //mobile fields
      if (this.type === 'mobile') return ['checkbox', 'name', 'size', 'more']

      //tablet fields
      if (this.type === 'tablet')
        return ['checkbox', 'name', 'status', 'size', 'more']

      //desktop fields
      if (this.type === 'desktop') {
        const arr = [
          'checkbox',
          'name',
          'status',
          'size',
          'subjectMessage',
          'dateCreated',
        ]
        return arr
      }
    },
    isShowMore() {
      const list = [
        'size',
        'status',
        'subject',
        'message',
        'dateCreated',
        'deletionDate',
        'historyId',
      ]

      //if there is something to show
      for (let l of list) {
        if (this.item[l]) return true
        if (l === 'size') return true
      }

      return false
    },

    isShowTooltipVer() {
      if (this.type === 'mobile') return false
      let width = this.item.name.length * 9
      if (this.item.status === 'Blocked') width -= 72 // remove the ".blocked" from the name
      if (this.item.serverName && this.item.serverName.includes('Ⓢ'))
        width += 41 //add the "UTC" to the name
      if (width < this.nameTitleWidth) return false
      return true
    },

    computedTableRowClass() {
      const arr = []

      if (this.accessibility.fontSize > 1) arr.push('h-60')
      if (this.type === 'tablet') arr.push('download-table-row-tablet')

      return arr
    },
  },

  methods: {
    parseIcon,
    getFileStatusImage(status) {
      switch (status) {
        case 'Intact':
          return intact
        case 'Modified':
          return modified
        case 'Partial':
          return partial
        case 'Blocked':
          return blocked
        default:
          ''
      }
    },
    selectFilesCheckboxClicked(val) {
      this.computedValue = val
      //when the checkbox is true add the dirFile to selected dirFiles array
      if (val) this.selectedDirFiles.push(this.item)
      //if the checkbox is false remove the dirFile from the array
      else {
        this.setSelectedDirFiles(
          this.selectedDirFiles.filter(
            dirFile => dirFile.name !== this.item.name
          )
        )
      }
    },
  },
  created() {
    if (this.type === 'desktop') this.fields = desktopTableHeaders(this)
    //tablet mobile
    else if (this.type === 'tablet') this.fields = tabletTableHeaders(this)
    else if (this.type === 'mobile') this.fields = mobileTableHeaders(this)
  },
}
</script>

<style scoped src="./TableRow.css"></style>
<style src="./TableRowUnScoped.css"></style>
