export const startSearchObj = {
  startDate: null,
  endDate: null,
  organizationId: null,
  userEmail: null,
  freeText: '',
  isIncludeHackingAttempts: false,
  isManagement: true,
  isOperations: true,
  isDevLogs: false,
  isShowActiveHosts: true,
  isShowInactiveHosts: false,
  isSuccessAuthLogs: false,
  operationId: null,
}
