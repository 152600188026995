export const startObjRoute = {
  saveButtonClicked: false,
  isRouteNameUniqe: true,
  isAdvancedSettingsOpen: false,
  message: '',
  isTargetSettingsError: false,
  actionType: '',
  fileLifeTime: {
    fileDeleteAfterNumber: 7,
    fileDeleteAfterType: 1,
  },
  alertBeforeDeleteTime: {
    number: 2,
    type: 'Days',
  },
  route: {},
  freezedRoute: {},
  isSaveButtonDisabled: true,
}
