<template>
  <div class="d-flex dynamic-field-wrapper" v-if="interfaceDynamicFields">
    <div
      class="advanced-settings-target pos-relative mx-1 mt-1 h-26"
      :class="[
        isFieldOpen && 'advanced-settings-target-active',
        isDarkMode && 'darkmode-advanced-settings-target',
      ]"
      @click.stop="fieldSpanClicked"
    >
      <!--TITLE THAT LOOKS LIKE INPUT -->
      <span class="advanced-title ml-1" style="width: 200px"
        >{{
          interfaceDynamicFields[fieldIndex].textToDisplay
            ? interfaceDynamicFields[fieldIndex].textToDisplay
            : $t(nthField) + ' Field'
        }}
        <i
          :class="[
            'v-icon notranslate mdi mdi-menu-down',
            isFieldOpen && 'primary--text rotate',
          ]"
        ></i>
      </span>

      <!-- <transition tag="div" name="hierarchy"> -->
      <!--WRAPPER FOR THE DROPDOWN-->
      <div
        v-if="isFieldOpen"
        @click.stop
        class="wrapper-2 px-2 pb-2 inputs-wrapper"
      >
        <div class="my-2 d-flex justify-end">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
              v-on="on"
                @click.stop="$emit('set-is-field-open', false)"
                class="close-button pointer"
                >mdi-close-circle-outline
              </v-icon>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>
        <!-- <span class="mt-1">Label of the text field:</span> -->
        <v-text-field
          v-model="interfaceDynamicFields[fieldIndex].textToDisplay"
          :label="$t('Label')"
          height="18px"
          class="mt-4 pa-0"
          style="margin-top: 3px !important"
          maxlength="50"
          hide-details
        ></v-text-field>
        <v-select
          v-if="interfaceDynamicFields"
          :items="enumItems"
          v-model="interfaceDynamicFields[fieldIndex].validation"
          :label="$t('Validation')"
          item-text="text"
          item-value="value"
          class="mt-4 w-145"
        ></v-select>
        <v-checkbox
        v-if="isGuests"
          v-model="interfaceDynamicFields[fieldIndex].required"
          :label="$t('Required field')"
          dense
          hide-details
          class="my-1 pa-0"
          style="min-width: 140px"
          height="24px"
        ></v-checkbox>
        <v-checkbox
          v-if="interfaceDynamicFields[fieldIndex].required"
          v-model="interfaceDynamicFields[fieldIndex].isHierarchy"
          :label="$t('The value is part of the target folder hirarchy')"
          dense
          hide-details
          class="my-1 mx-7 pa-0"
          style="min-width: 140px"
          height="24px"
        ></v-checkbox>
        <div
          v-if="isGuests && interfaceDynamicFields[fieldIndex].validation == 1"
        >
          <!-- <v-checkbox
            v-model="interfaceDynamicFields[fieldIndex].isSendApprovalEmail"
            hide-details
            class="my-1"
            :label="
              $t(
                'Send approval email to guest sender after sending session is finished'
              )
            "
            dense
          /> -->
          <v-checkbox
            v-model="interfaceDynamicFields[fieldIndex].isVerifyEmail"
            hide-details
            class="my-1"
            :label="$t('Verify guest sender email against impersonation')"
            dense
          />
        </div>
      </div>
      <!-- </transition> -->
    </div>
    <div>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-hover v-slot="{ hover }">
            <v-icon
              :color="hover ? 'red' : 'grey'"
              v-on="on"
              class="my-2"
              @click.stop="deleteFieldClicked"
              >mdi-delete</v-icon
            >
          </v-hover>
        </template>
        <span>{{$t('Delete field')}}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ManageDynamicFields',
  watch: {
    interfaceDynamicFields: {
      handler: function (n) {
        console.log(n)

        for (let i = 0; i < n.length; i++) {
          if (!n[i].textToDisplay) {
            this.$emit('set-error-messasge', 'All fields must have label')
          }
          if (!n[i].required) {
            n[i].isHierarchy = false
          }
          if (n[i].validation != 1){
            n[i].isVerifyEmail = false
          }
        }
      },
      deep: true,
    },
  },
  emits: [
    'delete-field',
    'set-is-field-open',
    'close-other-fields',
    'set-error-messasge',
  ],
  props: {
    interfaceDynamicFields: Array,
    fieldIndex: Number,
    isFieldOpen: Boolean,
    isGuests: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isDarkMode']),
    nthField() {
      switch (this.fieldIndex) {
        case 0:
          return 'First'
        case 1:
          return 'Second'
        case 2:
          return 'Third'
      }
    },
    isEmailOptionDisabled() {
      return true
    },
    enumItems() {
      return [
        { text: this.$t('None'), value: 0 },
        {
          text: this.$t('Email'),
          value: 1,
          disabled:
            this.interfaceDynamicFields.some(el => el.validation === 1) &&
            this.interfaceDynamicFields[this.fieldIndex].validation != 1,
        },
        { text: this.$t('Phone Number'), value: 2 },
        { text: this.$t('Israeli ID'), value: 3 },
      ]
    },
  },
  methods: {
    deleteFieldClicked() {
      this.$emit('delete-field')
      this.$emit('set-is-field-open', false)
    },
    fieldSpanClicked() {
      this.$emit('set-is-field-open', !this.isFieldOpen)
      this.$emit('close-other-fields', this.fieldIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}

.advanced-settings-target {
  transition: box-shadow 0.25s ease;
  box-shadow: #a1a1a1 0px 1px;
  &:hover {
    box-shadow: #000 0px 1px;
  }
}

.advanced-settings-target-active {
  transition: box-shadow 0.25s ease;
  box-shadow: #2289dd 0px 2px;
  &:hover {
    box-shadow: #2289dd 0px 2px;
  }
}

.advanced-settings-target-active-error {
  transition: box-shadow 0.25s ease;
  box-shadow: #f44336 0px 2px;
  &:hover {
    box-shadow: #000 0px 1px;
  }
}

.darkmode-advanced-settings-target {
  &:hover {
    box-shadow: #fff 0px 1px;
  }
}

.advanced-title {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease;
  &:active {
    color: rgba(0, 0, 0, 1);
  }
}

.inputs-wrapper {
  position: absolute;
  background: #fff;
  z-index: 10;
  width: 445px;
  top: 30px;
  transition: all 0.3s ease;
  overflow: auto;
}

.dynamic-field-wrapper {
  min-height: 40px;
}
</style>
