<template>
  <div
    :class="[type === 'mobile' ? '' : 'd-flex justify-space-between align-end']"
  >
    <!--SEARCH-->
    <div
      class="d-flex session-search-header align-center"
      Ç
      :class="[type === 'mobile' && 'flex-column']"
    >
      <!--DATES // REBUILD DATE PICKS - TO BASE COMPONENT-->
      <div class="d-flex" :class="[type === 'mobile' && 'flex-column']">
        <div class="d-flex dir-ltr flex-column mx-2 date-field">
          <span
            :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
            class="f-12 op-83 required-field"
            >{{ $t('Start Date') }}</span
          >
          <date-pick
            v-model="search.startDate"
            pickTime
            :format="'YYYY-MM-DD HH-mm'"
            :inputAttributes="{ readonly: true }"
            :startWeekOnSunday="true"
            :isDateDisabled="isFutureDateOrMoreThanEndDate"
            :selectableYearRange="{ from: 2020, to: 2023 }"
          ></date-pick>
        </div>

        <!--END DATE-->
        <div class="d-flex dir-ltr flex-column mx-2 date-field">
          <span
            class="f-12 op-83 required-field"
            :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
            >{{ $t('End Date') }}
          </span>
          <date-pick
            v-model="search.endDate"
            pickTime
            :format="'YYYY-MM-DD HH-mm'"
            :inputAttributes="{ readonly: true }"
            :startWeekOnSunday="true"
            :isDateDisabled="isFutureDateOrLessThanStartDate"
            :selectableYearRange="{ from: 2020, to: 2023 }"
          ></date-pick>
        </div>
      </div>

      <!--SHOW Received / SENT SESSIONS-->
      <div class="radio-wrapper">
        <v-radio-group v-model="search.direction" column hide-details>
          <v-radio :value="0" hide-details :label="$t('Sent')"></v-radio>
          <v-radio :value="1" hide-details :label="$t('Received')"></v-radio>
        </v-radio-group>
      </div>
      <div class="radio-wrapper ml-4">
        <v-radio-group v-model="search.isCasuals" column hide-details>
          <v-radio :value="false" hide-details :label="$t('Registered')"></v-radio>
          <v-radio :value="true" hide-details :label="$t('Guests')"></v-radio>
        </v-radio-group>
      </div>
    </div>
    <!--BUTTONS-->

    <Button
      :clickAction="searchHistory"
      :disabled="!search.startDate"
      class="mx-1"
      tabindex="0"
      :text="$t('Search')"
      icon="mdi-magnify"
      style="float: right"
    />
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import DatePick from 'vue-date-pick'
import { mapGetters } from 'vuex'

export default {
  name: 'SessionHistorySearchHeader',
  props: {
    searchHistory: Function,
    search: Object,
    type: String,
  },
  components: { Button, DatePick },
  computed: {
    ...mapGetters(['language']),
  },
  methods: {
    isFutureDateOrMoreThanEndDate(date) {
      //checks if the input date is in the future or after the end date
      if (!this.search.endDate) return this.isFutureDate(date)
      const endDate = new Date(this.search.endDate)
      const currentDate = new Date()
      return date > currentDate || date > endDate
    },

    isFutureDateOrLessThanStartDate(date) {
      //checks if the input date is in the future or before the start date
      if (!this.search.startDate) return this.isFutureDate(date)
      const startDate = new Date(this.search.startDate)
      const currentDate = new Date()
      return date > currentDate || date < startDate
    },

    isFutureDate(date) {
      const currentDate = new Date()
      return date > currentDate
    },
  },
}
</script>

<style src="./SessionHistorySearchHeader.css"></style>

<style scoped>
.radio-wrapper >>> .v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
