var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pos-relative"},[_c('span',{class:[
      'mobile-label',
      _vm.actionType === 'add' && _vm.required && 'required-field',
      _vm.isPhoneNumberActive && 'mobile-label-active',
      _vm.disabled && 'low-op-disabeld',
      _vm.isHideMobileTitle && 'op-0',
    ]},[_vm._v(" "+_vm._s(_vm.title ? _vm.title : _vm.$t('Mobile'))+" ")]),_c('vue-tel-input',{style:({ width: _vm.width }),attrs:{"validCharactersOnly":"","disabledFetchingCountry":"","defaultCountry":"IL","wrapperClasses":[
      _vm.isHideDropdown && 'phone-input-without-dropdown',
      'phone-wrapper',
    ],"inputClasses":[
      _vm.isPhoneNumberActive ? 'phone-input-active' : 'phone-input',
      _vm.isHideUnderline && 'no-underline ',
    ],"disabled":_vm.disabled,"inputOptions":_vm.inputOptions,"mode":"international","placeholder":_vm.placeholder ? _vm.placeholder : 'Enter phone number'},on:{"focus":() => (_vm.isPhoneNumberActive = true),"blur":() => (_vm.isPhoneNumberActive = false)},model:{value:(_vm.parentObject[_vm.field ? _vm.field : 'phoneNumber']),callback:function ($$v) {_vm.$set(_vm.parentObject, _vm.field ? _vm.field : 'phoneNumber', $$v)},expression:"parentObject[field ? field : 'phoneNumber']"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }