<template>
  <v-textarea
    v-model="session.message"
    @input="lightSessionRestart"
    :disabled="!session.subject || isInMiddleOfFiltering"
    outlined
    :label="$t(
              interactiveInterfaceFields.messageFieldText === 'Message'
                ? 'Message'
                : 'Sender Details'
            )"
    rows="3"
    no-resize
    hide-details
  ></v-textarea>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Message',
  props: {
    session: Object,
    lightSessionRestart: Function,
  },
  computed: {
    ...mapGetters(['isInMiddleOfFiltering', 'interactiveInterfaceFields']),
  },
}
</script>
