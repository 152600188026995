<template>
  <v-text-field
    v-model="session.subject"
    @input="lightSessionRestart"
    :rules="subjectRules"
    :disabled="isInMiddleOfFiltering"
    :label="
      interactiveInterfaceFields.subjectFieldText === 'Subject'
        ? $t('Subject')
        : $t('Request Number')
    "
    outlined
    dense
    maxlength="40"
    counter
  >
    <template v-slot:append>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information-outline</v-icon>
        </template>
        <span v-html="forbiddenChars + ' ' + $t('are forbidden')"> </span>
      </v-tooltip>
    </template>

    <template v-slot:counter="{ props }">
      <span :class="[props.value >= 35 && 'red--text']">
        {{ ` ${props.value} / ${props.max}` }}</span
      >
    </template>
  </v-text-field>
</template>

<script>
import { isFolderNameValid } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'Subject',

  data() {
    return {
      isSubjectError: false,
      forbiddenChars: '> , < , : , " , | , ? , *',
    }
  },

  props: {
    session: Object,
    lightSessionRestart: Function,
  },

  computed: {
    ...mapGetters([
      'loggedUser',
      'isInMiddleOfFiltering',
      'interactiveInterfaceFields',
    ]),
    subjectRules() {
      const rules = [
        v => {
          if (isFolderNameValid(v)) return true
          else {
            if (this.interactiveInterfaceFields.subjectFieldText === 'Subject')
              return this.$t('Subject is invalid')
            else return this.$t('Request number is invalid')
          }
        },
      ]

      //if the user is casual add the subject is required
      if (this.loggedUser.role === 'Casual')
        rules.unshift(v => {
          if (this.session.subject) return true
          else {
            if (this.interactiveInterfaceFields.subjectFieldText === 'Subject')
              return this.$t('Subject is required')
            else return this.$t('Request number is required')
          }
        })
      return rules
    },
  },
}
</script>
