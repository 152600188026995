var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2 mb-2"},[_c('h4',{staticClass:"settings-h4"},[_vm._v("Guest Users")]),_c('div',{staticClass:"wrapper-2 px-2 py-2 pos-relative"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{on:{"mouseover":function($event){_vm.showGuestsMessage = true},"mouseleave":function($event){_vm.showGuestsMessage = false}}},[_c('v-checkbox',{staticClass:"mb-2",attrs:{"disabled":_vm.isFieldDisabled ||
            (!_vm.freezedProfile.isAllowCasuals &&
              _vm.casualLicense.numOfInUse + 1 > _vm.casualLicense.numOfAllowed),"label":"License to interact with guests","hide-details":"","dense":""},model:{value:(_vm.profile.isAllowCasuals),callback:function ($$v) {_vm.$set(_vm.profile, "isAllowCasuals", $$v)},expression:"profile.isAllowCasuals"}})],1),(
          _vm.showGuestsMessage &&
          !_vm.freezedProfile.isAllowCasuals &&
          _vm.casualLicense.numOfInUse + 1 > _vm.casualLicense.numOfAllowed
        )?_c('span',{staticClass:"red--text ml-4"},[_vm._v("Not enough licenses")]):_vm._e()]),_c('Button',{staticClass:"mx-2",attrs:{"text":"SFTP Access Details for Downloading","btnType":"grey","clickAction":() => _vm.sftpDetailsDownloadingClicked()}}),_c('OnlineHelp',{attrs:{"page":"singleUser","section":"casual","title":"Guest Users"}}),(
        _vm.profile.license === 'SFTP' &&
        _vm.profile.isAllowCasuals &&
        _vm.profile.casualUsers.length > 0
      )?_c('CasualGroups',{attrs:{"user":_vm.profile,"parent":'admin'},on:{"setGroup":_vm.setGroup}}):_vm._e(),(_vm.isShowSftpDetailsDialogForCasualRecipients)?_c('SftpDetailsDialogForCasualRecipients',{attrs:{"closeDialog":() => (_vm.isShowSftpDetailsDialogForCasualRecipients = false),"isTarget":_vm.isTarget,"userEmail":_vm.profile.email,"groupName":_vm.group.name,"groupMembers":_vm.group.casualUsers}}):_vm._e(),(_vm.isShowSftpDetailsDialogForCasualSenders)?_c('SftpDetailsDialogForCasualSenders',{attrs:{"closeDialog":() => (_vm.isShowSftpDetailsDialogForCasualSenders = false),"userEmail":_vm.profile.email,"casualUsers":_vm.profile.casualUsers}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }