<template>
  <div
    :class="[
      type === 'desktop' && 'directory-tree-wrapper-desktop fill-width pt-2',
      type === 'tablet' && 'ma-2',
    ]"
    tabindex="0"
    aria-label="Directories tree"
  >
    <!--BUTTON TO SHOW ALL FILES ONLY ON TABLET-->
    <Button
      v-if="type === 'tablet'"
      :clickAction="changeTreeAllFilesClicked"
      :text="`Show Only ${isShowAllFiles ? 'Folders' : 'Files'}`"
      class="mb-3"
      width="150"
      btnType="grey"
    />

    <!--DIRECTORIES TREE-->
    <div class="wrapper-1 mx-2 v-treeview-wrapper">
      <v-treeview
        :items="directoriesTree.children"
        :class="[type === 'desktop' && 'dir-tree-desktop']"
        class="dir-tree bg-white"
        transition
        :dense="type === 'desktop'"
        hoverable
        @update:open="treeViewOpened"
      >
        <!--FOLDER IMAGE-->
        <template v-slot:prepend="{ item }">
          <img src="@/assets/folder.svg" class="h-1-25r" />
        </template>

        <!--FOLDER NAME-->
        <template v-slot:label="{ item }">
          <button
            @click="changeShownDirClicked(item.path, 'dirTree')"
            class="directory-tree-item fs-1-12r"
            :class="[
              shownDirectory.serverName === item.serverName &&
                'font-weight-regular',
            ]"
            :aria-label="item.name"
            tabindex="0"
          >
            {{ item.name }}
          </button>
        </template>
      </v-treeview>
    </div>
  </div>
</template>

<script>
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'DirectoriesTree',
  data() {
    return {
      isShowAllFiles: false,
    }
  },
  props: {
    directoriesTree: Object,
    changeShownDirClicked: Function,
    type: String,
    shownDirectory: Object,
  },
  components: { Button },
  methods: {
    changeTreeAllFilesClicked() {
      //if the page showing only directories
      if (!this.isShowAllFiles) {
        this.changeShownDirClicked(null, 'allFiles')
        this.isShowAllFiles = true
      }

      //if the page showing only directories
      else {
        this.changeShownDirClicked(null, 'start')
        this.isShowAllFiles = false
      }
    },

    treeViewOpened(item) {
      this.$nextTick(() => {
        const treeNodes = document.getElementsByClassName('v-treeview-node')

        for (let i = 0; i < treeNodes.length; i++) {
          const node = treeNodes[i]
          const toggleButton = node.querySelector('.v-treeview-node__toggle')

          if (toggleButton) {
            const isExpanded = toggleButton.classList.contains(
              'v-treeview-node__toggle--open'
            )
            toggleButton.setAttribute(
              'aria-label',
              isExpanded ? 'Close node' : 'Open node'
            )
          }
        }
      })
    },
  },
}
</script>

<style scoped src="./DirectoriesTree.css"></style>
