<template>
  <div
    class="wrapper-1 d-flex justify-space-evenly align-center ma-2 mt-3 pa-2"
  >
    <!--DOWNLOAD REPORT-->
    <Button
      v-if="isShowDownloadReport"
      :clickAction="downloadReportClicked"
      :btnType="computedButton"
      text="Download Report"
      width="160"
    />

    <!--DOWNLOAD FILES - INDIVIDUAL-->
    <Button
      v-if="isShowDownloadFiles"
      :clickAction="downloadIndividualFilesClicked"
      text="Download Files"
      width="160"
    />

    <!--RESTART SESSION-->
    <Button
      v-if="isShowRestartSessionButton"
      :btnType="computedButtonRestartSession"
      :clickAction="restartSessionClicked"
      :text="$t('Additional Upload')"
      width="180"
      icon="mdi-cached"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Functions to download dirFiles and report
import {
  DownloadReport,
  downloadFilesIndividual,
} from '@/components/WebsiteInterface/Websiteinterface'

export default {
  name: 'SessionActions',
  props: {
    sessionRes: Object,
    restartSessionClicked: Function,
    connectionType: String,
  },
  components: { Button },
  computed: {
    ...mapGetters(['loggedUser']),
    computedButtonRestartSession() {
      if (this.loggedUser.role === 'Individual') return 'grey'
      return 'blue'
    },
    isShowDownloadReport() {
      if (this.connectionType === 'mobile') return false
      const { isAllowReport, role } = this.loggedUser
      //if the user is Superadmin
      if (role === 'SuperAdmin') return true
      //individual cannot see report
      else if (role === 'Individual') return false
      //if the logged user have the option to see report
      else if (isAllowReport) return true
      return false
    },
    isShowDownloadFiles() {
      if (this.loggedUser.role === 'Individual') return true
      return false
    },
    isShowRestartSessionButton() {
      if (this.loggedUser.role === 'Individual') return false
      if (this.connectionType !== 'mobile') return false

      return true
    },
  },
  methods: {
    downloadReportClicked() {
      DownloadReport(this.sessionRes.htmlReport)
    },
    downloadIndividualFilesClicked() {
      downloadFilesIndividual(this, this.sessionRes.operationId)
    },
  },
}
</script>
