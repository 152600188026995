<template>
  <div class="pa-2">
    <div class="d-flex justify-space-between align-center">
      <v-text-field
        @keyup="$emit('set-sms-confirm-code', $event.target.value)"
        :label="$t('Verification Code')"
        @keypress.enter="confirmLogin"
        autofocus
        outlined
        class="pa-0 ma-0 mt-4"
        placeholder=" "
        type="number"
        hide-details
        dense
        maxLength="6"
        style="max-width: 130px"
      ></v-text-field>
      <Button
        :clickAction="confirmLogin"
        :text="$t('Confirm')"
        :loading="loading"
        width="120px"
      ></Button>
    </div>
    <v-divider class="my-2"></v-divider>
    <div class="d-flex justify-space-between align-center">
      <small class="light-text f-12" v-if="timerTime > 0">
        {{ $t('You can ask for a new code in') }} {{ timerTime }}
      </small>

      <Button
        v-else
        :clickAction="login"
        :text="$t('Send New Code')"
        :disabled="!!timerTime"
        width="120px"
        btnType="grey"
      >
      </Button>

      <Button
        :clickAction="clear"
        width="120px"
        :text="$t('Back to Login')"
        btnType="grey"
      ></Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'ConfirmedLogin',
  props: {
    login: Function,
    clear: Function,
    loading: Boolean,
    confirmLogin: Function,
    timerTime: Number,
  },
  emits: ["set-sms-confirm-code"],
  components: { Button },

}
</script>
