export const langs = [
  { label: 'English', value: 'en' },
  { label: 'Hebrew', value: 'he' },
]

export const licenses = [
  { shown: 'Kiosks', value: 'kiosks' },
  { shown: 'SFTP Folder', value: 'SFTP' },
  { shown: 'API', value: 'API' },
]

export const orgDefinitionsHeaders = [
  {
    text: 'Interface',
    align: 'left',
    sortable: false,
    width: '100px',
    class: 'bg-table-heading table-header-first ',
  },
  {
    text: 'Usage',
    align: 'center',
    sortable: false,
    width: '100px',
    class: 'bg-table-heading table-header-last ',
  },
]
