<template>
  <div class="d-flex flex-column fill-height">
    <div v-if="idx" class="d-flex justify-space-between">
      <!--TITLE-->
      <h2>
        {{ idx }}. Transference Per Date
        <span class="caption">{{ parsedAxisTitle }}</span>
      </h2>
      <!--EXPAND-->
      <v-icon @click="expandClicked('Transference')">mdi-arrow-expand</v-icon>
    </div>

    <!--RADIO TO SELECT TYPE OF CHART-->
    <v-radio-group v-model="chartType" row hide-details class="mt-0 mb-2 mx-2">
      <v-radio value="bytes" label="Volumes" hide-details></v-radio>
      <v-radio value="files" label="Files counts" hide-details></v-radio>
      <v-radio value="sessions" label="Sessions counts" hide-details></v-radio>
    </v-radio-group>

    <!--ON BIG SCREEN SHOW THE AXIS TITLES-->
    <span v-if="!idx" class="fs-1-12r my-1 mx-2 label">{{
      parsedAxisTitle
    }}</span>

    <!--CHART-->
    <LineChartGenerator
      style="height: calc(100% - 80px)"
      :chart-options="computedChartOptions"
      :chart-data="computedChartData"
    />
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js'
import { bytesToSize, megabytesToSize } from '@/utils'
import { mapGetters } from 'vuex'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'Transference',
  data() {
    return {
      chartType: 'bytes',
      bytesObj: {},
      filesContObj: {},
      sessionCountObj: {},
      chartData: {
        labels: [],
        datasets: [],
      },
    }
  },
  components: {
    LineChartGenerator,
  },

  props: {
    analyticsData: Object,
    idx: Number,
    expandClicked: Function,
  },

  computed: {
    ...mapGetters(['isDarkMode']),

    parsedAxisTitle() {
      if (this.chartType === 'bytes') return '(Dates / Volumes [MB])'
      if (this.chartType === 'files') return '(Dates / Files counts)'
      if (this.chartType === 'sessions') return '(Dates / Session counts)'
    },
    computedChartData() {
      if (this.chartType === 'bytes') {
        const newObj = { ...this.chartData }
        newObj.datasets[0] = this.bytesObj
        return newObj
      } else if (this.chartType === 'sessions') {
        const newObj = { ...this.chartData }
        newObj.datasets[0] = this.sessionCountObj
        return newObj
      } else {
        const newObj = { ...this.chartData }
        newObj.datasets[0] = this.filesContObj
        return newObj
      }
    },
    computedChartOptions() {
      // let yAxisLabel = ''

      // if (this.chartType === 'bytes') yAxisLabel = 'Megabytes'
      // else if (this.chartType === 'sessions') yAxisLabel = 'Sessions count'
      // else yAxisLabel = 'Files count'

      const vue = this

      const obj = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            // title: { display: true, text: yAxisLabel },
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
          x: {
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {},
          },
        },
        elements: {
          line: {
            tension: 0.5,
            borderColor: this.isDarkMode ? '#b5b5b5' : '#969696',
          },
        },
      }

      if (this.chartType === 'bytes')
        obj.plugins.tooltip.callbacks.label = function (context) {
          if (vue.chartType === 'bytes') {
            const foundObj = context.dataset.data[+context.dataIndex]
            return `${megabytesToSize(foundObj)}`
          }
        }

      return obj
    },
  },
  methods: {
    formatDate(milliseconds) {
      const date = new Date(milliseconds)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    },
  },
  created() {
    const dateLabelsArr = []
    const bytesArr = []
    const filesCountArr = []
    const sessionsCountArr = []

    this.analyticsData.trafficPerDays.forEach(el => {
      dateLabelsArr.push(this.formatDate(el.date))
      bytesArr.push(el.bytesCount / 1024 / 1024)
      filesCountArr.push(el.filesCount)
      sessionsCountArr.push(el.sessionsCount)
    })

    this.bytesObj = {
      label: 'Size',
      backgroundColor: '#2289dd',
      data: bytesArr,
    }

    this.filesContObj = {
      label: 'Files transfered',
      backgroundColor: '#2289dd',
      data: filesCountArr,
    }

    this.sessionCountObj = {
      label: 'Sessions',
      backgroundColor: '#2289dd',
      data: sessionsCountArr,
    }

    this.chartData.labels = dateLabelsArr
  },
}
</script>
