<template>
  <v-dialog
    v-model="dialog"
    width="auto !important"
    class="wrapper-1 bg-white pos-relative"
    @click:outside="closeDialog"
  >
    <v-card class="wrapper-1">
      <v-card-title class="text-center bg-grey mb-2">
        Insert Email
      </v-card-title>
      <!-- <v-card-text>{{ routeName }}</v-card-text>
        <v-card-text>{{ sftpUsername }}</v-card-text> -->
      <div class="pa-4">
        <v-text-field
          class="mb-2 profile-input required"
          :rules="userEmailRules"
          dense
          v-model="email"
          style="width: 248px !important"
          type="text"
          label="Email"
          validate-on-blur
        />
      </div>
      <div class="d-flex justify-space-between pa-2">
        <Button
          :clickAction="closeDialog"
          width="fit-content"
          btnType="grey"
          text="Cancel"
          class="mx-2"
        />
        <Button
          :clickAction="sendEmailClicked"
          width="fit-content"
          btnType="blue"
          class="mx-2"
          text="Send Email"
          :disabled="isSendMailBtnDisabled"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { validateEmail } from '@/utils'
import { api } from '@/config'
import { mapMutations } from 'vuex'

export default {
  name: 'SftpDetailsDialog',
  data() {
    return {
      dialog: true,
      email: null,
    }
  },
  props: {
    groupName: String,
    sftpUsername: String,
    closeDialog: Function,
    isTarget: Boolean,
  },
  components: { Button },

  computed: {
    userEmailRules() {
      return [
        v => !!v || this.$t('Email is required'),
        v => validateEmail(v) || this.$t('Email must be valid'),
      ]
    },
    isSendMailBtnDisabled() {
      return !validateEmail(this.email)
    }
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    async sendEmailClicked() {
      this.SET_PROCESSING(true)
      const res = await api.post('sftpdetails/send-email-for-casual', {
        routeGroupOrCasual: this.groupName,
        sftpUsername: this.sftpUsername,
        recipientEmail: this.email,
        isTarget: this.isTarget,
      })
      if (res.status != 200) {
        // handle error
      }
      this.SET_PROCESSING(false)
      this.closeDialog()
    },
  },
  created() {},
}
</script>

<style scoped>
.select-senders {
  padding: 0px 8px 7px 0px;
  display: flex;
  align-items: center;
}
</style>
