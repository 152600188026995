<template>
  <div class="wrapper-2 analytics-header pa-1 bg-white">
    <div class="d-flex align-center justify-space-between mt-3">
      <!--LEFT SIDE-->
      <div class="d-flex align-center">
        <!--PROFILE SELECT-->
        <v-select
          :value="shownProfile"
          @change="shownProfileSelected"
          return-object
          :items="profiles"
          label="Show"
          item-text="profileName"
          class="ma-0 mx-1 pa-0 mw-200"
          hide-details
        >
        </v-select>

        <!--DATES ON REGULAR PROFILE-->
        <span class="fs-1r mx-1">Dates: {{ searchDatesString }} </span>
      </div>

      <!--RIGHT SIDE-->
      <div class="d-flex align-center">
        <!--START STOP AUTO REFRESH-->
        <div v-if="shownProfile.askedPeriodType === 'recent'" class="d-flex">
          <Button
            :clickAction="() => setIsAutoUpdateActive(!isAutoUpdateActive)"
            :icon="isAutoUpdateActive ? 'mdi-pause' : 'mdi-play'"
            :text="
              isAutoUpdateActive ? 'Stop Auto Update' : 'Start Auto Update'
            "
            btnType="grey"
            class="mx-1"
          />
          <!--MESSAGE WHY BTN IS DISABLED-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="updateAnalytics(true)"
                :loading="isLoading"
                x-small
                outlined
                v-on="on"
                class="mx-2"
                :class="[
                  isDarkMode ? 'white--text' : 'grey--text text--darken-2',
                ]"
                fab
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Update screens</span>
          </v-tooltip>
        </div>

        <!--PROFILES SETTINGS-->
        <Button
          btnType="grey"
          text="Profiles Settings"
          class="mx-1"
          :icon="mdiCog"
          :clickAction="() => setIsShowAnalyticsSettings(true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import StartEndDateSelect from '@/components/BaseComponents/StartEndDateSelect/StartEndDateSelect.vue'
import { mdiCog } from '@mdi/js'
import AdvancedSettings from './Children/AdvancedSettings/AdvancedSettings.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AnalyticsHeader',
  data() {
    return {
      mdiCog,
      isSearchButtonDisabled: true,
      msgWhyBtnDisabled: '',
      isShowMessage: false,
    }
  },
  props: {
    profiles: Array,
    shownProfile: Object,
    setIsShowAnalyticsSettings: Function,
    shownProfileChanged: Function,
    updateAnalytics: Function,
    searchDatesString: String,
    isLoading: Boolean,
    setIsAutoUpdateActive: Function,
    isAutoUpdateActive: Boolean,
  },
  components: {
    Button,
    StartEndDateSelect,
    AdvancedSettings,
  },

  computed: {
    ...mapGetters(['isDarkMode']),
  },
  methods: {
    shownProfileSelected(v) {
      this.shownProfileChanged(v)
    },
  },
}
</script>

<style scoped>
.analytics-header {
  height: 60px;
  z-index: 2;
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
}

.analytics-header-open {
  height: unset;
}
</style>
