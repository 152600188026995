<template>
  <div
    class="wrapper-1 directories-navigate bg-white"
    :class="
      isDirTreeOpen ? 'directories-navigate-open' : 'directories-navigate-close'
    "
  >
    <div class="d-flex align-center pa-2">
      <!--BACK BUTTON-->
      <v-icon
        @click="changeShownDirClicked(null, 'backButton')"
        aria-label="Back one directory"
        x-large
        class="w-40"
        >mdi-arrow-left-circle-outline</v-icon
      >

      <!--DIRECTORY PATH-->
      <DirectoryPath
        :shownDirectory="shownDirectory"
        :changeShownDirClicked="changeShownDirClicked"
      />

      <!--SEARCH-->
      <div class="d-flex w-100">
        <!--SEARCH IN FOLDER-->
        <FolderSearch
          :setDirSearch="setDirSearch"
          :shownDirectory="shownDirectory"
        />

        <!--OPEN/CLOSE DIRECTORIES TREE-->
        <v-icon
          @click="isDirTreeOpen = !isDirTreeOpen"
          x-large
          :aria-label="`${isDirTreeOpen ? 'close' : 'open'} directories tree`"
          >mdi-chevron-{{
            isDirTreeOpen ? 'up' : 'down'
          }}-circle-outline</v-icon
        >
      </div>
    </div>

    <!--DIRECTORIES TREE-->
    <Transition name="hide">
      <DirectoriesTree
        v-show="isDirTreeOpen"
        :directoriesTree="directoriesTree"
        :changeShownDirClicked="changeShownDirClicked"
        :shownDirectory="shownDirectory"
        type="tablet"
      />
    </Transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Childrens
import FolderSearch from './Children/FolderSearch/FolderSearch.vue'

//Base components
import DirectoryPath from '@/components/WebsiteInterface/CommonComponents/DirectoryPath/DirectoryPath.vue'
import DirectoriesTree from '@/components/WebsiteInterface/CommonComponents/DirectoriesTree/DirectoriesTree.vue'

export default {
  name: 'DirectoriesNavigate',
  data() {
    return {
      isDirTreeOpen: false,
    }
  },
  props: {
    directoriesTree: Object,
    shownDirectory: Object,
    changeShownDirClicked: Function,
    setDirSearch: Function,
    dirSearch: String,
  },
  components: { DirectoriesTree, DirectoryPath, FolderSearch },
  watch: {
    shownDirectory: {
      handler: function () {
        this.isDirTreeOpen = false
      },
    },
  },
  computed: {
    ...mapGetters(['isInMiddleOfFiltering']),
  },
}
</script>

<style scoped src="./DirectoriesNavigate.css"></style>
