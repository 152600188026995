<template>
  <v-btn
    rounded
    :outlined="btnType === 'grey'"
    @click.prevent="clickAction"
    @click.stop="clickStopAction"
    class="no-shadow bg-white"
    :height="height"
    :width="width"
    :color="computedButtonColor"
    :disabled="disabled || loading"
    :loading="loading"
    :small="accessibility.fontSize === 1"
    :large="accessibility.fontSize === 2"
    :x-large="accessibility.fontSize === 3"
    style="text-transform: unset !important;"
  >
    <v-icon class="mx-1" v-if="beforeTextIcon" small>{{
      beforeTextIcon
    }}</v-icon>
    <span :class="[btnSize === 'small' ? 'fs-75r' : 'fs-1r']">{{ text }}</span>
    <v-icon class="mx-2" v-if="icon" small>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Button',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Click',
    },
    width: {
      type: String | Number,
      default: '',
    },
    height: {
      type: String | Number,
      default: '30px',
    },
    color: {
      type: String,
      default: 'primary',
    },
    btnType: {
      type: String,
      default: 'blue',
    },
    clickAction: {
      type: Function,
      default: () => {},
    },
    clickStopAction: {
      type: Function,
      default: () => {},
    },
    fontSize: {
      type: String | Number,
      default: 12,
    },
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    beforeTextIcon: {
      type: String,
      default: '',
    },
    btnSize: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['accessibility', 'isDarkMode']),
    computedButtonColor() {
      if (this.isDarkMode)
        return this.btnType === 'blue' ? 'blue darken-4' : 'grey lighten-2'
      return this.btnType === 'blue' ? 'primary' : 'grey darken-2'
    },
  },
}
</script>

<style scoped src="./Button.css"></style>

<style lang="sass">
$btn-text-transform : unset !important
</style>
