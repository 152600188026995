<template>
  <div class="my-4">
    <h4 class="settings-h4">Supported Languages</h4>
    <div class="wrapper-1 pa-2">
      <!--DEFAULT LANGUAGE-->
      <div class="d-flex align-center my-2 fs-1-12r">
        <span class="mx-2 mt-1 required-field"><b>Default language:</b></span>
        <v-select
          v-model="computedDefaultLang"
          :items="computedLangs"
          :item-text="item => getFullLangName(item.lang)"
          item-value="lang"
          class="ma-0 pa-0 mx-2 mw-160"
          dense
          hide-details
        ></v-select>
      </div>

      <!--LANGUAGES-->
      <div>
        <LanguageSettings
          v-for="language of computedLangs"
          :language="language"
          :key="language.lang"
          :setLanguageData="setLanguageData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSettings from './Children/LanguageSettings/LanguageSettings.vue'

export default {
  name: 'Languages',
  data() {
    return {
      computedDefaultLang: null,
    }
  },
  props: {
    kioskSettings: Object,
    setDefaultLang: Function,
  },
  watch: {
    computedDefaultLang(n) {
      if (n) this.setDefaultLang(n)
    },
  },

  computed: {
    computedLangs() {
      // only these langs
      return this.kioskSettings.languages.filter(
        el =>
          el.lang === 'en' ||
          el.lang === 'he' ||
          el.lang === 'ro' ||
          el.lang === 'cn' ||
          el.lang === 'ru' ||
          el.lang === 'fr'
      )
    },
  },
  methods: {
    setLanguageData(newLang) {
      this.kioskSettings.languages = this.kioskSettings.languages.map(iLang => {
        if (iLang.lang === newLang.lang) return newLang
        return lang
      })
    },
    getFullLangName(lang) {
      switch (lang) {
        case 'he':
          return 'Hebrew'
        case 'en':
          return 'English'
        case 'ru':
          return 'Russian'
        case 'ar':
          return 'Arabic'
        case 'ro':
          return 'Romanian'
        case 'cn':
          return 'Chinese'
        case 'fr':
          return 'French'
        default:
          return 'ERROR'
      }
    },
  },
  created() {
    this.computedDefaultLang = this.kioskSettings.defaultLang
  },
  components: { LanguageSettings },
}
</script>

<style scoped src="./Languages.css"></style>
