import store from '@/store'

function checkObjectValues(obj) {
  for (const key in obj) {
    const value = obj[key]
    if (!value) {
      return false
    }

    if (typeof value === 'object') {
      if (!checkObjectValues(value)) {
        return false
      }
    }
  }

  return true
}

export function checkKioskSettings(vue) {
  vue.isSaveButtonDisabled = true
  vue.messageWhySaveButtonDisabled = ''

  const kioskSettingsJson = JSON.stringify(vue.kioskSettings)
  const freezedKioskSettingsJson = JSON.stringify(vue.freezedKioskSettings)
  const isImagesChanged = vue.images.logo

  //if nothing changed
  if (kioskSettingsJson === freezedKioskSettingsJson && !isImagesChanged) {
    return store.commit('SET_IS_IN_MIDDLE_OF_EDITING', false)
  }

  //if something changed
  else {
    store.commit('SET_IS_IN_MIDDLE_OF_EDITING', true)
  }

  // Loop through the licenses array to check for missing hardware, nickname, and recipient
  for (const lic of vue.kioskSettings.licenses) {
    //if the kiosk is active and there is no id&name&recipent just let it be
    if (
      !lic.isActive &&
      !lic.kioskId &&
      !lic.nickname &&
      !lic.recipients.length
    ) {
      break
    }
    if (!lic.kioskId)
      return (vue.messageWhySaveButtonDisabled = 'Kiosks must have ID')

    if (!lic.nickname)
      return (vue.messageWhySaveButtonDisabled = 'Kiosks must have nickname')

    if (!lic.recipients.length)
      return (vue.messageWhySaveButtonDisabled = 'Kiosks must have recipient')
  }

  if (vue.numOfActiveKiosks > vue.kioskLicense.numOfAllowed) {
    return (vue.messageWhySaveButtonDisabled =
      'The number of active kiosks cannot exceed the allowed limit')
  }

  if (vue.messageWhySaveButtonDisabled) return

  //POLICY
  //if there is no policy
  if (!vue.kioskSettings.filtering.policy) {
    return (vue.messageWhySaveButtonDisabled = 'Policy is required')
  }

  //if there is no suppported media
  const { usb, cdDvd, mobile } = vue.kioskSettings.supportedMedia

  if (!usb && !cdDvd && !mobile) {
    return (vue.messageWhySaveButtonDisabled =
      'At least one supported media is required')
  }

  //DELETE AFTER

  const { alertBeforeDeletionByMs, deletionInByMs } =
    vue.kioskSettings.filesSettings

  if (
    alertBeforeDeletionByMs > deletionInByMs &&
    alertBeforeDeletionByMs !== 9999999999999
  )
    return (vue.messageWhySaveButtonDisabled = `Alert before deletion can't be bigger than delete after`)

  //LANGUAGES

  //if there is no language to the kiosk
  if (vue.kioskSettings.languages.length === 0) {
    return (vue.messageWhySaveButtonDisabled =
      'At least 1 supported language is required')
  }

  //if the default language is not supported
  const defaultLang = vue.kioskSettings.languages.find(
    lang => lang.lang === vue.kioskSettings.defaultLang
  )
  if (!vue.kioskSettings.defaultLang || !defaultLang.isSupported) {
    return (vue.messageWhySaveButtonDisabled =
      'Default language must be supported')
  }

  //if some fields on english is missing
  vue.kioskSettings.languages.forEach(lang => {
    if (lang.lang === 'en') {
      const isLanguageFull = checkObjectValues(lang)
      if (!isLanguageFull) {
        return (vue.messageWhySaveButtonDisabled = `All fields in English are required`)
      }
    }
  })
  //if everything is okay
  vue.isSaveButtonDisabled = false
  vue.messageWhySaveButtonDisabled = ''
}
