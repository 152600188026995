<template>
  <div class="d-flex">
    <div class="body">
      <div class="err-text">
        <h1 class="white--text text-center">Oops! Something is wrong.</h1>
        <h3 class="white--text">
          Don't worry, we know about the problem and it will be fixed soon.
          Please try again later
        </h3>
      </div>
      <a href="/" class="try-again"> <i class="icon-home"></i> Try again </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalError',
}
</script>

<style scoped src="./GlobalError.css"></style>
