<template>
  <tr>
    <td>
      <!--TITLES-->
      <div class="d-flex flex-column">
        <span
          :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']"
          class="my-2"
          >Website</span
        >

        <span
          :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']"
          class="ml-4 my-2"
          >Registered</span
        >

        <span
          :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']"
          class="ml-4 my-2"
          >Guest</span
        >
      </div>
    </td>

    <!--INPUTS-->
    <td>
      <div class="d-flex flex-column">
        <span class="my-5"></span>
        <!-- REGISTERED LICENSES-->
        <span class="d-flex justify-center align-center my-auto">
          <p
            class="mr-2 my-0"
            :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']"
          >
            {{ getProtocols('Website') }}
          </p>
          <span :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']"
            >/</span
          >
          <v-text-field
            :disabled="
              loggedUser.role !== 'SuperAdmin' || organization.status !== 0
            "
            class="numOfAllowedInput mb-3"
            hide-details
            @change="numOfAllowedChange($event, 'Website')"
            height="16px"
            type="number"
            :rules="[
              protocolNumberRules.isPositive,
              protocolNumberRules.required,
              protocolNumberRules.isInt,
              protocolNumberRules.isMoreThanUsed('Website'),
            ]"
            validate-on-blur
            :value="getProtocols('Website', true)"
          ></v-text-field>
        </span>

        <!--CASUAL LICENSES-->
        <span class="d-flex justify-center align-center my-auto">
          <p
            class="mr-2 my-0"
            :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']"
          >
            {{ getProtocols('Casual') }}
          </p>
          <span :class="[loggedUser.role !== 'SuperAdmin' && 'low-op-disabeld']"
            >/</span
          >
          <v-text-field
            :disabled="
              loggedUser.role !== 'SuperAdmin' || organization.status !== 0
            "
            class="numOfAllowedInput mb-3"
            hide-details
            @change="numOfAllowedChange($event, 'Casual')"
            height="16px"
            type="number"
            :rules="[
              protocolNumberRules.isPositive,
              protocolNumberRules.required,
              protocolNumberRules.isInt,
              protocolNumberRules.isMoreThanUsed('Casual'),
            ]"
            validate-on-blur
            :value="getProtocols('Casual', true)"
          ></v-text-field>
        </span>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WebsiteLicenses',
  props: {
    protocolNumberRules: Object,
    getProtocols: Function,
    numOfAllowedChange: Function,
    organization: Object,
    actionType: String,
  },
  computed: {
    ...mapGetters(['loggedUser']),
  },
}
</script>
<style scoped src="../OrgDefinitionsChildrens.css"></style>
