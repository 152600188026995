<template>
  <div>
    <v-stepper-step step="4" class="pa-2" tabindex="0">
      {{$t('Results')}} {{ loggedUser.role === 'Individual' ? '& Downloading' :'' }}
    </v-stepper-step>
    <v-stepper-content step="4" class="pa-0 ma-0 fs-1r">
      <div
        v-if="
          statusResults.session.htmlReport.summary && loggedUser.isAllowReport
        "
        class="wrapper-1 ma-1 pa-2"
      >
        <!--SUMMARY-->
        <div
          :class="
            isSuperSmallScreen
              ? ''
              : 'd-flex align-center justify-space-between'
          "
        >
          <!--LOOPING ON THE SUMMARY-->
          <Summary :statusResults="statusResults" />

          <div class="d-flex flex-column justify-center">
            <Button
              :clickAction="() => (isShowHtmlDialog = true)"
              class="my-2 mx-auto"
              :text="$t('Filtering Report')"
              btnType="grey"
            />
          </div>
        </div>
      </div>
      <!--IF THE USER CANNOT SEE REPORT-->
      <div v-else class="wrapper-1 pa-2 mt-2 mx-2 text-center">
        <h3 class="fs-1-5r">Finished</h3>
      </div>

      <SessionActions
        :sessionRes="statusResults.session"
        :restartSessionClicked="restartSessionClicked"
        connectionType="mobile"
      />

      <!--HTML REPORT-->
      <HtmlReportDialog
        v-if="isShowHtmlDialog"
        :closeHtmlDialog="v => (isShowHtmlDialog = false)"
        :sessionRes="statusResults.session"
      />
    </v-stepper-content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import SessionActions from '@/components/WebsiteInterface/CommonComponents/SessionActions/SessionActions.vue'

//Childrens
import HtmlReportDialog from './Childrens/HtmlReportDialog/HtmlReportDialog.vue'
import Summary from '@/components/BaseComponents/Summary/Summary.vue'

//Functions to report
import { DownloadReport } from '@/components/WebsiteInterface/Websiteinterface'

export default {
  name: 'FilteringReport',
  data() {
    return {
      isShowHtmlDialog: false,
    }
  },
  props: {
    step: Number,
    statusResults: Object,
    restartSessionClicked: Function,
  },
  components: { Button, HtmlReportDialog, SessionActions, Summary },
  computed: {
    ...mapGetters(['loggedUser']),
    isSuperSmallScreen() {
      if (this.$vuetify.breakpoint.width >= 390) return false
      return true
    },
  },
  methods: {
    downloadReportClicked() {
      DownloadReport(this.statusResults.session)
    },
  },
}
</script>
