<template>
  <div class="directory-path-wrapper d-flex align-center fs-1r">
    <div v-for="path in shownDirectory.path" class="d-flex align-center">
      <!--ICON REPRESENT "/"-->
      <v-icon>mdi-chevron-right</v-icon>

      <!--NAME OF THE FOLDER-->
      <span
        @click="changeShownDirClicked(path, 'navigation')"
        class="path-click big-text-ellipsis px-2 py-1"
        :aria-label="'Directory ' + path.name"
        tabindex="0"
        >{{ path.name }}

        <!--ADD UTC IF THE FOLDER IS SESSION-->
        <span v-if="path?.serverName.includes('Ⓢ')" class="fs-0-75r">
          (UTC)
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DirectoryPath',
  props: {
    shownDirectory: Object,
    changeShownDirClicked: Function,
  },
  computed: {},
}
</script>

<style scoped src="./DirectoryPath.css"></style>
