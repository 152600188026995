export const startDataSingleUser = {
  accountType: '',
  saveButtonClicked: false,
  isSaveButtonDisabled: true,
  isDataLoaded: false,
  isFromUsers: true,
  loading: false,
  isEmailUniqe: true,
  message: '',
  actionType: '',
  profile: {},
  casualLicense: {},
  fullName: {
    firstName: '',
    lastName: '',
  },
  freezedProfile: {},
}
