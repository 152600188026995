<template>
    <v-tabs-items
      v-model="tab"
      class="fill-height fill-width ma-auto desktop-tabs"
    >
      <!--!!!SOME BUG HERE THAT DON'T CHANGE THE UPLOADING/DOWNLOADING-->

      <!--UPLOAD-->
      <UploadDesktop
        v-if="tab === 'uploading'"
        :userActiveSourceRoutes="userActiveSourceRoutes"
      />

      <!--DOWNLOAD-->
      <DownloadDesktop
        v-if="tab === 'downloading'"
        :userCameFromEmailPath="userCameFromEmailPath"
        :setTab="setTab"
      />
    </v-tabs-items>
</template>

<script>
//Childrens
import DownloadDesktop from './Childern/DownloadDesktop/DownloadDesktop.vue'
import UploadDesktop from './Childern/UploadDesktop/UploadDesktop.vue'

export default {
  name: 'WebsiteInterfaceDesktop',

  components: { UploadDesktop, DownloadDesktop },

  props: {
    tab: String,
    userActiveSourceRoutes: Array,
    userCameFromEmailPath: String,
    setTab: Function,
  },

  
}
</script>

<style scoped src="./WebsiteinterfaceDesktop.css"></style>
