<template>
  <v-overlay z-index="10000" :value="isLoading">
    <v-progress-circular
      :size="200"
      width="15"
      color="primary"
      indeterminate
    >
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    isLoading: Boolean,
  },
}
</script>
