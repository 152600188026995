<template>
  <div
    tabindex="0"
    class="wrapper-1 pa-1 bg-white h-45 mb-1 fill-width d-flex align-center justify-space-between"
  >
    <!--TITLE-->
    <h3 class="fs-1-25r">{{ $t('Selected Files') }}</h3>

    <!--IN FILTERING SHOW PROGRESS-->
    <div v-if="isInMiddleOfFiltering" class="d-flex fs-1r">
      <span>
        {{ $t('Uploaded') }}: {{ sessionFiles.progress }} /
        {{ sessionFiles.total }}</span
      >
      <v-divider vertical class="mx-2" />
      <span>
        {{ $t('Sent') }}:
        {{ computedSessionSize | bytesToSize }}
        /
        {{ sessionSize.total | bytesToSize }}</span
      >
    </div>

    <!--TOTAL AND SIZE OF FILES BEFORE FILTERING-->
    <div v-else class="d-flex fs-1r">
      <span>{{ $t('Total Files') }}: {{ session.filesData.length }}</span>
      <v-divider vertical class="mx-2" />
      <span>
        {{ $t('Total Size') }}: {{ session.totalSize | bytesToSize }}</span
      >
    </div>

    <!--CANCEL BUTTON-->
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          v-if="isInMiddleOfFiltering"
          @click="cancelSessionClicked"
          large
          color="red"
          aria-label="stop filtering"
          >mdi-close-circle-outline</v-icon
        >
      </template>
      <span>Cancel</span>
    </v-tooltip>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'SessionInfo',
  props: {
    session: Object,
    curFileUploaded: Object,
    sessionFiles: Object,
    sessionSize: Object,
    cancelSessionClicked: Function,
  },

  filters: {
    bytesToSize,
  },

  computed: {
    ...mapGetters(['isInMiddleOfFiltering']),
    computedSessionSize() {
      return (
        this.sessionSize.progress +
        (this.curFileUploaded ? this.curFileUploaded.file.progress : 0)
      )
    },
  },
}
</script>
