<template>
  <!-- THIS IS THE FORM THAT WILL SHOWN-->
  <div class="d-flex registration-form-wrapper">
    <div v-if="!isUserCreated" class="wrapper-1 individual-registration-form">
      <v-card-title class="font-weight-bold bg-grey title-border fs-1-5r">
        <span class="mx-auto"> {{ $t('Individual Registration') }} </span>
      </v-card-title>
      <h4 class="px-2 mt-2 fs-1-12r">{{ $t('Personal details') }}</h4>
      <!--FORM -->
      <div class="wrapper-1 px-2 py-4 ma-2">
        <div class="d-flex justify-space-evenly">
          <!--NAME EMAIL AND COMPANY-->
          <div class="mx-2">
            <v-text-field
              class="mb-2 profile-input required"
              :rules="[v => !!v.trim() || $t('First name is required')]"
              dense
              :reverse="language === 'he'"
              v-model="user.firstName"
              style="width: 248px !important"
              type="text"
              :label="$t('First Name')"
              :class="[language === 'he' && 'rtl-input-message']"
              validate-on-blur
            >
            </v-text-field>

            <v-text-field
              class="required mb-2 profile-input"
              dense
              :label="$t('Email')"
              v-model="user.email"
              type="email"
              :reverse="language === 'he'"
              :rules="userEmailRules"
              :class="[language === 'he' && 'rtl-input-message']"
              style="width: 248px !important"
              validate-on-blur
              autocomplete="new-password"
              name="new-email-1"
            ></v-text-field>

            <v-text-field
              dense
              class="mb-2 profile-input"
              label="Organization"
              style="width: 248px !important"
              :reverse="language === 'he'"
              :class="[language === 'he' && 'rtl-input-message']"
              v-model="user.organization"
              type="text"
            ></v-text-field>
          </div>

          <!--LAST NAME PHONE AND COUNTRY-->
          <div class="mx-2">
            <v-text-field
              class="mb-2 profile-input required"
              :rules="[v => !!v.trim() || $t('Last name is required')]"
              dense
              :reverse="language === 'he'"
              v-model="user.lastName"
              type="text"
              :label="$t('Last Name')"
              style="width: 248px !important"
              :class="[language === 'he' && 'rtl-input-message']"
              validate-on-blur
            >
            </v-text-field>

            <PhoneNumberInput :parentObject="user" class="mt-4 mb-6" />

            <v-text-field
              class="required mb-2 profile-input"
              :reverse="language === 'he'"
              :rules="[v => !!v.trim() || $t('Country is required')]"
              dense
              :label="$t('Country')"
              :class="[language === 'he' && 'rtl-input-message']"
              v-model="user.country"
              validate-on-blur
              style="width: 248px !important"
              type="text"
            ></v-text-field>
          </div>
        </div>
      </div>

      <!--SERVICES-->
      <h4 class="px-2 fs-1-12r">{{ $t('Services') }}</h4>
      <div class="wrapper-1 d-flex pa-2 ma-2">
        <v-checkbox
          class="mt-0 mr-4"
          hide-details
          v-model="services"
          :label="$t('Self filtering')"
          disabled
          :value="0"
        ></v-checkbox>
        <v-checkbox
          disabled
          class="mt-0 mr-4"
          hide-details
          v-model="services"
          :label="$t('Files transference')"
          value="1"
        ></v-checkbox>
        <v-checkbox
          disabled
          class="mt-0 mr-4"
          hide-details
          v-model="services"
          label="TBD"
          value="tbd"
        ></v-checkbox>
      </div>

      <!--PACKAGE-->
      <h4 class="px-2 fs-1-12r">{{ $t('Plan (Package)') }}</h4>
      <div class="wrapper-1 ma-2 pa-2">
        <v-radio-group hide-details v-model="plan" row>
          <v-radio :label="$t('Free')" value="0" disabled></v-radio>
          <v-radio :label="$t('Basic')" value="1"></v-radio>
          <v-radio :label="$t('Premium')" value="2-" disabled></v-radio>
        </v-radio-group>
      </div>

      <!--BUTTONS-->
      <div class="pa-2 pos-relative">
        <div
          class="d-flex justify-end"
          @mouseover="isShowMessage = true"
          @mouseleave="isShowMessage = false"
        >
          <Button
            :disabled="isSignupBtnDisable"
            :clickAction="register"
            :text="$t('Sign Up')"
            class="mx-2"
          ></Button>
          <Button
            btnType="grey"
            :text="$t('Cancel')"
            :clickAction="back"
            class="mx-2"
          />
        </div>
        <!--PROBLEM MESSAGE-->
        <transition name="slide-fade">
          <span
            v-if="isShowMessage"
            class="red--text"
            :class="[language === 'he' ? 'rtl-message' : 'ltr-message']"
            >{{ message }}</span
          >
        </transition>
      </div>
    </div>
    <!--IF THE REGISTRATION COMPLETED-->
    <div
      v-else
      class="wrapper-1 individual-registration-form"
      aria-label="Success, Please check your email. We sent information about your account"
    >
      <v-card-title
        class="font-weight-bold grey pa-2 lighten-3 title-border fs-1-12r"
      >
        <span class="mx-auto"> {{ $t('Success') }}</span>
      </v-card-title>
      <div class="d-flex flex-column pa-2 fs-1r">
        <span class="py-2">{{
          $t('Please check your email. We sent information about your account')
        }}</span>

        <Button
          btnType="blue"
          :text="'Continue'"
          :clickAction="back"
          width="100px"
          class="align-self-end"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { confirmDialog, validateEmail } from '@/utils'
import { api } from '@/config'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'

export default {
  name: 'RegistrationForm',
  data() {
    return {
      isShowMessage: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        organization: '',
        country: '',
      },
      isSignupBtnDisable: true,
      isUserCreated: false,
      services: [0],
      plan: '1',
      message: '',
    }
  },
  components: {
    Button,
    PhoneNumberInput,
  },
  watch: {
    user: {
      handler: function (n) {
        this.checkRequirements()
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['language']),
    userEmailRules() {
      return [
        v => !!v || this.$t('Email is required'),
        v => validateEmail(v) || this.$t('Email must be valid'),
      ]
    },
    isPhoneValid() {
      if (
        this.user.phoneNumber.length > 8 &&
        this.user.phoneNumber.includes('+')
      )
        return true
      return false
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    checkRequirements() {
      this.isSignupBtnDisable = true
      if (!this.user.firstName || !this.user.firstName.trim()) {
        return (this.message = this.$t('First name is required'))
      } else if (!this.user.lastName || !this.user.lastName.trim()) {
        return (this.message = this.$t('Last name is required'))
      } else if (!this.user.email) {
        return (this.message = this.$t('Email is required'))
      } else if (!validateEmail(this.user.email)) {
        return (this.message = this.$t('Email must be valid'))
      } else if (!this.user.phoneNumber || this.user.phoneNumber === '+') {
        return (this.message = this.$t('Mobile is required'))
      } else if (!this.isPhoneValid) {
        return (this.message = this.$t('Mobile must be valid'))
      } else if (!this.user.country || !this.user.country.trim()) {
        return (this.message = this.$t('Country is required'))
      } else if (!this.plan) {
        return (this.message = this.$t('Plan is required'))
      }
      //if everything is fine
      this.message = ''
      this.isSignupBtnDisable = false
    },
    back() {
      if (this.isUserCreated) return this.$router.go(-1)

      //check if all the fields are empty
      let isAllFieldsEmpty = true
      for (let val of Object.entries(this.user)) {
        if (val[1] !== '') {
          isAllFieldsEmpty = false
          break
        }
      }
      //if all the fields are empty
      if (isAllFieldsEmpty) return this.$router.go(-1)

      //if something changed
      const text = `${this.$t('You have unsaved changes')}. <br/> ${this.$t(
        'Are you sure you want to leave this page without saving'
      )}?`

      const thenFunc = () => this.$router.go(-1)
      confirmDialog(this, text, 'Leave Without Saving', 'Cancel', thenFunc)
    },
    async register() {
      this.SET_PROCESSING(true)
      try {
        const user = {
          name: this.user.firstName + ' ' + this.user.lastName,
          email: this.user.email,
          phoneNumber: this.user.phoneNumber,
          company: this.user.organization,
          country: this.user.country,
          sercices: this.user.services,
          plan: this.user.plan,
        }
        const res = await api.post('users/registration', user)
        if (res.status === 200) {
          this.isUserCreated = true
          this.SET_PROCESSING(false)
        } else {
          this.SET_PROCESSING(false)
          throw new Error(res.data)
        }
      } catch (error) {
        console.log(error)
        this.SET_PROCESSING(false)
      }
    },
  },
}
</script>

<style scoped src="./RegistrationForm.css"></style>

<style src="./RegistrationFormUnScoped.css"></style>
