<template>
  <div id="policy" :class="[type !== 'mobile' && 'pos-relative mb-4']">
    <v-select
      :attach="type !== 'mobile' && '#policy'"
      v-model="session.policy"
      @change="lightSessionRestart"
      :items="policiesList"
      :error="errors.isPolicyError"
      :disabled="isInMiddleOfFiltering"
      label="Policy"
      outlined
      hide-details
      dense
    ></v-select>
  </div>
</template>

<script>
import { api } from '@/config'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SelectPolicy',
  data() {
    return {
      policiesList: [],
    }
  },
  props: {
    session: Object,
    errors: Object,
    lightSessionRestart: Function,
    type: String,
  },
  methods: { ...mapMutations(['SET_PROCESSING']) },
  computed: {
    ...mapGetters(['isInMiddleOfFiltering']),
  },
  async created() {
    try {
      //get the policies
      this.SET_PROCESSING(true)
      const res = await api.get(`organizations/0/policies`)

      if (res.status !== 200) throw Error
      this.policiesList = res.data.map(el => el.replace('.polx', ''))

      //if there is only one policy
      if (this.policiesList.length === 1)
        this.session.policy = this.policiesList[0]
    } catch (e) {
      console.log(e)
    } finally {
      this.SET_PROCESSING(false)
    }
  },
}
</script>
