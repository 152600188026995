<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      class="wrapper-1 bg-white pos-relative"
      :content-class="isDarkMode ? 'dark-mode' : ''"
    >
      <v-card class="wrapper-1">
        <!--TITLE-->
        <v-card-title class="bg-grey mb-2">
          <span class="ma-auto"> {{$t('Email Verification')}} </span>
        </v-card-title>

        <v-card-text class="d-flex pa-2">
          <!--BUTTON TO SEND CODE-->
          <Button
            v-if="!isCodeSent"
            :clickAction="sendVerCode"
            :text="$t('Send Email')"
            width="120"
            class="ma-auto py-4"
          />

          <!-- INPUT TO ENTER CODE-->
          <div v-if="isCodeSent" class="d-flex flex-column mx-auto">
            <span class="text-center">
              {{ $t('Email verification code sent to your email') }}
              {{ startOfEmail }}*******.
              <br />
              {{ $t('Enter code here') }}:
            </span>

            <!--INPUT FOR THE CODE-->
            <v-text-field
              v-model.number="code"
              autofocus
              hide-details
              lazy
              class="ma-auto mw-60 centered-input"
              type="number"
              dense
            ></v-text-field>
          </div>
        </v-card-text>

        <div v-if="isCodeSent" class="d-flex justify-space-between pa-2">
          <!--TIMER FOR SEND NEW VER CODE -->
          <span v-if="sendNewVerCodeTimer" class="caption my-auto">
           {{ $t('You can ask for a new code in') }} {{ sendNewVerCodeTimer }} {{$t('seconds')}}
          </span>

          <!--BUTTON TO RESEND CODE-->
          <Button
            v-else
            :text="$t('Resend Code')"
            :disabled="sendNewVerCodeTimer > 0"
            :clickAction="resendCode"
            btnType="grey"
            width="100"
            class="mx-2"
          />

          <!--BUTTON TO ACCEPT-->
          <Button
            :text="$t('Verify')"
            :clickAction="verifyCode"
            :disabled="!code"
            width="100"
            class="mx-2"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { api } from '@/config'

//Button
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'TwoStepFactorDialog',
  data() {
    return {
      dialog: true,
      code: '',
      isCodeSent: false,
      sendNewVerCodeTimer: 0,
      startOfEmail: '',
    }
  },
  props: {
    twoStepEnded: Function,
    casualUserGuid: String,
  },
  components: {
    Button,
  },
  watch: {
    sendNewVerCodeTimer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.sendNewVerCodeTimer--
          }, 1000)
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(['isDarkMode']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION', 'SET_USER']),
    // ...mapActions(['GET_INTERACTIVE_INTERFACE_FIELDS_DATA']),
    async sendVerCode() {
      //send the ver code to the user's email
      try {
        this.SET_PROCESSING(true)
        this.sendNewVerCodeTimer = 30

        const payload = {
          guid: this.casualUserGuid,
        }
        const res = await api.post(`auth/casual/sendcode`, payload)

        if (res.status !== 200) throw Error

        this.startOfEmail = res.data

        this.isCodeSent = true
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },

    async resendCode() {
      //send the code again
      try {
        this.SET_PROCESSING(true)
        this.sendNewVerCodeTimer = 30
        this.code = ''

        const payload = {
          guid: this.casualUserGuid,
        }
        const res = await api.post(`auth/casual/sendcode`, payload)
        if (res.status !== 200) throw Error

        this.SET_NOTIFICATION({
          type: 'success',
          text: this.$t('Code was sent successfully'),
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },

    async verifyCode() {
      //verify the user's code
      try {
        this.SET_PROCESSING(true)
        const payload = {
          code: this.code,
          guid: this.casualUserGuid,
        }
        const res = await api.post(`auth/casual/vercode`, payload)
        if (res.status !== 200) throw Error

        //set the user use as logged user
        this.SET_USER({ ...res.data.user, token: res.data.token })
        // this.GET_INTERACTIVE_INTERFACE_FIELDS_DATA()

        api.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${res.data.token}`

        this.SET_NOTIFICATION({
          type: 'success',
          text: this.$t('Verified successfully'),
        })

        this.twoStepEnded(res.data.user)
      } catch (e) {
        this.code = ''
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
}
</script>
