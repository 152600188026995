<template>
  <div class="pa-2">
    <h4 class="settings-h4">Automatic Empty Folder Handling</h4>

    <div class="wrapper-2 pa-4 mb-2 pos-relative">
      <v-select
        v-model="organization.foldersDeletion"
        :items="foldersDeletionItems"
        class="ma-0 mx-1 mb-4 pa-0 mw-360"
        hide-details
      >
      </v-select>

      <div v-if="organization.foldersDeletion === 2" class="my-1">
        <span>Paths to be deleted automatically: </span>
        <div
          v-for="(path, i) of organization.deleteAllUnderPath"
          class="d-flex justify-space-between"
        >
          <v-text-field
            v-model="organization.deleteAllUnderPath[i]"
            hide-details
            dense
            class="my-1 mw-360"
            append-icon="mdi-minus-circle-outline"
            @click:append="removeItemFromArr(path)"
          />
        </div>

        <div class="d-flex justify-end mw-360 mt-2">
          <v-icon @click="organization.deleteAllUnderPath.push('')"
            >mdi-plus-circle-outline</v-icon
          >
        </div>
      </div>
      <OnlineHelp
        page="settings"
        section="emptyFolders"
        title="Empty Folder Handling"
      />
    </div>
  </div>
</template>

<script>
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

const foldersDeletionItems = [
  {
    text: 'No Auto Deletion',
    value: 0,
  },

  {
    text: 'Delete All Empty Folders',
    value: 1,
  },

  {
    text: 'Delete Only Folders Under Specified Paths',
    value: 2,
  },
]
export default {
  name: 'FileDirOptions',
  data() {
    return {
      foldersDeletionItems,
    }
  },
  props: {
    organization: Object,
  },
  watch: {
    'organization.foldersDeletion': {
      handler: function (n) {
        if (n === 2 && this.organization.deleteAllUnderPath.length === 0)
          this.organization.deleteAllUnderPath.push('')
      },
      deep: true,
    },
  },
  components: { OnlineHelp },
  methods: {
    removeItemFromArr(item) {
      this.organization.deleteAllUnderPath =
        this.organization.deleteAllUnderPath.filter(el => el !== item)
    },
  },
}
</script>
