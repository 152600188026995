<template>
  <div class="pos-relative">
    <div
      :class="[isDrag && 'drop-files-drag']"
      @click.prevent="openSelectFiles"
      class="drop-files-wrapper pointer d-flex flex-column justify-center align-center"
    >
      <img
        class="my-1"
        height="60px"
        src="@/assets/upload-files-blue.svg"
        alt="Drop files here"
      />

      <h2 class="my-2 fs-1-5r">Drop Files Here</h2>
    </div>

    <input
      @change="inputChanged"
      type="file"
      class="d-none"
      multiple
      ref="fileInput"
    />
  </div>
</template>

<script>
import { fileAddedFromInput } from '../../Uploading'

export default {
  name: 'DropFiles',
  props: {
    isDrag: Boolean,
    session: Object,
  },
  methods: {
    openSelectFiles() {
      this.$refs.fileInput.click()
    },

    inputChanged(e) {
      fileAddedFromInput(this, e)
    },
  },
}
</script>

<style scoped src="./DropFiles.css"></style>
