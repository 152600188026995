<template>
  <v-btn
    @click="SET_IS_DARKMODE(!isDarkMode)"
    depressed
    color="primary"
    class="small-btn bg-white mx-1"
    outlined
  >
    <v-icon color="primary"
      >mdi-weather-{{ isDarkMode ? 'night' : 'sunny' }}</v-icon
    >
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'DarkMode',

  computed: {
    ...mapGetters(['isDarkMode']),
  },
  methods: {
    ...mapMutations(['SET_IS_DARKMODE']),
  },
}
</script>
