<template>
  <div class="tfa-wrapper wrapper-1 bg-white">
    <!--TITLE-->
    <v-card-title class="text-center bg-grey fs-1-5r card-title">
      {{ $t('2 Step Verification') }}
    </v-card-title>

    <div class="pa-2">
      <div v-if="lastDigits">
        <span
          >{{ $t('A verification code was sent to phone that end with') }}:
          ****{{ lastDigits }}</span
        >
      </div>

      <v-text-field
        v-model="code"
        :label="$t('Verification Code')"
        @keypress.enter="confirmLogin"
        autofocus
        outlined
        class="pa-0 ma-0 mt-4"
        placeholder=" "
        type="number"
        hide-details
        dense
        maxLength="6"
      ></v-text-field>
      <div class="d-flex justify-center">
        <Button
          :disabled="code == 0"
          :clickAction="confirmLogin"
          :text="$t('Confirm')"
          :loading="loading"
          class="my-2"
          width="120px"
        ></Button>
      </div>
      <v-divider class="my-2"></v-divider>
      <div class="d-flex justify-end align-center">
        <small class="light-text f-12" v-if="timerTime > 0">
          {{ $t('You can ask for a new code in') }}: {{ timerTime }}
          {{ $t('seconds') }}
        </small>

        <Button
          v-else
          :clickAction="sendCodeClicked"
          :text="$t('Send New Code')"
          :disabled="timerTime > 0"
          width="120px"
          btnType="grey"
        >
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { api } from '@/config'
import { mapMutations } from 'vuex'

export default {
  name: 'ConfirmedLogin',
  data() {
    return {
      timerTime: 0,
      timer: null,
      loading: false,
      code: '',
      lastDigits: null,
      recipient: null,
    }
  },
  props: {
    setIsAuthenticated: Function,
    encryptedPhone: String,
    recipientEmail: String,
    casualEmail: String,
  },
  emits: ['set-token'],
  components: { Button },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    async sendCodeClicked() {
      this.restartTimer()
      await this.sendCode()
    },
    async sendCode() {
      try {
        console.log("this.casualEmail: " + this.casualEmail)
        this.SET_PROCESSING(true)
        const res = await api.post(
          'casuals/send-tfa-code',
          {
            cipherText: this.encryptedPhone,
            registeredEmail: this.recipientEmail,
            casualEmail: this.casualEmail,
          }
        )
        if (res.status !== 200) throw Error
        if (res.data.token){
          this.$emit('set-token', res.data.token)
          this.setIsAuthenticated()
        }
        this.lastDigits = res.data.lastDigits
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    async confirmLogin() {
      try {
        this.SET_PROCESSING(true)
        const res = await api.post(`casuals/verify-code/${this.code}`, {
            cipherText: this.encryptedPhone,
            registeredEmail: this.recipientEmail,
            casualEmail: this.casualEmail,
          })
        if (res.status !== 200) throw Error
        this.$emit('set-token', res.data.token)
        this.setIsAuthenticated()
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },

    restartTimer() {
      clearInterval(this.timer)
      this.timerTime = 30
      this.timer = setInterval(() => {
        --this.timerTime
      }, 1000)
    },
  },
  async beforeMount() {
    await this.sendCode()
    this.restartTimer()
  },
}
</script>

<style lang="scss" scoped>
.tfa-wrapper {
  width: 250px;
}
h1 {
  font-size: 1.25rem;
}

.card-title {
  border-radius: 10px 10px 0 0;
}
</style>
