<template>
  <div
    class="register-tab"
    :class="[
      loggedUser.role === 'SuperAdmin' &&
        $route.path.includes('/registered') &&
        'register-tab-super',
    ]"
  >
    <v-tabs v-model="tabModel" class="mb-2">
      <v-tab @click="tabClicked('users')">Users</v-tab>
      <v-tab @click="tabClicked('routes')">Routes</v-tab>
    </v-tabs>
    <!--CHANGE -->
    <router-view @tabClicked="tabClicked"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RegisteredUsersTab',
  data() {
    return {
      tabModel: 'users',
    }
  },
  emits: ['changeTab'],
  computed: {
    ...mapGetters(['currentOrganization', 'loggedUser']),
  },
  methods: {
    ...mapActions(['GET_SINGLE_ORGANIZATION']),
    tabClicked(tabName) {
      if (
        this.$route.path !==
        `/management/hosts/${this.currentOrganization.organizationId}/registered/${tabName}`
      ) {
        this.tabModel = tabName
        this.$router.push(
          `/management/hosts/${this.currentOrganization.organizationId}/registered/${tabName}`
        )
      }
    },
  },
  async created() {
    const { organizationId } = this.$route.params
    // await this.GET_SINGLE_ORGANIZATION(organizationId)
    this.tabClicked('users')
    this.$emit('changeTab', 'tab-registered')
  },
}
</script>

<style scoped src="./RegisteredUsersTab.css"></style>
