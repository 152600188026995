<template>
  <div class="pa-2">
    <h4 class="settings-h4">Security</h4>

    <div class="wrapper-2 d-flex align-center pa-4 mb-4 pos-relative">
      <!--CHECKBOX TO ALLOW EDIT-->
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-simple-checkbox
              v-model="isSettingsEnabled"
              style="display: inline"
              class="mr-2"
              :disabled="status !== 0"
            ></v-simple-checkbox>
          </span>
        </template>
        <span>{{ isSettingsEnabled ? 'Disable' : 'Enable' }} updating</span>
      </v-tooltip>

      <div>
        <!--HOW MUCH TIME BEFORE LOGUOT TO SHOW THE MESSAGE-->
        <div class="d-flex align-baseline">
          <span class="mx-1" :class="[!isSettingsEnabled && 'disabled']"
            >Idle time duration before logging out automatically:</span
          >
          <v-text-field
            v-model.number="organization.theme.idleCountDown"
            :error="isErrorIdle"
            style="max-width: 30px"
            class="mx-1 light-text centered-input"
            dense
            height="20px"
            hide-details
            type="number"
            :disabled="!isSettingsEnabled"
          >
          </v-text-field>
          <span :class="[!isSettingsEnabled && 'disabled']">minutes.</span>
        </div>

        <!--HOW MUCH TIME TO SHOW THE MESSAGE BEFORE IT WILL LOGOUT-->
        <div class="d-flex align-baseline">
          <span class="mx-1" :class="[!isSettingsEnabled && 'disabled']"
            >Warning message before logging out:</span
          >
          <v-text-field
            v-model.number="organization.theme.idleCountDownMessage"
            :error="isErrorMessage"
            style="max-width: 30px"
            class="mx-1 light-text centered-input"
            dense
            height="20px"
            hide-details
            type="number"
            :disabled="!isSettingsEnabled"
          >
          </v-text-field>
          <span class="mx-1" :class="[!isSettingsEnabled && 'disabled']"
            >seconds.</span
          >
        </div>
      </div>
      <OnlineHelp page="settings" section="security" title="Security"/>
    </div>
  </div>
</template>

<script>
  //Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
    name: "IdleTimeSettings",
    data() {
        return {
            isErrorIdle: false,
            isSettingsEnabled: false,
            isErrorMessage: false,
        };
    },
    props: {
        organization: Object,
        status: Number,
    },
    watch: {
        "organization.theme.idleCountDown": {
            handler: function (n) {
                this.isErrorIdle = false;
                if (n <= 0)
                    this.isErrorIdle = true;
                else if (n > 30)
                    this.isErrorIdle = true;
                else if (n.toString().includes("."))
                    this.isErrorIdle = true;
            },
        },
        "organization.theme.idleCountDownMessage": {
            handler: function (n) {
                this.isErrorMessage = false;
                if (n <= 0)
                    this.isErrorMessage = true;
                else if (n < 60 || n > 1000)
                    this.isErrorMessage = true;
                else if (n.toString().includes("."))
                    this.isErrorMessage = true;
            },
        },
    },
    components: { OnlineHelp }
}
</script>

