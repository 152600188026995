export const policiesTableHeaders = [
  {
    text: 'Policy name',
    value: 'name',
    class: 'table-header-first bg-table-heading',
  },
  {
    text: '',
    value: 'rename',
    class: ' bg-table-heading',
    sortable: false,
  },
  {
    text: '',
    value: 'edit',
    class: ' bg-table-heading',
    sortable: false,
  },
  {
    text: '',
    value: 'delete',
    class: 'table-header-last bg-table-heading',
    sortable: false,
  },
]
