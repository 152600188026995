<template>
  <div class="next-back-wrapper">
    <div>
      <Button :text="step === 2 ? 'restart' : 'Back'" v-if="step != 1" :clickAction="backClicked" />
    </div>
    <div class="d-flex align-center">
      <span v-if="isShowErrMsg" class="red--text mr-4 error-msg">{{ errorMessage }}</span>
      <div @mouseover="isShowErrMsg = true" @mouseleave="isShowErrMsg = false">
        <Button :text="backBtnText" :clickAction="nextClicked" :disabled="isNextDisabled" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'NextBackButtons',
  data() {
    return {
      isShowErrMsg: false,
    }
  },
  props: {
    step: Number,
    backClicked: Function,
    nextClicked: Function,
    isNextDisabled: Boolean,
    errorMessage: String,
  },
  components: {
    Button,
  },
  computed: {
    backBtnText() {
      if (this.step === 1) return 'Start by selecting some files'
      if (this.step === 2) return 'Next'
      if (this.step === 3) return 'Send to Filter'
      return 'Next'
    }
  }
}
</script>

<style scoped>
.next-back-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-msg {
  font-size: medium; font-weight: 400;
}
</style>
