<template>
  <div class="pa-2">
    <h4 class="settings-h4">Policies Management</h4>
    <div class="wrapper-2 pa-4 mb-4 pos-relative">
      <!--BUTTONS TO IMPORT AND CREATE-->
      <div class="d-flex justify-space-between mr-3">
        <Button
          :text="
            isShowAllPolices ? 'Close Policies Table' : 'Open Policies Table'
          "
          width="230px"
          :icon="isShowAllPolices ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          btnType="grey"
          class="mx-2"
          :clickAction="() => (isShowAllPolices = !isShowAllPolices)"
        />
        <ButtonsCreateImportPolicy :createNewPolicy="createNewPolicy" />
      </div>

      <v-data-table
        v-if="isShowAllPolices"
        class="wrapper-1 mt-2"
        :headers="policiesTableHeaders"
        :items="computedPolicies"
        :options="{ sortBy: ['name'] }"
        must-sort
        dense
        noDataText="No policies defined"
      >
        <!--EDIT-->
        <template v-slot:item.edit="{ item }">
          <Button
            text="Edit"
            class="my-2"
            :clickAction="() => editPolicyClicked(item.name)"
          />
        </template>

        <!--RENAME-->
        <template v-slot:item.rename="{ item }">
          <Button
            btnType="grey"
            text="Rename"
            :clickAction="() => (policyToRename = item.name)"
          />
        </template>

        <!--DELETE-->
        <template v-slot:item.delete="{ item }">
          <v-icon
            large
            @click="() => deletePolicyClicked(item.name)"
            color="red"
            >mdi-delete-circle-outline</v-icon
          >
        </template>
      </v-data-table>

      <div v-if="isShowAllPolices" style="height: 30px">
        <OnlineHelp page="settings" section="policies" title="Policies" />
      </div>
      <OnlineHelp v-else page="settings" section="policies" title="Policies" />
    </div>

    <!--DIALOG TO RENAME-->
    <RenameDialog
      v-if="policyToRename"
      :closeDialog="() => (policyToRename = null)"
      :policyToRename="policyToRename"
    />

    <!--DIALOG TO EDIT-->
    <EditDialog
      v-if="policyToEdit"
      :policyToEdit="policyToEdit"
      :closeDialog="closeEditDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { confirmDialog } from '@/utils'
import { api } from '@/config'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Childrens
import RenameDialog from './Children/RenameDialog/RenameDialog.vue'
import EditDialog from './Children/EditDialog/EditDialog.vue'
import ButtonsCreateImportPolicy from './Children/ButtonsCreateImportPolicy/ButtonsCreateImportPolicy.vue'

//Component files
import { policiesTableHeaders } from './PolicyScreen'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'PolicyScreen',
  data() {
    return {
      policyToRename: null,
      policyToEdit: null,
      isShowAllPolices: false,
      policiesTableHeaders,
    }
  },
  components: {
    Button,
    RenameDialog,
    EditDialog,
    ButtonsCreateImportPolicy,
    OnlineHelp,
  },
  computed: {
    ...mapGetters(['policies', 'loggedUser']),
    computedPolicies() {
      return this.policies.map(pol => ({ name: pol }))
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    ...mapActions(['GET_POLICIES']),

    createNewPolicy(policyName) {
      this.policyToEdit = policyName
    },

    editPolicyClicked(policyName) {
      this.policyToEdit = policyName
    },

    async closeEditDialog() {
      try {
        this.SET_PROCESSING(true)
        await this.GET_POLICIES(this.loggedUser.organizationId)
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
        this.policyToEdit = null
      }
    },
    async deletePolicyClicked(policyName) {
      const thenFunc = () => null
      //catchFunc will remove policy
      const catchFunc = async () => {
        try {
          this.SET_PROCESSING(true)

          const hostId = this.loggedUser.organizationId
          const res = await api.delete(
            `organizations/${hostId}/deletepolicy?policy=${policyName}`
          )

          if (res.status !== 200) throw Error

          this.SET_NOTIFICATION({
            type: 'success',
            text: 'Policy was deleted successfully.',
          })

          this.GET_POLICIES(this.loggedUser.organizationId)
        } catch (e) {
          this.SET_NOTIFICATION({
            type: 'Error',
            text: 'Error: Policy was not deleted',
          })
          console.log(e)
        } finally {
          this.SET_PROCESSING(false)
        }
      }
      const text = `Are you sure you want to delete the policy "${policyName}"?`
      confirmDialog(this, text, 'Cancel', 'Delete', thenFunc, catchFunc)
    },
  },
  async created() {
    if (!this.policies.length)
      await this.GET_POLICIES(this.loggedUser.organizationId)
  },
}
</script>
