<template>
  <div
    :style="`margin: 8px ${(level + 1) * 4}px`"
    class="wrapper-1 directory-wrapper"
    tabindex="0"
    :aria-label="'directory ' + dir.name"
  >
    <!--DIRECTORY NAME AND FOLDER IMAGE-->
    <div class="directory-name-img-wrapper">
      <div class="folder status-dir-foramt" />
      <span class="dir-name">{{ dir.name }}</span>
    </div>

    <!--FILES INSIDE THE FOLDER-->
    <FileRow
      v-if="files.length"
      v-for="childFile of files"
      :file="childFile"
      :key="`${childFile.name}${childFile.historyId}`"
    />

    <!--THIS IS RECURSIVE!!!! - DIRECTORIES -->
    <DirRow
      v-if="directories.length"
      v-for="childDir of directories"
      :dir="childDir"
      :key="childDir.name"
      :level="level + 1"
    />
  </div>
</template>

<script>
import FileRow from '../FileRow/FileRow.vue'

export default {
  name: 'DirRow',
  data() {
    return {
      files: [],
      directories: [],
    }
  },
  props: {
    dir: Object,
    level: Number,
  },
  created() {
    this.dir.children.forEach(el => {
      if (el.type === 'file') this.files.push(el)
      else this.directories.push(el)
    })
  },
  components: { FileRow },
}
</script>

<style scoped src="../../DirFilesTableReportDialog.css"></style>
