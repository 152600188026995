<template>
  <div class="d-flex align-center new-password">
    <!--SHOW THE PASSWORDS SETS -->
    <NewPasswordFields
      v-if="!isPasswordUpdated"
      @set-is-password-updated="v => (isPasswordUpdated = v)"
      @set-email="v => (email = v)"
      :setIsFirstTimeGlobal="v => (isFirstTimeGlobal = v)"
    />
    <!--SHOW THE PASSWORD WAS UPDATED SUCCESESSFULLY -->
    <ContinueScreen v-else :email="email" :isFirstTimeGlobal="isFirstTimeGlobal"/>
  </div>
</template>

<script>
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Childrens
import NewPasswordFields from './Childrens/NewPasswordFields/NewPasswordFields.vue'
import ContinueScreen from './Childrens/ContinueScreen/ContinueScreen.vue'

export default {
  name: 'SetNewPassword',
  data() {
    return {
      isPasswordUpdated: false,
      email: null,
      isFirstTimeGlobal: false,
    }
  },
  components: {
    Button,
    NewPasswordFields,
    ContinueScreen,
  },
}
</script>

<style scoped src="./SetNewPassword.css"></style>
