<template>
  <div class=" mx-2">
    <!--LET THE USER ADD SUBJECT-->
    <!-- <v-checkbox
      v-model="userEndPoint.isAllowInteractiveSubject"
      class="mt-0 mr-4"
      :label="interactiveInterfaceFields.subjectFieldText === 'Subject' ? 'Allow subject and message' : 'Allow '"
      dense
      hide-details
      :disabled="isEditDisabled || userEndPoint.userStatus !== 0"
    >
    </v-checkbox> -->

    <!-- SHOW PROGRESS-->
    <v-checkbox
      v-model="userEndPoint.isShowInteractiveProgress"
      label="Show progress"
      :disabled="isEditDisabled || userEndPoint.userStatus !== 0"
      hide-details
      class="mt-0 mr-4"
      dense
    >
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'SourceInteractive',
  props: {
    isEditDisabled: Boolean,

    userEndPoint: {
      type: Object,
    },
  },
}
</script>
