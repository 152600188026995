<template>
  <div class="theme-settings ma-2">
    <h4 class="settings-h4">Interactive Interface Settings</h4>
    <div class="wrapper-2 pa-3 mb-2 pos-relative">
      <div class="d-flex">
        <span class="fs-1-12r mr-2 mt-1">Text in "Subject" field: </span>
        <v-select
        v-model="
          organization.interactiveInterfaceDynamicFieldNames.subjectFieldText
          "
        :items="['Subject', 'Request number']"
        dense
        hide-details
        class="mw-160 mx-1"
        height="22px"
        ></v-select>
      </div>
      <div class="d-flex mt-4">
        <span class="fs-1-12r mr-2 mt-1">Text in "Message" field: </span>
      <v-select
        v-model="
          organization.interactiveInterfaceDynamicFieldNames.messageFieldText
        "
        :items="['Message', 'Sender details']"
        dense
        hide-details
        class="mw-160 mx-1"
        height="22px"
      ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadingScreenOptions',
  props: {
    organization: Object,
  },
}
</script>

