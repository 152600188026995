<template>
  <div class="my-4">
    <h4 class="settings-h4">Directory Structure</h4>
    <div class="wrapper-1 pa-2">
      <div>
        <v-checkbox
          v-model="kioskSettings.isIncludeKioskInHierarchy"
          class="ma-0 pa-0 my-2"
          hide-details
          label="Create a target folder per each kiosk"
        >
        </v-checkbox>

        <div v-if="kioskSettings.isIncludeKioskInHierarchy">
          <span class="fs-1r mx-4 mb-1 label">Create new folder under:</span>
          <v-radio-group
            v-model="kioskSettings.kioskFolderName"
            row
            class="ma-0 pa-0 mx-5 mt-1"
            hide-details
          >
            <v-radio label="Kiosk Name" value="kioskName"></v-radio>
            <v-radio label="Hardware ID" value="hardwareId"></v-radio>
          </v-radio-group>
        </div>
      </div>

      <v-checkbox
        v-model="kioskSettings.isIncludeSessionTimeInHierarchy"
        class="ma-0 pa-0 my-2"
        hide-details
        label="Create a timestamp folder per each session"
      >
      </v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FoldersHierarchy',

  props: {
    kioskSettings: Object,
  },
}
</script>
