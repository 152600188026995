<template>
  <footer class="d-flex justify-space-between">
    <span class="version-text">Version: {{ CURRENT_VERSION }}</span>
    <a
      v-if="isShowTestSSL"
      class="ssl-logo"
      href="javascript:if(window.open('http://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=POSDV&v_search=http://bestcdr.com&x=6&y=5','tl_wnd_credentials'+(new Date()).getTime(),'toolbar=0,scrollbars=1,location=1,status=1,menubar=1,resizable=1,width=374,height=660,left=60,top=120')){};tLlB(tLTB);"
    >
      <img :src="ssl" alt="SSL" />
    </a>
    <a
      v-else-if="isShowProdSSL"
      class="ssl-logo"
      href="javascript:if(window.open('http://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=POSDV&v_search=http://dotengines.com&x=6&y=5','tl_wnd_credentials'+(new Date()).getTime(),'toolbar=0,scrollbars=1,location=1,status=1,menubar=1,resizable=1,width=374,height=660,left=60,top=120')){};tLlB(tLTB);"
    >
      <img :src="ssl" alt="SSL" />
    </a>
  </footer>
</template>

<script>
import metadata from '@/metadata.json'
import ssl from '@/assets/positivessl.png'

export default {
  name: 'Footer',
  data() {
    return {
      ssl,
    }
  },
  computed: {
    CURRENT_VERSION() {
      return metadata.buildRevision
    },
    isShowTestSSL() {
      if (
        window.location.host.includes('bestcdr') &&
        this.$route.name === 'Home'
      )
        return true
      return false
    },
    isShowProdSSL() {
      if (
        window.location.host.includes('dotengines') &&
        this.$route.name === 'Home'
      )
        return true
      return false
    },
  },
}
</script>

<style scoped src="./Footer.css"></style>
