<template>
  <div class="d-flex my-2 light-text">
    <span class="ml-1 w-210" :class="[isEditDisabled && 'low-op-disabeld']"
      >Alert before deletion (email):</span
    >

    <!--INPUT BY NUMBER-->
    <v-text-field
      v-model="alertBeforeDeleteTime.number"
      @input="deleteFileChanged"
      :rules="digitsRules"
      :disabled="isEditDisabled"
      :error="isDeleteAlertTimesError"
      class="mx-3 my-0 pa-0 mw-30 centered-input"
      height="16px"
      type="number"
      hide-details
    ></v-text-field>

    <!--SELECT WHEN-->
    <v-select
      v-model="alertBeforeDeleteTime.type"
      @input="deleteFileChanged"
      :error="isDeleteAlertTimesError"
      :disabled="isEditDisabled"
      :items="['Hours', 'Days']"
      class="ma-0 pa-0 mw-100"
      height="16px"
      hide-details
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'AlertBeforeDelete',
  props: {
    alertBeforeDeleteTime: Object,
    isEditDisabled: Boolean,
    route: Object,
    isDeleteAlertTimesError: Boolean,
  },
  computed: {
    digitsRules(value) {
      return [
        value => {
          if (value === 0) return true
          return !!value || 'Required'
        },
        value => value >= 0 || 'Only positive numbers',
        value => (value % 1 === 0 && value !== 'e') || 'Only integers',
      ]
    },
  },

  methods: {
    deleteFileChanged() {
      //   this.route.alertBeforeDeletionByMs
      if (!this.alertBeforeDeleteTime.number) return 0

      //alert file deletion x HOURS before
      if (this.alertBeforeDeleteTime.type === 'Hours')
        return (this.route.alertBeforeDeletionByMs =
          this.alertBeforeDeleteTime.number * 3600000)
      // value * 1 hour
      //alert file deletion x DAYS before
      else if (this.alertBeforeDeleteTime.type === 'Days')
        return (this.route.alertBeforeDeletionByMs =
          this.alertBeforeDeleteTime.number * 86400000) // value * 1 day
    },
  },

  mounted() {
    //if the alert before delete is in HOURS
    if (this.route.alertBeforeDeletionByMs < 86400001) {
      this.alertBeforeDeleteTime.type = 'Hours'
      this.alertBeforeDeleteTime.number =
        this.route.alertBeforeDeletionByMs / 3600000
    }

    //if the alert is in DAYS
    else {
      this.alertBeforeDeleteTime.type = 'Days'
      this.alertBeforeDeleteTime.number =
        this.route.alertBeforeDeletionByMs / 86400000
    }
  },
}
</script>
