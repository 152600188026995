<template>
  <div class="d-flex justify-center pb-2">
    <!--START BUTTON-->

    <button
      v-if="isShowStartButton"
      @mouseover="setIsShowFilteringErrMessage(true)"
      @mouseleave="setIsShowFilteringErrMessage(false)"
      @click="
        filteringErrMessage
          ? setIsShowUploadingDetails(true)
          : startFilteringClicked()
      "
      :class="[filteringErrMessage ? 'start-btn-disabled' : 'start-btn-active']"
      class="start-btn"
      :aria-label="
        filteringErrMessage
          ? filteringErrMessage + 'to start filtering'
          : 'Start filtering'
      "
      tabindex="0"
    >
      <div role="button" class="ma-auto">
        <h3>{{ $t('Start') }}</h3>
      </div>
    </button>

    <!--RESTART BUTTON-->
    <div
      v-if="isShowRestartButton"
      @click="restartSessionClicked"
      @mouseover="setIsShowFilteringErrMessage(false)"
      class="start-btn start-btn-active d-flex justify-center my-2"
    >
      <h3 class="mx-1" role="button">{{ $t('Additional Upload') }}</h3>
      <v-icon color="white" class="mx-1">mdi-cached</v-icon>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StartRestartButtons',
  data() {
    return {
      isStartButtonDisabled: false,
    }
  },
  props: {
    startFilteringClicked: Function,
    setIsShowFilteringErrMessage: Function,
    setIsShowUploadingDetails: Function,
    restartSessionClicked: Function,
    filteringErrMessage: String,
    session: Object,
  },
  computed: {
    ...mapGetters(['isInMiddleOfFiltering']),
    isShowStartButton() {
      //if the user is in middle of session
      if (this.session.operationId) return false
      return true
    },

    isShowRestartButton() {
      if (this.isInMiddleOfFiltering) return false
      if (!this.session.operationId) return false
      return true
    },
  },
}
</script>

<style scoped lang="scss" src="./StartRestartButtons.scss"></style>
