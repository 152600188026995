<template>
  <div class="wrapper-1 pa-2 my-4 mw-300">
    <span class="fs-1r label">Search by host</span>
    <div class="d-flex">
      <div class="d-flex align-center">
        <!--ACTIVE / INACTIVE-->
        <div class="mx-1">
          <v-checkbox
            v-model="isShowActiveHosts"
            label="Active"
            class="ma-0 pa-0"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="isShowInactiveHosts"
            label="Inactive"
            class="ma-0 pa-0"
            hide-details
          ></v-checkbox>
        </div>
      </div>
      <!--SELECT HOST-->
      <v-select
        v-model="profile.orgId"
        :items="computedOrgsList"
        item-value="organizationId"
        item-text="name"
        class="mw-220 mx-2 pa-0 my-0"
        hide-details
        clearable
      ></v-select>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { api } from '@/config'
export default {
  name: 'HostSelect',
  data() {
    return {
      isShowActiveHosts: true,
      isShowInactiveHosts: false,
      activeHosts: [],
      inactiveHosts: [],
    }
  },

  props: {
    profile: Object,
  },

  computed: {
    computedOrgsList() {
      let arr = []

      if (this.isShowActiveHosts) arr = [...this.activeHosts]
      if (this.isShowInactiveHosts) arr = [...arr, ...this.inactiveHosts]

      return arr
    },
  },

  methods: {
    ...mapMutations(['SET_PROCESSING']),
  },
  async created() {
    try {
      this.SET_PROCESSING(true)
      const res = await api.get('organizations/getallorgnames')
      if (res.status !== 200) throw Error

      const activeHosts = []
      const inactiveHosts = []

      res.data.forEach(host => {
        if (host.status === 3) inactiveHosts.push(host) //3 is enum for deleted
        else activeHosts.push(host)
      })

      this.activeHosts = activeHosts
      this.inactiveHosts = inactiveHosts
    } catch (e) {
      console.log(e)
    } finally {
      this.SET_PROCESSING(false)
    }
  },
}
</script>
