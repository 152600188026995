<template>
  <div class="mx-2 fill-height">
    <div v-if="idx" class="d-flex justify-space-between">
      <!--TITLE-->
      <h2>{{ idx }}. Storage Used</h2>
      <!--EXPAND-->
      <v-icon @click="expandClicked('Storage')">mdi-arrow-expand</v-icon>
    </div>

    <div class="d-flex justify-end fs-1-25r">
      <span>{{ computedusedStorage }}</span>
    </div>
    <v-progress-linear :value="storagePercentage" height="30" color="#a2d5ff">
      <span class="fs-1-5r font-weight-bold">{{ storagePercentage }}</span>
    </v-progress-linear>

    <!--ON SMALL SCREEN - TOP 3 -  ON BIG SCREEN - ALL-->
    <span v-if="idx" class="fs-1r">Top 3 organizations for occupancy:</span>
    <div
      class="d-flex flex-column justify-space-between justify-center "
      :class="[!idx && 'small-screen-height overflow-auto']"
    >
      <div
        v-for="org of computedShowOrgs"
        :key="org.id"
        class="d-flex justify-space-between align-center fs-1-25r my-1 pos-relative"
      >
        <div
          class="counter-line"
          :style="{ width: `${org.percentage}%` }"
        ></div>
        <!--IMAGE AND NAME-->
        <div class="d-flex align-center">
          <v-img
            :src="`${selectedHost}/api/images/${org.id}/logo`"
            alt="Header logo"
            class="shown-logo mx-2"
            :class="[!idx && 'h-50']"
            contain
          />

          <span class="mx-2 font-weight-bold z-idx-1">{{ org.name }}</span>
        </div>

        <!--BYTES & PERCENTAGE-->
        <div class="z-idx-1 font-weight-bold">
          <span class="mx-1">{{ org.used | bytesToSize }}</span>
          <span class="mx-1">|</span>
          <span class="mx-1">{{ org.percentage }} %</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectedHost } from '@/config'
import { bytesToSize } from '@/utils'
const organizations = [
  { name: 'dotEngines', used: 12312234123, id: 0 },
  { name: 'D and A', used: 52332238234, id: 8 },
  { name: 'test1', used: 0, id: 21 },
  { name: 'sdfsdfsd', used: 0, id: 9 },
  { name: 'dfdsfsd', used: 0, id: 7 },
  { name: 'EAT', used: 0, id: 11 },
  { name: 'transaction', used: 0, id: 12 },
  { name: 'ASDASD', used: 0, id: 6 },
  { name: 'Butterfly', used: 0, id: 1 },
  { name: 'Lion Investmenssts', used: 0, id: 4 },
  { name: 'Lion Investmentdas', used: 0, id: 15 },
  { name: 'Lion Investsdsments', used: 0, id: 42 },
  { name: 'Ddos', used: 99111191231, id: 51 },
]
export default {
  name: 'Storage',
  data() {
    return {
      selectedHost,
      allOrgs: [],
      biggestOrgs: [],
    }
  },
  props: {
    analyticsData: Object,
    idx: Number,
    expandClicked: Function,
  },
  filters: {
    bytesToSize,
  },
  computed: {
    storagePercentage() {
      const { total, used } = this.analyticsData.storage
      return `${((used / total) * 100).toFixed(2)} %`
    },
    computedusedStorage() {
      const { total, used } = this.analyticsData.storage

      return `${bytesToSize(used)} / ${bytesToSize(total)}`
    },

    computedShowOrgs() {
      //on small screen
      if (this.idx) return this.biggestOrgs
      //big screen
      return this.allOrgs
    },
  },

  created() {
    //cancel it when supported
    this.analyticsData.storage = {
      total: 536870912000,
      used: 245334231312,
      organizations,
    }
    const { total } = this.analyticsData.storage

    const sortedOrganizations = this.analyticsData.storage.organizations.sort(
      (a, b) => b.used - a.used
    )

    this.allOrgs = sortedOrganizations.map(el => {
      const percentage = ((el.used / total) * 100).toFixed(2)

      return {
        ...el,
        percentage,
      }
    })
    this.biggestOrgs = this.allOrgs.slice(0, 3)
  },
}
</script>

<style scoped>
.shown-logo {
  max-width: 50px !important;
  height: 40px !important;
  border: 1px solid #dedee2;
  background-color: #dedee2;
}

.counter-line {
  position: absolute;
  background-color: #a2d5ff;
  z-index: 0;
  height: 100%;
  width: 500px;
}

.z-idx-1 {
  z-index: 1;
}

.small-screen-height {
  height: calc(100% - 120px);
}
</style>
