var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"org-routes"},[(!_vm.isUserRoutes)?_c('HeadingPanel',{attrs:{"type":"route","filtersToShow":['status'],"updateFilter":_vm.updateFilter,"updateSearch":_vm.updateSearch,"isFromUsers":false},on:{"open-modal":function($event){return _vm.openRouteModal(null, 'add')}}}):_vm._e(),(!_vm.isUserRoutes)?_c('Table',{attrs:{"items":_vm.computedRoutesList,"headers":_vm.computedRouteHeaders,"options":{ sortBy: ['fakeID'] },"rowClickAction":_vm.openRouteModal,"itemsPerPage":-1,"type":"org routes"}}):_vm._e(),(_vm.isUserRoutes)?_c('v-data-table',{staticClass:"wrapper-1 routes-table ma-1",class:[_vm.isUserRoutes && 'my-2'],attrs:{"fixed-header":"","footer-props":{
      'items-per-page-options': [-1, 5, 10, 25, 50],
    },"items-per-page":-1,"items":_vm.computedRoutesList,"headers":_vm.computedRouteHeaders,"options":{ sortBy: ['id'] },"must-sort":"","noDataText":"No routes defined"},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([(_vm.computedRoutesList.length)?{key:"body",fn:function({ items }){return [_c('tbody',[_vm._l((items),function(item){return _c('tr',{key:item.email,staticClass:"pointer",class:[item.status !== 0 && 'semi-opacity'],on:{"click":function($event){return _vm.openRouteModal(item, 'edit')}}},[_c('td',[_c('span',{class:{
                'semi-opacity': item.routeStatus === 1,
                'semi-opacity-archived': item.routeStatus === 2,
              }},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('td',[_c('span',{class:{
                'semi-opacity': item.routeStatus === 1,
                'semi-opacity-archived': item.routeStatus === 2,
              }},[_vm._v(" "+_vm._s(_vm._f("convertRouteStatus")(item.status))+" ")])]),(_vm.isUserRoutes)?_c('td',[_c('span',{class:{
                'semi-opacity': item.routeStatus === 1,
                'semi-opacity-archived': item.routeStatus === 2,
              }},[_vm._v(" "+_vm._s(item.sourceOrTarget)+" ")])]):_vm._e(),_c('td',{class:{
              'semi-opacity': item.routeStatus === 1,
              'semi-opacity-archived': item.routeStatus === 2,
            }},[_vm._v(" "+_vm._s(item.fakeID || item.routeId)+" ")]),(!_vm.isUserRoutes)?_c('td',[_c('v-btn',{attrs:{"x-small":"","fab":"","outlined":"","color":"#5B5B7A"},on:{"click":function($event){$event.stopPropagation();return _vm.openRouteModal(item, 'edit')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()])}),(!_vm.isSearchMatch)?_c('tr',[_c('td',{attrs:{"align":"center","colspan":"8"}},[_vm._v("No routes to display")])]):_vm._e()],2)]}}:{key:"no-data",fn:function(){return [_c('p',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.loading ? 'Loading...' : 'No routes to display')+" ")])]},proxy:true}],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }