<template>
  <div
    class="wrapper-1 pa-2 ma-1 d-flex justify-space-evenly align-center h-50"
    :class="[accessibility.fontSize > 1 && 'h-65']"
  >
    <!--FILTERING / TRANSEFERENCE REPORT-->
    <v-btn
      v-if="isShowFilteringReport"
      @click="showReportClicked"
      :disabled="isShowReportDisabled"
      fab
      :small="accessibility.fontSize === 1"
      outlined
      color="grey"
      aria-label="Filtering Report selected"
    >
      <v-icon :large="accessibility.fontSize > 1"> mdi-file </v-icon>
    </v-btn>

    <!--DELETE FILES-->
    <v-btn
      @click="deleteFilesClicked"
      :disabled="isDeleteDownloadFilesDisabled"
      fab
      :small="accessibility.fontSize === 1"
      outlined
      color="red"
      aria-label="Delete selected"
    >
      <v-icon :large="accessibility.fontSize > 1"> mdi-delete </v-icon>
    </v-btn>

    <!--DOWNLOAD FILES-->
    <v-btn
      @click="downloadFilesClicked"
      :disabled="isDeleteDownloadFilesDisabled"
      fab
      :small="accessibility.fontSize === 1"
      outlined
      color="primary"
      aria-label="Download files selected"
    >
      <v-icon :large="accessibility.fontSize > 1"> mdi-download </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SelectedDirFilesActions',

  props: {
    selectedDirFiles: Array,
    showReportClicked: Function,
    downloadFilesClicked: Function,
    deleteFilesClicked: Function,
  },

  computed: {
    ...mapGetters(['loggedUser', 'accessibility']),
    isShowFilteringReport() {
      //casual can't see report
      if (this.loggedUser.role === 'Casual') return false

      //if the logged user dosen't have the option to see report
      if (!this.loggedUser.isAllowReport) return false

      return true
    },

    isShowReportDisabled() {
      if (this.isDeleteDownloadFilesDisabled) return true
      //if all the files that the user selected dosen't have historyId then there is no option to report
      if (this.selectedDirFiles.every(el => el.status && !el.historyId))
        return true
      return false
    },

    isDeleteDownloadFilesDisabled() {
      if (!this.selectedDirFiles.length) return true

      let sumOfSize = 0

      this.selectedDirFiles.forEach(dirFlie => (sumOfSize += dirFlie.size))

      if (sumOfSize === 0) return true
      return false
    },
  },
}
</script>
