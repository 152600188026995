<template>
  <div class="wrapper-1 pa-2 ma-2">
    <v-checkbox
      v-model="kiosk.isActive"
      label="Active"
      hide-details
    ></v-checkbox>

    <!--KIOSK NIKENAME-->
    <div class="d-flex align-center mb-1">
      <span class="mx-1 mt-2 required-field w-260">Kiosk nickname:</span>
      <v-text-field
        v-model="kiosk.nickname"
        dense
        hide-details
        height="20px"
        class="mx-0 pa-0 mw-300"
      >
      </v-text-field>
    </div>

    <!--KIOSK ID-->
    <div class="d-flex align-center mb-1">
      <span class="mx-1 mt-2 required-field w-260"
        >Uploading by Kiosk hardware S/N:</span
      >
      <v-text-field
        v-model="kiosk.kioskId"
        dense
        hide-details
        height="20px"
        class="mx-0 pa-0 mw-300"
      >
      </v-text-field>
    </div>

    <div class="d-flex align-center">
      <span class="mx-1 mt-2 required-field w-260"
        >Downloading by SFTP recipient Email:</span
      >
      <v-select
        v-model="localKioskRecipient"
        :items="computedUsers"
        item-value="email"
        item-text="email"
        dense
        hide-details
        height="20px"
        class="mx-0 pa-0 mw-300"
      ></v-select>
    </div>

    <!--USER SELECT FOR RECIPIENT AND DELETE-->
    <div class="d-flex justify-space-between align-center">
      <!--OPEN KIOSK IN TESTMODE-->
      <v-checkbox
        v-model="kiosk.isFullScreen"
        label="Open kiosk in full screen"
        hide-details
        class="mt-0"
      ></v-checkbox>

      <!--DELETE KIOSK-->
      <v-tooltip top>
        <template v-slot:activator="{ on, hover }">
          <v-hover v-slot="{ hover }">
            <v-btn
              @click="deleteKioskClicked(kiosk, i)"
              v-on="on"
              text
              icon
              :color="hover ? 'red' : 'grey'"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-hover>
        </template>
        <span>Delete kiosk</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'KioskLicense',
  data() {
    return {
      localKioskRecipient: '',
    }
  },
  watch: {
    localKioskRecipient(n) {
      if (n) this.kiosk.recipients = [n]
    },
  },
  props: {
    i: Number,
    kiosk: Object,
    computedUsers: Array,
    deleteKioskClicked: Function,
  },
  created() {
    if (this.kiosk.recipients)
      this.localKioskRecipient = this.kiosk.recipients[0]
  },
  components: { Button },
}
</script>
