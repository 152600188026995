<template>
  <div v-if="kioskSettings.filtering.isAutoSelectFiles" class="ml-4 mr-2 my-2">
    <div class="wrapper-1 pa-2 mt-1 w-250p">
      <span class="fs-1-12r my-1"> Folder names: </span>
      <div class="py-2 list-wrapper">
        <div v-for="(f, i) of foldersArr" :key="i" class="d-flex px-2">
          <v-text-field
            v-model="f.name"
            hide-details
            dense
            height="22px"
            class="ma-0 pa-0 mx-2"
          >
          </v-text-field>

          <v-icon @click="deleteFileExt(i)" small class="mt-06"
            >mdi-delete</v-icon
          >
        </div>
      </div>

      <div class="d-flex justify-end mt-2">
        <v-icon @click="foldersArr.push({ name: '' })">mdi-plus</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoSelectFiles',
  data() {
    return {
      foldersArr: [],
    }
  },
  props: {
    kioskSettings: Object,
  },

  watch: {
    foldersArr: {
      handler: function (n) {
        this.kioskSettings.filtering.autoFolderNames = this.foldersArr.map(f =>
          f.name.replace('.', '')
        )
      },
      deep: true,
    },
  },

  methods: {
    deleteFileExt(idx) {
      this.foldersArr.splice(idx, 1)
    },
  },

  created() {
    if (this.kioskSettings.filtering.autoFolderNames)
      this.foldersArr = this.kioskSettings.filtering.autoFolderNames.map(
        el => ({
          name: el,
        })
      )
    else this.foldersArr = []
    // this.filesArr = [{ name: 'png' }, { name: 'abc' }, { name: 'ai' }]
  },
}
</script>

<style scoped>
.mt-06 {
  margin-top: 6px;
}

.w-130 {
  width: 130px;
}

.list-wrapper {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
