<template>
  <div>
    <!-- THIS WILL ONLY SHOWN IF ACCOUNT TYPE IS MANAGEMENT -->
    <v-radio-group
      v-model="profile.role"
      :disabled="isFieldDisabled"
      class="mx-3"
      row
      hide-details
    >
      <v-radio label="Host Admin" value="Admin"></v-radio>
      <v-radio
        v-if="profile.organizationId == 0"
        label="Super Admin"
        value="SuperAdmin"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'PersonalManagement',
  props: {
    profile: Object,
    isFieldDisabled: Boolean,
  },
}
</script>
