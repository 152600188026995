<template>
  <!--IN HERE THERE IS A BUG THAT DOSENT LET SELECT DATE ON V-DIALOG-->
  <!--BLACK BACKGROUND-->
  <div>
    <v-overlay z-index="14"></v-overlay>
    <div class="component-dialog-wrapper">
      <div class="wrapper-1 analystics-dialog-wrapper">
        <!--TITLE-->
        <v-card-title class="text-center bg-grey fs-1-5r card-title">
          Settings
        </v-card-title>

        <div class="bg-white data-wrapper">
          <div class="content">
            <!--DEFAULT PROFILE-->
            <v-select
              :value="selectedDefaultProfile"
              @change="setDefaultProfile"
              :items="clonedData"
              item-text="profileName"
              class="ma-4 mw-200"
              label="Default Profile"
              hide-details
            ></v-select>

            <!--DATA -->
            <div class="my-2">
              <v-expansion-panels hover class="px-4">
                <v-expansion-panel v-for="(profile, i) in clonedData" :key="i">
                  <v-expansion-panel-header>
                    <h3 class="fs-1-25r">{{ profile.profileName }}</h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pb-4">
                    <Button
                      text="Delete"
                      icon="mdi-delete"
                      btnType="grey"
                      :clickAction="() => deleteProfile(profile)"
                      class="float-right"
                    />

                    <!--PROFILE NAME-->
                    <v-text-field
                      v-model="profile.profileName"
                      label="Profile Name"
                      hide-details
                      class="my-2 mw-200"
                    />

                    <!--AUTO REFRESH-->
                    <v-checkbox
                      v-model="profile.isAutoUpdate"
                      label="Auto update"
                      class="my-2 mw-200"
                      hide-details
                    />

                    <!--SELECT HOST - SUPER ADMIN-->
                    <HostSelect
                      v-if="loggedUser.role === 'SuperAdmin'"
                      :profile="profile"
                    />
                    <!--SCREEN POSITIONS-->
                    <v-select
                      v-model="profile.mode"
                      :items="screenPositionsItems"
                      hide-details
                      label="Arrangement"
                      class="my-2 mw-100"
                    />

                    <div>
                      <!--POSITIONS AND ITEMS INSIDE-->
                      <div :class="['grid-' + profile.mode]">
                        <div
                          v-for="(grid, i, c) in profile.order"
                          v-if="isShowGrid(profile, c)"
                          :key="i"
                          class="grid-item wrapper-1 d-flex align-center"
                        >
                          <span class="mx-2 fs-1r">{{ c + 1 }}.</span>
                          <v-select
                            v-model="profile.order[i]"
                            :items="computedArrangeItems"
                            hide-details
                            class="my-0 py-0"
                          >
                          </v-select>
                        </div>
                      </div>
                    </div>

                    <!--DATES-->
                    <div class="wrapper-1 pa-2 my-8">
                      <!--DATES SELECT-->
                      <div class="my-1">
                        <span class="fs-1r label">Period selection:</span>
                        <v-radio-group
                          v-model="profile.askedPeriodType"
                          class="pa-0 ma-0"
                          hide-details
                          row
                        >
                          <v-radio
                            value="recent"
                            label="Recent period"
                            class="pa-0 ma-0 mx-1"
                          ></v-radio>
                          <v-radio
                            value="custom"
                            label="Custom range"
                            class="pa-0 ma-0 mx-1"
                          ></v-radio>
                        </v-radio-group>
                      </div>

                      <TimeSelector
                        v-if="profile.askedPeriodType === 'recent'"
                        :profile="profile"
                      />

                      <DatesSelector v-else :profile="profile" />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>

          <!--ADD NEW PROFILE-->
          <div class="d-flex justify-end my-2 mx-4">
            <Button
              text="Add New Profile"
              icon="mdi-plus"
              :clickAction="addNewProfileClicked"
              btnType="grey"
            />
          </div>
          <!--FOOTER-->
          <div class="d-flex justify-end align-center bg-white pa-2">
            <!--MESSAGE WHY BUTTON IS DISABLED-->
            <Transition name="slide-fade">
              <span v-if="isShowMsg" class="red--text f-14 mx-2">{{
                msgWhyBtnIsDisabled
              }}</span>
            </Transition>

            <!--SAVE-->
            <div @mouseover="isShowMsg = true" @mouseleave="isShowMsg = false">
              <Button
                text="Save"
                :clickAction="saveAnalyticsSettings"
                :disabled="isSaveButtonDisabled"
              />
            </div>

            <!--CANCEL-->
            <Button
              width="fit-content"
              btnType="grey"
              text="Cancel"
              class="mx-2"
              :clickAction="cancelButtonClicked"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Button from '@/components/BaseComponents/Button/Button.vue'
import { api } from '@/config'
import {
  defaultSettings,
  datesItems,
  arrangeItems,
  screenPositionsItems,
  checkAnalysticsProfiles,
} from './AnalyticsSettingsDialog'
import TimeSelector from './Children/TimeSelector/TimeSelector.vue'
import DatesSelector from './Children/DatesSelector/DatesSelector.vue'
import HostSelect from './Children/HostSelect/HostSelect.vue'
import { confirmDialog } from '@/utils'

export default {
  name: 'AnalyticsSettingsDialog',
  data() {
    return {
      dialog: true,
      clonedData: [],
      datesItems,
      screenPositionsItems,
      isSaveButtonDisabled: false,
      msgWhyBtnIsDisabled: '',
      isShowMsg: false,
      askedPeriod: 'custom',
    }
  },
  props: {
    setIsShowAnalyticsSettings: Function,
    profiles: Array,
    profileSettingsSaved: Function,
  },

  watch: {
    clonedData: {
      handler: function (n) {
        checkAnalysticsProfiles(this)
      },
      deep: true,
    },
  },
  components: { Button, TimeSelector, DatesSelector, HostSelect },
  computed: {
    ...mapGetters(['isDarkMode', 'loggedUser']),

    computedArrangeItems() {
      if (this.loggedUser.role === 'SuperAdmin') {
        const superAdminData = [
          {
            text: 'Activity',
            value: 'Activity',
          },
          {
            text: 'Storage',
            value: 'Storage',
          },
        ]
        return [...arrangeItems, ...superAdminData]
      }
      return arrangeItems
    },
    selectedDefaultProfile() {
      return this.clonedData.find(el => el.isDefault === true)
    },
  },
  methods: {
    ...mapMutations(['SET_NOTIFICATION', 'SET_PROCESSING']),
    async saveAnalyticsSettings() {
      try {
        this.SET_PROCESSING(true)

        const payload = this.clonedData.map(el => {
          if (typeof el.analyticsProfileId === 'string')
            return { ...el, analyticsProfileId: 0 }
          return el
        })

        const res = await api.put('statistics/profiles', payload)
        if (res.status !== 200) throw Error

        const res2 = await api.get('statistics/profiles')
        if (res2.status !== 200) throw Error

        this.SET_NOTIFICATION({
          type: 'success',
          text: 'Profiles settings was updated successfully',
        })

        this.profileSettingsSaved(res.data)
        this.setIsShowAnalyticsSettings(false)
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },

    deleteProfile(profile) {
      const text =
        'Are you sure you want to delete this profile? <br/> The profile will be deleted after clicking the "Save" button.'
      const thenFunc = () => {
        this.clonedData = this.clonedData.filter(
          el => el.analyticsProfileId !== profile.analyticsProfileId
        )
      }
      confirmDialog(this, text, 'Delete', 'Cancel', thenFunc)
    },

    cancelButtonClicked() {
      this.setIsShowAnalyticsSettings(false)
    },

    setDefaultProfile(val) {
      this.clonedData = this.clonedData.map(pr =>
        pr.profileName === val
          ? { ...pr, isDefault: true }
          : { ...pr, isDefault: false }
      )
    },

    isShowGrid(profile, idx) {
      if (idx === 0) return true
      const { mode } = profile

      const conditions = [
        [1, ['1x1']],
        [2, ['1x1', '2x1']],
        [3, ['1x1', '2x1', '3x1']],
        [4, ['1x1', '2x1', '3x1', '2x2']],
        [5, ['1x1', '2x1', '3x1', '2x2']],
      ]

      const invalidModes = conditions[idx - 1][1]

      return !invalidModes.includes(mode)
    },

    addNewProfileClicked() {
      const newProfile = { ...defaultSettings }
      newProfile.userId = this.loggedUser.userId
      newProfile.analyticsProfileId = 'a' + Date.now() //temp id
      if (this.clonedData.length === 0) newProfile.isDefault = true
      this.clonedData.push(newProfile)
    },
  },

  created() {
    this.clonedData = JSON.parse(JSON.stringify(this.profiles))
  },
}
</script>

<style lang="scss" src="@/components/Analytics/Analyitics.scss"></style>

<style scoped src="./AnalyticsSettingsDialog.css"></style>
