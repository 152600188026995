<template>
  <div>
    <h4 class="light-text mt-2">In Case of File Name Collision:</h4>
    <v-radio-group
      v-model="route.isRenameDuplicateFilenames"
      :disabled="isEditDisabled"
      hide-details
      class="pa-0 mx-0 my-2"
    >
      <v-radio
        :value="false"
        label="Overwrite"
        hide-details
        class="pa-0 ma-0"
      ></v-radio>
      <v-radio
        :value="true"
        label="Add timestamp"
        hide-details
        class="pa-0 ma-0"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'DuplicateFileNames',
  props: {
    route: Object,
    isEditDisabled: Boolean,
  },
}
</script>
