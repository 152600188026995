<template>
  <div>
    <div class="qa">
      <h1>DotEngines - Questions & Answers</h1>

      <v-divider />
      <section>
        <h2>Introduction</h2>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">What is dotEngines?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  YazamTech’s dotEngines is a cloud service, a sophisticated
                  file-filtering engine, and a mechanism for moving information
                  across organizations.
                </p>
                <p>
                  With dotEngines, the transference is managed, secured,
                  reliable, and easy to implement.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">Who needs dotEngines?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  It’s time to stop unsecured and unmanaged files transference
                  between organizations. Everyone needs dotEngines, whether you
                  belong to:
                </p>
                <ul>
                  <li>
                    Small-Sized Businesses, such as medical clinics, law firms,
                    accountant offices, and travel agencies.
                  </li>
                  <li>
                    Medium-Sized Businesses, such as medical centers,
                    municipalities, Universities, governmental authorities, and
                    factories.
                  </li>
                  <li>
                    Enterprise-Sized Businesses, such as airlines, national
                    infrastructures, Industrial corporations, banks, and
                    insurance companies.
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">Why is dotEngines needed?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  dotEngines is a response to the weaknesses of the files
                  transference by popular implementations, such as:
                </p>
                <ul>
                  <li>Emails and attachments.</li>
                  <li>Files downloading and uploading by FTP.</li>
                  <li>
                    Files in media, such as USB pen drives, CD/DVD, and Mobile.
                  </li>
                  <li>
                    Files downloading and uploading via public websites.
                  </li>
                  <li>Files shared by clouds.</li>
                </ul>

                <p>
                  Some of the most important cyber security components are
                  routinely overlooked by these sharing platforms; examples of
                  these components are:
                </p>

                <ul>
                  <li>Not filter against incoming risk.</li>
                  <li>Not filter against leakage.</li>
                  <li>Not encrypt files and protocols.</li>
                  <li>Not manage the users.</li>
                  <li>Not manage the traffic.</li>
                </ul>
                <p>
                  In addition, part of them limits the size and volume of the
                  files.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >Why is dotEngines unique in comparison to MFT systems?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <ul>
                  <li>
                    dotEngines contains an integral CDR (Content Disarm and
                    Reconstruction) engine that actively responds against
                    embedded threats in the incoming files and is not only based
                    on anti-viruses scans like other MFT systems.
                  </li>

                  <li>
                    dotEngines contains an integral DLP (Data Leakage
                    Prevention) engine that actively responds against leakage in
                    the outgoing files and is not only based on simple
                    black-lists as mostly found in MFT systems.
                  </li>

                  <li>
                    dotEngines focuses on transferring data quickly between
                    senders and receivers rather than selling storage space to
                    customers.
                  </li>

                  <li>
                    dotEngines is supplied as a service, without on-premises
                    installations on the customer servers.
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>
      <section>
        <h2>Interfaces, Users, Protocols, Routes</h2>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">Which interfaces are supported?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  dotEngines supports many interfaces to upload the original
                  files from their source and download the filtered files to
                  their target:
                </p>
                <ul>
                  <li>
                    <b>Website</b> for interactive usage by personal users.
                  </li>
                  <li>
                    <b>SFTP,</b> replacing the outdated FTP, both for private
                    users and applications.
                  </li>
                  <li><b>API</b> for customer applications.</li>
                  <li><b>Cloud,</b>for external storage locations.</li>
                  <li><b>Email</b> and the attachments.</li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">Which kind of users are supported?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>Users can be:</p>
                <ul>
                  <li>
                    <b>Person:</b> a human who uses the system manually when
                    needed.
                  </li>
                  <li>
                    <b>Application:</b> program that uses the system
                    automatically without human involvement.
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">Which is the affiliation of the users?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>Each user can belong to:</p>
                <ul>
                  <li>
                    <b>Host:</b> an organization that has registered with the
                    dotEngines service The host has host administrators,
                    internal users (persons and applications), and interfaces.
                  </li>

                  <li>
                    <b>Guest:</b> an external partner of a registered host,
                    contains external users (persons, applications), and uses
                    interfaces.
                    <br />
                    Examples of guests are suppliers, contractors, trading
                    partners, customers, citizens, developers.
                  </li>
                </ul>

                <p>
                  The host and his quests must be registered to dotEngines.
                </p>
                <p>
                  Both users, who belong to the host and its guests, must be
                  registered to dotEngines under the host.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">What usages are supported?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      ><b class="question">Website (interactive) usage</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="answer">
                        <ul>
                          <li>
                            <b>Permanent:</b> when a single user uses his
                            account for a long time.
                          </li>
                          <li>
                            <b>Transient:</b> when a few users alternately share
                            the same account.
                            <br />

                            dotEngines lets the administrator limit the working
                            time of each temporary user.
                          </li>

                          <li>
                            <b>Individual:</b> a case of a single user that
                            doesn’t belong to any host. The individual can use
                            dotEngines only for self file filtering
                            interactively via the website.
                            <br />

                            Examples of individual users are doctors, lawyers,
                            and CPAs.
                          </li>
                        </ul>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      ><b class="question">SFTP usage</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="answer">
                        <ul>
                          <li>
                            <p>
                              <b>Manually:</b> when files are uploaded and
                              downloaded manually, mainly using SFTP
                              applications with a user interface.
                            </p>
                            <p>
                              WinSCP is an example of a manual application with
                              UI.
                            </p>
                          </li>

                          <li>
                            <p>
                              <b>Application:</b> when files are uploaded and
                              downloaded automatically using the SFTP protocol.
                            </p>
                            <p>
                              MoveIT is an example of a system developed by
                              YazamTech for automatic uploading and downloading
                              by many interfaces, such as SFTP.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      ><b class="question">API usage</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="answer">
                        <ul>
                          <li>
                            <b>dotEngines API (native):</b> this is used when
                            external applications use the high-level dotEngines
                            API and enjoy all dotEngines capabilities, including
                            its management.
                          </li>
                          <li>
                            <b>SelecotrIT API (filter):</b> this is used when
                            external applications use only the low-level
                            dotEngines API and enjoy only the filtering
                            capabilities by SelectorIT.
                          </li>
                        </ul>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      ><b class="question">Cloud usage</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="answer">
                        <ul>
                          <li>
                            <b>Google Drive:</b> when a Google Drive storage
                            location is utilized as the source of the original
                            files or the target of the filtered contents.
                          </li>
                          <li>
                            <b>Microsoft OneDrive:</b> when a Microsoft OneDrive
                            storage location is utilized as the source of the
                            original files or the target of the filtered
                            contents.
                          </li>
                        </ul>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      ><b class="question">Management usage</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="answer">
                        <ul>
                          <li>
                            <p>
                              <b>Host admin:</b> the administrator in the host
                              level manages only the settings that belong to his
                              host.
                            </p>
                            <p>
                              A host admin creates accounts for the hosts and
                              manages the sensitive data that belongs to the
                              hosts, such as the filtering policies, the
                              filtering results (logs), the quarantined files.
                            </p>
                            <p>There can be several host admins.</p>
                          </li>
                          <li>
                            <p>
                              <b>Super admin:</b> the primary administrator
                              manages the global settings and establishes the
                              new hosts.
                            </p>
                            <p>
                              Super admin can help with accounts created for the
                              hosts but is prevented from sensitive data that
                              belongs to the hosts, such as the user’s
                              passwords, the filtering policies, the filtering
                              results (logs), the quarantined files.
                            </p>
                            <p>There can be several super admins.</p>
                          </li>
                          <li>
                            <p>
                              <b>Approver:</b> a special user that can decide if
                              to approve or reject the files’ movement by
                              dotEngines and their filtering.
                            </p>
                            <p>
                              The approver is defined per host, and there can be
                              few approvers per host.
                            </p>
                            <p>
                              The approver has dedicated screens where he can
                              find the approval requests and accept or reject
                              them.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >Which communication protocols are supported?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  HTTPS and SFTP are the secured protocols used by the
                  interfaces to upload the original files from their sources and
                  download the filtered files to their targets.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">What are the dotEngines routes?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  Routes in dotEngines are sets of traffic rules. Each route
                  contains definitions about:
                </p>
                <ul>
                  <li>The route name.</li>
                  <li>
                    The sources: the details of the senders and the uploading
                    interfaces.
                  </li>
                  <li>
                    The filtering policy: as a response to both cyber attacks
                    and leakage.
                  </li>
                  <li>
                    The targets: the details of the recipients and the
                    downloading interfaces.
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >What are the differences between interactive and automatic
                actions?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>Interactive actions: these are done by personal users.</p>
                <p>
                  Examples of interactive actions are uploading/downloading
                  files by web interfaces and uploading/downloading files by
                  SFTP clients with a user interface.
                </p>
                <p>Automatic actions: these are done by applications.</p>
                <p>
                  Examples of automatic actions are uploading/downloading files
                  by API (application interface) and uploading/downloading files
                  by SFTP programs that are a computer’s service.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >What are the limitations on the volume of the files?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  The system can transfer and filter files of tens of gigabytes.
                </p>
                <p>
                  The ambition here is to support the transfer and filter files
                  of hundreds of gigabytes.
                </p>
                <p>
                  There are settings in the system where the admin can choose
                  whether to limit the volume of the files per host and user.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >How can the user get a notification that files are waiting for
                him?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  After being transported and filtered by dotEngines, the files
                  await their recipient.
                </p>
                <p>
                  The recipient can receive notifications from the system about
                  arrived files via emails and SMSs.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >Which technical skills are needed to manage the system?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  The system is easy to manage for any system administrator and
                  doesn’t require special knowledge or skills.
                </p>
                <p>
                  You will be able to use the system easily after a short
                  training.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >Which skills are needed for a user of the system?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  The system is simple to use and does not need any special
                  knowledge or abilities.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>

      <section>
        <h2>Files filtering</h2>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">Why is professional filtering mandatory?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  The challenge is to filter files that reach the internal
                  sensitive network via any medium and may pose a wide variety
                  of threats.
                </p>
                <p>
                  Examples of these threats include APTs (Advanced Persistent
                  Threats), Zero Day Attacks, Trojans, and Ransomware. These
                  attacks, which are continuously updated, will in many cases
                  bypass the systems in use within the organization gateways,
                  like Anti-Virus and Anti-Spam filters, EDR and XDR, Mail
                  Relays, Firewalls, Sandboxes, and more.
                </p>
                <p>
                  Each popular file in use is never based on a simple structure
                  as it may seem, but on very complex content and the
                  possibility of malicious content, and therefore must be
                  filtered professionally. In this way, threats within complex
                  files that are being detected within the organization can be
                  stopped or removed at the gateway level before the attack
                  vectors can enter the internal network inside of the
                  organization, where it can pose a significant risk.
                </p>
                <p>
                  It is critical to ensure that the user receives the filtered
                  files of the same quality and formats as the originals, even
                  in case of complex files, and will be able to use them as if
                  they were untouched.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >Which filters are implemented in dotEngines to reduce
                attacks?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  SelectorIT by YazamTech is the leading CDR (Content Disarm and
                  Reconstruction) system that works at the center of dotEngines
                  and filters each incoming file against embedded cyber threats
                  even they are hidden.
                </p>
                <p>Main use cases:</p>
                <ul>
                  <li>
                    Filter Macros and ActiveX within Microsoft Office files.
                  </li>
                  <li>Filter Scripts within PDF, HTML, XML files.</li>
                  <li>
                    Filter internal OLE objects within Microsoft Office files,
                    Embedded files within PDF files, Attachments in emails.
                  </li>
                  <li>
                    Filter external links within Microsoft Office, PDF, HTML
                    files, and body of email files.
                  </li>
                  <li>
                    Filter the internal content of 13 formats of Archive files.
                  </li>
                  <li>
                    Re-encode 25 formats of Image, Sound, and Video files.
                  </li>
                  <li>
                    Filter non-printable characters within Plain Text files.
                  </li>
                  <li>
                    Filter password-protected files of Microsoft Office (Word,
                    Excel, PowerPoint), PDF, and many Archives files.
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >Which filters are implemented to reduce data leakage?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  SelectorIT by YazamTech is the leading DLP (Data Loss
                  Prevention) system that works at the center of dotEngines and
                  filters each outgoing file against leakage.
                </p>
                <p>Main use cases:</p>

                <ul>
                  <li>Control the file types.</li>
                  <li>
                    Search and block forbidden text and characters, even hidden,
                    in a dozen file types.
                  </li>
                  <li>Reset shell properties.</li>
                  <li>Reset metadata in many file types.</li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >How much time does it take to filter files?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  The filtering process shouldn’t take long, and the files
                  should arrive without a significant delay.
                </p>
                <p>
                  The filtering time is less than 1 second to file for ‘normal’
                  files.
                </p>

                <p>
                  Generally, the transference delay on the internet takes more
                  time than the filtering delay.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question"
                >What kind of quality degradation does filtering cause?</b
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  The filtering process is safe and shouldn’t hurt the quality
                  of the filtered files.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>

      <section>
        <h2>Business model and licensing</h2>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">What is the dotEngines business model?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>
                  The main principle of the business model is pay-per-use.
                </p>
                <p>
                  The cost of the host is mostly determined by the number of
                  interfaces in use and the price of each interface.
                </p>

                <p>Extra traffic by the host users can affect this cost.</p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><b class="question">What are the licensing principles?</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="answer">
                <p>The licenses are issued for the interfaces.</p>
                <p>
                  The super admin allocates the licenses for each host,
                  depending on his order.
                </p>
                <p>
                  The host admin divides the licenses quota between his users
                  (who belong to the host and its guests).
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionsAndAnswers',
}
</script>

<style scoped src="./QuestionsAndAnswers.css"></style>
