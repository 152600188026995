<template>
  <div
    class="ma-auto wrapper-2 bg-white w-350"
    :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
  >
    <v-card-title class="text-h6 font-weight-regular bg-grey title-border pa-2">
      <span class="mx-auto"> {{ $t('Success') }}</span>
    </v-card-title>
    <v-divider></v-divider>

    <span class="pa-2 fs-1r">{{ computedMessage }}</span>

    <div class="d-flex justify-end">
      <Button
        class="ma-2"
        width="110"
        :clickAction="continueSession"
        :text="$t('Continue')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions ,mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'ContinueScreen',
  props: { email: String, isFirstTimeGlobal: Boolean },
  components: { Button },
  computed: {
    ...mapGetters(['language']),
    computedMessage() {
      if (this.isFirstTimeGlobal)
        return this.$t('Your password was successfully created')
      return this.$t('Your password was successfully updated')
    },
  },
  methods: {
    ...mapActions(['LOG_OUT']),
    async continueSession() {
      await this.LOG_OUT()
      this.$router.push({ name: 'Home', query: { email: this.email } })
    },
  },
}
</script>
