import dayjs from 'dayjs'
import store from '@/store'
import { selectedHost } from '@/config'
import { i18n } from '@/main'

//===============================================
//===================FOR USERS===================
//===============================================

export const convertID = (value, letter = 'U') => {
  if (value < 10) {
    return `${letter}-000${value}`
  } else if (value < 100) {
    return `${letter}-00${value}`
  } else if (value < 1000) {
    return `${letter}-0${value}`
  } else if (value < 10000) {
    return `${letter}-${value}`
  }
  return value
}

export const convertImage = role => {
  if (role === 'Admin') return 'account-tie'
  else if (role === 'Individual') return 'account-outline'
  else if (role === 'SuperAdmin') return 'crown'
  return 'account'
}

export const convertrole = role => {
  switch (role) {
    case 'SuperAdmin':
      return 'Super Admin'
    case 'Admin':
      return 'Host Admin'
    case 'User':
      return 'Transfer'
    case 'Approver':
      return 'Approver'
    case 'Application':
      return 'Application'
    case 'GuestApplication':
      return 'Guest Application'
    case 'GuestUser':
      return 'Guest Person'
    case 'Individual':
      return 'Individual'
    default:
      return '-'
  }
}

//===============================================
//=================FOR VALIDATION================
//===============================================

const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/
const PHONE_NUMBER_REGEX = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/

export const validateEmail = email => EMAIL_REGEX.test(email)
export const validatePhoneNumber = phoneNumber => {
  return (
    phoneNumber[0] === '+' &&
    JSON.parse(JSON.stringify(phoneNumber))
      .replaceAll(' ', '')
      .replaceAll('+', '')
      .replaceAll('-', '').length >= 10 &&
    JSON.parse(JSON.stringify(phoneNumber))
      .replaceAll(' ', '')
      .replaceAll('+', '')
      .replaceAll('-', '').length <= 15
  )
}
export const isFolderNameValid = path => {
  if (path[0] === '\\') return false
  if (path.length === 0) return true
  const forbiddenChars = ['<', '>', ':', '"', '|', '?', '*', '/']
  for (let char of path) {
    if (forbiddenChars.includes(char)) {
      return false
    }
  }
  if (!path.trim()) return false
  return true
}

export const validateIsraelId = id => {
  if (id.length !== 9 || isNaN(id)) {
    // Make sure ID is formatted properly
    return false
  }
  let sum = 0,
    incNum
  for (let i = 0; i < id.length; i++) {
    incNum = Number(id[i]) * ((i % 2) + 1) // Multiply number by 1 or 2
    sum += incNum > 9 ? incNum - 9 : incNum // Sum the digits up and add to total
  }
  return sum % 10 === 0
}

//===============================================
//===================FOR DATES===================
//===============================================

const customParseFormat = require('dayjs/plugin/customParseFormat')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

//===========================================
//=================NEW FUNCS=================
//===========================================

//UTC MEANS UTC+0
//LOCAL MEANS LOCAL USER'S DATE

//date to ms (get local/utc date and return ms in local or utc)
export function convertDateToMs(date, typeOfReturnDateTimezone) {
  let newDate

  //HANDLE DATE TYPE
  //if type of date is js Date object
  if (typeof date === 'object') {
    newDate = date
  }

  //if type of date is dayjs string
  else if (typeof date === 'string') {
    const formatedDate = formatDateStringToDayjsStr(date)
    newDate = new Date(formatedDate)
  }

  //invaild value
  else {
    console.warn('Invaild value')
    return date
  }

  //HANDLE TIME ZONE
  //if the the func needs to return UTC
  if (typeOfReturnDateTimezone === 'UTC') {
    let offset = newDate.getTimezoneOffset()
    const time = newDate.getTime()
    offset = offset * 60000
    newDate = offset + time
    return newDate
  }

  //if the func needs to return Local
  else {
    return newDate.getTime()
  }
}

//2. ms to format date
export function convertMsToDateString(msDate, isAddMs) {
  let formatType = isAddMs ? 'YYYY-MM-DD HH-mm-ss-SSS' : 'YYYY-MM-DD HH-mm-ss'
  const newDate = dayjs(msDate).format(formatType)
  return newDate
}

// 3. utc date to local
export function convertUTCtoLocalDate(date, isReturnMs) {
  //CONVERT TO TYPE
  //if date is already date object
  let newDate = date
  //if date is ms
  if (typeof date === 'number') {
    newDate = new Date(date)
  }
  //if date is dayjs string
  if (typeof date === 'string') {
    const formatedDate = formatDateStringToDayjsStr(date)
    newDate = new Date(formatedDate)
  }

  //HANDALE TIMEZONE CHANGES
  let offset = newDate.getTimezoneOffset()
  const time = newDate.getTime()
  offset = offset * 60000
  const newDateMs = time - offset

  //HANDLE RETURN
  if (isReturnMs) return newDateMs
  else {
    const newDateFormated = dayjs(newDateMs).format('YYYY-MM-DD HH-mm-ss')
    return newDateFormated
  }
}

// 4. local date to utc
export function convertLocalDateToUTC(date, isReturnMs) {
  //CONVERT TO TYPE
  //if date is already date object
  let newDate = date
  //if date is ms
  if (typeof date === 'number') {
    newDate = new Date(date)
  }
  //if date is dayjs string
  if (typeof date === 'string') {
    const formatedDate = formatDateStringToDayjsStr(date)
    newDate = new Date(formatedDate)
  }

  //HANDALE CHANGES
  let offset = newDate.getTimezoneOffset()
  const time = newDate.getTime()
  offset = offset * 60000
  const newDateMs = offset + time

  //HANDLE RETURN
  if (isReturnMs) return newDateMs
  else {
    const newDateFormated = dayjs(newDateMs).format('YYYY-MM-DD HH-mm-ss')
    return newDateFormated
  }
}

export function formatDateStringToDayjsStr(str) {
  //this function gets '2023-03-06 10-30' or '2023-03-06 10-30-11'
  // and return '2023-03-06 10:30:11'

  //if string is "2023-03-06 10-30"
  if (str.length === 16) {
    const lastHyphenIndex = str.lastIndexOf('-')

    return (
      str.substring(0, lastHyphenIndex) +
      ':' +
      str.substring(lastHyphenIndex + 1).replace('-', ':')
    )
  }

  //if string is "2023-03-06 10-30-11"
  else {
    // Split the input date string into date and time parts
    const [datePart, timePart] = str.split(' ')

    // Replace the hyphen in the time part with a colon
    const formattedTimePart = timePart.replace(/-/g, ':')

    // Combine the formatted date and time parts and return the result
    const formattedDate = `${datePart} ${formattedTimePart}`
    return formattedDate
  }
}

//===========================================
//=================OLD FUNCS=================
//===========================================
export const generateLocalDate = val => {
  if (!val) return ''
  try {
    const localDate = dayjs(val).format('YYYY-MM-DD HH-mm-ss')
    return localDate
  } catch (e) {
    console.log(e)
    return 'Invalid date'
  }
}

export const generateLocalDateFromUTC0 = val => {
  //get the local user's date from utc+0 date
  if (!val) return ''
  try {
    const now = new Date(val)
    const time = now.getTime()
    let offset = now.getTimezoneOffset()
    offset = offset * 60000
    const newDate = dayjs(time - offset).format('YYYY-MM-DD HH-mm-ss')
    return newDate
  } catch (e) {
    console.log(e)
    return 'Invalid date'
  }
}

export const generateNicerLocalDateFromUTC0 = val => {
  //get the local user's date from utc+0 date
  if (!val) return ''
  try {
    const now = new Date(val)
    const time = now.getTime()
    let offset = now.getTimezoneOffset()
    offset = offset * 60000
    const newDate = dayjs(time - offset).format('DD/MM/YYYY HH:mm:ss')
    return newDate
  } catch (e) {
    console.log(e)
    return 'Invalid date'
  }
}

export const generateLocalDateFromUTC0NoMS = val => {
  if (!val) return ''
  try {
    const now = new Date(val)
    const time = now.getTime()
    let offset = now.getTimezoneOffset()
    offset = offset * 60000
    const newDate = dayjs(time - offset).format('YYYY-MM-DD HH-mm')
    return newDate
  } catch (e) {
    console.log(e)
    return 'Invalid date'
  }
}

export const getUTCdateFromLocalDate = date => {
  const s = new Date(date)
  const time = s.getTime()
  let offset = s.getTimezoneOffset()
  offset = offset * 60000
  return offset + time
}

export const getUTC0MSDateFromLocalDate = () => {
  //get miliseconds of UTC+0 from now
  const s = new Date()
  const time = s.getTime()
  let offset = s.getTimezoneOffset()
  offset = offset * 60000
  return offset + time
}

export const generateUTCDate = val => {
  if (!val) return ''
  try {
    const utcDate = dayjs(val).format('YYYY-MM-DD HH-mm-ss')
    return utcDate
  } catch (e) {
    console.log(e)
    return 'Invalid date'
  }
}

export const getUTCMSNow = date => {
  const now = new Date(date)
  const time = now.getTime()
  let offset = now.getTimezoneOffset()
  offset = offset * 60000
  return time - offset
}

export function convertUtcToLocal(utcDate) {
  // Set the input format of the UTC date
  const inputFormat = 'YYYY-MM-DD HH:mm:ss'
  // Parse the input UTC date into a Day.js object
  const utcObj = dayjs.utc(utcDate, inputFormat)
  // Convert the UTC date to the user's local timezone
  const localObj = utcObj.local()
  // Set the output format of the local date
  const outputFormat = 'YYYY-MM-DD HH-mm'
  // Format the local date as a string and return it
  return localObj.format(outputFormat)
}

export function formatDateStringToDayjs(str) {
  //move to utils - DUPLICATED CODE
  //this function gets '2023-03-06 10-30' and return '2023-03-06 10:30'

  const lastHyphenIndex = str.lastIndexOf('-')

  return (
    str.substring(0, lastHyphenIndex) +
    ':' +
    str.substring(lastHyphenIndex + 1).replace('-', ':')
  )
}

//david new funcs

export const getUTCdateFromLocalDateDavid = date => {
  // return date in ms
  const s = new Date(date)
  const time = s.getTime()
  return time
}

export const generateUTCDateDavid = (val, isAddMs) => {
  // return convertion of local date to utc date
  // by dayJs library in a specific format
  if (!val) return ''
  try {
    const x = new Date(val)
    let utcDate
    if (isAddMs) utcDate = dayjs.utc(x).format('YYYY-MM-DD HH-mm-ss-SSS')
    else utcDate = dayjs.utc(x).format('YYYY-MM-DD HH-mm-ss')

    return utcDate
  } catch (e) {
    console.log(e)
    return 'Invalid date'
  }
}

export const generateLocalDateFromUTC0David = (val, isAddMs) => {
  //format the date got in val to specific format
  if (!val) return ''
  try {
    const x = new Date(val)
    let newDate
    if (isAddMs) newDate = dayjs(x).format('YYYY-MM-DD HH-mm-ss-SSS')
    else newDate = dayjs(x).format('YYYY-MM-DD HH-mm-ss')

    return newDate
  } catch (e) {
    console.log(e)
    return 'Invalid date'
  }
}

export const generateLocalDateFromUTC0DavidWithMs = val => {
  //format the date got in val to specific format
  if (!val) return ''
  try {
    const newDate = dayjs(val).format('YYYY-MM-DD HH-mm-ss-SSS')
    return newDate
  } catch (e) {
    console.log(e)
    return 'Invalid date'
  }
}

export const generateUTCDateDavidWithMs = val => {
  // return convertion of local date to utc date
  // by dayJs library in a specific format
  if (!val) return ''
  try {
    const x = new Date(val).toUTCString()
    const utcDate = dayjs.utc(x).format('YYYY-MM-DD HH-mm-ss-SSS')
    return utcDate
  } catch (e) {
    console.log(e)
    return 'Invalid date'
  }
}

export function generateDeletionDate(d) {
  //this function get date in the future and caculate in how much time it will be by DD HH
  if (d.status === 'Blocked') return ''
  const today = new Date().getTime()
  const deletionDate = new Date(d.deletionDate)
  const diffTime = Math.abs(deletionDate - today)
  if (diffTime > 99999998065) return 'Never'
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60))
  if (diffHours < 25) return `${diffHours} Hours`
  else return `${Math.floor(diffHours / 24)}D ${Math.floor(diffHours % 24)}H`
}

export const parseTime = value => {
  let minutes = Math.floor(value / 60000)
  let seconds = ((value % 60000) / 1000).toFixed(0)

  if (minutes < 60) {
    if (minutes < 10) minutes = '0' + minutes
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }

  let hours = Math.floor(minutes / 60)
  minutes %= 60

  if (hours < 10) hours = '0' + hours
  if (minutes < 10) minutes = '0' + minutes
  if (seconds < 10) seconds = '0' + seconds

  return hours + ':' + minutes + ':' + seconds
}

export const getRelativeTimeFromUTC0 = date => {
  //get relative time from utc to now (return "2 weeks ago")

  //get miliseconds of now from UTC+0
  const s = new Date(date)
  const time = s.getTime()
  let offset = s.getTimezoneOffset()
  offset = offset * 60000
  const curTime = time - offset

  // Calculate the time difference between the given date and the current date
  const msDate = new Date(curTime)
  const currentDate = new Date()
  let diff = currentDate.getTime() - msDate.getTime()

  // Convert the time difference to years, months, weeks, days, hours, minutes, and seconds
  const years = Math.round(diff / (1000 * 60 * 60 * 24 * 365))
  diff -= years * (1000 * 60 * 60 * 24 * 365)
  const months = Math.round(diff / (1000 * 60 * 60 * 24 * 30))
  diff -= months * (1000 * 60 * 60 * 24 * 30)
  const weeks = Math.round(diff / (1000 * 60 * 60 * 24 * 7))
  diff -= weeks * (1000 * 60 * 60 * 24 * 7)
  const days = Math.round(diff / (1000 * 60 * 60 * 24))
  diff -= days * (1000 * 60 * 60 * 24)
  const hours = Math.round(diff / (1000 * 60 * 60))
  diff -= hours * (1000 * 60 * 60)
  const minutes = Math.round(diff / (1000 * 60))
  diff -= minutes * (1000 * 60)
  const seconds = Math.round(diff / 1000)

  // Build the string to return
  let timeString = ''
  if (years > 0) timeString += years + ' years '
  if (months > 0) timeString += months + ' months '
  if (weeks > 0) timeString += weeks + ' weeks '
  if (days > 0) timeString += days + ' days '
  if (hours > 0) timeString += hours + ' hours '
  if (minutes > 0) timeString += minutes + ' minutes '
  if (seconds > 0) timeString += seconds + ' seconds'

  // Split the time string into an array
  const timeArray = timeString.split(' ')
  // Only return the first two values in the array
  return timeArray.slice(0, 2).join(' ')
}

export function convertToLocalTime(serverTime) {
  const nums = serverTime.split(':')
  const nDate = new Date()
  const offset = nDate.getTimezoneOffset() / 60
  nDate.setHours(nums[0] - offset)
  nDate.setMinutes(nums[1])
  nDate.setSeconds(nums[2])

  const hours = padZero(nDate.getHours())
  const minutes = padZero(nDate.getMinutes())
  const seconds = padZero(nDate.getSeconds())
  return `${hours}:${minutes}:${seconds}`
}

//===============================================
//====================PARSERS====================
//===============================================

export const bytesToSize = bytes => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return '0 B'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  if (i < 2) {
    //if the number is in kb or bytes
    const number = Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
    if (number.includes('NaN')) return
    return number
  } else {
    const number = (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
    if (number.includes('NaN')) return
    return number
  }
}

export const megabytesToSize = bytes => {
  return bytesToSize(bytes * 1024 * 1024)
}

export const parseIcon = name => {
  const pName = name.replace('.blocked', '')
  const icons = require('file-icons-js')
  if (pName.includes('.jpeg')) return 'image-icon medium-orange'
  else if (pName.includes('.jfif')) return 'image-icon medium-orange'
  else if (pName.includes('.docm')) return 'word-icon dark-blue'
  const icon = icons.getClassWithColor(pName)
  if (icon) return icon
  return 'default-icon medium-black'
}

export const generateNewName = (name, filesData) => {
  let newFileName = name.substr(0, name.lastIndexOf('.'))
  const ext = name.substring(name.lastIndexOf('.') + 1, name.length) || name
  for (let i = 1; i < 100; i++) {
    if (
      !filesData.some(el => el.file.name === `${newFileName} (${i}).${ext}`)
    ) {
      return `${newFileName} (${i}).${ext}`
    }
  }
}

export const padZero = (num, len = 2) => {
  return num.toString().padStart(len, '0')
}

//===============================================
//====================OTHERS====================
//===============================================

export const sortArrOfObjByField = (arr, field) => {
  return arr.sort((a, b) => {
    if (a[field].toLowerCase() < b[field].toLowerCase()) return -1
    if (a[field].toLowerCase() > b[field].toLowerCase()) return 1
    return 0
  })
}

export function formatReport(htmlReport) {
  const { themeSettings } = store.getters
  const { name, organizationId } = themeSettings
  console.log(htmlReport.date)
  const localDate = convertUtcToLocal(htmlReport.date)
  console.log(localDate)
  const location = window.location.hostname
  const activeLang = i18n.i18next.language

  return (
    htmlReport.summary
      //this will give the report a nice frame with the information about the sent data
      .replace(
        //replace the start of the html
        '<body style="margin: 0; padding: 0;">',

        //with
        `<body style="padding: 0;margin: 8px; border: 1px solid #e3e3e3; width: calc(100% - 16px);height: fit-content; border-radius: 10px;">
     <div style="display: flex; justify-content: space-between; align-items: center; padding: 8px; border-radius: 10px 10px 0 0; background: #e3e3e3;direction: ${
       activeLang === 'he' ? 'rtl' : 'ltr'
     }">
              <span style="margin: 0;font-weight: 600;font-size: 1.5rem; margin-right: 30px;">${i18n.t(
                'Filtering Report'
              )}</span>
               <img style="height: 35px" src="https://www.${location}/api/images/${
          organizationId || '0'
        }/logo" alt="dotEngines report"> 
    </div> 
    <hr style="margin-top: 0">`
      )
      .replace(
        //replace the end of the body
        '</body>',

        //with
        `<hr> 
    <div style="padding: 5px; display: flex;justify-content: space-between; align-items: center;">
      <span>${localDate}</span>
      <div style="display: flex; align-items: center;"> 
          <h3 style="margin: 0 5px; font-weight: 300;">${
            name || 'dotEngines'
          }</h3> 
        </div>
    </div>
  </body>`
      )
  )
}

export function extractAttemptsLeft(message) {
  const regex = /Attempts left: (\d+)\.$/
  const match = message.match(regex)
  if (match) {
    return parseInt(match[1], 10)
  } else {
    return null // If no match is found
  }
}

//===============================================
//====================DIALOGS====================
//===============================================

export async function alertDialog(vue, text, okText = 'OK') {
  //script for autofocus - donse't work very well
  const script = () => {
    setTimeout(() => {
      const dialog = document.querySelector('.message-class')
      const mainContent = document.querySelector('.dg-main-contet')
      dialog.autofocus = true
      if (dialog) dialog.focus()
    }, 100)
  }
  const activeLang = i18n.i18next.language

  return await vue.$dialog.alert(
    `<p class="message-class fs-1-25r"  tabindex="0">${text}</p>
    <script>${script()}</script>`,
    {
      okText: okText,
      animation: 'fade',
      customClass: `message-class ${activeLang === 'he' ? 'dir-rtl' : ''}`,
      html: true,
    }
  )
}

export async function confirmDialog(
  vue,
  text,
  okText,
  cancelText,
  thenFunc,
  catchFunc
) {
  //script for autofocus - don't work very well
  const script = () => {
    setTimeout(() => {
      const dialog = document.querySelector('.message-class')
      dialog.autofocus = true
      if (store.getters.isDarkMode) changeAlertToDarkMode()

      if (dialog) dialog.focus()
    }, 50)
  }

  const activeLang = i18n.i18next.language
  return await vue.$dialog
    .confirm(
      `<div class="message-class fs-1-25r" tabindex="0" autofocus ref="abc">${text}</div>
      <script>${script()}</script>`,
      {
        okText,
        cancelText: cancelText || 'Cancel',
        animation: 'fade',
        customClass: `message-class ${activeLang === 'he' ? 'dir-rtl' : ''}`,
        html: true,
      }
    )
    //if user click okay
    .then(v => thenFunc(v))

    //if user click cancel
    .catch(e => {
      if (catchFunc) catchFunc(e)
      else return e
    })
}

function changeAlertToDarkMode() {
  const mainContent = document.querySelector('.dg-main-content')
  const okBtn = document.querySelector('.dg-btn--ok')
  const cancelButton = document.querySelector('.dg-btn--cancel')

  if (mainContent) mainContent.classList.add('dg-main-content-dark')
  if (okBtn) okBtn.classList.add('dg-btn--ok-dark')
  if (cancelButton) cancelButton.classList.add('dg-btn--cancel-dark')
}
