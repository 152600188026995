export const getUploadedFilesTableHeaders = vue => [
  {
    text: vue.$t('Name'),
    sortBy: 'name',
    class: `bg-table-heading table-header-${
      vue.language === 'he' ? 'last' : 'first' //bandage
    }`,
    sortable: true,
    width: '60%',
  },
  {
    text: vue.$t('Size'),
    sortBy: 'size',
    class: 'bg-table-heading ',
    sortable: true,
    width: '10%',
    align: 'center',
  },
  {
    text: vue.$t('Last Modified'),
    sortBy: 'lastModifiedDate',
    class: 'bg-table-heading ',
    sortable: true,
    width: '25%',
    align: 'center',
  },
  {
    sortBy: 'action',
    name: '',
    sortable: false,
    class: `bg-table-heading table-header-${
      vue.language === 'he' ? 'first' : 'last'
    }`,
    width: '5%',
  },
]
