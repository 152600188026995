<template>
  <v-dialog
    v-model="dialog"
    width="90vw"
    class="wrapper-1 bg-white pos-relative"
    persistent
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card height="90vh" class="wrapper-1">
      <!--TITLE-->
      <v-card-title class="text-center bg-grey mb-2">
        Edit Policy: {{ policyToEdit }}
      </v-card-title>

      <!--IFRAME FOR POLICY-->
      <iframe
        class="wrapper-1 policy-iframe bg-white ma-4"
        :src="computedPolicy"
      />

      <!--FOOTER-->
      <div class="d-flex justify-end bg-white pa-2">
        <Button
          width="fit-content"
          btnType="grey"
          text="Close"
          class="mx-2"
          :clickAction="closeDialogClicked"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { selectedHost } from '@/config'
import { confirmDialog } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'EditDialog',
  data() {
    return {
      dialog: true,
    }
  },
  props: {
    closeDialog: Function,
    policyToEdit: String,
  },
  components: { Button },
  computed: {
    ...mapGetters(['loggedUser', 'isDarkMode']),
    computedPolicy() {
      return `${selectedHost}/Engine/${this.loggedUser.token}/Designer/${this.policyToEdit}`
    },
  },
  methods: {
    async closeDialogClicked() {
      //tell user that his changes might not be saved
      const thenFunc = () => {}

      const catchFunc = () => {
        this.closeDialog()
      }
      const text = `Attention: Please remember to save your changes by clicking on the 'diskette' icon in the policy screen before closing this dialog. Your changes will not be applied unless explicitly saved. Click 'Cancel' to return and save your modifications, or 'Close' to discard any unsaved changes.`
      await confirmDialog(this, text, 'Cancel', 'Close', thenFunc, catchFunc)
    },
  },
}
</script>

<style scoped src="./EditDialog.css"></style>
