<template>
  <div class="my-4">
    <h4 class="settings-h4">Design</h4>
    <div class="wrapper-1 pa-2">
      <!--LOGO-->
      <Logo :images="images"/>

      <!--SLOGAN-->
      <!-- <Slogan :kioskSettings="kioskSettings" /> -->
    </div>
  </div>
</template>

<script>
import Logo from './Children/Logo/Logo.vue'
import Slogan from './Children/Slogan/Slogan.vue'

export default {
  name: 'Design',
  components: { Logo, Slogan },
  props: {
    kioskSettings: Object,
    images: Object,
  },
}
</script>
