<template>
  <div>
    <!-- IF THE USER IS PART OF ORGANIZZTION  -->
    <div
      v-if="actionType === 'edit'"
      class=" wrapper-1 pa-1 d-flex align-right mx-2"
    >
      <img height="40" :src="getCurrentOrganizationLogo()" />
      <span class="ml-3 align-self-center"
        ><b>{{ getCurrentOrganizationName() }}</b></span
      >
    </div>

    <!-- SELECT THE USER ORGANIZATION ON ADD-->
    <v-select
      v-if="isShowAccountHost"
      v-model="profile.organizationId"
      :items="getOrganizationsSelections"
      :disabled="isFieldDisabled"
      label="Hosts"
      class="required mx-2"
      style="max-width:320px"
      hide-details
    >
      <template slot="item" slot-scope="data">
        <img class="select-organization-images" :src="data.item.image" />
        <span class="ml-4">{{ data.item.text }}</span>
      </template>
      <template slot="selection" slot-scope="data">
        <img class="selected-organization-image" :src="data.item.image" />
        <span class="ml-4">{{ data.item.text }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { selectedHost } from '@/config'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AccountOrganization',
  props: { profile: Object, actionType: String, isFieldDisabled: Boolean },
  watch: {
    'profile.organizationId': {
      handler: async function(n) {
        if (n !== 0) await this.GET_SINGLE_ORGANIZATION(n)
      },
    },
  },

  computed: {
    ...mapGetters(['organizations']),
    isShowAccountHost() {
      if (this.actionType === 'edit') return false
      else if (this.profile.role === 'SuperAdmin') return false
      else if (this.profile.role === 'Individual') return false
      return true
    },
    getOrganizationsSelections() {
      const organizationArray = this.organizations.map(organization => {
        return {
          text: organization.name,
          value: organization.organizationId,
          image: `${selectedHost}/api/images/${organization.organizationId}/logo`,
        }
      })
      return organizationArray
    },
  },
  methods: {
    ...mapActions(['GET_ORGANIZATIONS', 'GET_SINGLE_ORGANIZATION']),
    getCurrentOrganizationLogo() {
      return `${selectedHost}/api/images/${this.profile.organizationId}/logo`
    },

    getCurrentOrganizationName() {
      const foundOrganization = this.organizations.find(
        el => el.organizationId === this.profile.organizationId
      )
      if (foundOrganization) return foundOrganization.name
      else return 'dotEngines'
    },
  },

  async created() {
    await this.GET_ORGANIZATIONS()
  },
}
</script>

<style scoped src="./AccountOrganization.css"></style>
