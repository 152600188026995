<template>
  <div class="my-4">
    <h4 class="settings-h4">Allowed Media</h4>
    <div class="wrapper-1 pa-2">
      <v-checkbox
        v-for="media of mediaTypes"
        :key="media.model"
        v-model="kioskSettings.supportedMedia[media.model]"
        :label="media.label"
        class="pa-0 ma-0"
        hide-details
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportedMedia',
  data() {
    return {
      mediaTypes: [
        { label: 'USB (Mass Storage Device)', model: 'usb' },
        { label: 'Optical (CD/DVD)', model: 'cdDvd' },
        { label: 'Portable media (Mobile)', model: 'mobile' },
      ],
    }
  },
  props: {
    kioskSettings: Object,
  },
}
</script>
