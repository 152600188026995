<template>
  <div class="wrapper-1 profile-wrapper bg-white pa-2">
    <div class="mb-2 fill-height">
      <v-tabs
        v-model="tab"
        active-class="black-border"
        :hide-slider="accessibility.isStrongColors"
        :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
      >
        <v-tab href="#tab-personal" @click.stop="tabClicked('personal')">
          {{ $t('Personal') }}
        </v-tab>
        <v-tab
          v-if="loggedUser.organizationId !== 0"
          @click.stop="tabClicked('routes')"
          href="#tab-routes"
        >
          {{ $t('My Routes') }}
        </v-tab>
        <v-tab
          v-if="loggedUser.role !== 'Individual'"
          @click.stop="tabClicked('history')"
          href="#tab-history"
        >
          {{ $t('My History') }}
        </v-tab>
        <v-tab
          v-if="
            (loggedUser.license === 'SFTP' || loggedUser.license === 'Website') && loggedUser.isAllowCasuals
          "
          @click.stop="tabClicked('guests')"
          href="#tab-guests"
        >
          {{ $t('My Guests') }}
        </v-tab>
      </v-tabs>

      <router-view
        @changeTab="e => (tab = e)"
        :cameFrom="cameFrom"
      ></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import RoutesList from '@/components/Profile/RoutesList/RoutesList.vue'
import { alertDialog } from '@/utils'

export default {
  name: 'Profile',
  data() {
    return {
      tab: '',
      cameFrom: '/',
      routes: [],
      userFolders: {
        sourceFolders: [],
        targetFolders: [],
      },
    }
  },
  components: {
    Button,
    RoutesList,
  },
  computed: {
    ...mapGetters(['loggedUser', 'accessibility', 'language', 'isLoggedIn']),
  },
  methods: {
    tabClicked(tabName) {
      if (
        !this.$route.path.includes(tabName) ||
        this.$route.path.includes(tabName + '/')
      )
        this.$router.push(`/profile/${tabName}`)
    },
  },

  mounted() {
    if (!this.isLoggedIn) {
      alertDialog(this, this.$t('Log in to continue.'))
      return this.$router.push('/')
    }

    this.tabClicked('personal')
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.cameFrom = from.path
    })
  },
}
</script>

<style scoped src="./Profile.css"></style>
