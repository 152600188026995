<template>
  <div>
    <v-stepper-step :complete="step > 3" step="3" class="pa-2" tabindex="0">
      {{ $t('Uploading & Filtering') }}
    </v-stepper-step>

    <v-stepper-content step="3" class="pa-0 ma-0" aria-label="filtering...">
      <div class="wrapper-1 pa-1 ma-1">
        <!--TOP LINE--->
        <div class="d-flex justify-space-between align-center">
          <div class="f-14">
            <!--FILES SENT-->
            <span class="mx-2">
              {{ $t('Uploaded') }}: {{ sessionFiles.progress }} /
              {{ sessionFiles.total }}
            </span>

            <!--SIZE BREAKS-->
            <br v-if="$vuetify.breakpoint.width < 310" />
            <span v-else>|</span>

            <!--UPLOADED FILES-->
            <span class="mx-2">
              {{ $t('Sent') }}: {{ computedFilesSent | bytesToSize }} /
              {{ sessionSize.total | bytesToSize }}
            </span>
          </div>
          <!--CANCEL BUTTON-->
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click="stopSessionClicked"
                color="red"
                large
                aria-label="stop filtering"
                >mdi-close-circle-outline</v-icon
              ></template
            >
            <span>Stop</span>
          </v-tooltip>
        </div>

        <!--UPLOAD PROGRESS-->
        <UploadProgress
          :filteringProgress="filteringProgress"
          :timer="timer"
          :session="session"
          type="mobile"
        />
      </div>
    </v-stepper-content>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils'

//Base components
import UploadProgress from '@/components/WebsiteInterface/CommonComponents/UploadProgress/UploadProgress.vue'

export default {
  name: 'Filtering',

  props: {
    step: Number,
    filteringProgress: Object,
    sessionFiles: Object,
    sessionSize: Object,
    curFileUploaded: Object,
    stopSessionClicked: Function,
    session: Object,
    timer: Number,
  },
  components: { UploadProgress },
  filters: {
    bytesToSize,
  },
  computed: {
    computedFilesSent() {
      const num =
        this.sessionSize.progress +
        (this.curFileUploaded ? this.curFileUploaded.file.progress : 0)

      if (num >= this.sessionSize.total) return this.sessionSize.total
      return num
    },
  },
}
</script>
