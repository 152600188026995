export const uploadedFilesTableHeaders = [
  {
    text: 'Name',
    sortBy: 'name',
    class: 'bg-table-heading table-header-first',
    sortable: true,
    width: '60%',
  },
  {
    text: 'Size',
    sortBy: 'size',
    class: 'bg-table-heading ',
    sortable: true,
    width: '10%',
    align: 'center',
  },
  {
    text: 'Last Modified',
    sortBy: 'lastModifiedDate',
    class: 'bg-table-heading ',
    sortable: true,
    width: '25%',
    align: 'center',
  },
  {
    sortBy: 'action',
    name: '',
    sortable: false,
    class: 'bg-table-heading table-header-last',
    width: '5%',
  },
]
