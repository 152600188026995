<template>
  <div class="d-flex">
    <!--ROUTE NAME-->
    <v-text-field
      v-model="route.name"
      :rules="[v => (!!v && !!v.trim()) || 'Name is required']"
      :class="[actionType === 'add' && 'required']"
      :disabled="isEditDisabled"
      @input="checkIfRouteNameTaken"
      :error="!isRouteNameUniqe"
      label="Route Name"
      class="mx-1"
      placeholder=" "
      dense
      style="max-width: 200px"
      validate-on-blur
      hide-details
    >
    </v-text-field>
    <!--POLICY OF THE ROUTE -->
    <v-select
      v-model="route.policy"
      :items="policies"
      :class="[actionType === 'add' && 'required']"
      :disabled="isEditDisabled"
      label="Policy"
      class="mx-1"
      dense
      hide-details
      placeholder=" "
      style="max-width: 200px"
    >
    </v-select>
  </div>
</template>

<script>
import { api } from '@/config'
import { mapGetters } from 'vuex'

export default {
  name: 'RouteNamePolicy',
  props: {
    route: Object,
    freezedRoute: Object,
    actionType: String,
    isEditDisabled: Boolean,
    isRouteNameUniqe: Boolean,
    setIsRouteNameUniqe: Function,
    checkRoute: Function,
  },
  computed: {
    ...mapGetters(['policies']),
  },
  methods: {
    async checkIfRouteNameTaken(v) {
      try {
        this.setIsRouteNameUniqe(true)
        if (this.freezedRoute.name === this.route.name) return

        const res = await api.get(`routes/checkifrouteexists/${v}`)

        if (res.status !== 200) throw Error
        //if the route name is NOT uniqe
        if (res.data === true) {
          this.setIsRouteNameUniqe(false)
        }

        //if the route name is uniqe
        else this.setIsRouteNameUniqe(true)

        this.checkRoute()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
