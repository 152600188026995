<template>
  <div class="totals-wrapper wrapper-1">
    <span class="totals">
      {{ $t('Total Files') }}: {{ totalFiles }} | {{ $t('Size') }}: {{ totalSize }}
    </span>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'Totals',

  computed: {
    ...mapGetters(['getSessionData']),
    totalSize() {
      const total = this.getSessionData.filesData.reduce(
        (acc, file) => acc + file.data.size,
        0
      )

      return bytesToSize(total)
    },
    totalFiles() {
      return this.getSessionData.filesData.length
    },
  },
}
</script>

<style scoped lang="scss">
.totals-wrapper {
  padding: 8px 12px;
  font-size: 1rem;
  width: fit-content;
}
</style>
