<template>
  <v-stepper v-model="step" vertical class="pa-0 ma-0 stepper-wrapper" flat>
    <!--UPLOADING DETAILS-->
    <UploadingDetails
      :session="session"
      :step="step"
      :userActiveSourceRoutes="userActiveSourceRoutes"
      :setStep="v => (step = v)"
      :activeTab="activeTab"
    />

    <!--SELECT FILES-->
    <SelectFiles
      :step="step"
      :session="session"
      :startMobileFiltering="startMobileFiltering"
    />

    <!--FILTERING-->
    <Filtering
      :step="step"
      :filteringProgress="filteringProgress"
      :sessionFiles="sessionFiles"
      :sessionSize="sessionSize"
      :stopSessionClicked="stopSessionClicked"
      :curFileUploaded="curFileUploaded"
      :session="session"
      :timer="timer"
    />

    <!--RESULTS-->
    <FilteringReport
      :step="step"
      :statusResults="statusResults"
      :restartSessionClicked="restartSessionClicked"
    />
  </v-stepper>
</template>

<script>
import { api } from '@/config'

//Childrens
import Filtering from './Childrens/Filtering/Filtering.vue'
import FilteringReport from './Childrens/FilteringReport/FilteringReport.vue'
import SelectFiles from './Childrens/SelectFiles/SelectFiles.vue'
import UploadingDetails from './Childrens/UploadingDetails/UploadingDetails.vue'

//Filtering functions
import {
  startFiltering,
  deleteIndividualFiles,
  cancelUploading,
} from '@/components/WebsiteInterface/uploadingFunctions'

//data to start the session
import { startObjForUpload } from '@/components/WebsiteInterface/Websiteinterface'
import { mapGetters } from 'vuex'

export default {
  name: 'UploadMobile',
  data() {
    return {
      step: 1,
      ...JSON.parse(JSON.stringify(startObjForUpload)),
    }
  },
  props: {
    userActiveSourceRoutes: Array,
  },

  watch: {
    'activeTab.tab': {
      handler: function (n) {
        this.session.subject = ''
        this.session.message = ''
        this.session.selectedCasualUsers = []
        this.session.selectedUsers = []
        if (this.userActiveSourceRoutes.length > 1)
          this.session.selectedRoute = ''
      },
      immediate: true,
    },
  },
  components: { UploadingDetails, SelectFiles, Filtering, FilteringReport },
  computed: {
    ...mapGetters(['loggedUser', 'language']),
  },
  methods: {
    async startMobileFiltering() {
      let biggestFileSize = 0
      this.session.filesData.forEach(el =>
        el.file.numbSize > biggestFileSize
          ? (biggestFileSize = el.file.numbSize)
          : null
      )

      const isSessionApprovedRes = await api.get(
        `filter/sizepermissions?total=${this.session.totalSize}&biggest=${biggestFileSize}`
      )

      if (isSessionApprovedRes.status !== 200) return

      this.step = 3
      startFiltering(this, 'mobile')
    },
    async restartSessionClicked() {
      //if the user is individual and the session wansnt restarted
      if (
        this.loggedUser.role === 'Individual' &&
        !this.session.filesData.length
      )
        await deleteIndividualFiles(
          this.session.operationId,
          this.loggedUser.token
        )
      //this will restart the session
      Object.keys(startObjForUpload).forEach(key => {
        this[key] = JSON.parse(JSON.stringify(startObjForUpload))[key]
      })
      this.step = 1
    },

    stopSessionClicked() {
      cancelUploading(this)
    },
  },
}
</script>

<style scoped src="./UploadMobile.css"></style>
