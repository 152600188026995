<template>
  <div>
    <!--UPLOADING FIELDS-->
    <UploadingDetailsFields
      :session="session"
      :userActiveSourceRoutes="userActiveSourceRoutes"
      :errors="{}"
      :lightSessionRestart="lightSessionRestart"
    />

    <!--BUTTONS TO UPLOAD FILE/FOLDER-->
    <div class="wrapper-1 my-2 mx-1">
      <FileDirButtons
        :session="session"
        :lightSessionRestart="lightSessionRestart"
      />
    </div>
  </div>
</template>

<script>
//Base components
import FileDirButtons from '@/components/WebsiteInterface/CommonComponents/FileDirButtons/FileDirButtons.vue'
import UploadingDetailsFields from '@/components/WebsiteInterface/CommonComponents/UploadingDetailsFields/UploadingDetailsFields.vue'

export default {
  name: 'RegularSend',
  props: {
    session: Object,
    userActiveSourceRoutes: Array,
    restartSessionClicked: Function,
    lightSessionRestart: Function,
  },
  components: { UploadingDetailsFields, FileDirButtons },
}
</script>
