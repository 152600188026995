export const convertstatus = (s) =>  {
    switch (s) {
      case 0:
        return 'Active'
      case 1:
        return 'Blocked'
      case 2:
        return 'Archived'
      default:
        return '-'
    }
  }