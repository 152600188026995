import { selectedHost } from '@/config'

export const resendCredentials = async email => {
  try {
    const token = sessionStorage.getItem('token')
    const body = JSON.stringify(email)
    const res = await fetch(
      selectedHost + '/api/auth/SendResetPasswordMailByAdmin',
      {
        method: 'POST',
        body: body,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    )
    return res.status
  } catch (error) {
    console.log(error)
    return error
  }
}
