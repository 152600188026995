<template>
  <div style="min-width: 200px" class="d-flex align-center mb-5">
    <v-checkbox
      v-model="profile.isAllowReport"
      :disabled="isFieldDisabled"
      class="mt-0 mr-4"
      label="Show transference report on Website"
      hide-details
    >
    </v-checkbox>
    <div v-if="profile.isAllowReport" class="d-flex mt-2 light-text">
      <span
        :class="[isFieldDisabled && 'low-op-disabeld']"
        style="margin-bottom: 2px"
        class="op-83"
        >Details level:
      </span>
      <v-select
        v-model="profile.reportLevel"
        :disabled="isFieldDisabled"
        :items="detailsLevelItems"
        placeholder=" "
        height="16px"
        hide-details
        class="mx-4 mt-0 pt-0 mb-1 mw-100"
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportSection',
  data() {
    return {
      detailsLevelItems: [
        { text: 'All', value: 0 },
        { text: 'Modified', value: 1 },
        { text: 'Partial', value: 2 },
        { text: 'Rejected', value: 3 },
      ],
    }
  },

  props: {
    profile: Object,
    isFieldDisabled: Boolean,
  },
}
</script>
