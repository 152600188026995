<template>
  <div>
    <!--FROM - SENDING ACCOUNT-->
    <v-text-field
      v-model.sync="modelFrom"
      :disabled="!isSettingsEnabled"
      label="Sending Email Account"
      class="mw-400"
      hide-details
    >
    </v-text-field>

    <!--CLIENT ID-->
    <v-text-field
      v-model.sync="modelClientIdProtocol"
      :disabled="!isSettingsEnabled"
      label="Client ID"
      class="mw-400"
      hide-details
    >
    </v-text-field>

    <!--TENANT ID-->
    <v-text-field
      v-model.sync="modelTeanantId"
      :disabled="!isSettingsEnabled"
      label="Tenant ID"
      class="mw-400"
      hide-details
    >
    </v-text-field>

    <!--SECRET-->
    <v-text-field
      v-model.sync="modelSecret"
      :disabled="!isSettingsEnabled"
      :type="isShowSecret ? 'text' : 'password'"
      autocomplete="new-password"
      class="mw-400"
      label="Secret Key"
    >
      <template v-slot:append>
        <v-icon
          @mousedown="isShowSecret = true"
          @mouseup="isShowSecret = false"
          class="pointer"
          >mdi-eye{{ isShowSecret ? '-off' : '' }}</v-icon
        >
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'MsGraph',
  data() {
    return {
      isShowSecret: false,
    }
  },
  props: {
    organization: Object,
    isSettingsEnabled: Boolean,
    isBackupSettings: Boolean,
  },
  computed: {
    modelClientIdProtocol: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.msGraphDefinitions
            .clientId
        return this.organization.emailDefinitions.msGraphDefinitions.clientId
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.msGraphDefinitions.clientId =
            n)
        return (this.organization.emailDefinitions.msGraphDefinitions.clientId =
          n)
      },
    },

    modelTeanantId: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.msGraphDefinitions
            .tenantId
        return this.organization.emailDefinitions.msGraphDefinitions.tenantId
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.msGraphDefinitions.tenantId =
            n)
        return (this.organization.emailDefinitions.msGraphDefinitions.tenantId =
          n)
      },
    },

    modelSecret: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.msGraphDefinitions
            .clientSecret
        return this.organization.emailDefinitions.msGraphDefinitions
          .clientSecret
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.msGraphDefinitions.clientSecret =
            n)
        return (this.organization.emailDefinitions.msGraphDefinitions.clientSecret =
          n)
      },
    },

    modelFrom: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.msGraphDefinitions
            .senderEmail
        return this.organization.emailDefinitions.msGraphDefinitions.senderEmail
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.msGraphDefinitions.senderEmail =
            n)
        return (this.organization.emailDefinitions.msGraphDefinitions.senderEmail =
          n)
      },
    },
  },
}
</script>
