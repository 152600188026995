<template>
  <div class="d-flex align-center justify-center mt-3">
    <div class="bg-white wrapper-1 pa-1 font-weight-bold">
      <v-icon :color="isBusy ? 'red' : 'success'" class="mx-2"
        >mdi-{{ isBusy ? 'alert-' : '' }}octagon</v-icon
      >
      <span v-if="!miniDrawer" :class="[isBusy ? 'red--text' : 'green--text']"
        >Server is {{ isBusy ? '' : 'not ' }}busy</span
      >
    </div>
  </div>
</template>

<script>
import { api } from '@/config'

export default {
  name: 'BusyServer',
  data() {
    return {
      isBusy: false,
      interval: null,
    }
  },
  props: {
    miniDrawer: Boolean,
  },

  methods: {
    async getIsBusy() {
      const res = await api.get('logs/isbusy')

      //if the response is not 200 then don't ask again from the server
      if (res.status !== 200) {
        this.isBusy = false
        return null
      }
      this.isBusy = res.data
    },
  },

  async created() {
    this.interval = setInterval(() => {
      this.getIsBusy()
    }, 3000)

    this.getIsBusy()
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>
