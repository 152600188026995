import { api } from '@/config'

export default {
  state: {
    routesList: [],
    currentUserRoutes: [],
    userRoutes: [],
  },
  actions: {
    async GET_ROUTES({ commit }, { id = null }) {
      try {
        const res = await api.get(
          `routes${id || id === 0 ? '?orgId=' + id : ''}`
        )
        if (res.status !== 200) {
          throw { res }
        }
        commit('SET_ROUTES', res.data)
      } catch (error) {
        console.log(error)
      }
    },
    async GET_USER_ROUTES({ commit }, { id }) {
      try {
        const res = await api.get(`routes?userId=${id}`)
        if (res.status !== 200) {
          throw { res }
        }
        commit('SET_USER_ROUTES', res.data)
      } catch (error) {
        console.log(error)
      }
    },

    async GET_LOGGED_USER_ROUTES({ commit }) {
      try {
        const res = await api.get(`routes/loggedUserRoutes`)
        if (res.status !== 200) {
          throw { res }
        }
        commit('SET_LOGGED_USER_ROUTES', res.data)
      } catch (error) {
        console.log(error)
      }
    },
    async ADD_ROUTE({ commit, dispatch }, payload) {
      try {
        const res = await api.post(`routes`, payload)
        if (res.status !== 200) {
          throw new Error(res.data || 'Error while adding route')
        }
        commit('SET_NOTIFICATION', {
          type: 'success',
          text: 'Route was created successfully',
        })
        return res.data.routeId
      } catch (error) {
        console.log(error)
      }
    },
    async EDIT_ROUTE({ commit }, payload) {
      try {
        const res = await api.put(`routes/${payload.routeId}`, payload)
        if (res.status !== 200) {
          throw { res }
        }
        commit('SET_NOTIFICATION', {
          type: 'success',
          text: 'Route was updated successfully',
        })
        // commit('SET_ROUTES', res.data)
      } catch (error) {
        console.log(error)
      }
    },
    async REMOVE_ROUTE({ commit, state }, payload) {
      try {
        const res = await api.delete(`routes/${payload}`)
        if (res.status !== 200) {
          throw { res }
        }
        const newRoutes = state.routesList.filter(
          el => el.routeId !== payload.routeId
        )
        commit('SET_ROUTES', newRoutes)
        commit('SET_NOTIFICATION', {
          type: 'success',
          text: 'Route was deleted successfully',
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  mutations: {
    SET_ROUTES(state, payload) {
      state.routesList = payload
    },
    SET_USER_ROUTES(state, payload) {
      state.userRoutes = payload
    },
    SET_LOGGED_USER_ROUTES(state, payload) {
      state.currentUserRoutes = payload
    },
  },
  getters: {
    routesList: ({ routesList }) => routesList,
    currentUserRoutes: ({ currentUserRoutes }) => currentUserRoutes,
    userRoutes: ({ userRoutes }) => userRoutes,
  },
}
