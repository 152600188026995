<template>
  <div class="d-flex flex-column fill-height">
    <div v-if="idx" class="d-flex justify-space-between">
      <!--TITLE-->
      <h2>
        {{ idx }}. Routes
        <span class="caption">{{ parsedAxisTitle }}</span>
      </h2>
      <!--EXPAND-->
      <v-icon @click="expandClicked('Routes')">mdi-arrow-expand</v-icon>
    </div>
    <!--RADIO TO SELECT TYPE OF CHART-->
    <v-radio-group v-model="chartType" row hide-details class="mt-0 mb-2 mx-2">
      <v-radio value="bytes" label="Volumes" hide-details></v-radio>
      <v-radio value="files" label="Files counts" hide-details></v-radio>
      <v-radio value="sessions" label="Sessions counts" hide-details></v-radio>
    </v-radio-group>

    <v-checkbox
      v-model="sortByAbc"
      label="Sort alphabetically"
      class="mt-0 pt-0 mx-2"
      hide-details
      dense
    ></v-checkbox>
    <!--ON BIG SCREEN SHOW THE AXIS TITLES-->
    <span v-if="!idx" class="fs-1-12r my-1 mx-2 label">{{
      parsedAxisTitle
    }}</span>

    <div style="width: 100%; overflow: auto; height: calc(100% - 80px)">
      <Bar
        class="fill-height"
        :chart-options="computedChartOptions"
        :chart-data="computedData"
        :style="{ width: computeWidth }"
      />
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'
import { bytesToSize, megabytesToSize } from '@/utils'
import { mapGetters } from 'vuex'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'Routes',

  data() {
    return {
      sortByAbc: true,
      chartType: 'bytes',
      volumesArr: [],
      sessionsCountArr: [],
      filesCountArr: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Total files',
            backgroundColor: '#a2d5ff',
            data: [],
          },
        ],
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: {},
        },
      },
    }
  },

  components: {
    Bar,
  },

  props: {
    analyticsData: Object,
    idx: Number,
    expandClicked: Function,
  },

  computed: {
    ...mapGetters(['isDarkMode']),
    parsedAxisTitle() {
      if (this.chartType === 'files') return '(Names / Files counts)'
      if (this.chartType === 'sessions') return '(Names / Sessions counts)'
      if (this.chartType === 'bytes') return '(Names / Volumes [MB])'
      return ''
    },
    computedChartOptions() {
      //this will add colors to grids
      return {
        ...this.chartOptions,
        scales: {
          y: {
            ...this.chartOptions?.scales?.y,
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },

          x: {
            ...this.chartOptions?.scales?.x,
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
        },
      }
    },
    computedData() {
      const labelsArr = []
      const volumesArr = []
      const sessionsCountArr = []
      const filesCountArr = []
      let copyArr = []

      const copyOfDataRoutes = JSON.parse(
        JSON.stringify(this.analyticsData.routes)
      )
      //if sort by abc
      if (this.sortByAbc) {
        copyArr = this.getABCSortedArray(copyOfDataRoutes)
      }

      //if sort by size
      else {
        //if showing size
        if (this.chartType === 'bytes')
          copyArr = this.getSizeSortedArray(copyOfDataRoutes)
        //if showing sort by sessions count
        else if (this.chartType === 'sessions')
          copyArr = this.getSessionsCountSortedArray(copyOfDataRoutes)
        //if showing sort by files count
        else copyArr = this.getFilesCountSortedArray(copyOfDataRoutes)
      }

      copyArr.forEach(route => {
        labelsArr.push(route.name)
        volumesArr.push(route.volume / 1024 / 1024)
        sessionsCountArr.push(route.sessionsCount)
        filesCountArr.push(route.filesCount)
      })

      this.chartData.labels = [...labelsArr]

      this.volumesArr = volumesArr
      this.sessionsCountArr = sessionsCountArr
      this.filesCountArr = filesCountArr

      //if the user wants to see files count
      if (this.chartType === 'files') {
        this.chartOptions.plugins.tooltip.callbacks = {}
        this.chartOptions.scales = {
          y: {
            title: { display: true, text: 'Files count' },
          },
        }
        return {
          ...this.chartData,
          datasets: [
            {
              data: [...this.filesCountArr],
              label: 'Total files',
              backgroundColor: '#a2d5ff',
            },
          ],
        }
      }

      //if the user want to see the volume (bytes)
      else if (this.chartType === 'bytes') {
        this.chartOptions.plugins.tooltip.callbacks = {
          label: function (context) {
            const foundObj = context.dataset.data[+context.dataIndex]
            return `${context.dataset.label}: ${megabytesToSize(foundObj)}`
          },
        }

        // this.chartOptions.scales = {
        //   y: {
        //     title: { display: true, text: 'Megabytes' },
        //   },
        // }

        return {
          ...this.chartData,
          datasets: [
            {
              data: [...this.volumesArr],
              label: 'Size',
              backgroundColor: '#a2d5ff',
            },
          ],
        }
      }

      //if the user want to see sessions count
      else {
        this.chartOptions.plugins.tooltip.callbacks = {}
        this.chartOptions.scales = {
          y: {
            title: { display: true, text: 'Files count' },
          },
        }
        return {
          ...this.chartData,
          datasets: [
            {
              data: [...this.sessionsCountArr],
              label: 'Total sessions',
              backgroundColor: '#a2d5ff',
            },
          ],
        }
      }
    },
    computeWidth() {
      const { length } = this.sessionsCountArr

      if (length < 10) return '100%'
      else if (length < 15) return '150%'
      else if (length < 20) return '200%'
      else return '300%'
    },
  },

  methods: {
    getABCSortedArray(arr) {
      return arr.sort((a, b) => {
        {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          else return 0
        }
      })
    },
    getSizeSortedArray(arr) {
      return arr.sort((a, b) => {
        {
          if (a.volume > b.volume) return -1
          else if (a.volume < b.volume) return 1
          else return 0
        }
      })
    },

    getSessionsCountSortedArray(arr) {
      return arr.sort((a, b) => {
        {
          if (a.sessionsCount > b.sessionsCount) return -1
          else if (a.sessionsCount < b.sessionsCount) return 1
          else return 0
        }
      })
    },

    getFilesCountSortedArray(arr) {
      return arr.sort((a, b) => {
        {
          if (a.filesCount > b.filesCount) return -1
          else if (a.filesCount < b.filesCount) return 1
          else return 0
        }
      })
    },
  },
}

// {
//   routeId: 1234,
//   name: 'route 1',
//   volume: 12312341, // bytes
//   filesCount: 234,
//   sessionsCount: 12,
//}
</script>
