<template>
  <div
    class="wrapper-1 bg-white"
    :class="computedDirctoryWrapper"
    tabindex="0"
    aria-label="Downloading table"
  >
    <!--TABLE HEADER-->
    <TableHeader
      :isAllDirFilesSelected="isAllDirFilesSelected"
      :computedFileList="computedFileList"
      :setSelectedDirFiles="setSelectedDirFiles"
      :type="type"
      :sortByChanged="sortByChanged"
      :setNameTitleWidth="v => (nameTitleWidth = v)"
    />

    <!--TABLE ROWS-->
    <div class="table-rows-wrapper" :class="computedFileRowsWrapper">
      <TableRow
        v-if="computedFileList.length"
        v-for="item of computedFileList"
        :item="item"
        :key="item.name + item.dateCreated"
        :changeShownDirClicked="changeShownDirClicked"
        :setMessage="setMessage"
        :setDynamicFields="setDynamicFields"
        :twoDaysMsFromNow="twoDaysMsFromNow"
        :nowDate="nowDate"
        :isAllDirFilesSelected="isAllDirFilesSelected"
        :setSelectedDirFiles="setSelectedDirFiles"
        :selectedDirFiles="selectedDirFiles"
        :setShownMoreDataDirFile="v => (shownMoreDataDirFile = v)"
        :type="type"
        :computedFileList="computedFileList"
        :shownDirectory="shownDirectory"
        :nameTitleWidth="nameTitleWidth"
      />

      <span v-if="!computedFileList.length" class="text-center fill-width">{{
        $t('No files were found')
      }}</span>
    </div>

    <!--DIALOG FOR MESSAGE SUBJCT-->
    <!-- <SubjectMessageDialog
      v-if="messageDialog"
      :messageDialog="messageDialog"
      :closeSubjectMessage="() => (messageDialog = null)"
    /> -->

    <DynamicFieldsDialog
      v-if="interfaceDynamicFields"
      :closeDynamicFieldsDialog="() => (interfaceDynamicFields = null)"
      :interfaceDynamicFields="this.interfaceDynamicFields"
    />

    <!--MORE INFO ABOUT FILE / DIR-->
    <MoreDirFileDataDialog
      v-if="shownMoreDataDirFile"
      :shownMoreDataDirFile="shownMoreDataDirFile"
      :setShownMoreDataDirFile="v => (shownMoreDataDirFile = v)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Childrens
import MoreDirFileDataDialog from './Children/MoreDirFileDataDialog/MoreDirFileDataDialog.vue'
// import SubjectMessageDialog from './Children/SubjectMessageDialog/SubjectMessageDialog.vue'
import TableHeader from './Children/TableHeader/TableHeader.vue'
import TableRow from './Children/TableRow/TableRow.vue'
import DynamicFieldsDialog from '@/components/WebsiteInterface/CommonComponents/DynamicFieldsDialog/DynamicFieldsDialog.vue'

export default {
  name: 'DirectoryDownloadTable',
  data() {
    return {
      messageDialog: null,
      interfaceDynamicFields: null,
      twoDaysMsFromNow: 0,
      isAllDirFilesSelected: false,
      shownMoreDataDirFile: null,
      sortBy: 'name',
      sortType: 'inc',
      nameTitleWidth: 200,
    }
  },

  components: {
    TableHeader,
    TableRow,
    // SubjectMessageDialog,
    MoreDirFileDataDialog,
    DynamicFieldsDialog,
  },
  props: {
    shownDirectory: Object,
    changeShownDirClicked: Function,
    setSelectedDirFiles: Function,
    dirSearch: String,
    selectedDirFiles: Array,
    type: String,
  },
  watch: {
    selectedDirFiles(n) {
      if (n && n.length === this.computedFileList.length)
        this.isAllDirFilesSelected = true
      else this.isAllDirFilesSelected = false
    },
  },

  computed: {
    ...mapGetters(['accessibility']),
    computedFileRowsWrapper() {
      const arr = []
      if (this.type === 'tablet') arr.push('table-rows-wrapper-tablet')

      if (!this.computedFileList.length) arr.push('d-flex')

      if (this.type === 'desktop') {
        arr.push(`table-rows-wrapper-fs-${this.accessibility.fontSize}`)
      }

      return arr
    },
    computedDirctoryWrapper() {
      const arr = []
      if (this.type === 'desktop') arr.push('my-2 down-table-desktop-wrapper')
      else if (this.type === 'tablet') arr.push('down-table-tablet-wrapper')
      else if (this.type === 'mobile') {
        arr.push('down-table-mobile-wrapper mx-1')

        if (this.accessibility.fontSize > 1)
          arr.push('down-table-mobile-wrapper-big-font')
      }

      const width = this.$vuetify.breakpoint.width
      if (this.accessibility.fontSize === 3 && width > 1024 && width < 1180)
        arr.push('down-table-accessibility')

      return arr
    },
    computedFileList() {
      //this computed function caculate what to present to the user based on sort by an search
      let filesListCopy = this.shownDirectory.children.map(el => el)

      if (this.dirSearch) {
        filesListCopy = filesListCopy.filter(item =>
          item.name.toLowerCase().includes(this.dirSearch.toLowerCase())
        )
      }

      //if the sort is by NAME
      if (this.sortBy === 'name') {
        filesListCopy.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          else return 0
        })
        if (this.sortType === 'inc') return filesListCopy
        return filesListCopy.reverse()
      }

      //if the sort is by STATUS
      else if (this.sortBy === 'status') {
        filesListCopy.sort((a, b) => {
          if (a.status < b.status) return -1
          else if (a.status > b.status) return 1
          else return 0
        })
        if (this.sortType === 'inc') return filesListCopy
        return filesListCopy.reverse()
      }

      //if the sort is by SIZE
      else if (this.sortBy === 'size') {
        filesListCopy.sort((a, b) => {
          if (a.size < b.size) return -1
          else if (a.size > b.size) return 1
          else return 0
        })
        if (this.sortType === 'inc') return filesListCopy
        return filesListCopy.reverse()
      }

      //if the sort is by CREATED DATE
      else if (this.sortBy === 'dateCreated') {
        filesListCopy.sort((a, b) => {
          if (a.dateCreated < b.dateCreated) return -1
          else if (a.dateCreated > b.dateCreated) return 1
          else return 0
        })
        if (this.sortType === 'inc') return filesListCopy
        return filesListCopy.reverse()
      }

      //if the sort is by DELETION DATE
      else if (this.sortBy === 'deletionIn') {
        filesListCopy.sort((a, b) => {
          if (a.deletionDate < b.deletionDate) return -1
          else if (a.deletionDate > b.deletionDate) return 1
          else return 0
        })
        if (this.sortType === 'inc') return filesListCopy
        return filesListCopy.reverse()
      }
    },
  },
  methods: {
    setMessage(item) {
      this.messageDialog = {
        subject: item.subject,
        message: item.message,
      }
    },
    setDynamicFields(item) {
      console.log(item)
      this.interfaceDynamicFields = item.interfaceDynamicFields
    },

    sortByChanged(sortBy, sortType) {
      this.sortType = sortType
      this.sortBy = sortBy
    },
  },
  created() {
    //in desktop sort by date
    if (this.type === 'desktop') this.sortByChanged('dateCreated', 'dec')
    //in mobile and tablet sort by name
    else this.sortByChanged('name', 'inc')

    this.twoDaysMsFromNow = new Date().valueOf() + 172800000
    this.nowDate = new Date().valueOf()
  },
}
</script>

<style scoped src="./DirectoryDownloadTable.css"></style>
