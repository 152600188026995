import intact from '@/assets/intact.svg'
import modified from '@/assets/modified.svg'
import partial from '@/assets/partial.svg'
import blocked from '@/assets/blocked.svg'

export const summaryBuilder = [
  { type: 'intact', img: intact, label: 'Intact' },
  { type: 'modified', img: modified, label: 'Modified' },
  { type: 'partial', img: partial, label: 'Partial' },
  { type: 'rejected', img: blocked, label: 'Blocked' },
]

export const sessionHistoryHeaders = vue => [
  // {
  //   text:
  //     vue.interactiveInterfaceFields.subjectFieldText === 'Subject'
  //       ? vue.$t('Subject')
  //       : vue.$t('Request Number'),
  //   value: 'subject',
  //   align: 'start',
  //   width: '200px',
  //   class: 'bg-table-heading',
  // },

  {
    text: vue.$t('Report Summary'),
    value: 'reportSummary',
    align: 'center',
    class: 'bg-table-heading',
    width: '250px',
    sortable: false,
  },
  {
    text: vue.$t('More'),
    value: 'more',
    icon: 'mdi-dots-horizontal-circle-outline',
    align: 'center',
    width: '50px',
    class: `bg-table-heading ${
      vue.language === 'he' ? 'table-header-first' : 'table-header-last'
    }`,
    sortable: false,
  },
]
