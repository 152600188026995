<template>
  <div>
    <h4 class="light-text mt-2">Awaiting Files</h4>
    <div class="d-flex my-2 light-text">
      <span class="ml-1 w-210" :class="[isEditDisabled && 'low-op-disabeld']"
        >Delete files after:</span
      >

      <!--INPUT BY NUMBER-->
      <v-text-field
        v-model="fileLifeTime.fileDeleteAfterNumber"
        @input="deleteFileChanged"
        :rules="digitsRules"
        :error="isDeleteAlertTimesError"
        :disabled="
          isEditDisabled || fileLifeTime.fileDeleteAfterNumber === null
        "
        class="mx-3 my-0 pa-0 mw-30 centered-input"
        height="16px"
        type="number"
        hide-details
      ></v-text-field>

      <!--SELECT WHEN-->
      <v-select
        v-model="fileLifeTime.fileDeleteAfterType"
        @input="deleteFileChanged"
        :error="isDeleteAlertTimesError"
        class="ma-0 pa-0 mw-100"
        height="16px"
        hide-details
        :items="computedLifeTimeType"
        :disabled="isEditDisabled"
      ></v-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AwaitingFiles',
  props: {
    isEditDisabled: Boolean,
    fileLifeTime: Object,
    isDeleteAlertTimesError: Boolean,
    route: Object,
  },
  watch: {
    'fileLifeTime.fileDeleteAfterType': {
      handler: function (n) {
        if (n === 2) this.fileLifeTime.fileDeleteAfterNumber = null
        if (n < 2 && !this.fileLifeTime.fileDeleteAfterNumber) {
          this.fileLifeTime.fileDeleteAfterNumber = 1
          this.deleteFileChanged()
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(['loggedUser']),

    computedLifeTimeType() {
      return [
        { text: 'Hours', value: 0 },
        { text: 'Days', value: 1 },
        {
          text: 'Never',
          value: 2,
          disabled: this.loggedUser.role !== 'SuperAdmin',
        },
      ]
    },
    digitsRules(value) {
      const vue = this
      if (this.loggedUser.role === 'SuperAdmin')
        return [
          value => !!value || value === 0 || 'Required',
          value => value > 0 || 'Only positive Numbers',
          value => (value % 1 === 0 && value !== 'e') || 'Only Integers',
        ]
        
      return [
        value => !!value || value === 0 || 'Required',
        value => value > 0 || 'Only positive Numbers',
        value => (value % 1 === 0 && value !== 'e') || 'Only Integers',
        value => {
          if (this.fileLifeTime.fileDeleteAfterType === 0) {
            // IF HOURS
            const date = value * 3600000 + 3600000 // value * hour
            return date < 604800000 + 3600000 // one week + hour
          } else {
            const date = value * 86400000 // value * days

            return date < 604800000 + 3600000 // one week + hour
          }
        },
      ]
    },
  },
  methods: {
    deleteFileChanged() {
      if (!this.fileLifeTime.fileDeleteAfterNumber) return 0

      //HOURS
      if (this.fileLifeTime.fileDeleteAfterType === 0) {
        //if delete file after x hours
        this.route.deleteFilesAtByMs =
          this.fileLifeTime.fileDeleteAfterNumber * 3600000 // value * 1 hour
        return
      }
      //DAYS
      else if (this.fileLifeTime.fileDeleteAfterType === 1) {
        this.route.deleteFilesAtByMs =
          this.fileLifeTime.fileDeleteAfterNumber * 86400000 // value * 1 day
        return
      }
      //NEVER
      else {
        this.route.deleteFilesAtByMs = -1
      }
    },
  },
}
</script>
