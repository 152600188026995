export const regAccountAlerts = [
  {
    description: "Senders or recipients list of routes updated",
    model: 'emailsListUpdated',
  },
  {
    description: 'Account is added to a route',
    model: 'newEndpointAdded',
  },
]
