<template>
  <div id="accessibility-menu">
    <v-menu
      v-model="menu"
      attach="#accessibility-menu"
      :close-on-content-click="false"
      :nudge-width="50"
      max-width="300px"
      offset-y
    >
      <!--CLICK TO OPEN MENU-->
      <template v-slot:activator="{ on, attrs }">
        <div
          aria-label="Accessibility menu"
          class="accessibility-icon-wrapper d-flex align-center justify-center mx-1"
          v-bind="attrs"
          v-on="on"
          role="button"
          tabindex="0"
        >
          <img
            :src="accessibilityIcon"
            alt="Accessibility Icon"
            style="width: 30px"
            tabindex="-1"
          />
        </div>
      </template>

      <!--CARD IS THE INFO INSIDE THE MENU-->
      <v-card
        :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
        width="250px"
        tabindex="0"
      >
        <v-list ref="card">
          <!--TITLE-->
          <v-list-item>
            <v-list-item-title class="text-center">
              <h3>{{ $t('Accessibility') }}</h3>
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>

          <!--STRONG COLORS -->
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t('Strong colors')
            }}</v-list-item-title>
            <v-switch
              aria-label="Strong colors"
              v-model="accessibility.isStrongColors"
              @change="strongColorsChanged"
              hide-details
              class="ma-0 pa-0"
              :disabled="isDarkMode"
            ></v-switch>
          </v-list-item>

          <!--REGULAR FONT - TOGGLE-->
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t('Regular font')
            }}</v-list-item-title>
            <v-switch
              v-model="accessibility.isRegularFont"
              aria-label="Regular font"
              hide-details
              class="ma-0 pa-0"
            ></v-switch>
          </v-list-item>

          <!--ANIMATIONS - TOGGLE-->
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t('Animations')
            }}</v-list-item-title>
            <v-switch
              v-model="accessibility.isAnimation"
              aria-label="Show animations"
              hide-details
              class="ma-0 pa-0"
            ></v-switch>
          </v-list-item>

          <v-divider class="mb-2"></v-divider>

          <!-- FONT SIZE RADIO -->
          <v-list-item
            class="flex-column min-height-unset my-3"
            role="radiogroup"
            aria-label="Font size"
            :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
          >
            <v-list-item-title class="font-weight-medium"
              >{{ $t('Font size') }}:</v-list-item-title
            >
            <v-radio-group
              class="mx-auto my-0"
              row
              v-model="accessibility.fontSize"
              hide-details
            >
              <v-radio
                :value="1"
                role="radio"
                aria-label="small"
                :aria-checked="accessibility.fontSize === 1"
              >
                <template v-slot:label>
                  <span class="f-16">A</span>
                </template>
              </v-radio>
              <v-radio
                :value="2"
                role="radio"
                aria-label="medium"
                :aria-checked="accessibility.fontSize === 2"
              >
                <template v-slot:label>
                  <span class="f-18">A</span>
                </template>
              </v-radio>
              <v-radio
                :value="3"
                role="radio"
                aria-label="large"
                :aria-checked="accessibility.fontSize === 3"
              >
                <template v-slot:label>
                  <span class="f-22">A</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-list-item>

          <!--MOUSE TYPE RADIO BUTTON-->
          <v-list-item
            class="flex-column min-height-unset my-3"
            role="radiogroup"
            aria-label="Font size"
            :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
          >
            <v-list-item-title class="font-weight-medium"
              >{{ $t('Mouse type') }}:</v-list-item-title
            >
            <v-radio-group
              class="mx-auto my-0"
              row
              v-model="accessibility.mouseType"
              hide-details
            >
              <v-radio value="regular">
                <template v-slot:label>
                  <img
                    :src="whiteCursorIcon"
                    style="height: 20px"
                    alt="regular cursor icon"
                  />
                </template>
              </v-radio>

              <v-radio label="A" value="white">
                <template v-slot:label>
                  <img :src="whiteCursorIcon" alt="white cursor icon" />
                </template>
              </v-radio>

              <v-radio label="A" value="black">
                <template v-slot:label>
                  <img :src="blackCursorIcon" alt="black cursor icon" />
                </template>
              </v-radio>
            </v-radio-group>
          </v-list-item>

          <!--CLOSE-->
          <v-list-item class="d-flex justify-end">
            <Button
              :text="$t('Close')"
              btnType="grey"
              :clickAction="() => (menu = false)"
              class="mx-1"
            />
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
//Icons
import accessibilityIcon from '@/assets/accessibilityIcon.svg'
import blackCursorIcon from '@/assets/blackCursorIcon.svg'
import whiteCursorIcon from '@/assets/whiteCursorIcon.svg'

export default {
  name: 'Accessibility',
  data() {
    return {
      accessibilityIcon,
      blackCursorIcon,
      whiteCursorIcon,
      menu: false,
    }
  },
  watch: {
    accessibility: {
      handler: function () {
        localStorage.setItem(
          'accessibility',
          JSON.stringify(this.accessibility)
        )
      },
      deep: true,
    },
  },
  components: { Button },
  computed: {
    ...mapGetters(['accessibility', 'isDarkMode', 'language']),
  },
  methods: {
    ...mapMutations(['RESET_ACCESSIBILITY', 'SET_ACCESSIBILITY']),
    strongColorsChanged(v) {
      if (v) this.$vuetify.theme.themes.light.primary = '#000000'
      else this.$vuetify.theme.themes.light.primary = '#2289DD'
    },
  },

  beforeDestroy() {
    this.strongColorsChanged(false)
    this.RESET_ACCESSIBILITY()
  },

  beforeMount() {
    const savedAccessibility = localStorage.getItem('accessibility')
    if (savedAccessibility) {
      this.SET_ACCESSIBILITY(JSON.parse(savedAccessibility))
    }
  },
}
</script>

<style scoped>
.accessibility-icon-wrapper {
  background-color: #2289dd;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.min-height-unset {
  min-height: unset;
}

#accessibility-menu .v-menu__content {
  right: 50px !important;
  left: unset !important;
}
</style>
