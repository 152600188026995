<template>
  <v-dialog
    v-model="showRenamePolicy"
    @click:outside="$emit('show-rename-policy', false)"
    width="auto !important"
    class="wrapper-1 bg-white pos-relative"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card class="wrapper-1">
      <v-card-title class="text-center bg-grey mb-2">
        Rename policy
      </v-card-title>
      <v-card-text class="px-2 pb-1">
        <p class="mb-2">
          Current name: <b>{{ policyToRename }}</b>
        </p>

        <v-text-field
          v-model="policy"
          lazy
          :rules="newPolicyRules"
          class="ma-auto"
          placeholder="New policy name"
          type="text"
          dense
        ></v-text-field>
      </v-card-text>
      <div class="d-flex justify-space-between pa-2">
        <Button
          width="fit-content"
          btnType="grey"
          text="Cancel"
          class="mx-2"
          :clickAction="cancelRenamePolicyClicked"
        />
        <Button
          :clickAction="confirmRenamePolicy"
          :disabled="isCreateRenameBtnsDisabled"
          width="fit-content"
          btnType="blue"
          class="mx-2"
          text="Rename"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { isFolderNameValid } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Component files
import { newPolicyRules, renamePolicy } from '../Policies'

export default {
  name: 'RenamePolicy',
  data() {
    return {
      policy: '',
    }
  },
  props: { policyToRename: String, showRenamePolicy: Boolean },
  emits: ['show-rename-policy'],
  components: { Button },
  computed: {
    ...mapGetters(['policies', 'loggedUser', 'isDarkMode']),
    newPolicyRules,
    isCreateRenameBtnsDisabled() {
      if (!this.policy) return true
      else if (!isFolderNameValid(this.policy)) return true
      else if (this.policies.includes(this.policy)) return true
      return false
    },
  },
  methods: {
    ...mapActions(['GET_POLICIES']),
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    async confirmRenamePolicy() {
      try {
        this.SET_PROCESSING(true)
        const data = {
          oldName: this.policyToRename,
          newName: this.policy,
        }
        const res = await renamePolicy(this.loggedUser.organizationId, data)
        if (res.status === 200) {
          this.SET_NOTIFICATION({
            type: 'success',
            text: 'Policy name was changed successfully.',
          })
          await this.GET_POLICIES(this.loggedUser.organizationId)
        } else {
          this.SET_NOTIFICATION({
            type: 'error',
            text: 'Some error occured. Please try again later.',
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        this.policy = ''
        this.$emit('show-rename-policy', false)
        this.SET_PROCESSING(false)
      }
    },
    cancelRenamePolicyClicked() {
      this.policy = ''
      this.$emit('show-rename-policy', false)
    },
  },
}
</script>
