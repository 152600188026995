var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2"},[_c('h4',{staticClass:"settings-h4"},[_vm._v("Guest Accounts")]),_c('div',{staticClass:"wrapper-2 pa-4 mb-4 pos-relative"},[_c('v-checkbox',{staticClass:"pa-0 ma-0 mb-2",attrs:{"disabled":!_vm.organization.casualUsersDefinitions.isActive ||
        _vm.organization.status !== 0,"label":"Two-Factor Authentication","hide-details":""},on:{"change":_vm.allowTwoStepChanged},model:{value:(_vm.organization.casualUsersDefinitions.isTwoStep),callback:function ($$v) {_vm.$set(_vm.organization.casualUsersDefinitions, "isTwoStep", $$v)},expression:"organization.casualUsersDefinitions.isTwoStep"}}),_c('TimezoneOffset',{attrs:{"currentTimezoneOffset":_vm.organization.casualUsersDefinitions.timezoneOffsetInMinutes,"disabled":false,"label":"Timezone offset in folder names of guests"},on:{"offset-type-changed":_vm.offsetTypeChanged}}),_c('CasualFilesLifeTime',{attrs:{"organization":_vm.organization}}),_c('span',{staticClass:"light-text",class:[
        (!_vm.organization.casualUsersDefinitions.isActive ||
          _vm.organization.status !== 0) &&
          'low-op-disabeld',
      ]},[_vm._v(" Policies: ")]),_c('div',{staticClass:"d-flex mt-2"},[_c('v-select',{staticClass:"px-0 mx-3 py-0",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.computedPolicies,"disabled":!_vm.organization.casualUsersDefinitions.isActive ||
          _vm.organization.status !== 0,"label":"Incoming Files","hide-details":""},model:{value:(_vm.organization.casualUsersDefinitions.policyIn),callback:function ($$v) {_vm.$set(_vm.organization.casualUsersDefinitions, "policyIn", $$v)},expression:"organization.casualUsersDefinitions.policyIn"}}),_c('v-select',{staticClass:"px-0 mx-3 py-0",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.computedPolicies,"disabled":!_vm.organization.casualUsersDefinitions.isActive ||
          _vm.organization.status !== 0,"label":"Outgoing Files","hide-details":""},model:{value:(_vm.organization.casualUsersDefinitions.policyOut),callback:function ($$v) {_vm.$set(_vm.organization.casualUsersDefinitions, "policyOut", $$v)},expression:"organization.casualUsersDefinitions.policyOut"}})],1),_c('LicensesTable',{attrs:{"casualsLicense":_vm.casualLicense,"allowCasualsChanged":_vm.allowCasualsChanged}}),_c('OnlineHelp',{attrs:{"page":"settings","section":"casualSettings","title":"Guest Accounts"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }