<template>
  <div
    class="wrapper-1 pa-2 d-flex justify-space-evenly align-center h-50"
    :class="computedFileDirActionsWrapper"
  >
    <!--GENERATE REPORT-->
    <Button
      v-if="isShowFilteringReport"
      :clickAction="showReportClicked"
      :disabled="isShowReportDisabled"
      btnType="grey"
      :text="$t('View Filtering Report')"
      fontSize="16"
      height="35px"
      width="220"
    />

    <!--DELETE FILES-->
    <Button
      :clickAction="deleteFilesClicked"
      :disabled="isDeleteFilesDisabled"
      btnType="grey"
      :text="$t('Delete Selected')"
      fontSize="16"
      height="35px"
      width="220"
    />
    <!--DOWNLOAD FILES-->
    <Button
      v-if="loggedUser.license !== 'SFTP'"
      :clickAction="downloadFilesClicked"
      :disabled="isDownloadFilesDisabled"
      :text="$t('Download Selected')"
      fontSize="16"
      height="35px"
      width="220"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'FileDirActions',
  props: {
    selectedDirFiles: Array,
    showReportClicked: Function,
    downloadFilesClicked: Function,
    deleteFilesClicked: Function,
  },

  components: { Button },

  computed: {
    ...mapGetters(['loggedUser', 'accessibility']),

    computedFileDirActionsWrapper() {
      const width = this.$vuetify.breakpoint.width
      if (this.accessibility.fontSize === 3 && width > 1024 && width < 1180) {
        return 'accessibility-big-font-size'
      }
      return ''
    },
    isShowFilteringReport() {
      //casual can't see report
      if (this.loggedUser.role === 'Casual') return false

      //if the logged user dosen't have the option to see report
      if (!this.loggedUser.isAllowReport) return false

      return true
    },

    isShowReportDisabled() {
      if (!this.selectedDirFiles.length) return true
      //if all the files that the user selected dosen't have historyId then there is no option to report
      if (this.selectedDirFiles.every(el => el.status && !el.historyId))
        return true
      return false
    },

    isDownloadFilesDisabled() {
      if (!this.selectedDirFiles.length) return true
      let sumOfSize = 0

      this.selectedDirFiles.forEach(dirFlie => (sumOfSize += dirFlie.size))

      if (sumOfSize === 0) return true
      return false
    },

    isDeleteFilesDisabled() {
      if (!this.selectedDirFiles.length) return true

      return false
    },
  },
}
</script>

<style scoped>
.accessibility-big-font-size {
  height: 100px !important;
  flex-wrap: wrap;
}
</style>
