<template>
  <div class="d-flex flex-column fill-height">
    <div v-if="idx" class="d-flex justify-space-between">
      <!--TITLE-->
      <h2>{{ idx }}. Alerts</h2>

      <!--EXPAND-->
      <v-icon @click="expandClicked('Alerts')">mdi-arrow-expand</v-icon>
    </div>

    <div class="d-flex">
      <div v-for="item of severityItems" class="d-flex mx-2">
        <v-checkbox
          v-model="shownAlertsTypes"
          :value="item.severity"
          hide-details
          class="ma-0 pa-0"
        >
          <template v-slot:label>
            <div class="d-flex align-center">
              <v-icon :color="item.color">mdi-alert</v-icon>
              <span class="fs-1-12r mx-1"> {{ item.severity }}</span>
            </div>
          </template>
        </v-checkbox>
      </div>
    </div>
    <!--SELECT WHICH ALERT TO SHOW-->
    <v-select
      v-if="false"
      :items="severityItems"
      item-value="severity"
      label="Show"
      hide-deatils
      class="mw-160"
      multiply
    >
      <template v-slot:item="{ item }">
        <div class="d-flex align-center">
          <v-checkbox v-model="shownAlertsTypes" :value="item.severity">
          </v-checkbox>
          <div @click="addRemoveShownAlerts(item.severity)">
            <v-icon :color="item.color">mdi-alert</v-icon>
            <span class="fs-1-12r font-weight-bold w-75 mx-2">{{
              item.severity
            }}</span>
          </div>
        </div>
      </template>

      <template v-slot:selection>
        <div class="d-flex align-center">
          <v-icon
            v-if="shownAlertsTypes.includes('High')"
            class="mx-1"
            color="red"
          >
            mdi-alert
          </v-icon>
          <v-icon
            v-if="shownAlertsTypes.includes('Medium')"
            class="mx-1"
            color="orange"
            >mdi-alert</v-icon
          >
          <v-icon
            v-if="shownAlertsTypes.includes('Low')"
            class="mx-1"
            color="green"
            >mdi-alert</v-icon
          >
        </div>
      </template>

      <template v-slot:no-data>sda</template>
    </v-select>

    <!--WRAPPER-->
    <div class="fill-width fill-height overflow-auto">
      <!--ONE MESSAGE -->
      <Alert v-for="alert of computedAlerts" :key="alert.id" :alert="alert" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import Alert from './Children/Alert/Alert.vue'
const alerts = [
  {
    id: '2134tgfd',
    message: 'this is message',
    title: 'some title',
    isRead: false,
    time: 1695799637306, //ms
    organizationId: 34,
    severity: 1, // 1/2/3 - 1 High
  },
  {
    id: '2134tjhgfd',
    message: 'this is message 2 ',
    title: 'some title',
    isRead: true,
    time: 1695739637306, //ms
    organizationId: 34,
    severity: 2, // 1/2/3 - 1 High
  },
  {
    id: '2134tjshgfd',
    message: 'this is message 2 ',
    title: 'some title',
    isRead: true,
    time: 1695736737306, //ms
    organizationId: 34,
    severity: 3, // 1/2/3 - 1 High
  },
  {
    id: '2134tjsdhgfd',
    message: 'this is message 2 ',
    title: 'some title',
    isRead: false,
    time: 1695739237306, //ms
    organizationId: 34,
    severity: 2, // 1/2/3 - 1 High
  },
]

export default {
  name: 'Alerts',
  data() {
    return {
      alerts,
      shownAlertsTypes: ['High', 'Medium', 'Low'],
      severityItems: [
        {
          severity: 'High',
          color: 'red',
        },
        {
          severity: 'Medium',
          color: 'orange',
        },
        {
          severity: 'Low',
          color: 'green',
        },
      ],
    }
  },

  props: {
    idx: Number,
    expandClicked: Function,
  },

  components: { Alert },

  computed: {
    computedAlerts() {
      const obj = {
        low: [],
        medium: [],
        high: [],
      }
      this.alerts.forEach(el => {
        if (el.severity === 1) obj.high.push(el)
        else if (el.severity === 2) obj.medium.push(el)
        else obj.low.push(el)
      })

      let arr = []
      if (this.shownAlertsTypes.includes('High')) arr = [...arr, ...obj.high]
      if (this.shownAlertsTypes.includes('Medium'))
        arr = [...arr, ...obj.medium]
      if (this.shownAlertsTypes.includes('Low')) arr = [...arr, ...obj.low]

      return arr.sort((a, b) => a.time > b.time)
    },
  },

  methods: {
    addRemoveShownAlerts(item) {
      if (this.shownAlertsTypes.includes(item))
        this.shownAlertsTypes = this.shownAlertsTypes.filter(el => el !== item)
      else this.shownAlertsTypes.push(item)
    },
  },
}
</script>
