<template>
    <div v-if="getIsInMiddleOfFilteingData" class="pa-2 fill-width" :class="['wrapper-1 bg-white mt-2']">
    <!--TITLE-->
    <h3 class="text-center mb-3 fs-1-12r">
      {{ $t('Uploading & Filtering Progress') }}
    </h3>

    <!--TIMER-->
    <div v-if="timer" class="d-flex align-center my-3">
      <img width="30px" class="mx-2" src="@/assets/timer.svg" alt="Timer" />
      <span class="f-14">
        {{ $t('Duration') }}
        <span> {{ timer | parseTime }} </span>
        [{{ timer > 3600000 ? 'HH:' : '' }}MM:SS]
      </span>
    </div>

    <div>
      <!--PROGRESSBAR-->
      <v-progress-linear
        v-show="getPercentOfFiltering < 100"
        color="primary"
        :value="getPercentOfFiltering"
        height="32px"
      >
        <span class="fs-1-25r font-weight-bold">{{
          getPercentOfFiltering + '%'
        }}</span></v-progress-linear
      >

      <v-progress-linear
        v-show="getPercentOfFiltering >= 100"
        color="primary"
        :value="getPercentOfFiltering"
        height="32px"
      >
        <span class="fs-1-25r font-weight-bold"
          >100.00%</span
        ></v-progress-linear
      >
    </div>
  </div>
</template>

<script>
import { parseTime } from '@/utils'

//Images of progress
import uploadImage from '@/assets/preparing-files.svg'
import analyzeImage from '@/assets/analyze.svg'
import synthesizeImage from '@/assets/synthesize.svg'

import { mapGetters } from 'vuex'

//Component data
import { progressItems } from '@/components/WebsiteInterface/NEW/Uploading/Childrens/UploadProgress/UploadProgress'

export default {
  name: 'UploadProgress',
  data() {
    return {
      progressItems,
      filteringProgress: null,
      timer: 0,
      session: null,
    }
  },

  filters: {
    parseTime,
  },
  computed: {
      ...mapGetters(['getFilteringProgressData', 'getTimer', 'getSessionData', 'getIsInMiddleOfFilteingData',]),

    getPercentOfFiltering() {
      let val = 0
      val += +this.filteringProgress.dataSent
      val += +this.filteringProgress.analyzedPercentage
      val += +this.filteringProgress.synthesizedPercentage

      return (val / 3).toFixed(2)
    },
  },
  methods: {
    getImage(name) {
      if (name === 'uploading') return uploadImage
      else if (name === 'analyze') return analyzeImage
      else if (name === 'synthesize') return synthesizeImage
    },
  },
  beforeMount() {
    this.filteringProgress = this.getFilteringProgressData
    this.timer = this.getTimer
    this.session = this.getSessionData
  },
}
</script>
