const splashBuilder = [
  {
    label: 'Language title',
    model: 'languageName',
  },
  {
    label: 'Welcome',
    model: 'welcome',
  },
  {
    label: 'Insert Media',
    model: 'insertMedia',
  },
  {
    label: 'Mobile',
    model: 'mobile',
  },

  {
    label: 'CD-DVD',
    model: 'cdDvd',
  },

  {
    label: 'Memory - USB',
    model: 'usb',
  },
]

const identificationScreenBuilder = [
  {
    label: 'Enter ID',
    model: 'enterId',
  },
  {
    label: 'Invalid ID',
    model: 'invalidId',
  },
  {
    label: 'Next <Button name>',
    model: 'nextButtonText',
  },
]

const filesTreeScreenBuilder = [
  {
    label: 'Select Files',
    model: 'selectFiles',
  },
  {
    label: 'Send <Button name>',
    model: 'sendButtonText',
  },

  {
    label: 'ID',
    model: 'id',
  },

  {
    label: 'Selected Files Size',
    model: 'selectedSize',
  },

  {
    label: 'Selected Files Number ',
    model: 'selectedNumber',
  },
]

const uploadingScreenBuilder = [
  {
    label: 'Sending Files...',
    model: 'uploadingFiles',
  },

  {
    label: 'Canceled',
    model: 'canceled',
  },

  {
    label: 'Cancel <Button name>',
    model: 'cancelButtonText',
  },
]

const endScreenBuilder = [
  {
    label: 'All files arrived to destination',
    model: 'allFiltered',
  },
  {
    label:
      'According to the security policy, some of the files have not reached their destination',
    model: 'partialFiltered',
  },

  {
    label: 'Completed',
    model: 'completed',
  },
]

const popupsBuilder = [
  {
    label: 'Access denied',
    model: 'accessDenied',
  },

  {
    label: 'Please unlock your mobile and then click OK',
    model: 'pleaseUnlock',
  },

  {
    label: 'Sending failed',
    model: 'uploadingFailed',
  },

  {
    label: 'The media was removed during file sending',
    model: 'deviceRemovedInMiddle',
  },
  {
    label: 'Unsupported media',
    model: 'unsupportedMedia',
  },

  {
    label: 'Please remove your media',
    model: 'removeMedia',
  },

  { label: 'Internet connection problem', model: 'badCommunicationTitle' },
  {
    label: 'There seems to be an issue with the internet connection',
    model: 'badCommunicationMessage',
  },

  { label: 'Check your connection and try again', model: 'checkConnection' },
  {
    label: 'OK <button>',
    model: 'ok',
  },
]

export const builders = [
  {
    type: 'identificationScreen',
    builder: identificationScreenBuilder,
    title: 'Identification Screen',
  },

  {
    type: 'splashScreen',
    builder: splashBuilder,
    title: 'Splash Screen',
  },

  {
    type: 'filesTreeScreen',
    builder: filesTreeScreenBuilder,
    title: 'Files Tree Screen',
  },

  {
    type: 'uploadingScreen',
    builder: uploadingScreenBuilder,
    title: 'Uploading Screen',
  },

  {
    type: 'endScreen',
    builder: endScreenBuilder,
    title: 'Ending Screen',
  },

  {
    type: 'popups',
    builder: popupsBuilder,
    title: 'Popups',
  },
]

export const flagList = {
  en: [
    { country: 'No flag', code: 'no' },
    { country: 'Antigua and Barbuda', code: 'ag' },
    { country: 'Australia', code: 'au' },
    { country: 'Bahamas', code: 'bs' },
    { country: 'Barbados', code: 'bb' },
    { country: 'Belize', code: 'bz' },
    { country: 'Canada', code: 'ca' },
    { country: 'Dominica', code: 'dm' },
    { country: 'Grenada', code: 'gd' },
    { country: 'Guyana', code: 'gy' },
    { country: 'Ireland', code: 'ie' },
    { country: 'Jamaica', code: 'jm' },
    { country: 'New Zealand', code: 'nz' },
    { country: 'Saint Kitts and Nevis', code: 'kn' },
    { country: 'Saint Lucia', code: 'lc' },
    { country: 'Saint Vincent and the Grenadines', code: 'vc' },
    { country: 'South Africa', code: 'za' },
    { country: 'Trinidad and Tobago', code: 'tt' },
    { country: 'United Kingdom', code: 'gb' },
    { country: 'United States', code: 'us' },
  ],

  he: [
    { country: 'No flag', code: 'no' },
    { country: 'Israel', code: 'il' },
  ],

  ru: [
    { country: 'No flag', code: 'no' },
    { country: 'Belarus', code: 'by' },
    { country: 'Kazakhstan', code: 'kz' },
    { country: 'Kyrgyzstan', code: 'kg' },
    { country: 'Russia', code: 'ru' },
  ],

  ar: [
    { country: 'No flag', code: 'no' },
    { country: 'Algeria', code: 'dz' },
    { country: 'Bahrain', code: 'bh' },
    { country: 'Comoros', code: 'km' },
    { country: 'Djibouti', code: 'dj' },
    { country: 'Egypt', code: 'eg' },
    { country: 'Iraq', code: 'iq' },
    { country: 'Jordan', code: 'jo' },
    { country: 'Kuwait', code: 'kw' },
    { country: 'Lebanon', code: 'lb' },
    { country: 'Libya', code: 'ly' },
    { country: 'Mauritania', code: 'mr' },
    { country: 'Morocco', code: 'ma' },
    { country: 'Oman', code: 'om' },
    // { country: 'Palestine', code: 'ps' },
    { country: 'Qatar', code: 'qa' },
    { country: 'Saudi Arabia', code: 'sa' },
    { country: 'Sudan', code: 'sd' },
    { country: 'Syria', code: 'sy' },
    { country: 'Tunisia', code: 'tn' },
    { country: 'United Arab Emirates', code: 'ae' },
    { country: 'Yemen', code: 'ye' },
  ],

  ro: [
    { country: 'No flag', code: 'no' },
    { country: 'Romania', code: 'ro' },
  ],

  cn: [
    { country: 'No flag', code: 'no' },
    { country: 'China', code: 'cn' },
    { country: 'Taiwan', code: 'tw' },
    { country: 'Singapore', code: 'sg' },
  ],

  fr: [
    { country: 'No flag', code: 'no' },
    { country: 'France', code: 'fr' },
    { country: 'Canada', code: 'ca' },
    { country: 'Belgium', code: 'be' },
    { country: 'Switzerland', code: 'ch' },
    { country: 'Luxembourg', code: 'lu' },
    { country: 'Monaco', code: 'mc' },
    { country: 'Republic of the Congo', code: 'cg' },
    { country: 'Democratic Republic of the Congo', code: 'cd' },
    { country: 'Gabon', code: 'ga' },
    { country: "Côte d'Ivoire", code: 'ci' },
    { country: 'Cameroon', code: 'cm' },
    { country: 'Chad', code: 'td' },
    { country: 'Central African Republic', code: 'cf' },
    { country: 'Djibouti', code: 'dj' },
    { country: 'Guinea', code: 'gn' },
    { country: 'Equatorial Guinea', code: 'gq' },
    { country: 'Mali', code: 'ml' },
    { country: 'Niger', code: 'ne' },
    { country: 'Senegal', code: 'sn' },
    { country: 'Togo', code: 'tg' },
    { country: 'Benin', code: 'bj' },
    { country: 'Burkina Faso', code: 'bf' },
    { country: 'Burundi', code: 'bi' },
    { country: 'Rwanda', code: 'rw' },
    { country: 'Seychelles', code: 'sc' },
    { country: 'Haiti', code: 'ht' },
    { country: 'Vanuatu', code: 'vu' },
    { country: 'Comoros', code: 'km' },
    { country: 'Madagascar', code: 'mg' },
    { country: 'Mauritius', code: 'mu' },
    { country: 'French Polynesia', code: 'pf' },
    // { country: 'Wallis and Futuna', code: 'wf' },
    // { country: 'New Caledonia', code: 'nc' },
    // { country: 'Mayotte', code: 'yt' },
    // { country: 'Saint Pierre and Miquelon', code: 'pm' },
    // { country: 'Guadeloupe', code: 'gp' },
    // { country: 'Martinique', code: 'mq' },
    // { country: 'Reunion', code: 're' },
  ],
}
