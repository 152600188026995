<template>
  <v-dialog
    v-model="dialog"
    class="wrapper-1 bg-white"
    @click:outside="closeClicked"
    :content-class="isDarkMode ? 'dark-mode' : ''"
    width="95vw"
  >
    <v-card class="wrapper-1 fs-1-25r" height="90vh">
      <v-card-title class="bg-grey mb-2 fs-1-5r">
        <span class="ma-auto">{{ parseTitle }}</span>
      </v-card-title>

      <!--DATA-->
      <div class="pa-4 bg-white" style="height: calc(100% - 100px)">
        <!-- asd -->
        <span class="fs-1-12r mx-2">{{ searchDatesString }}</span>
        <component :is="expandedItem" :analyticsData="analyticsData" />
      </div>

      <!--BOTTOM-->
      <div class="d-flex justify-end">
        <Button
          text="Close"
          :clickAction="closeClicked"
          btnType="grey"
          class="mx-4"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Childrens
import Alerts from '../Alerts/Alerts.vue'
import FilteringSummary from '../FilteringSummary/FilteringSummary.vue'
import Routes from '../Routes/Routes.vue'
import Transference from '../Transference/Transference.vue'
import Users from '../Users/Users.vue'
import FileTypes from '../FileTypes/FileTypes.vue'
import Activity from '../Activity/Activity.vue'
import Storage from '../Storage/Storage.vue'

export default {
  name: 'ExpandItemDialog',
  data() {
    return { dialog: true }
  },

  computed: {
    ...mapGetters(['isDarkMode']),
    parseTitle() {
      switch (this.expandedItem) {
        case 'FileTypes':
          return 'File Types'
        case 'Alerts':
          return 'Alerts'
        case 'FilteringSummary':
          return 'Files Summary'
        case 'Routes':
          return 'Routes'
        case 'FileTypes':
          return 'File Types'
        case 'Transference':
          return 'Transference Per Date'
        case 'Users':
          return 'Users Usage'
        case 'Activity':
          return 'Activity'
        case 'Storage':
          return 'Storage'
      }
    },
  },

  props: {
    closeClicked: Function,
    expandedItem: String,
    analyticsData: Object,
    searchDatesString: String,
  },

  components: {
    Alerts,
    FilteringSummary,
    FileTypes,
    Routes,
    Transference,
    Users,
    Button,
    Storage,
    Activity,
  },
}
</script>
