import { api } from '@/config'

export default {
  state: {
    users: [],
    currentUser: {},
  },

  mutations: {
    SET_USERS(state, users) {
      state.users = users
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user
    },
  },
  actions: {
    async GET_USERS({ commit }) {
      try {
        commit('SET_PROCESSING', true)
        let res = await api.get('users')
        if (res.status !== 200) throw { response: res }

        commit('SET_USERS', [...res.data])
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },

    async GET_USERS_OF_THE_SINGLE_ORGANIZATION({ commit }, id) {
      const res = await api.get(`/organizations/${id}/users`)
      if (res.status !== 200) throw { response: res }
      commit('SET_USERS', res.data)
    },

    async GET_SINGLE_USER({ commit }, id) {
      try {
        commit('SET_PROCESSING', true)
        let res = await api.get(`users/${id}`)
        if (res.status !== 200) throw { response: res }

        commit('SET_CURRENT_USER', res.data)
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },

    async ADD_USER({ commit, dispatch }, payload) {
      try {
        commit('SET_PROCESSING', true)
        let res = await api.post('users', payload)
        if (res.status !== 200) throw { response: res }
        await dispatch(
          'GET_USERS_OF_THE_SINGLE_ORGANIZATION',
          payload.organizationId
        )

        commit('SET_NOTIFICATION', {
          type: 'success',
          text: 'Account was created successfully',
        })

        return res.data.userId
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },
    async EDIT_USER({ commit, dispatch }, payload) {
      try {
        commit('SET_PROCESSING', true)
        const { userId, orgId, ...data } = payload
        let res = await api.patch(`users/${userId}`, { ...data, userId })
        if (res.status !== 200) throw { response: res }
        if (!orgId) await dispatch('GET_USERS')
        else dispatch('GET_USERS_OF_THE_SINGLE_ORGANIZATION', orgId)

        commit('SET_NOTIFICATION', {
          type: 'success',
          text: "Account's information was updated",
        })
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },
    async REMOVE_USER({ commit, dispatch }, payload) {
      try {
        commit('SET_PROCESSING', true)
        let res = await api.delete(`users/${payload.userId}`)
        if (res.status !== 200) throw { response: res }
        if (payload.organizationId !== 0) {
          await dispatch(
            'GET_USERS_OF_THE_SINGLE_ORGANIZATION',
            payload.organizationId
          )
        }
        commit('SET_NOTIFICATION', {
          type: 'success',
          text: 'User was deleted successfully',
        })
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },

    //need to ask david if this end point is active
    async RESET_PASSWORD_USER({ commit }, payload) {
      try {
        commit('SET_PROCESSING', true)
        let res = await api.patch(`users/${payload.email}/reset`)
        if (res.status == 200) {
          commit('SET_NOTIFICATION', {
            type: 'success',
            text:
              'User password has been reset and notification email was sent',
          })
        } else {
          throw { response: res }
        }
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },
  },
  getters: {
    users: state => state.users,
    currentUser: state => state.currentUser,
  },
}
