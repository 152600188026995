<template>
  <div class="route-header d-flex justify-space-between align-center mb-2">
    <!--TITLE -->
    <span class="title"> {{ actionType | parseTitle }} </span>

    <div class="pos-relative">
      <OnlineHelp
        style="top: -20px; left: 0px"
        page="routes"
        section="routeSettings"
        title="Routes Settings"
      />
    </div>
    <!-- MESSAGE -->
    <div class="d-flex ml-auto mr-4">
      <transition name="slide-fade">
        <span
          class="mr-8 light-text red--text font-weight-regular fs-1r"
          v-if="showMessage"
          >{{ message }}</span
        >
      </transition>
      <!--BUTTONS TO SAVE-->
      <div @mouseover="showMessage = true" @mouseleave="showMessage = false">
        <Button
          :disabled="isSaveButtonDisabled"
          :clickAction="changestatus"
          class="mr-4"
          :text="actionType === 'edit' ? 'Save' : 'Create'"
        />
      </div>
      <Button
        btnType="grey"
        text="Cancel"
        :clickAction="() => $router.go(-1)"
      />
    </div>
  </div>
</template>

<script>
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'RouteTitleButtons',
  data() {
    return {
      showMessage: false,
    }
  },
  props: {
    actionType: String,
    isSaveButtonDisabled: Boolean,
    message: String,
    changestatus: Function,
  },
  components: { Button, OnlineHelp },

  filters: {
    parseTitle(v) {
      let parsedTitle = ''
      if (v === 'add') {
        parsedTitle = 'create route'
      } else if (v === 'edit') {
        parsedTitle = 'edit route'
      }
      return parsedTitle.toUpperCase()
    },
  },
}
</script>
