<template>
  <div
    @mouseover="showMessage = true"
    @mouseleave="showMessage = false"
    class="d-flex buttons"
  >
    <!--WARNING MESSAGE-->
    <transition name="slide-fade">
      <span class="mr-8 red--text message fs-1r" v-if="showMessage">{{
        message
      }}</span>
    </transition>

    <div class="mr-4">
      <Button
        :clickAction="goToSave"
        :loading="loading"
        :disabled="isSaveButtonDisabled"
        text="Save"
      />
    </div>
    <div class="mr-4">
      <Button
        :clickAction="() => $router.go(-1)"
        btnType="grey"
        text="Cancel"
      />
    </div>
  </div>
</template>

<script>
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'SavaCancelButtons',
  data() {
    return {
      showMessage: false,
    }
  },
  props: {
    goToSave: Function,
    loading: Boolean,
    isSaveButtonDisabled: Boolean,
    message: String,
  },
  components: { Button },
}
</script>

<style scoped src="./SaveCancelButtons.css"></style>
