<template>
  <div class="d-flex align-center" :class="[!isSuperSmallScreen && 'mx-2']">
    <img
      v-if="isShowLogo"
      @click="$router.push('/')"
      :src="themeSettings.logoUrl"
      :alt="`${themeSettings.name} Logo`"
      class="host-logo pa-1 mx-1"
      contain
    />

    <span
      v-if="isShowHostNameAndText && !isSuperSmallScreen"
      @click="$router.push('/')"
      :style="{ color: isDarkMode ? '#ffffff' :themeSettings.themeColor }"
      class="header-text bg-white mx-1"
      :aria-label="themeSettings.name"
    >
      {{ computedTextHeader }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderLogoText',
  data() {
    return {
      isShowHostName: false,
      isShowHeader: false,
    }
  },
  watch: {
    themeSettings: {
      handler: function (n) {
        this.$forceUpdate()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['themeSettings', 'accessibility', 'loggedUser', 'isDarkMode']),
    computedTextHeader() {
      let str = ''

      //this is some fix - super admin and individuals wown't see org name
      if (this.loggedUser.organizationId === 0) this.isShowHostName = false

      if (this.isShowHostName) str += this.themeSettings.name

      if (this.isShowHostName && this.isShowHeader) str += ' - '

      if (this.isShowHeader) str += this.themeSettings.headerTextValue

      return str
    },
    isShowHostNameAndText() {
      const isShowHostName =
        this.themeSettings.nameInterface === 1 ||
        this.themeSettings.nameInterface === 3

      const isShowHostHeader =
        this.themeSettings.headerTextInterface === 1 ||
        this.themeSettings.headerTextInterface === 3

      if (!isShowHostName && !isShowHostHeader) return false

      let nameCount = this.themeSettings.name.length
      let headerCount = this.themeSettings.headerTextValue.length

      let width = this.$vuetify.breakpoint.width - 180 //remove from the width the buttons

      let FontSizeEachLetter = 10
      if (this.accessibility.fontSize === 2) FontSizeEachLetter = 14
      if (this.accessibility.fontSize === 3) FontSizeEachLetter = 16

      // check if the width can contain all the header - 10 px each letter
      if (width > (nameCount + headerCount) * FontSizeEachLetter) {
        if (isShowHostName) this.isShowHostName = true
        if (isShowHostHeader) this.isShowHeader = true
      }

      //check if the header can contain only the host name
      else if (width > nameCount * FontSizeEachLetter) {
        this.isShowHostName = true
        this.isShowHeader = false
      }

      //there is no place for nothing
      else {
        this.isShowHostName = false
        this.isShowHeader = false
      }

      return true
    },
    isSuperSmallScreen() {
      if (this.$vuetify.breakpoint.width <= 350) return true
      return false
    },
    isShowLogo() {
      return (
        this.themeSettings.logoInterface === 1 ||
        this.themeSettings.logoInterface === 3
      )
    },
  },
}
</script>

<style scoped src="./HeaderLogoText.css"></style>
