import { api, selectedHost } from '@/config'
export default {
  state: {
    operationId: null,
    isInMiddleOfFiltering: false,
  },
  mutations: {
    // async SET_ID(state, id) { // do i need here async
    SET_OPERATION_ID(state, id) {
      state.operationId = id
    },

    SET_IS_IN_MIDDLE_OF_FILTERING(state, value) {
      //if the user is in middle of editing then don't let the user quit the system easly
      if (value === true) {
        window.onbeforeunload = function(e) {
          e.preventDefault()
          e.returnValue = ''
        }
      }
      //if the payload is false then remove function
      else {
        window.onbeforeunload = null
      }

      state.isInMiddleOfFiltering = value
    },

    CLEAR_ID(state) {
      state.operationId = null
    },
  },
  actions: {
    async GET_OPERATION_ID({ commit, dispatch }, payload) {
      await dispatch('CLEAR_OPERATION_ID')
      try {
        let res = await api.post('filter', payload)
        const operationId = res.data
        commit('SET_OPERATION_ID', operationId)
      } catch (error) {
        console.log(error)
        commit('SET_NOTIFICATION', {
          type: 'error',
          text: `${error}`,
        })
      }
    },
    async GET_OPERATION_ID_CASUAL({ commit, dispatch }, payload) {
      await dispatch('CLEAR_OPERATION_ID')
      try {
        let res = await api.post('filter/casual', payload)
        const operationId = res.data
        commit('SET_OPERATION_ID', operationId)
      } catch (error) {
        console.log(error)
        commit('SET_NOTIFICATION', {
          type: 'error',
          text: `${error}`,
        })
      }
    },

    async CLEAR_OPERATION_ID({ commit, state }) {
      if (state.operationId !== null) {
        await api.delete(`filter?operationId=${state.operationId}`)
        commit('CLEAR_ID')
      }
    },
  },
  getters: {
    operationId: state => state.operationId,
    report: state => state.report,
    isInMiddleOfFiltering: state => state.isInMiddleOfFiltering,
  },
}
