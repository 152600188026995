<template>
  <div class="send-email-casual-wrapper">
    <v-card>
      <v-card-title class="text-center bg-grey fs-1-5r card-title">
        {{ computedTitle }}
      </v-card-title>
      <div class="d-flex align-center pa-2" style="width: 275px;">
        <span class="mt-1 fs-1-12r" style="min-width: 120px;">{{$t('Link expiration')}}:</span>
        <v-text-field
          v-model="displayedTimespan"
          @input="updateTimespan"
          height="18px"
          class="ma-0 pa-0 mx-2 offset-ammount w-40"
          hide-details
          type="number"
          />
          
          <v-select
          class="mt-10px"
          height="18px"
          v-model="selectedUnit"
          @input="updateTimespan"
          :items="[
            { text: $t('Minutes'), value: 'minutes' },
            { text: $t('Hours'), value: 'hours' },
            { text: $t('Days'), value: 'days' },
          ]"
        />
      </div>
      <v-card-text>
        <v-textarea
          v-model="sendMessageToCasual"
          :label="$t('Message (Optional)')"
          outlined
          dense
          rows="5"
          multi-line
          class="mt-2"
          hide-details
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex fill-width justify-space-between buttons-wrapper">

          <Button width="75px" :text="$t('Send')" :clickAction="sendEmailClicked" />
          <Button
          width="75px"
          :clickAction="closeDialog"
          :text="$t('Cancel')"
          btnType="grey"
          />
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { api } from '@/config'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SendEmailToCasualPopUp',
  data() {
    return {
      dialog: true,
      sendMessageToCasual: null,
      displayedTimespan: 7,
      timespanInMinutes: 10080, // base value in minutes
      selectedUnit: 'days', // default unit
    }
  },
  props: {
    closeDialog: Function,
    casualUser: Object,
  },
  filters: {
    convertMinutesToHoursAndDays(val) {
      return val * 2
    },
  },
  components: { Button },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'isInMiddleOfFiltering',
      'isDarkMode',
      'loggedUser',
      'language',
    ]),

    computedTitle() {
      const isHe = this.language === 'he'
      return (
        this.$t('Send a request to') +
        (isHe ? '' : ' ') +
        this.casualUser.name +
        ' ' +
        this.$t('to send files')
      )
    },
  },

  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    async sendEmailClicked() {
      try {
        this.SET_PROCESSING(true)
        const res = await api.post('casuals/send-link-for-sending', {
          email: this.casualUser.email,
          message: this.sendMessageToCasual,
          minutesToExpire: this.timespanInMinutes,
        })

        if (res.status !== 200) throw Error

        this.SET_NOTIFICATION({
          type: 'success',
          text: this.$t('Email Sent Successfully'),
        })
        this.closeDialog()
      } catch (error) {
        console.log('error', error)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    updateTimespan() {
      // console.log(value)
      const input = this.displayedTimespan
      console.log('input: ' + input)
      switch (this.selectedUnit) {
        case 'minutes':
          this.timespanInMinutes = input
          break
        case 'hours':
          this.timespanInMinutes = input * 60
          break
        case 'days':
          this.timespanInMinutes = input * 1440
          break
      }
      console.log('this.timespanInMinutes: ' + this.timespanInMinutes)
    },
  },
}
</script>

<style scoped lang="scss">
.send-email-casual-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt-10px {
  margin-top: 10px;
}
.buttons-wrapper >>> .v-btn--rounded {
  background-color: red;
}
</style>
