<template>
  <div class="d-flex justify-end " style="position: sticky; right: 0; top:0; z-index: 4;">
    <!--MESSAGE-->
    <transition name="slide-fade">
      <span v-show="showMessage" class="mx-2 red--text message bg-white">{{
        messageWhySaveButtonDisabled
      }}</span>
    </transition>

    <!--SAVE-->
    <div
      @mouseover="showMessage = true"
      @mouseleave="showMessage = false"
      class="mx-2 bg-white"
    >
      <Button
        text="Save"
        :disabled="isSaveButtonDisabled"
        :clickAction="saveKioskPage"
      />
    </div>

    <!--CANCEL-->
    <Button
      class="mx-2 bg-white"
      text="Cancel"
      btnType="grey"
      :clickAction="cancelKioskPage"
    />
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'SaveCancelMessage',
  data() {
    return {
      showMessage: false,
    }
  },
  props: {
    saveKioskPage: Function,
    cancelKioskPage: Function,
    messageWhySaveButtonDisabled: String,
    isSaveButtonDisabled: Boolean,
  },
  components: { Button },
}
</script>
