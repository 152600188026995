<template>
  <v-tab-item value="contact">
    <div>
      <div class="d-flex fill-width h-60vh fs-1-12r">
        <div class="pa-4 d-flex justify-space-between my-auto fill-width">
          <div class="mx-2">
            <p><u>Email:</u> info@dotengines.com</p>
            <p><u>Phone:</u> +1-347-474-7987</p>
            <p><u>Sales:</u> +972-54-432-6621</p>
          </div>
          <div class="mx-2">
            <p>
              <u>Website:</u>
              <a
                style="text-decoration: none"
                href="https://www.YazamTech.com"
                target="_blank"
              >
                www.YazamTech.com</a
              >
            </p>
            <p>
              <u>Address:</u> 10 Zarhin St., Industrial Zone, Ra'anana, Israel
            </p>
            <p>
              <u>Postal address:</u> 33 Arlozorov St., Ra'anana, 4360719, Israel
            </p>
          </div>

          <div class="mx-2">
            <button>
              <a
                href="https://www.linkedin.com/company/yazamtech-ltd./"
                target="_blank"
                aria-label="YazamTech on linkdein"
              >
                <img
                  width="50px"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/600px-LinkedIn_logo_initials.png"
                  alt="linked-in"
                />
              </a>
            </button>
          </div>
        </div>
      </div>
      <!-- LIBRARYS USAGE -->
      <div class="mb-3 mx-2">
        <p>dotEngines was created using:</p>
        <a
          v-for="(pac, i) in packagesUsage"
          :key="pac.name"
          :href="pac.url"
          class="mx-1"
          :class="[i === packagesUsage.length - 1 ? 'dot' : 'colon']"
          target="_blank"
          >{{ pac.name }}</a
        >
      </div>
    </div>
  </v-tab-item>
</template>

<script>
import { packagesUsage } from './Contact'

export default {
  name: 'Contact',
  data() {
    return {
      packagesUsage,
    }
  },
}
</script>

<style scoped src="./Contact.css"></style>
