<template>
  <div class="d-flex light-text">
    <span class="mt-1">{{ label }}:</span>
    <v-text-field
      v-model="timezoneOffsetInputValue"
      :disabled="disabled"
      height="18px"
      class="mw-30 ma-0 pa-0 mx-2 offset-ammount"
      style="margin-top: 7px !important"
      type="number"
      hide-details
      @input="offsetTypeChanged"
    >
    </v-text-field>
    <v-select
      v-model="offsetType"
      @input="offsetTypeChanged"
      class="pa-0 mw-100"
      style="margin-top: 5px !important"
      height="16px"
      hide-details
      :items="[
        { text: 'Hours', value: 60 },
        { text: 'Minutes', value: 1 },
      ]"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'TimezoneOffset',
  data() {
    return {
      offsetType: 60,
      timezoneOffsetInputValue: 0,
    }
  },
  props: {
    disabled: Boolean,
    currentTimezoneOffset: Number,
    label: String,
  },
  emits: ['offset-type-changed'],
  computed: {
    pathRules(value) {
      return [
        value => !!value || value === '' || 'Required',
        value => isFolderNameValid(value) || 'Path is not valid',
      ]
    },
  },
  methods: {
    offsetTypeChanged() {
      this.$emit(
        'offset-type-changed',
        this.timezoneOffsetInputValue * this.offsetType
      )
    },
  },
  created() {
    if (this.currentTimezoneOffset % 60 === 0) {
      this.offsetType = 60
      this.timezoneOffsetInputValue = this.currentTimezoneOffset / 60
    } else {
      this.offsetType = 1
      this.timezoneOffsetInputValue = this.currentTimezoneOffset
    }
  },
}
</script>

<style scoped>
.offset-ammount {
  margin-top: 3px !important;
}

.offset-ammount >>> .v-input__slot {
  height: 14px !important;
}
</style>
