<template>
  <div class="policy-editor-wrapper">
    <div class="d-flex justify-end mb-2">
      <Button btnType="grey" text="Back" :clickAction="() => $router.go(-1)" />
    </div>
    <iframe
      id="policyEditor"
      ref="iframe"
      class="wrapper-1 fill-height fill-width bg-white"
      :src="computedPolicy"
      @load="loaded"
    />
  </div>
</template>

<script>
import { selectedHost } from '@/config'
import { mapGetters, mapMutations } from 'vuex'
import { newPolicyRules } from '../Policies'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'PolicyEditor',
  data() {
    return {
      policy: '',
    }
  },
  components: {
    Button,
  },
  computed: {
    ...mapGetters(['loggedUser']),
    newPolicyRules,
    computedPolicy() {
      return `${selectedHost}/Engine/${this.loggedUser.token}/Designer/${this.policy}`
    },
  },

  methods: {
    ...mapMutations(['SET_PROCESSING']),
    loaded() {
      this.SET_PROCESSING(false)
    },
  },
  mounted() {
    this.SET_PROCESSING(true)
    this.policy = this.$route.params.policy
  },
}
</script>

<style scoped src="./PolicyEditor.css"></style>
