import { render, staticRenderFns } from "./ThemeSettings.vue?vue&type=template&id=2e0a56e0&scoped=true&"
import script from "./ThemeSettings.vue?vue&type=script&lang=js&"
export * from "./ThemeSettings.vue?vue&type=script&lang=js&"
import style0 from "./ThemeSettings.css?vue&type=style&index=0&id=2e0a56e0&prod&scoped=true&lang=css&"
import style1 from "./ThemeSettingsUnScoped.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e0a56e0",
  null
  
)

export default component.exports