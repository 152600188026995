export const desktopTableHeaders = vue => [
  {
    name: 'checkbox',
    title: 'checkbox',
    class: 'table-header-first d-flex justify-center align-center',
    width: '5',
    sortable: false,
  },
  {
    name: 'name',
    title: vue.$t('Name'),
    class: 'd-flex align-center',
    width: '25',
    sortable: true,
  },
  {
    name: 'status',
    title: vue.$t('Status'),
    class: 'text-center',
    width: '10',
    sortable: true,
  },
  {
    name: 'size',
    title: vue.$t('Size'),
    class: 'text-center',
    width: '10',
    sortable: true,
  },
  {
    name: 'dynamicFields',
    title: vue.$t('Dynamic Fields'),
    class: 'text-center',
    width: '20',
    sortable: false,
  },
  {
    name: 'dateCreated',
    title: vue.$t('Date Created'),
    class: 'text-center',
    width: '15',
    sortable: true,
  },
  {
    name: 'deletionIn',
    title: vue.$t('Deletion In'),
    class: 'table-header-last text-center',
    width: '15',
    sortable: true,
  },
]

export const tabletTableHeaders = vue => [
  {
    name: 'checkbox',
    title: 'checkbox',
    class: 'table-header-first d-flex align-center justify-center',
    width: '10',
    sortable: false,
  },
  {
    name: 'name',
    title: vue.$t('Name'),
    class: '',
    width: '50',
    sortable: true,
  },
  {
    name: 'status',
    title: vue.$t('Status'),
    class: 'text-center',
    width: '10',
    sortable: true,
  },
  {
    name: 'size',
    title: vue.$t('Size'),
    class: 'text-center',
    width: '15',
    sortable: true,
  },
  {
    name: 'more',
    title: vue.$t('More'),
    class: 'd-flex align-center justify-center',
    width: '15',
    sortable: false,
  },
]

export const mobileTableHeaders = vue => [
  {
    name: 'checkbox',
    title: 'checkbox',
    class: vue.$t('table-header-first d-flex align-center justify-center'),
    width: '15',
    sortable: false,
  },
  {
    name: 'name',
    title: vue.$t('Name'),
    class: '',
    width: '45',
    sortable: true,
  },
  {
    name: 'size',
    title: vue.$t('Size'),
    class: 'text-center',
    width: '20',
    sortable: true,
  },
  {
    name: 'more',
    title: vue.$t('More'),
    class: 'd-flex align-center justify-center',
    width: '20',
    sortable: false,
  },
]

// function getTitle(vue) {
//   vue.$t(
//     `${vue.interactiveInterfaceFields.subjectFieldText} & ${vue.interactiveInterfaceFields.messageFieldText}`
//   )
//   let subject
//   let message
//   if (vue.interactiveInterfaceFields.subjectFieldText === 'Subject')
//     subject = 'Subject'
//   else subject = 'Request Number'
//   if (vue.interactiveInterfaceFields.messageFieldText === 'Message')
//     message = 'Message'
//   else message = 'Sender Details'
//   return vue.$t(`${subject} & ${message}`)
// }
