import { api } from '@/config'

export async function getSingleRoute(id = null) {
  let data = null
  let error = null
  const res = await api.get(`/routes/${id}`)
  if (res.status !== 200) {
    error = res.data
    return { data, error }
  }
  data = res.data
  return { data, error }
}

export async function getEmptyRoute(){
  const res = await api.get('routes/emptyroute')
  if (res.status !== 200){
    throw {response}
  }

  return res.data
}


