<template>
  <v-tab-item
    v-if="shownDirectory"
    value="downloading"
    class="wrapper-1 bg-white fill-height mt-2 pa-2 pos-realtive"
  >
    <!--NAVIGATION HEADER-->
    <DirectoriesNavigate
      :directoriesTree="directoriesTree"
      :shownDirectory="shownDirectory"
      :changeShownDirClicked="changeShownDirClicked"
      :setDirSearch="v => (dirSearch = v)"
      :dirSearch="dirSearch"
    />

    <!--DIRECTORY TABLE-->
    <DirectoryDownloadTable
      :shownDirectory="shownDirectory"
      :changeShownDirClicked="changeShownDirClicked"
      :dirSearch="dirSearch"
      :selectedDirFiles="selectedDirFiles"
      :setSelectedDirFiles="v => (selectedDirFiles = v)"
      type="tablet"
    />

    <!--DIRECTORY DATA-->
    <DirectoryData :shownDirectory="shownDirectory" />

    <!--ACTIONS-->
    <FileDirActions
      :shownDirectory="shownDirectory"
      :selectedDirFiles="selectedDirFiles"
      :showReportClicked="showReportClicked"
      :deleteFilesClicked="deleteFilesClicked"
      :downloadFilesClicked="downloadFilesClicked"
    />

    <!--INSERT PASSWORD DIALOG-->
    <InsertPassword
      v-if="isShowPasswordScreen"
      :setIsShowPasswordScreen="v => (isShowPasswordScreen = v)"
      :downloadFilesClicked="downloadFilesClicked"
    />

    <!--REPORT DIALOG FOR DIRECTORIES AND FILES-->
    <DirFilesTableReportDialog
      v-if="isShowReportDialog"
      :closeReportDialog="closeReportDialog"
      :report="report"
    />
  </v-tab-item>
</template>

<script>
import { mapGetters } from 'vuex'

//Children
import DirectoriesNavigate from './Children/DirectoriesNavigate/DirectoriesNavigate.vue'

//Base components
import FileDirActions from '@/components/WebsiteInterface/CommonComponents/FileDirActions/FileDirActions.vue'
import DirectoryData from '@/components/WebsiteInterface/CommonComponents/DirectoryData/DirectoryData.vue'
import InsertPassword from '@/components/WebsiteInterface/CommonComponents/InsertPassword/InsertPassword.vue'
import DirectoryDownloadTable from '@/components/WebsiteInterface/CommonComponents/DirectoryDownloadTable/DirectoryDownloadTable.vue'
import DirFilesTableReportDialog from '@/components/WebsiteInterface/CommonComponents/DirFilesTableReportDialog/DirFilesTableReportDialog.vue'

//Downloading functions
import {
  //get directoreis
  getStartFileDirPoint,
  getSingleDirFromPathArr,
  getSingleDirFromPathNav,
  getOneDirBack,
  getAllFiles,
  //get actual data
  getFilteringReport,
  deleteDirFiles,
  downloadDirFiles,
} from '@/components/WebsiteInterface/downloadingFunctions'

export default {
  name: 'DownloadTablet',
  data() {
    return {
      directoriesTree: null,
      shownDirectory: null,
      dirSearch: null,
      selectedDirFiles: [],
      isShowPasswordScreen: false,
      report: {
        files: [],
        directories: [],
      },
      isShowReportDialog: false,
    }
  },
  props: {
    userCameFromEmailPath: String,
    setTab: Function,
    tab: String,
  },
  components: {
    DirectoriesNavigate,
    FileDirActions,
    DirectoryData,
    InsertPassword,
    DirectoryDownloadTable,
    DirFilesTableReportDialog,
  },

  watch: {
    shownDirectory(n) {
      this.selectedDirFiles = []
    },
    async tab(n) {
      if (n === 'downloading') {
        await getStartFileDirPoint(this, 'tablet')
      }
    },
  },

  computed: {
    ...mapGetters(['usrPass', 'loggedUser']),
  },
  methods: {
    changeShownDirClicked(dirPath, type) {
      //if the user clicked the navigation route
      if (type === 'navigation') getSingleDirFromPathNav(this, dirPath)
      //if the user clicked the directories tree
      else if (type === 'dirTree') {
        
        getSingleDirFromPathArr(this, dirPath)}
      //if the user clicked the directory from the table row
      else if (type === 'tableRow') getSingleDirFromPathArr(this, dirPath)
      //if the user clicked the back on directory button
      else if (type === 'backButton') getOneDirBack(this)
      //if the user asked for all files
      else if (type === 'allFiles') getAllFiles(this)
      //if the user asked to start from the begining
      else if (type === 'start') getStartFileDirPoint(this, 'tablet')
    },

    closeReportDialog() {
      this.isShowReportDialog = false
      this.report = {
        files: [],
        directories: [],
      }
    },

    showReportClicked() {
      getFilteringReport(this)
    },

    deleteFilesClicked() {
      deleteDirFiles(this)
    },
    downloadFilesClicked() {
      //if the user refreshsed and he dosen't hav epassord and also he is not casual
      if (!this.usrPass && this.loggedUser.role !== 'Casual')
        return (this.isShowPasswordScreen = true)

      //if the user have password
      this.isShowPasswordScreen = false
      downloadDirFiles(this)
    },
  },
}
</script>
