<template>
  <div class="fill-width">
    <v-textarea
      :value="input"
      outlined
      rows="3"
      auto-grow
      class="ma-0 dir-ltr"
      @keyup="addedCasualUsers"
    />

    <v-icon @click="isShowCasualList = !isShowCasualList"
      >mdi-chevron-{{ isShowCasualList ? 'up' : 'down' }}</v-icon
    >

    <v-card v-if="isShowCasualList" class="wrapper-1 ma-2">
      <v-list>
        <v-list-item-group multiple>
          <v-list-item v-for="user of loggedUser.casualUsers">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  @change="() => addRemoveCasualUser(user.email, !active)"
                  :input-value="active"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ user.email }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CasualTextareaInput',
  data() {
    return {
      computedSelectedUsers: [],
      input: '',
      isShowCasualList: false,
    }
  },
  props: {
    session: Object,
  },

  watch: {
    'session.selectedCasualUsers': {
      handler: function (n) {
        this.input += n.join(', ')
        this.formatEmails()
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(['loggedUser']),
  },
  methods: {
    addedCasualUsers(e) {
      this.input = e.target.value
      this.formatEmails()
    },

    formatEmails() {
      // Split the string by newline, comma, or space
      let emails = this.input.split(/[\n, ]+/)

      // Filter out any empty strings that might be created by multiple separators
      emails = emails.filter(email => email.trim() !== '')

      // Join the emails with a comma and a space
      this.input = emails.join(', ')
    },

    addRemoveCasualUser(userEmail, active) {
      console.log(userEmail)
      if (active) {
        this.session.selectedCasualUsers.push(userEmail+',')
      } else {
        console.log(this.session)
        this.session.selectedCasualUsers =
          this.session.selectedCasualUsers.filter(email => email !== userEmail)
        this.input = this.input.replace(userEmail, '')
      }
      this.formatEmails()
    },
  },
}
</script>
