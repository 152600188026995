<template>
  <div class="main-settings-wrapper d-flex align-center fill-width">
    <!--START DATE-->
    <div class="d-flex flex-column mx-2 date-field">
      <span class="f-12 op-83 required-field"
        >Start Date ({{ logsSettings.dateFormat }})</span
      >
      <date-pick
        v-model="localStartDate"
        pickTime
        :format="'DD/MM/YYYY HH:mm'"
        :inputAttributes="{ readonly: true }"
        :startWeekOnSunday="true"
        :isDateDisabled="isFutureDateOrMoreThanEndDate"
        :selectableYearRange="{ from: 2020, to: 2023 }"
      ></date-pick>
    </div>

    <!--END DATE-->
    <div class="d-flex flex-column mx-2 date-field">
      <span class="f-12 op-83 required-field"
        >End Date ({{ logsSettings.dateFormat }})</span
      >
      <date-pick
        v-model="search.endDate"
        pickTime
        :format="'DD/MM/YYYY HH:mm'"
        :inputAttributes="{ readonly: true }"
        :startWeekOnSunday="true"
        :isDateDisabled="isFutureDateOrLessThanStartDate"
        :selectableYearRange="{ from: 2020, to: 2023 }"
      ></date-pick>
    </div>

    <!--HOST STATUS-->
    <div
      v-if="loggedUser.role === 'SuperAdmin'"
      class="wrapper-1 mx-2 py-1 px-2"
    >
      <span class="label f-14">Host:</span>
      <v-checkbox
        v-model="search.isShowActiveHosts"
        label="Active & Blocked"
        class="ma-0 pa-0"
        hide-details
      ></v-checkbox>

      <v-checkbox
        v-model="search.isShowInactiveHosts"
        label="Removed"
        class="ma-0 pa-0"
        hide-details
      ></v-checkbox>
    </div>

    <!--SEARCH BY HOST-->
    <v-select
      v-if="loggedUser.role === 'SuperAdmin'"
      v-model="search.organizationId"
      :items="computedOrganizationsList"
      clearable
      label="Host"
      item-text="name"
      item-value="organizationId"
      dense
      hide-details
      class="mw-200 mt-4 mx-2"
    >
      <template v-slot:item="{ item }"
        ><span class="f-16">{{ item.name }}</span></template
      >
    </v-select>

    <!--BUTTON FOR THE ADVANCED TABLE SETTINGS-->
    <Button
      :clickAction="() => setIsShowAdvancedSettings(!isShowAdvancedSettings)"
      text="Advanced"
      btnType="grey"
      width="120"
      class="ma-1"
      :icon="isShowAdvancedSettings ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      :style="computedStyle"
    ></Button>
  </div>
</template>

<script>
import DatePick from 'vue-date-pick'
import 'vue-date-pick/dist/vueDatePick.css'
import { mapGetters } from 'vuex'
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'MainSettings',
  data() {
    return {
      localStartDate: '',
    }
  },
  props: {
    logsSettings: Object,
    search: Object,
    organizationsList: Array,
    setIsShowAdvancedSettings: Function,
    isShowAdvancedSettings: Boolean,
  },
  components: {
    DatePick,
    Button,
  },

  watch: {
    localStartDate(n) {
      if (n) this.search.startDate = n
    },
  },

  computed: {
    ...mapGetters(['loggedUser']),
    computedOrganizationsList() {
      const { isShowActiveHosts, isShowInactiveHosts } = this.search
      //show active and inactive
      if (isShowActiveHosts && isShowInactiveHosts)
        return this.organizationsList
      //show only active
      else if (isShowActiveHosts)
        return this.organizationsList.filter(el => el.isActive)
      //show only inactive
      else if (isShowInactiveHosts)
        return this.organizationsList.filter(el => !el.isActive)

      //nothing
      return []
    },

    computedStyle() {
      if (this.$vuetify.breakpoint.width < 1220)
        return {
          'align-self': 'baseline',
        }
      return {}
    },
  },

  methods: {
    isFutureDateOrMoreThanEndDate(date) {
      //checks if the input date is in the future or after the end date
      if (!this.search.endDate) return this.isFutureDate(date)
      const endDate = new Date(this.search.endDate)
      const currentDate = new Date()
      return date > currentDate || date > endDate
    },

    isFutureDateOrLessThanStartDate(date) {
      //checks if the input date is in the future or before the start date
      if (!this.search.startDate) return this.isFutureDate(date)
      const startDate = new Date(this.search.startDate)
      const currentDate = new Date()
      return date > currentDate || date < startDate
    },

    isFutureDate(date) {
      const currentDate = new Date()
      return date > currentDate
    },
  },
}
</script>

<style src="./MainSettingsUnscoped.css"></style>
