<template>
  <div class="my-4">
    <h4 class="settings-h4">Filtering</h4>
    <div class="wrapper-1 pa-2">
      <!--POLICY-->
      <div class="d-flex">
        <span class="mx-2 required-field">Policy:</span>
        <v-select
          v-model="kioskSettings.filtering.policy"
          :items="policies"
          hide-details
          height="24px"
          class="mw-200 pa-0 ma-0 mx-2"
        ></v-select>
      </div>

      <!--SAVE FILTERING FILE REPORT-->
      <v-checkbox
        v-model="kioskSettings.filtering.isSaveReportWithFiles"
        label="Save filtering report in the destination folder"
        hide-details
        class="mt-2"
      ></v-checkbox>

      <!--WAIT FOR RESULTS-->
      <v-checkbox
        v-model="kioskSettings.filtering.isSkipResults"
        label="Don't wait for results when session ends"
        hide-details
        class="mt-2"
      ></v-checkbox>

      <!--AUTO SELECT FILES-->
      <v-checkbox
        v-model="kioskSettings.filtering.isAutoSelectFiles"
        :label="`Auto select and send files from user’s media${
          kioskSettings.filtering.isAutoSelectFiles ? ':' : ''
        }`"
        hide-details
        class="mt-2"
      ></v-checkbox>

      <div class="d-flex">
        <!--AUTO SELECT FILES-->
        <AutoSelectFiles :kioskSettings="kioskSettings" />

        <!--AUTO SELECT FOLDERS-->
        <AutoSelectFolders :kioskSettings="kioskSettings" />

        <!--AUTO FILENAMES-->
        <AutoFilenames :kioskSettings="kioskSettings" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

//Children
import AutoSelectFiles from './Children/AutoSelectFiles/AutoSelectFiles.vue'
import AutoSelectFolders from './Children/AutoSelectFolders/AutoSelectFolders.vue'
import AutoFilenames from './Children/AutoFilenames/AutoFilenames.vue'
export default {
  name: 'Filtering',
  props: {
    kioskSettings: Object,
    setPolicy: Function,
  },
  components: {
    AutoSelectFiles,
    AutoSelectFolders,
    AutoFilenames,
  },
  computed: {
    ...mapGetters(['policies', 'currentOrganization']),
  },

  methods: {
    ...mapActions(['GET_POLICIES']),
  },
  created() {
    //need to fix the policies somehow
    if (!this.policies.length)
      this.GET_POLICIES(this.currentOrganization.organizationId)
  },
}
</script>
