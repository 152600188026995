<template>
  <div class="pa-2" :class="[type === 'desktop' && 'wrapper-1 bg-white mt-2']">
    <!--TITLE-->
    <h3 v-if="type !== 'mobile'" class="text-center mb-3 fs-1-12r">
      {{ $t('Uploading & Filtering Progress') }}
    </h3>

    <!--TIMER-->
    <div class="d-flex align-center my-3">
      <img width="30px" class="mx-2" src="@/assets/timer.svg" alt="Timer" />
      <span class="f-14">
        {{ $t('Duration') }}
        <span> {{ timer | parseTime }} </span>
        [{{ timer > 3600000 ? 'HH:' : '' }}MM:SS]
      </span>
    </div>

    <!--IF LOGGED USER CAN SEE PROGRESS LOOPING ON PROGRESS ITEMS-->
    <div v-if="isUserCanSeeProgress">
      <!--PROGRESSBAR-->
      <v-progress-linear
        v-show="getPrecentOfFiltering < 100"
        color="primary"
        :value="getPrecentOfFiltering"
        height="32px"
      >
        <span class="fs-1-25r font-weight-bold">{{
          getPrecentOfFiltering + '%'
        }}</span></v-progress-linear
      >

      <v-progress-linear
        v-show="getPrecentOfFiltering >= 100"
        color="primary"
        :value="getPrecentOfFiltering"
        height="32px"
      >
        <span class="fs-1-25r font-weight-bold"
          >100.00%</span
        ></v-progress-linear
      >
    </div>

    <div v-else class="d-flex justify-center align-center">
      <!--LOADING-->
      <v-progress-circular
        class="mb-2"
        color="primary"
        indeterminate
        :size="52"
        :width="9"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { parseTime } from '@/utils'

//Images of progress
import uploadImage from '@/assets/preparing-files.svg'
import analyzeImage from '@/assets/analyze.svg'
import synthesizeImage from '@/assets/synthesize.svg'

//Function to check if user can see the uploading progress
import { checkIfUserCanSeeProgress } from '@/components/WebsiteInterface/Websiteinterface'

//Component data
import { progressItems } from './UploadProgress'

export default {
  name: 'UploadProgress',
  data() {
    return {
      progressItems,
    }
  },
  props: {
    filteringProgress: Object,
    type: String,
    timer: Number,
    session: Object,
  },
  filters: {
    parseTime,
  },
  computed: {
    isUserCanSeeProgress() {
      return checkIfUserCanSeeProgress(this)
    },

    getPrecentOfFiltering() {
      let val = 0
      val += +this.filteringProgress.dataSent
      val += +this.filteringProgress.analyzedPercentage
      val += +this.filteringProgress.synthesizedPercentage

      return (val / 3).toFixed(2)
    },
  },
  methods: {
    getImage(name) {
      if (name === 'uploading') return uploadImage
      else if (name === 'analyze') return analyzeImage
      else if (name === 'synthesize') return synthesizeImage
    },
  },
}
</script>
