<template>
  <div class="d-flex my-2 light-text">
    <span
      class="ml-1 w-175"
      :class="[currentOrganization.status !== 0 && 'low-op-disabeld']"
      >Delete files after:</span
    >

    <!--INPUT BY NUMBER-->
    <v-text-field
      v-model="fileLifeTime.fileDeleteAfterNumber"
      @input="deleteFileChanged"
      :rules="digitsRules"
      :disabled="
        currentOrganization.status !== 0 ||
        fileLifeTime.fileDeleteAfterNumber === null
      "
      class="mx-3 my-0 pa-0 mw-30 centered-input"
      height="16px"
      type="number"
      hide-details
    ></v-text-field>

    <!--SELECT WHEN-->
    <v-select
      v-model="fileLifeTime.fileDeleteAfterType"
      @input="deleteFileChanged"
      class="ma-0 pa-0 mw-100"
      height="16px"
      hide-details
      :items="computedLifeTimeType"
      :disabled="currentOrganization.status !== 0"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FileLifeTime',

  data() {
    return {
      fileLifeTime: {
        fileDeleteAfterNumber: null,
        fileDeleteAfterType: 0,
      },
    }
  },

  props: {
    kioskSettings: Object,
  },

  computed: {
    ...mapGetters(['loggedUser', 'currentOrganization']),
    computedLifeTimeType() {
      return [
        { text: 'Hours', value: 0 },
        { text: 'Days', value: 1 },
        {
          text: 'Never',
          value: 2,
          disabled: this.loggedUser.role !== 'SuperAdmin',
        },
      ]
    },

    digitsRules(value) {
      const vue = this
      if (this.loggedUser.role === 'SuperAdmin')
        return [
          value => !!value || value === 0 || 'Required',
          value => value > 0 || 'Only positive Numbers',
          value => (value % 1 === 0 && value !== 'e') || 'Only Integers',
        ]

      return [
        value => !!value || value === 0 || 'Required',
        value => value > 0 || 'Only positive Numbers',
        value => (value % 1 === 0 && value !== 'e') || 'Only Integers',
        value => {
          //hours
          if (this.fileLifeTime.fileDeleteAfterType === 0) {
            const date = value * 3600000 + 3600000 // value * hour
            return date < 604800000 + 3600000 // one week + hour
          }
          // days
          else {
            const date = value * 86400000 // value * days
            return date < 604800000 + 3600000 // one week + hour
          }
        },
      ]
    },
  },
  methods: {
    deleteFileChanged() {
      const { fileDeleteAfterNumber, fileDeleteAfterType } = this.fileLifeTime

      //Hours
      if (fileDeleteAfterType === 0) {
        this.kioskSettings.filesSettings.deletionInByMs =
          fileDeleteAfterNumber * 3600000 // value * 1 hour
      }

      //Days
      else if (fileDeleteAfterType === 1) {
        this.kioskSettings.filesSettings.deletionInByMs =
          fileDeleteAfterNumber * 86400000 // value * 1 day
      }

      //Never
      else {
        this.kioskSettings.filesSettings.deletionInByMs = 9999999999999
      }
    },
  },

  created() {
    const { deletionInByMs } = this.kioskSettings.filesSettings
    //if the delete files after is unlimited
    if (deletionInByMs === 9999999999999) {
      this.fileLifeTime.fileDeleteAfterType = 2
      this.fileLifeTime.fileDeleteAfterNumber = null
    }
    //if days
    else if (deletionInByMs > 86400000) {
      this.fileLifeTime.fileDeleteAfterType = 1
      this.fileLifeTime.fileDeleteAfterNumber = deletionInByMs / 86400000
    }

    // if hours
    else {
      this.fileLifeTime.fileDeleteAfterType = 0
      this.fileLifeTime.fileDeleteAfterNumber = deletionInByMs / 3600000
    }
  },
}
</script>
