<template>
  <div class="d-flex align-center pa-2">
    <!--SELECT SMTP VENDOR-->
    <v-select
      v-model.sync="modelEmailProtocol"
      :items="computedVendors"
      :disabled="!isSettingsEnabled"
      label="Email Protocol"
      item-text="name"
      class="mx-2 mw-160"
      dense
      hide-details
    >
    </v-select>

    <!--SENDER NAME TO SMTP-->
    <v-text-field
      v-model.sync="modelNameSmtp"
      :disabled="!isSettingsEnabled"
      class="mx-2 mb-4 mw-200"
      label="Sender Name to Display"
      :class="[actionType === 'add' && 'required-field']"
      hide-details
    >
    </v-text-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InputsSenderVendor',
  props: {
    organization: Object,
    isSettingsEnabled: Boolean,
    isBackupSettings: Boolean,
    actionType: String,
    isGlobalSettings: Boolean,
  },
  watch: {
    'organization.name': {
      handler: function (n) {
        if (
          this.actionType === 'add' &&
          n !== undefined &&
          !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(n)
        ) {
          this.organization.emailDefinitions.displaySenderName = n
          this.organization.backupEmailDefinitions.displaySenderName = n
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(['loggedUser']),
    modelEmailProtocol: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.emailProtocol
        return this.organization.emailDefinitions.emailProtocol
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.emailProtocol = n)
        return (this.organization.emailDefinitions.emailProtocol = n)
      },
    },

    modelNameSmtp: {
      get() {
        if (this.isBackupSettings)
          return this.organization.backupEmailDefinitions.displaySenderName
        return this.organization.emailDefinitions.displaySenderName
      },
      set(n) {
        if (this.isBackupSettings)
          return (this.organization.backupEmailDefinitions.displaySenderName =
            n)
        return (this.organization.emailDefinitions.displaySenderName = n)
      },
    },

    computedVendors() {
      if (this.loggedUser.role === 'SuperAdmin' && this.isGlobalSettings)
        return ['SMTP', 'Microsoft Graph']
      return ['Default', 'SMTP', 'Microsoft Graph']
    },
  },
}
</script>
