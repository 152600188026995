<template>
  <div class="d-flex">
    <span class="fs-1-12r mt-1 light-text">Interactive interface language:</span>
    <v-select
      v-model="organization.casualUsersDefinitions.language"
      :items="languages"
      :disabled="
        !organization.casualUsersDefinitions.isActive ||
        organization.status !== 0
      "
      class="px-0 mx-1 py-0 mw-100"
      hide-details
      height="20px"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'CasualLanguage',
  data() {
    return {
      languages: [
        { text: 'English', value: 'en' },
        { text: 'עברית', value: 'he' },
      ],
    }
  },
  props: {
    organization: Object,
  },
}
</script>
