<template>
  <div class="d-flex my-2 light-text">
    <span
      class="ml-1 w-175"
      :class="[currentOrganization.status !== 0 && 'low-op-disabeld']"
      >Alert before file deletion:</span
    >

    <!--INPUT BY NUMBER-->
    <v-text-field
      v-model="alertBeforeDeleteTime.alertBeforeNumber"
      @input="alertFileChanged"
      :rules="digitsRules"
      :disabled="
        currentOrganization.status !== 0 ||
        alertBeforeDeleteTime.alertBeforeNumber === null
      "
      class="mx-3 my-0 pa-0 mw-30 centered-input"
      height="16px"
      type="number"
      hide-details
    ></v-text-field>

    <!--SELECT WHEN-->
    <v-select
      v-model="alertBeforeDeleteTime.alertBeforeType"
      @input="alertFileChanged"
      class="ma-0 pa-0 mw-100"
      height="16px"
      hide-details
      :items="computedLifeTimeType"
      :disabled="currentOrganization.status !== 0"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AlertBeforeDelete',

  data() {
    return {
      alertBeforeDeleteTime: {
        alertBeforeNumber: null,
        alertBeforeType: 0,
      },
    }
  },

  props: {
    kioskSettings: Object,
  },

  computed: {
    ...mapGetters(['loggedUser', 'currentOrganization']),
    computedLifeTimeType() {
      return [
        { text: 'Hours', value: 0 },
        { text: 'Days', value: 1 },
        {
          text: 'Never',
          value: 2,
          disabled: this.loggedUser.role !== 'SuperAdmin',
        },
      ]
    },

    digitsRules(value) {
      const vue = this
      if (this.loggedUser.role === 'SuperAdmin')
        return [
          value => !!value || value === 0 || 'Required',
          value => value > 0 || 'Only positive Numbers',
          value => (value % 1 === 0 && value !== 'e') || 'Only Integers',
        ]

      return [
        value => !!value || value === 0 || 'Required',
        value => value > 0 || 'Only positive Numbers',
        value => (value % 1 === 0 && value !== 'e') || 'Only Integers',
        value => {
          //hours
          if (this.alertBeforeDeleteTime.alertBeforeType === 0) {
            const date = value * 3600000 + 3600000 // value * hour
            return date < 604800000 + 3600000 // one week + hour
          }
          // days
          else {
            const date = value * 86400000 // value * days
            return date < 604800000 + 3600000 // one week + hour
          }
        },
      ]
    },
  },
  methods: {
    alertFileChanged() {
      const { alertBeforeNumber, alertBeforeType } = this.alertBeforeDeleteTime

      //Hours
      if (alertBeforeType === 0) {
        this.kioskSettings.filesSettings.alertBeforeDeletionByMs =
          alertBeforeNumber * 3600000 // value * 1 hour

        console.log(this.kioskSettings)
      }

      //Days
      else if (alertBeforeType === 1) {
        this.kioskSettings.filesSettings.alertBeforeDeletionByMs =
          alertBeforeNumber * 86400000 // value * 1 day
      }

      //Never
      else {
        this.kioskSettings.filesSettings.alertBeforeDeletionByMs = 9999999999999
      }
    },
  },

  created() {
    const { alertBeforeDeletionByMs } = this.kioskSettings.filesSettings
    //if the delete files after is unlimited
    if (alertBeforeDeletionByMs === 9999999999999) {
      this.alertBeforeDeleteTime.alertBeforeType = 2
      this.alertBeforeDeleteTime.alertBeforeNumber = null
    }
    //if days
    else if (alertBeforeDeletionByMs > 86400000) {
      this.alertBeforeDeleteTime.alertBeforeType = 1
      this.alertBeforeDeleteTime.alertBeforeNumber =
        alertBeforeDeletionByMs / 86400000
    }

    // if hours
    else {
      this.alertBeforeDeleteTime.alertBeforeType = 0
      this.alertBeforeDeleteTime.alertBeforeNumber =
        alertBeforeDeletionByMs / 3600000
    }
  },
}
</script>
