<template>
  <div class="wrapper-2 pa-2 ma-2 mt-4">
    <h4 class="settings-h4 mb-2">Advanced Settings</h4>

    <div class="d-flex flex-wrap">
      <!--HOST SELECT FOR SUPER ADMIN-->
      <div
        v-if="loggedUser.role === 'SuperAdmin'"
        class="wrapper-1 mx-2 py-1 px-2"
      >
        <span class="label f-14">Host:</span>

        <div class="d-flex">
          <!--ACTIVE / INACTIVE-->
          <div class="mx-1">
            <v-checkbox
              v-model="isShowInactiveHosts"
              label="Active"
              class="ma-0 pa-0"
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="isShowActiveHosts"
              label="Inactive"
              class="ma-0 pa-0"
              hide-details
            ></v-checkbox>
          </div>

          <!--SELECT HOST-->
          <v-select
            v-model="customSearch.orgId"
            :items="computedOrgsList"
            item-value="organizationId"
            item-text="name"
            class="mw-220 mx-1 pa-2"
            hide-details
            clearable
          ></v-select>
        </div>
      </div>

      <!--DATE FORMATS--> 
      <div class="wrapper-1 pa-2 mx-2 w-100">
        <span class="label f-14">Time format:</span>
        <v-radio-group
          v-model="customSearch.dateFormat"
          class="pa-0 ma-0"
          hide-details
        >
          <v-radio value="Local" label="Local" class="pa-0 ma-0 my-1"></v-radio>
          <v-radio value="UTC" label="UTC" class="pa-0 ma-0 my-1"></v-radio>
        </v-radio-group>
      </div>

      <!--SHOW STATISTICS FOR INTERFACES-->
      <div class="wrapper-1 pa-2 mx-2">
        <span class="f-14 label">Statistics for interfaces:</span>
        <div class="licenses-wrapper">
          <v-checkbox
            v-for="lic in licensesArr"
            :key="lic"
            v-model="customSearch.licenses"
            :label="lic"
            :value="lic"
            hide-details
            class="pa-0 ma-0 mx-2"
          ></v-checkbox>
        </div>
      </div>
      <!--GRID SHOW-->
      <div class="wrapper-1 pa-2 ma-2">
        <span class="f-14 label">Arrangement</span>
        <div class="d-flex align-center">
          <!--SCREEN POSITIONS-->
          <v-select
            v-model="customSearch.mode"
            :items="screenPositionsItems"
            hide-details
            class="my-2 mw-100 mx-1"
          />

          <!--POSITIONS AND ITEMS INSIDE-->
          <div :class="['mb-2 pa-2 grid-' + customSearch.mode]">
            <div
              v-for="(grid, i, c) in customSearch.order"
              v-if="isShowGrid(c)"
              :key="i"
              class="grid-item wrapper-1 d-flex align-center mw-200"
            >
              <span class="mx-2 fs-1r">{{ c + 1 }}.</span>
              <v-select
                v-model="customSearch.order[i]"
                :items="arrangeItems"
                hide-details
                class="my-0 py-0"
              >
              </v-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/config'
import { mapGetters, mapMutations } from 'vuex'
import {
  screenPositionsItems,
  arrangeItems,
} from '../../../AnalyticsSettingsDialog/AnalyticsSettingsDialog'
export default {
  name: 'AdvancedSettings',
  data() {
    return {
      isShowActiveHosts: true,
      isShowInactiveHosts: false,
      activeHosts: [],
      inactiveHosts: [],
      screenPositionsItems,
      arrangeItems,
      licensesArr: [
        'Regular user',
        'SFTP',
        'Host admin',
        'Kiosk',
        'Casual',
        'API',
      ],
    }
  },

  props: {
    customSearch: Object,
  },

  computed: {
    ...mapGetters(['loggedUser']),
    computedOrgsList() {
      let arr = []

      if (this.isShowActiveHosts) arr = [...this.activeHosts]
      if (this.isShowInactiveHosts) arr = [...arr, ...this.inactiveHosts]

      return arr
    },
  },

  methods: {
    ...mapMutations(['SET_PROCESSING']),
    isShowGrid(idx) {
      if (idx === 0) return true
      const { mode } = this.customSearch

      const conditions = [
        [1, ['1x1']],
        [2, ['1x1', '2x1']],
        [3, ['1x1', '2x1', '3x1']],
        [4, ['1x1', '2x1', '3x1', '2x2']],
        [5, ['1x1', '2x1', '3x1', '2x2']],
      ]

      const invalidModes = conditions[idx - 1][1]

      return !invalidModes.includes(mode)
    },
  },

  async created() {
    if (this.loggedUser.role !== 'SuperAdmin') return
    try {
      this.SET_PROCESSING(true)

      const res = await api.get('organizations/getallorgnames')
      if (res.status !== 200) throw Error

      const activeHosts = []
      const inactiveHosts = []

      res.data.forEach(host => {
        if (host.isActive) activeHosts.push(host)
        else inactiveHosts.push(host)
      })

      this.activeHosts = activeHosts
      this.inactiveHosts = inactiveHosts
    } catch (e) {
      console.log(e)
    } finally {
      this.SET_PROCESSING(false)
    }
  },
}
</script>
<style scoped>
.licenses-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 80px;
  flex-wrap: wrap;
}
</style>
