<template>
  <div class="fill-height pos-relative">
    <!--HEADER-->
    <AnalyticsHeader
      :profiles="profiles"
      :shownProfile="shownProfile"
      :shownProfileChanged="shownProfileChanged"
      :setIsShowAnalyticsSettings="v => (isShowSettingDialog = v)"
      :searchDatesString="searchDatesString"
      :updateAnalytics="updateAnalytics"
      :isLoading="isLoading"
      :isAutoUpdateActive="isAutoUpdateActive"
      :setIsAutoUpdateActive="v => (isAutoUpdateActive = v)"
    />

    <!--CARDS-->
    <AnalyticsCards
      v-if="analyticsData"
      :analyticsData="analyticsData"
      :shownProfile="shownProfile"
      :searchDatesString="searchDatesString"
    />

    <!--DIALOG FOR SETTINGS-->
    <AnalyticsSettingsDialog
      v-if="isShowSettingDialog"
      :profiles="profiles"
      :setIsShowAnalyticsSettings="v => (isShowSettingDialog = v)"
      :profileSettingsSaved="profileSettingsSaved"
    />
  </div>
</template>

<script>
import AnalyticsCards from '@/components/Analytics/AnalyticsCards/AnalyticsCards.vue'
import AnalyticsHeader from '@/components/Analytics/AnalyticsHeader/AnalyticsHeader.vue'
import AnalyticsSettingsDialog from '@/components/Analytics/AnalyticsSettingsDialog/AnalyticsSettingsDialog.vue'
import { api } from '@/config'
import { analyticsResFake, defaultProfile } from './Analytics'
import { mapGetters, mapMutations } from 'vuex'
import {
  alertDialog,
  getUTCdateFromLocalDate,
  getUTCMSNow,
  getUTCdateFromLocalDateDavid,
  formatDateStringToDayjs,
  generateLocalDate,
  generateLocalDateFromUTC0,
  generateLocalDateFromUTC0David,
} from '@/utils'
import dayjs from 'dayjs'

export default {
  name: 'Analytics',
  data() {
    return {
      isLoading: false,
      profiles: [],
      interval: null,
      shownProfile: {},
      fromDateMs: 0,
      isShowSettingDialog: false,
      analyticsData: null,
      isAutoUpdateActive: false,
    }
  },

  watch: {
    shownProfile(n) {
      if (!n) return
      this.isAutoUpdateActive = n.isAutoUpdate
      if (!n.isAutoUpdate) clearInterval(this.interval)
    },
  },
  components: {
    AnalyticsCards,
    AnalyticsHeader,
    AnalyticsSettingsDialog,
  },
  computed: {
    ...mapGetters(['loggedUser']),
    searchDatesString() {
      let str = ''

      //recent dates
      if (this.shownProfile.askedPeriodType === 'recent') {
        str += generateLocalDateFromUTC0David(this.fromDateMs) + ' (Local)'

        str += ' to current time'
      }

      //regular dates
      else {
        //utc search
        if (this.shownProfile.dateFormat === 'UTC')
          str += generateLocalDate(this.shownProfile.startDate)
        //local search
        else
          str +=
            generateLocalDateFromUTC0David(this.shownProfile.startDate) +
            ' (Local)'

        str += ' to '

        //if utc
        if (this.shownProfile.dateFormat === 'UTC')
          str += generateLocalDate(this.shownProfile.endDate) + ' (UTC)'
        //if local
        else
          str +=
            generateLocalDateFromUTC0David(this.shownProfile.endDate) +
            ' (Local)'
      }

      return str
    },
  },

  methods: {
    ...mapMutations(['SET_PROCESSING']),
    shownProfileChanged(v) {
      this.shownProfile = v
      this.analyticsData = null
      this.getProfileData()
    },
    profileSettingsSaved(profiles) {
      this.profiles = profiles
      const shownProfile = profiles.find(
        el => el.analyticsProfileId === this.shownProfile.analyticsProfileId
      )

      if (shownProfile) this.shownProfile = shownProfile
      this.getProfileData()
    },

    formatDateFromMilliseconds(milliseconds) {
      const date = new Date(milliseconds)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}`
    },

    async updateAnalytics(isClickedRefresh) {
      if (!isClickedRefresh && !this.isAutoUpdateActive) return
      try {
        this.isLoading = true

        let payload = {}

        //if the time is recent
        if (this.shownProfile.askedPeriodType === 'recent') {
          //set dates of search

          const currentDate = Date.now()
          const searchTime = this.shownProfile.duration
          this.fromDateMs = new Date(currentDate - searchTime).getTime()

          //get data from server by now and SEARCH TIME
          payload = {
            startDate: getUTCdateFromLocalDate(this.fromDateMs),
            endDate: getUTCdateFromLocalDate(currentDate),
            orgId:
              this.loggedUser.role === 'SuperAdmin'
                ? this.shownProfile.orgId
                : this.loggedUser.organizationId,
          }
        }

        //if the time is custom dates
        else {
          let startDate
          let endDate

          //OLD WAY
          // const newFormatStartDate = this.formatDateFromMilliseconds(
          //   this.shownProfile.startDate
          // )
          // const newFormatEndDate = this.formatDateFromMilliseconds(
          //   this.shownProfile.endDate
          // )

          //NEW WAY !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

          const newFormatStartDate = dayjs().format('MM/DD/YYYY HH:mm:ss.SSS')
          const newFormatEndDate = dayjs().format('MM/DD/YYYY HH:mm:ss.SSS')

          //format local
          if (this.shownProfile.dateFormat === 'Local') {
            startDate = getUTCdateFromLocalDate(newFormatStartDate)
            endDate = getUTCdateFromLocalDate(newFormatEndDate)
          }
          
          //format utc+0
          else {
            startDate = getUTCMSNow(newFormatStartDate)
            endDate = getUTCMSNow(newFormatEndDate)
          }
          console.log(new Date(endDate))

          //get data from server by now and SEARCH TIME
          payload = {
            startDate,
            endDate,
            orgId:
              this.loggedUser.role === 'SuperAdmin'
                ? this.shownProfile.orgId
                : this.loggedUser.organizationId,
          }
        }
        const analyticsRes = await api.post(`statistics`, payload)

        //if res in empty
        if (analyticsRes.status === 204)
          return alertDialog(this, 'No data was found')

        if (analyticsRes.status !== 200) throw Error
        this.analyticsData = analyticsRes.data
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => (this.isLoading = false), 700)
      }
    },
    async getProfileData(isDefault) {
      try {
        this.SET_PROCESSING(true)
        this.analyticsData = null
        //get profiles
        const profilesRes = await api.get('statistics/profiles')

        if (profilesRes.status !== 200) throw Error

        //remove the null
        if (profilesRes.data.length && profilesRes.data[0].profileName !== null)
          this.profiles = profilesRes.data
        else
          this.profiles = [
            {
              ...defaultProfile,
              orgId:
                this.loggedUser.role === 'Admin'
                  ? this.loggedUser.organizationId
                  : null,
              userId: this.loggedUser.userId,
            },
          ]

        //set shown profile
        if (isDefault)
          this.shownProfile = this.profiles.find(el => el.isDefault)

        this.updateAnalytics(true)

        if (!this.shownProfile.isAutoUpdate) return

        this.interval = setInterval(() => this.updateAnalytics(), 10000)
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
  async created() {
    this.getProfileData(true)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>
