<template>
  <div class="d-flex my-2">
    <!--TITLE-->
    <span class="mr-2 fs-1-12r">Time presented: last</span>

    <!--NUMBER SELECT-->
    <v-text-field
      v-model.number="numberSelect"
      @input="timeSelectorChanged"
      class="my-0 mr-2 pa-0 mw-30 centered-input"
      height="22px"
      type="number"
      hide-details
    ></v-text-field>

    <!--TYPE SELECT-->
    <v-select
      v-model="typeSelect"
      @input="timeSelectorChanged"
      :items="timeItems"
      class="ma-0 pa-0 mw-100"
      height="22px"
      hide-details
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'TimeSelector',
  data() {
    return {
      numberSelect: 1,
      typeSelect: 'Weeks',
      timeItems: ['Hours', 'Days', 'Weeks', 'Months'],
    }
  },

  props: {
    profile: Object,
  },

  methods: {
    timeSelectorChanged() {
      //if hours
      if (this.typeSelect === 'Hours') {
        this.profile.duration = this.numberSelect * 1000 * 60 * 60
      }

      //if days
      else if (this.typeSelect === 'Days') {
        this.profile.duration = this.numberSelect * 60 * 60 * 24 * 1000
      }

      //if weeks
      else if (this.typeSelect === 'Weeks') {
        this.profile.duration = this.numberSelect * 60 * 60 * 24 * 7 * 1000
      }

      //if months //30 days?
      else {
        this.profile.duration = this.numberSelect * 60 * 60 * 24 * 30 * 1000
      }
    },
  },

  created() {
    const { duration } = this.profile

    //if hours
    if (duration < 86400000) {
      this.typeSelect = 'Hours'
      this.numberSelect = duration / 60 / 60 / 1000
    }

    //if days
    else if (duration < 604800000) {
      this.typeSelect = 'Days'
      this.numberSelect = duration / 60 / 60 / 24 / 1000
    }

    //if weeks
    else if (duration < 2592000000) {
      this.typeSelect = 'Weeks'
      this.numberSelect = duration / 60 / 60 / 24 / 7 / 1000
    }

    //if months
    else {
      this.typeSelect = 'Months'
      this.numberSelect = duration / 60 / 60 / 24 / 30 / 1000
    }
  },
}
</script>
