<template>
  <div class="d-flex justify-end">
    <!--CREATE POLICY-->
    <Button
      :clickAction="() => (isShowNewPolicyNameDialog = true)"
      class="mx-2"
      text="Create Policy"
    />

    <!--IMPORT POLICY-->
    <Button
      :clickAction="() => $refs.fileInputPolicyHostSettings.click()"
      text="Import Policy"
      btnType="grey"
      class="mx-2"
    />

    <!--INPUT FOR IMPORT POLICY-->
    <input
      @change="e => importPolicy(e)"
      type="file"
      ref="fileInputPolicyHostSettings"
      class="d-none"
      dense
      accept=".polx"
    />

    <!--DIALOG FOR NEW POLICY NAME-->
    <NewPolicyNameDialog
      v-if="isShowNewPolicyNameDialog"
      :closeDialog="() => (isShowNewPolicyNameDialog = false)"
      :createNewPolicy="createNewPolicy"
    />
  </div>
</template>

<script>
import { addPolicy } from '@/actions/policies'
import { mapActions, mapGetters, mapMutations } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import NewPolicyNameDialog from './Childrens/NewPolicyNameDialog/NewPolicyNameDialog.vue'

export default {
  name: 'ButtonsCreateImportPolicy',
  data() {
    return {
      isShowNewPolicyNameDialog: false,
    }
  },
  props: {
    createNewPolicy: Function,
  },
  components: { Button, NewPolicyNameDialog },

  computed: {
    ...mapGetters(['loggedUser']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    ...mapActions(['GET_POLICIES']),
    async importPolicy(e) {
      try {
        this.SET_PROCESSING(true)
        if (!e.target.files[0].name.includes('.polx')) {
          throw new Error("Only files with '.polx' extension are allowed.")
        }

        const res = await addPolicy(
          this.loggedUser.organizationId,
          e.target.files[0]
        )
        if (res.status !== 200) throw new Error(res.data)

        this.SET_NOTIFICATION({
          type: 'success',
          text: 'Policy file was uploaded successfully.',
        })

        this.GET_POLICIES(this.loggedUser.organizationId)
      } catch (error) {
        console.log(error)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
}
</script>
