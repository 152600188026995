<template>
  <div class="fill-height pos-relative">
    <LogsHeader
      :isShowAdvancedSettings="isShowAdvancedSettings"
      :setIsShowAdvancedSettings="v => (isShowAdvancedSettings = v)"
      :search="search"
      :searchLogsButtonClicked="searchLogsButtonClicked"
      :logsTableHeaders="logsTableHeaders"
      :logsSettings="logsSettings"
    />
    <LogsTable
      :isShowAdvancedSettings="isShowAdvancedSettings"
      :logs="logs"
      :page="page"
      :search="search"
      :logsHeaders="logsHeaders"
      :logsSettings="logsSettings"
      :addToMarkedLinesArr="v => markedLinesArr.push(v)"
      :removeFromMarkedLinesArr="
        v => (markedLinesArr = markedLinesArr.filter(logId => logId !== v))
      "
      :markedLinesArr="markedLinesArr"
    />

    <!--BUTTONS TO CONTINUE AND BACK-->
    <div
      v-if="logs.length"
      :class="[loggedUser.role === 'SuperAdmin' ? 'pt-55px' : '']"
      class="h-50 d-flex justify-space-between font-weight-bold align-center"
    >
      <!--BUTTON BACK 1000-->
      <Button
        v-if="isShowBackButton"
        :clickAction="back1000Clicked"
        beforeTextIcon="mdi-arrow-left"
        text="Back"
      />
      <div v-else class="w-145"></div>

      <div class="fs-1r mb-2">
        {{ countOfLogsInPage }}
        <div class="d-flex justify-center h-20">
          <v-progress-circular
            v-if="isWaitingForTotalLogs"
            indeterminate
            size="12"
            color="primary"
          ></v-progress-circular>

          <div v-else>{{ totalLogs ? totalLogs : 0 }} Total</div>
        </div>
      </div>

      <!--BUTTON NEXT 1000-->
      <Button
        v-if="isShowNextButton"
        :clickAction="next1000Clicked"
        icon="mdi-arrow-right"
        text="Next"
      />

      <div v-else class="w-145"></div>
    </div>
  </div>
</template>

<script>
import { api } from '@/config'
import {
  generateLocalDateFromUTC0DavidWithMs,
  generateLocalDateFromUTC0David,
  generateUTCDateDavidWithMs,
  generateUTCDateDavid,
  getUTCMSNow,
  getUTCdateFromLocalDateDavid,
  padZero,
} from '@/utils'
import dayjs from 'dayjs'
import { mapGetters, mapMutations } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Children
import LogsHeader from '@/components/SystemLogs/LogsHeader/LogsHeader.vue'
import LogsTable from '@/components/SystemLogs/LogsTable/LogsTable.vue'

//Component file
import { startSearchObj } from './SystemLogs'

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: 'SystemLogs',
  data() {
    return {
      isShowAdvancedSettings: false,
      page: 0,
      search: JSON.parse(JSON.stringify(startSearchObj)),
      logs: [],
      markedLinesArr: [],
      isShowNextButton: false,
      isShowBackButton: false,
      lastStartDatesArray: [],
      totalLogs: null,
      isWaitingForTotalLogs: false,
      logsSettings: {
        dateFormat: 'Local',
      },
      logsTableHeaders: {
        checkbox: false,
        id: false,
        date: true,
        host: true,
        account: true,
        message: true,
        moreDataOfLog: true,
      },
    }
  },
  components: { LogsHeader, LogsTable, Button },
  watch: {
    search: {
      handler: function (n, o) {
        if (!n) return
        if (this.logs.length) {
          this.logs = []
        }
      },
      deep: true,
    },
    logsSettings: {
      handler: function (n) {
        if (!n) return
        if (this.logs.length) {
          this.logs = []
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUser']),
    countOfLogsInPage() {
      const total = (this.lastStartDatesArray.length + 1) * 1000
      return `${this.lastStartDatesArray.length * 1000 + 1} - ${
        total < this.logs.length
          ? total
          : this.lastStartDatesArray.length * 1000 + this.logs.length
      }`
    },
    logsHeaders() {
      const logsHeaders = []
      if (this.logsTableHeaders.checkbox)
        logsHeaders.push({
          text: ``,
          value: 'checkbox',
          class: 'bg-table-heading table-header-first',
          width: '30px',
          align: 'center',
        })
      if (this.logsTableHeaders.date)
        logsHeaders.push({
          text: `Date (${this.logsSettings.dateFormat})`,
          value: 'shownDate',
          class: 'bg-table-heading',
          width: '150px',
          align: 'center',
        })
      if (this.logsTableHeaders.host && this.loggedUser.role === 'SuperAdmin')
        logsHeaders.push({
          text: 'Host',
          value: 'relevantOrgName',
          class: 'bg-table-heading',
          width: '150px',
        })
      if (this.logsTableHeaders.account)
        logsHeaders.push({
          text: 'Sender Account',
          value: 'executingUserEmail',
          class: 'bg-table-heading',
          width: '250px',
        })
      if (this.logsTableHeaders.message)
        logsHeaders.push({
          text: 'Message',
          value: 'logMessage',
          isHtml: true,
          class: 'bg-table-heading',
        })

      if (this.logsTableHeaders.id)
        logsHeaders.push({
          text: 'Log S/N',
          value: 'logId',
          class: 'bg-table-heading ',
          width: '90px',
          align: 'center',
        })

      if (this.logsTableHeaders.moreDataOfLog) {
        logsHeaders.push({
          text: 'More About',
          value: 'moreDataOfLog',
          class: 'bg-table-heading ',
          width: '100px',
          align: 'center',
          sortable: false,
        })
      }

      return logsHeaders.map((el, idx) => {
        //add class table first to the first heading
        if (idx === 0) {
          return { ...el, class: 'bg-table-heading table-header-first' }
        }
        //add table last to the last element
        if (idx === logsHeaders.length - 1) {
          return { ...el, class: 'bg-table-heading table-header-last' }
        }

        return el
      })
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),

    incrementString(str) {
      let newNum = +str
      newNum++
      const newStr = newNum + ''
      if (newStr.length === 1) return '00' + newStr
      if (newStr.length === 2) return '0' + newStr
      return '' + newStr
    },

    async searchLogsButtonClicked() {
      this.page = 0
      this.lastStartDatesArray = []
      this.isShowBackButton = false
      this.isShowNextButton = false
      this.isShowAdvancedSettings = false
      await this.searchLogs()
      this.page = 1
    },

    formatDateStringToDayjs(dateString) {
      let datePart = ''
      let timePart = ''

      if (dateString.includes(' ')) {
        [datePart, timePart] = dateString.split(' ')
      } else {
        datePart = dateString
      }

      const [year, month, day] = datePart.split('-')
      const [hour, minute, second, millisecond] = timePart.split('-')

      // Format the date parts with leading zeros if necessary
      const formattedDate = `${year}-${padZero(month)}-${padZero(
        day
      )} ${padZero(hour)}:${padZero(minute)}:${
        second ? padZero(second) : '00'
      }.${millisecond ? padZero(this.incrementString(millisecond), 3) : '000'}`

      return formattedDate
    },

    next1000Clicked() {
      this.lastStartDatesArray.push(this.logs[0].dateTime)

      this.search.startDate = this.logs[999].dateTime

      this.isShowBackButton = true
      this.searchLogs(true)
    },

    back1000Clicked() {
      this.search.startDate = this.lastStartDatesArray.pop()
      if (this.search.startDate) {
        this.searchLogs(true)
      }
      if (this.lastStartDatesArray.length === 0) {
        this.isShowBackButton = false
      }
    },
    async searchLogs(isCallFromNextBackButtons) {
      try {
        this.SET_PROCESSING(true)
        let startDate
        let endDate

        if(!isCallFromNextBackButtons) this.logs = []

        const newFormatStartDate = dayjs(
          this.formatDateStringToDayjs(this.search.startDate)
        ).format('MM/DD/YYYY HH:mm:ss.SSS')

        const newFormatEndtDate = dayjs(
          this.formatDateStringToDayjs(this.search.endDate)
        ).format('MM/DD/YYYY HH:mm:ss.SSS')

        //format local
        if (this.logsSettings.dateFormat === 'Local') {
          startDate = getUTCdateFromLocalDateDavid(newFormatStartDate)
          endDate = getUTCdateFromLocalDateDavid(newFormatEndtDate)
        }
        //format utc
        else {
          startDate = getUTCMSNow(newFormatStartDate)
          endDate = getUTCMSNow(newFormatEndtDate)
        }

        const payload = {
          ...this.search,
          startDate,
          endDate,
        }

        //for casual users
        if (payload.userEmail)
          payload.userEmail = payload.userEmail.replace(' (Casual)', '').trim()

        const res = await api.post('logs', payload)
        if (res.status !== 200) throw Error

        if (!isCallFromNextBackButtons) this.getTotalLogs(payload)

        this.logs = res.data.logs.map(log => {
          let dateTime
          let shownDate
          if (this.logsSettings.dateFormat === 'Local') {
            shownDate = generateLocalDateFromUTC0David(log.dateTime)
            dateTime = generateLocalDateFromUTC0DavidWithMs(log.dateTime)
          } else {
            shownDate = generateUTCDateDavid(log.dateTime)
            dateTime = generateUTCDateDavidWithMs(log.dateTime)
          }
          return {
            ...log,
            dateTime,
            shownDate,
            moreDataOfLog: {
              operationId: log.operationId,
              fileGuid: log.fileGuid,
            },
          }
        })

        //if the user didn't clicked next
        // if (!isCallFromNextBackButtons) this.totalLogs = res.data.count

        this.isShowNextButton = res.data.isMore
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    async getTotalLogs(payload) {
      this.isWaitingForTotalLogs = true
      this.totalLogs = 0
      const res = await api.post('logs/count', payload)
      this.totalLogs = res.data
      this.isWaitingForTotalLogs = false
    },
  },
}
</script>

<style scoped>
.pt-55px {
  padding-top: 55px;
}
</style>
