<template>
  <div class="pa-2 summary fs-1r" tabindex="0">
    <!--TOTAL FILES-->
    <div>
      <div
        class="mx-2 d-flex justify-space-between"
        :aria-label="`Total of ${statusResults.total}`"
      >
        <b>{{ $t('Total') }}: </b>
        <span>{{ statusResults.total | parseBigNumber }}</span>
      </div>

      <v-divider class="mb-1" />
    </div>
    <!--LOOP FOR GENERATING THE LINES-->
    <div
      v-for="t of summary"
      :key="t.type"
      class="d-flex mb-1"
      :aria-label="`${statusResults[t.type]} files are ${t.title}`"
    >
      <v-icon small class="ml-3" v-if="t.type === 'partial'">{{
        language === 'he' ? mdiArrowLeftBottom : mdiArrowRightBottom
      }}</v-icon>
      <!--IMAGE-->
      <img
        class="mx-1 w-20"
        :class="[t.type === 'partial' && 'ml-1']"
        :src="t.img"
      />

      <!--TITLE AND NUMBER-->
      <div class="mx-2 d-flex justify-space-between fill-width">
        <b>{{ t.title }}: </b>
        <span>{{ statusResults[t.type] | parseBigNumber }}</span>
      </div>
    </div>
  </div>
</template>

<script>
//Images to show status
import intact from '@/assets/intact.svg'
import modified from '@/assets/modified.svg'
import partial from '@/assets/partial.svg'
import blocked from '@/assets/blocked.svg'
import { mdiArrowRightBottom, mdiArrowLeftBottom } from '@mdi/js'
import { mapGetters } from 'vuex'
export default {
  name: 'Summary',
  data() {
    return {
      mdiArrowRightBottom,
      mdiArrowLeftBottom
    }
  },
  props: {
    statusResults: Object,
  },
  filters: {
    parseBigNumber(number) {
      let numberString = number.toString()
      let numberArray = numberString.split('')

      let commaCount = Math.floor((numberArray.length - 1) / 3)

      for (let i = 1; i <= commaCount; i++) {
        numberArray.splice(-(i * 3 + i - 1), 0, ',')
      }

      let formattedNumber = numberArray.join('')

      return formattedNumber
    },
  },

  computed: {
    ...mapGetters(['language']),
    summary() {
      return [
        { type: 'intact', title: this.$t('Intact'), img: intact },
        { type: 'modified', title: this.$t('Modified'), img: modified },
        { type: 'partial', title: this.$t('Partial'), img: partial },
        { type: 'rejected', title: this.$t('Rejected'), img: blocked },
      ]
    },
  },
}
</script>

<style scoped src="./Summary.css"></style>
