<template>
  <div class="pa-2">
    <h4 class="settings-h4">Host Status</h4>
    <div class="wrapper-2 pa-3 mb-4">
      <div class="d-flex">
        <!--CHANGE HOST STATUS-->
        <v-radio-group
          v-model="organization.status"
          :disabled="organization.status === 2"
          dense
          hide-details
          class="ma-0"
          row
        >
          <v-radio :value="0" label="Active" class="ma-0 mr-8"></v-radio>
          <v-radio
            :value="1"
            label="Blocked (Temporary)"
            class="ma-0 mr-8"
          ></v-radio>
        </v-radio-group>
        <!--DELETE HOST -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="removeOrganization"
              class="ml-auto"
              v-on="on"
              small
              rounded
              fab
              color="error"
            >
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete host</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { confirmDialog } from '@/utils'

export default {
  name: 'ChangeHostStatus',
  props: {
    organization: Object,
  },

  methods: {
    ...mapActions(['REMOVE_ORGANIZATION']),
    removeOrganization() {
      const text =
        'Are you sure you want to delete this host?<br><br><span class="message-class" style="color: red">Warning:<span><br>All routes, users, definitions, files and any other data related to this host, will be lost permanently!'

      const thenFunc = async () => {
        await this.REMOVE_ORGANIZATION(this.organization)
        this.$router.push('/management/hosts')
      }

      confirmDialog(this, text, 'Delete', 'Cancel', thenFunc)
    },
  },
}
</script>
