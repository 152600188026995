<template>
  <div
    class="wrapper-2 d-flex align-center justify-space-between pa-2 ma-1 h-50"
  >
    <!--BACK BUTTON-->
    <v-icon
      @click="changeShownDirClicked(null, 'backButton')"
      x-large
      tabindex="0"
      aria-label="Back one directory"
      >mdi-arrow-left-circle-outline</v-icon
    >

    <!--SEARCH IN DIRECTORY-->
    <FolderSearch
      :setDirSearch="setDirSearch"
      :dirSearch="dirSearch"
      :shownDirectory="shownDirectory"
    />
  </div>
</template>

<script>
import FolderSearch from './Children/FolderSearch/FolderSearch.vue'

export default {
  name: 'DirectoryHeader',
  props: {
    shownDirectory: Object,
    changeShownDirClicked: Function,
    setDirSearch: Function,
    dirSearch: String,
  },
  components: { FolderSearch },
}
</script>
