<template>
  <div
    class="single-route pos-relative ma-1"
    @click.stop="isAdvancedSettingsOpen ? (isAdvancedSettingsOpen = false) : ''"
    v-if="route.sourceEndPoints"
  >
    <!-- TITLE AND BUTTONS  -->
    <RouteTitleButtons
      v-if="!isFromUser"
      :actionType="actionType"
      :isSaveButtonDisabled="isSaveButtonDisabled"
      :message="message"
      :changestatus="changestatus"
    />

    <!--EDIT DETAILS-->
    <EditDetails
      v-if="isShowEditDetails"
      :parentObj="route"
      editOf="route"
      :class="[ isFromUser ? 'edit-details' : 'my-3']"
    />

    <!--HEADER OF THE ROUTE-->
    <div class="d-flex align-center justify-space-between">
      <!-- NAME AND POLICY  -->
      <div class="d-flex align-center pos-relative">
        <RouteNamePolicy
          :route="route"
          :actionType="actionType"
          :isEditDisabled="isEditDisabled"
          :isRouteNameUniqe="isRouteNameUniqe"
          :freezedRoute="freezedRoute"
          :setIsRouteNameUniqe="v => (isRouteNameUniqe = v)"
          :checkRoute="checkRoute"
        />

        <!-- ADVANCED SETTINGS  -->
        <AdvancedSettings
          @set-is-advanced-settings-open="v => (isAdvancedSettingsOpen = v)"
          :route="route"
          :isAdvancedSettingsOpen="isAdvancedSettingsOpen"
          :isTargetSettingsError="isTargetSettingsError"
          :fileLifeTime="fileLifeTime"
          :alertBeforeDeleteTime="alertBeforeDeleteTime"
          :isDeleteAlertTimesError="isDeleteAlertTimesError"
          :isEditDisabled="isEditDisabled"
        />
      </div>
    </div>

    <v-divider class="my-3 mt-4" />
    <div class="route-wrapper">
      <div class="d-flex pa-1">
        <!-- SOURCES -->
        <SourceEndPoints
          :actionType="actionType"
          :route="route"
          :computedUsers="computedUsers"
          :isEditDisabled="isEditDisabled"
          :freezedRoute="freezedRoute"
        />

        <!-- TARGETS -->
        <TargetEndPoints
          :actionType="actionType"
          :route="route"
          :computedUsers="computedUsers"
          :isEditDisabled="isEditDisabled"
          :freezedRoute="freezedRoute"
        />
      </div>
    </div>

    <!-- ROUTE STATUS -->
    <RouteStatus
      v-if="actionType === 'edit' && !isFromUser"
      :freezedRoute="freezedRoute"
      :route="route"
      :setSaveButtonClicked="v => (saveButtonClicked = v)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getSingleRoute, getEmptyRoute } from '@/actions/route'
import {
  getUTC0MSDateFromLocalDate,
  isFolderNameValid,
  confirmDialog,
} from '@/utils'

//Base components
import EditDetails from '@/components/BaseComponents/EditDetails/EditDetails.vue'

//Childrens
import SourceOrTargetItem from './Children/SourceOrTargetItem/SourceOrTargetItem.vue'
import RouteTitleButtons from './Children/RouteTiltleButtons/RouteTitleButtons.vue'
import RouteNamePolicy from './Children/RouteNamePolicy/RouteNamePolicy.vue'
import AdvancedSettings from './Children/AdvancedSettings/AdvancedSettings.vue'
import SourceEndPoints from './Children/SourceEndPoints/SourceEndPoints.vue'
import TargetEndPoints from './Children/TargetEndPoints/TargetEndPoints.vue'
import RouteStatus from './Children/RouteStatus/RouteStatus.vue'

//Component files
import { startObjRoute } from './SingleRoute'

export default {
  name: 'SingleRoute',
  data() {
    return {
      ...JSON.parse(JSON.stringify(startObjRoute)),
    }
  },
  props: {
    routeId: Number,
    isFromUser: Boolean,
  },

  components: {
    SourceOrTargetItem,
    RouteTitleButtons,
    RouteNamePolicy,
    AdvancedSettings,
    SourceEndPoints,
    TargetEndPoints,
    RouteStatus,
    EditDetails,
  },

  watch: {
    route: {
      handler: function () {
        this.checkRoute()
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(['currentOrganization', 'loggedUser', 'users']),

    isEditDisabled() {
      if (this.route.status !== 0) return true
      else if (this.currentOrganization.status !== 0) return true
      return false
    },
    isShowEditDetails() {
      if (!this.route.createUpdateDetails) return false
      //remove after add to emptyroute
      else if (this.actionType === 'add') return false
      else if (this.route.createUpdateDetails.createdByName) return true
      else if (this.route.createUpdateDetails.updatedByName) return true
      return false
    },
    isDigitError() {
      if (this.fileLifeTime.fileDeleteAfterNumber === null) return false
      if (this.fileLifeTime.fileDeleteAfterNumber.toString().includes('.'))
        return true
      if (this.loggedUser.role === 'SuperAdmin') return false //Super admin can put anything here
      if (this.freezedRoute.deleteFilesAtByMs === this.route.deleteFilesAtByMs)
        return false // if the value changed
      if (this.fileLifeTime.fileDeleteAfterNumber <= 0) return true
      if (this.fileLifeTime.fileDeleteAfterType === 0) {
        // IF HOURS
        const date = this.fileLifeTime.fileDeleteAfterNumber * 3600000 + 3600000 // value * hour
        return date > 604800000 + 3600000 // one week + hour
      } else {
        const date = this.fileLifeTime.fileDeleteAfterNumber * 86400000 // value * days

        return date > 604800000 + 3600000 // one week + hour
      }
    },
    isDeleteAlertTimesError() {
      if (this.route.deleteFilesAtByMs < 0) return false
      if (this.route.alertBeforeDeletionByMs >= this.route.deleteFilesAtByMs)
        return true
      return false
    },

    computedUsers() {
      if (!this.users.length) return []
      const users = this.users
        .filter(user => user.status !== 2)
        .map(e => ({
          userId: e.userId,
          userEmail: e.email,
          userStatus: e.status,
          license: e.license,
          userName: e.name,
          folderPath: '',
          isShowInteractiveProgress: false,
          isAllowInteractiveSubject: false,
          isFolderSecondTimeStamp: false, //on target - two options or time stamp or email
          sendingMethodForNewFilesArrived: 1,
          language: e.language
        }))

      return users
    },
  },
  methods: {
    ...mapActions([
      'ADD_ROUTE',
      'EDIT_ROUTE',
      'GET_ROUTES',
      'GET_LOGGED_USER_ROUTES',
      'GET_SINGLE_ORGANIZATION',
      'GET_POLICIES',
    ]),
    ...mapMutations(['SET_IS_IN_MIDDLE_OF_EDITING', 'SET_PROCESSING']),
    checkRoute() {
      this.isSaveButtonDisabled = true
      this.isTargetSettingsError = false

      const jsonFreezed = JSON.stringify(this.freezedRoute)
      const jsonRoute = JSON.stringify(this.route)

      //if the organization is blocked or archived
      if (this.currentOrganization.status !== 0) {
        this.message =
          "Route's host is either blocked or archived, unable to make changes"
        return
      }

      //if the route changed
      if (jsonFreezed !== jsonRoute) this.SET_IS_IN_MIDDLE_OF_EDITING(true)
      //if the route doesnt changed
      else if (jsonFreezed === jsonRoute) {
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        this.message = ''
        return
      }

      //if the route is archived
      if (this.route.status === 2 && this.freezedRoute.status === 2) {
        this.message = "Can't edit archived route"
        return
      }

      //if there is no path when there is prepend path
      // else if (
      //   this.route.foldersHierarchy.isPath &&
      //   this.route.foldersHierarchy.path === ''
      // ) {
      //   this.isTargetSettingsError = true
      //   this.message = 'Directory structure path is required'
      //   return
      // }

      //when the path is not valid
      // else if (
      //   this.route.foldersHierarchy.isPath &&
      //   !isFolderNameValid(this.route.foldersHierarchy.path)
      // ) {
      //   this.isTargetSettingsError = true
      //   this.message = 'Directory structure path is not valid'
      //   return
      // }

      //if there is no route name
      else if (!this.route.name || !this.route.name.trim()) {
        this.message = 'Route name is required'
        return
      }
      //if the route name is taken
      if (this.isRouteNameUniqe === false) {
        this.message = 'Route with that name already exists'
        return
      }

      //if there is no policy
      else if (!this.route.policy || this.route.policy === ' ') {
        this.message = 'Policy is required'
        return
      }

      //if there are no source end point
      else if (!this.route.sourceEndPoints?.length) {
        this.message = 'At least one source user is required'
        return
      }

      //if there are no target end points and there are no casuals
      else if (!this.route.targetEndPoints?.length) {
        this.message = 'At least one target user is required'
        return
      }

      //need license in all of the end points of the source
      else if (this.route.sourceEndPoints.some(el => el.license === '')) {
        this.message = 'Interfaces for all sources are required'
        return
      }

      //need license in all of the end points of the target
      else if (this.route.targetEndPoints.some(el => el.license === '')) {
        this.message = 'Interfaces for all targets are required'
        return
      }

      //if there is at least one dynamic field with no label
      // else if (true) console.log(this.route)
      else if(this.route.foldersHierarchy.interfaceDynamicFields?.some(el => !el.textToDisplay)){
        console.log(this.route.foldersHierarchy.interfaceDynamicFields)
        this.message = 'All dynamic fields must have label'
        return
      }

      //if the number inserted is not integer
      else if (
        this.fileLifeTime.fileDeleteAfterNumber &&
        this.fileLifeTime.fileDeleteAfterNumber.toString().includes('.')
      ) {
        this.isTargetSettingsError = true
        this.message = 'Delete files after must be integer'
        return
      }

      //if the life time is bigger than one week
      else if (
        this.loggedUser.role !== 'SuperAdmin' &&
        this.freezedRoute.deleteFilesAtByMs !== this.route.deleteFilesAtByMs &&
        this.route.deleteFilesAtByMs > 604830001 // one week + 2 more minutes
      ) {
        this.isTargetSettingsError = true
        this.message = 'The maximum value to delete files after is 7 days'
        return
      }

      //if the life time is less than 10 ms
      else if (
        this.loggedUser.role !== 'SuperAdmin' &&
        this.freezedRoute.deleteFilesAtByMs !== this.route.deleteFilesAtByMs &&
        this.route.deleteFilesAtByMs < 10
      ) {
        this.isTargetSettingsError = true
        this.message = 'The minimum value to delete files after is 1 hour'
        return
      }

      //if the life time is by hours and bigger than 24 hours
      else if (
        this.fileLifeTime.fileDeleteAfterType === 0 &&
        this.route.deleteFilesAtByMs > 86400000
      ) {
        // one day in ms
        this.isTargetSettingsError = true
        this.message = 'The maximum value to delete files after in hours is 24'
        return
      } else if (this.isDigitError) {
        this.isTargetSettingsError = true
        this.message = 'Delete files value is not valid'
        return
      }

      //if the delete is smaller than the alert
      if (this.isDeleteAlertTimesError) {
        this.isTargetSettingsError = true
        this.message =
          '"Delete files after" cannot be bigger than "Alert before deletion"'
        return
      }

      //if the delete is smaller than 0
      if (this.route.alertBeforeDeletionByMs < 0) {
        this.isTargetSettingsError = true
        this.message = '"Alert before deletion" cannot be less than 0'
        return
      }

      this.message = ''
      this.isSaveButtonDisabled = false
      this.isTargetSettingsError = false

      return
    },

    async changestatus() {
      if (this.actionType === 'add') await this.saveRoute()
      else if (this.route.status === this.freezedRoute.status) {
        await this.saveRoute()
      } else {
        let status
        switch (this.route.status) {
          case 0:
            status = 'activate'
            break
          case 1:
            status = 'block'
            break
          case 2:
            status = 'archive'
            break
          default:
            break
        }

        const text = `Are you sure you want to ${status} this route?`

        const thenFunc = async () => await this.saveRoute()

        confirmDialog(this, text, 'Continue', 'No', thenFunc)
      }
    },
    async saveRoute() {
      let newRouteId = null
      try {
        this.SET_PROCESSING(true)
        this.saveButtonClicked = true

        // if the value is less than 0 then make the files delete after unlimited
        if (this.route.deleteFilesAtByMs < 0) {
          this.route.deleteFilesAtByMs = 9999999999999
        }

        //add route
        if (this.actionType === 'add') {
          this.route.organizationId = this.currentOrganization.organizationId

          //add the created by
          if (!this.route.createUpdateDetails)
            this.route.createUpdateDetails = {} //remove when add to emptyroute
          this.route.createUpdateDetails.createdByUserId = this.loggedUser.userId
          this.route.createUpdateDetails.dateCreatedByMs =
            getUTC0MSDateFromLocalDate()

          newRouteId = await this.ADD_ROUTE(this.route)
        }

        //edit route
        else {
          //add the edited by
          this.route.createUpdateDetails.updatedByUserId = this.loggedUser.userId
          this.route.createUpdateDetails.dateUpdatedByMs =
            getUTC0MSDateFromLocalDate()

          await this.EDIT_ROUTE(this.route)
        }
        await this.GET_ROUTES({ id: this.currentOrganization.organizationId })
        await this.GET_LOGGED_USER_ROUTES()
      } catch (error) {
        console.log(error)
        this.saveButtonClicked = false
      } finally {
        this.SET_PROCESSING(false)
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)

        //if the action type is add return to all routes
        if (this.actionType === 'add')
          this.$router.push(
            `/management/hosts/${this.currentOrganization.organizationId}/registered/routes`
          )
        else this.getStartPointOfSingleRoute(newRouteId)
      }
    },

    async getStartPointOfSingleRoute(newRouteId) {
      try {
        this.SET_PROCESSING(true)
        //restart the start page
        Object.keys(startObjRoute).forEach(key => {
          this[key] = JSON.parse(JSON.stringify(startObjRoute))[key]
        })

        const orgId = this.$route.params.organizationId
        let routeId = newRouteId ? newRouteId : this.$route.params.routeId

        //if user came from single user
        if (this.isFromUser) routeId = this.routeId
        if (!this.currentOrganization.organizationId) {
          //do we need this ?
          await this.GET_SINGLE_ORGANIZATION(orgId)
        }

        if (routeId === 'new') {
          this.route = await getEmptyRoute()
          this.route.organizationId = orgId
          this.freezedRoute = JSON.parse(JSON.stringify(this.route))
          this.actionType = 'add'
        } else {
          this.actionType = 'edit'

          let { data, error } = await getSingleRoute(routeId)
          if (error) {
            console.log(error)
          }
          this.route = { ...data }
          //if the delete files after is unlimited
          if (this.route.deleteFilesAtByMs === 9999999999999) {
            this.fileLifeTime.fileDeleteAfterType = 2
            this.fileLifeTime.fileDeleteAfterNumber = null
          }
          //if days
          else if (this.route.deleteFilesAtByMs > 86400000) {
            this.fileLifeTime.fileDeleteAfterType = 1
            this.fileLifeTime.fileDeleteAfterNumber =
              this.route.deleteFilesAtByMs / 86400000
            // if hours
          } else {
            this.fileLifeTime.fileDeleteAfterType = 0
            this.fileLifeTime.fileDeleteAfterNumber =
              this.route.deleteFilesAtByMs / 3600000
          }

          if (this.isFromUser) this.route.status = 4 // if you see the component and see the the route from a user
          this.freezedRoute = JSON.parse(JSON.stringify(this.route))
        }

        await this.GET_POLICIES(this.currentOrganization.organizationId)
      } catch (error) {
        console.log(error)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },

  async mounted() {
    this.getStartPointOfSingleRoute()
  },
  beforeRouteLeave(to, from, next) {
    try {
      if (!this.saveButtonClicked) {
        const jsonFreezed = JSON.stringify(this.freezedRoute)
        const jsonRoute = JSON.stringify(this.route)

        if (jsonFreezed !== jsonRoute && to.name !== 'Home') {
          const text =
            'You have unsaved changes.<br>Are you sure you want to leave this page without saving?'

          const thenFunc = () => {
            this.route = this.freezedRoute
            this.SET_IS_IN_MIDDLE_OF_EDITING(false)
            next()
          }

          const catchFunc = () => this.$emit('changeTab', 'tab-routes')

          confirmDialog(
            this,
            text,
            'Leave Without Saving',
            'Cancel',
            thenFunc,
            catchFunc
          )
        } else {
          //if the user changed the route and quit the system
          next()
        }
      } else {
        // if the user clicked save
        next()
      }
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style scoped src="./SingleRoute.css"></style>
