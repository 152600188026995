<template>
  <div class="theme-settings ma-2">
    <h4 class="settings-h4">Design</h4>
    <div class="wrapper-2 pa-3 mb-4 pos-relative">
      <span v-if="!isGlobal" class="show-in">Show In</span>

      <!--HOST NAME-->
      <HostName
        :organization="organization"
        :status="status"
        :isGlobal="isGlobal"
        :actionType="actionType"
      />

      <!--HEADER LOGO-->
      <HeaderLogo
        :organization="organization"
        :status="status"
        :isGlobal="isGlobal"
        :actionType="actionType"
        :images="images"
      />

      <!--HEADER TEXT-->
      <HeaderText
        :organization="organization"
        :status="status"
        :isGlobal="isGlobal"
      />

      <!--BACKGROUND IMAGE-->
      <BackgroundImage
        :organization="organization"
        :status="status"
        :isGlobal="isGlobal"
        :actionType="actionType"
        :images="images"
      />

      <!--THEME COLOR-->
      <ThemeColor
        :organization="organization"
        :status="status"
        :isGlobal="isGlobal"
      />

      <!--SUFFIX-->
      <Suffix
        v-if="!isGlobal"
        :organization="organization"
        :status="status"
        :actionType="actionType"
      />

      <OnlineHelp
        :page="isGlobal ? 'globalSettings' : 'settings'"
        section="design"
        title="Design"
      />
    </div>
  </div>
</template>

<script>
//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

//Childrens
import HostName from './Childrens/HostName/HostName.vue'
import HeaderLogo from './Childrens/HeaderLogo/HeaderLogo.vue'
import HeaderText from './Childrens/HeaderText/HeaderText.vue'
import BackgroundImage from './Childrens/BackgroundImage/BackgroundImage.vue'
import ThemeColor from './Childrens/ThemeColor/ThemeColor.vue'
import Suffix from './Childrens/Suffix/Suffix.vue'

export default {
  name: 'ThemeSettings',
  props: {
    isGlobal: Boolean,
    status: Number,
    organization: Object,
    actionType: String,
    images: Object,
  },

  components: {
    HostName,
    HeaderLogo,
    HeaderText,
    BackgroundImage,
    ThemeColor,
    Suffix,
    OnlineHelp,
  },
}
</script>

<style scoped src="./ThemeSettings.css"></style>
<style src="./ThemeSettingsUnScoped.css"></style>
