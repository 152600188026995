<template>
  <div
    class="d-flex align-center bg-grey pa-1"
    :class="[isShowUploadingDetails ? 'title-border' : 'wrapper-1']"
    tabindex="0"
  >
    <v-icon
      @click="toggleIsShowUpDetails"
      v-show="session.filesData.length"
      class="pointer"
      large
      >mdi-chevron-{{ isShowUploadingDetails ? 'down' : 'right' }}</v-icon
    >
    <h3 class="font-weight-medium fs-1-5r">
      <!-- {{ $t('Details') }} -->
      &nbsp
    </h3>
  </div>
</template>

<script>
export default {
  name: 'UploadingDetailsHeader',
  props: {
    isShowUploadingDetails: Boolean,
    toggleIsShowUpDetails: Function,
    session: Object,
  },
}
</script>
