<template>
  <div>
    <div class="d-flex justify-end"  :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']">
      <Button
        btnType="grey"
        :text="$t('Cancel')"
        class="mx-2 mt-2"
        :clickAction="() => $router.push(cameFrom)"
      />
    </div>

    <div
      class="wrapper-2 d-flex align-center justify-space-between flex-wrap pa-2 ma-2 fs-1-12r"
      tabindex="0"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    >
      <div>
        <div class="my-2">
          <b class="mx-1">{{ $t('Name') }}:</b>
          <span class="mx-1">{{ loggedUser.name }}</span>
        </div>

        <div class="my-2">
          <b class="mx-1">{{ $t('Email') }}:</b>
          <span class="mx-1">{{ loggedUser.email }}</span>
        </div>

        <div v-if="loggedUser.phoneNumber" class="my-2">
          <b class="mx-1">{{ $t('Mobile') }}:</b>
          <span class="mx-1">
            {{
              `${language === 'he' ? '' : '+'}${loggedUser.phoneNumber.replace('+', '')}${language === 'he' ? '+' : ''}`
            }}</span
          >
        </div>

        <!-- <div class="d-flex align-center mt-4">
          <b class="mx-1 mt-2">{{ $t('Language') }}:</b>

          <v-select
            v-model="language"
            :items="langs"
            @change="langChanged"
            :label="$t('Language')"
            item-text="label"
            item-value="value"
            dense
            hide-details
            style="max-width: 140px !important"
            class="pa-0 mx-1"
          >
          </v-select>
        </div> -->
      </div>

      <Button
        v-if="loggedUser.role !== 'Individual'"
        class="ma-4"
        width="fit-content"
        btnType="grey"
        :text="$t('Request Reset Password')"
        :clickAction="requestResetPassword"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { confirmDialog } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import { selectedHost } from '@/config'

export default {
  name: 'PersonalProfile',
  data() {
    return {
      langs: [
        { label: 'English', value: 'en' },
        { label: 'עברית', value: 'he' },
        { label: '‪中文(台灣)‬', value: 'zh' },
      ],
    }
  },
  props: {
    cameFrom: String,
  },
  components: { Button },
  computed: {
    ...mapGetters(['loggedUser', 'language']),
  },
  methods: {
    // langChanged() {
    //   localStorage.setItem('dot-engines-language', n)
    //   this.SET_LANGUAGE(this.language)
    //   this.$i18n.i18next.changeLanguage(this.language)
    // },
    requestResetPassword() {
      const email = this.loggedUser.email
      const text = this.$t(
        'By pressing on Continue, you start the procedure to reset your password. Check your email inbox for more details'
      )

      const thenFunc = async () => {
        try {
          const token = sessionStorage.getItem('token')
          const body = JSON.stringify(email)

          const res = await fetch(
            selectedHost + '/api/auth/SendResetPasswordMail',
            {
              method: 'POST',
              body: body,
              headers: {
                'content-type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
            }
          )
          return res.status
        } catch (error) {
          console.log(error)
          return error
        }
      }
      confirmDialog(
        this,
        text,
        this.$t('Continue'),
        this.$t('Cancel'),
        thenFunc
      )
    },
  },
  async created() {
    // this.language = this.loggedUser.language || 'en'
    // const storgedLanguage = localStorage.getItem('dot-engines-language')
    // if (storgedLanguage) {
    //   this.language = storgedLanguage
    // }
  },
}
</script>
