<template>
  <div class="fill-height" ref="activityCard">
    <div v-if="idx" class="d-flex justify-space-between">
      <!--TITLE-->
      <h2>{{ idx }}. Activity</h2>

      <!--EXPAND-->
      <v-icon @click="expandClicked('Activity')">mdi-arrow-expand</v-icon>
    </div>

    <div style="height: calc(100% - 0px)">
      <DataOfCPU
        :computerData="computerData"
        style="height: calc(50% - 16px)"
        :isBigWindow="isBigWindow"
      />
      <DataOfRam
        :computerData="computerData"
        style="height: calc(50% - 16px)"
        :isBigWindow="isBigWindow"
      />
    </div>
  </div>
</template>

<script>
import { selectedHost } from '@/config'
import { mapGetters } from 'vuex'
import DataOfCPU from './Children/DataOfCPU/DataOfCPU.vue'
import DataOfRam from './Children/DataOfRam/DataOfRam.vue'

const fakeData = {
  cpuPercentage: 14, //%
  usedRam: 12314, //bytes
  totalRam: 12311232, //bytes
  storage: {
    total: 1232423536, //bytes
    used: 123576543, //bytes
    hosts: [
      {
        name: 'Ddos',
        used: 234342, //bytes
      },
      {
        name: 'test',
        used: 23344342, //bytes
      },
    ],
  },
  network: {
    received: 1234243, //bytes
    sent: 34234, //bytes
  },

  //MAYBE
  antivirus: [
    {
      name: 'eset',
      licenceEnd: 123456342134, //ms
    },
  ],
}

const copyOfData = {
  CpuPercentage: 1,
  UsedRam: 6900072448,
  TotalRam: 8589328384,
  storage: {
    Total: 249, //?
    Used: 148, //?
    Organizations: [
      { Name: 'dotEngines', Used: 0 },
      { Name: 'D and A', Used: 0 },
      { Name: 'test1', Used: 0 },
      { Name: 'sdfsdfsd', Used: 0 },
      { Name: 'dfdsfsd', Used: 0 },
      { Name: 'EAT', Used: 0 },
      { Name: 'transaction', Used: 0 },
      { Name: 'ASDASD', Used: 0 },
      { Name: 'Butterfly', Used: 0 },
      { Name: 'Lion Investments', Used: 0 },
      { Name: 'Ddos', Used: 0 },
      { Name: 'xcvxcvcvxcvxxv', Used: 0 },
      { Name: 'rabbitit', Used: 0 },
      { Name: 'rabitot', Used: 0 },
      { Name: 'fsdfsdfsdf', Used: 0 },
      { Name: 'asdasdasdas', Used: 0 },
      { Name: 'sdfsdvscvsdf', Used: 0 },
      { Name: 'bjbjfnfkjnd', Used: 0 },
      { Name: 'gfgd', Used: 0 },
      { Name: 'ccccvcvc v', Used: 0 },
      { Name: 'cxbdfbdf', Used: 0 },
      { Name: 'SomeName', Used: 0 },
      { Name: 'SomeNewOrg', Used: 0 },
      { Name: 'some host', Used: 0 },
    ],
  },
  Network: { Received: 0, Sent: 0 },
}

export default {
  name: 'Activity',
  data() {
    return {
      eventSource: null,
      computerData: null,
      componentHeight: 0,
    }
  },
  props: {
    idx: Number,
    expandClicked: Function,
  },
  components: { DataOfCPU, DataOfRam },
  computed: {
    ...mapGetters(['loggedUser']),
    isBigWindow() {
      if (this.componentHeight < 300) return false
      return true
    },
  },

  async created() {
    this.computerData = fakeData
    const vue = this
    // return
    //create the event source
    this.eventSource = new EventSource(
      `${selectedHost}/api/statistics/performance?bearer=${this.loggedUser.token}`
    )
    //when connection is open
    this.eventSource.addEventListener('open', function (event) {
      console.log('Connection to server opened.')
    })
    //get data ("message") from the server
    this.eventSource.addEventListener('message', function (event) {
      vue.computerData = JSON.parse(event.data)
    })
    this.eventSource.onmessage = function (event) {
      vue.computerData = JSON.parse(event.data)
    }
    //on error
    this.eventSource.addEventListener('error', function (event) {
      if (event.readyState === EventSource.CLOSED) {
        console.log('Connection to server closed.')
      } else {
        console.error('Error occurred:', event)
      }
    })
  },

  mounted() {
    this.componentHeight = this.$refs.activityCard.clientHeight
  },
  updated() {
    const newHeight = this.$refs.activityCard.clientHeight
    if (newHeight !== this.componentHeight) {
      this.componentHeight = newHeight
    }
  },
  beforeDestroy() {
    this.eventSource.close()
  },
}
</script>
