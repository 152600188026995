<template>
  <div
    class="d-flex table-actions-wrapper"
    :class="[accessibility.fontSize > 1 && 'w-130']"
  >
    <!--SHOW / HIDE SEARCH-->
    <div class="pos-relative">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            @click="isShowSearch = !isShowSearch"
            v-on="on"
            class="mx-1"
            :large="accessibility.fontSize > 1"
            aria-label="Search in directory"
            >mdi-magnify</v-icon
          >
        </template>
        <span>{{ isShowSearch ? $t('Close search') : $t('Open search') }}</span>
      </v-tooltip>

      <!--SEARCH IN FOLDER-->
      <div
        v-if="isShowSearch"
        class="wrapper-1 pa-1 bg-white text-search-wrapper wrapper-hard"
        :class="[language === 'he' && 'text-search-wrapper-he']"
      >
        <v-text-field
          @input="searchDirChanged"
          hide-details
          autofocus
          :placeholder="$t('Directory search')"
          class="ma-0 pa-1"
        >
          <template v-slot:append>
            <v-icon
              @click="searchDirChanged(null)"
              aria-label="Cancel search"
              tabindex="0"
            >
              mdi-close
            </v-icon>
          </template>
        </v-text-field>
      </div>
    </div>

    <!--SHOW ALL FILES-->
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon
          @click="toggleShowOnlyFiles"
          v-on="on"
          class="mx-1"
          :aria-label="isShowOnlyFiles ? 'Directory view' : 'Show all files'"
          tabindex="0"
          :large="accessibility.fontSize > 1"
          >mdi-{{ isShowOnlyFiles ? 'folder' : 'file' }}-multiple</v-icon
        >
      </template>
      <span>{{
        isShowOnlyFiles ? $t('Directory view') : $t('Show all files')
      }}</span>
    </v-tooltip>

    <!--REFRESH-->
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon
          @click="refreshShownDir"
          v-on="on"
          class="mx-1"
          aria-label="Refresh directory"
          tabindex="0"
          :large="accessibility.fontSize > 1"
          >mdi-refresh</v-icon
        >
      </template>
      <span>{{ $t('Refresh') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TableActions',
  data() {
    return {
      isShowSearch: false,
      isShowOnlyFiles: false,
      lastDirectoryBeforeShowOnlyFilesPath: '',
    }
  },
  props: {
    setDirSearch: Function,
    changeShownDirClicked: Function,
    shownDirectory: Object,
  },
  watch: {
    shownDirectory: {
      handler: function (n) {
        this.searchDirChanged(null)

        //if the user was on all files and the new shown directory is not all file
        if (this.isShowOnlyFiles && n.name !== 'All files') {
          this.lastDirectoryBeforeShowOnlyFilesPath = null
          this.isShowOnlyFiles = false
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['accessibility', 'language']),
  },
  methods: {
    searchDirChanged(v) {
      if (v === null) this.isShowSearch = false
      this.setDirSearch(v)
    },
    toggleShowOnlyFiles() {
      //switch between showing only files and the folders hierarchy
      this.isShowOnlyFiles = !this.isShowOnlyFiles

      //the user ask to show him all files
      if (this.isShowOnlyFiles) {
        //store where the user was
        this.lastDirectoryBeforeShowOnlyFilesPath = [
          ...this.shownDirectory.path,
        ]
        return this.changeShownDirClicked(null, 'allFiles')
      }

      //show the user the last place that he was in
      return this.changeShownDirClicked(
        this.lastDirectoryBeforeShowOnlyFilesPath,
        'dirTree'
      )
    },
    refreshShownDir() {
      //if the user see only files then refresh the only files
      if (this.isShowOnlyFiles) {
        this.changeShownDirClicked(null, 'allFiles')
      }

      //if the user see directrory refresh and get the shown directory
      else
        this.changeShownDirClicked(
          this.lastDirectoryBeforeShowOnlyFilesPath,
          'navigation'
        )
    },
  },
}
</script>

<style scoped src="./TableActions.css"></style>
