var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"wrapper-1 bg-white",attrs:{"width":"500","content-class":_vm.isDarkMode ? 'dark-mode' : ''},on:{"click:outside":_vm.closeShownSession},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"wrapper-1"},[_c('v-card-title',{staticClass:"bg-grey fs-1-5r"},[_c('span',{staticClass:"ma-auto",attrs:{"tabindex":"0"}},[_vm._v(_vm._s(_vm.$t('Transference Information')))])]),_c('v-card-text',{staticClass:"pa-1 session-info-report-wrapper pa-2 fs-1-25r",class:[
        _vm.accessibility.isStrongColors && 'strong-colors',
        _vm.language === 'he' ? 'dir-rtl' : 'dir-ltr',
      ],attrs:{"tabindex":"0"}},[_c('div',{staticClass:"fill-height d-flex flex-column overflow-auto"},[(_vm.shownSession.startTime)?_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Started'))+":")]),_c('div',{staticClass:"dir-ltr mr-1 d-inline"},[_vm._v(" "+_vm._s(_vm.shownSession.startTime)+" ")])]):_vm._e(),(_vm.shownSession.endTime)?_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Ended'))+":")]),_c('div',{staticClass:"dir-ltr mr-1 d-inline"},[_vm._v(" "+_vm._s(_vm.shownSession.endTime)+" ")])]):_vm._e(),(_vm.shownSession.duration)?_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Duration'))+":")]),_c('div',{staticClass:"dir-ltr mr-1 d-inline"},[_vm._v(" "+_vm._s(_vm._f("parseTime")(_vm.shownSession.duration))+" (mm:ss) ")])]):_vm._e(),_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Size'))+":")]),_c('div',{staticClass:"dir-ltr mr-1 d-inline"},[_vm._v(" "+_vm._s(_vm._f("bytesToSize")(_vm.shownSession.size))+" ")])]),(_vm.shownSession.transferenceFlow === 'RegisteredToRegistered')?_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Route'))+":")]),_vm._v(" "+_vm._s(_vm.shownSession.route))]):_vm._e(),_vm._l((_vm.shownSession.interfaceDynamicFields.filter(
            el => el.value
          )),function(item){return _c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t(item.textToDisplay))+":")]),_vm._v(" "+_vm._s(item.value))])}),(_vm.shownSession.senderEmail)?_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Sender'))+":")]),_vm._v(" "+_vm._s(_vm.shownSession.senderEmail))]):_vm._e(),(_vm.isShowSentToRegistered)?_c('div',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Sent to registered accounts'))+":")]),_c('ul',_vm._l((_vm.sentToRegistered),function(recipient){return _c('li',{domProps:{"innerHTML":_vm._s(recipient)}})}),0)]):_vm._e(),(_vm.isShowSentToCasuals)?_c('div',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Sent to guests'))+":")]),_c('ul',_vm._l((_vm.sentToCasual),function(recipient){return _c('li',{domProps:{"innerHTML":_vm._s(recipient)}})}),0)]):_vm._e(),(_vm.isShowSentToCasuals)?_c('div',{staticClass:"d-flex justify-end"},[_c('Button',{staticClass:"my-2",attrs:{"text":_vm.$t('Delete Guest Files'),"btnType":"grey","clickAction":_vm.deleteCasualFilesClicked}})],1):_vm._e(),(_vm.shownSession.reportSummary && _vm.loggedUser.isAllowReport)?[_c('v-divider',{staticClass:"my-1"}),_c('div',{staticClass:"my-2 d-flex justify-space-between"},[_c('div',[_c('div',{staticClass:"mb-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('Transference Results'))+" ")]),_c('Summary',{attrs:{"statusResults":_vm.shownSession.reportSummary}})],1),(false)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('Button',{staticClass:"my-1",attrs:{"text":_vm.isShowFullReport
                    ? _vm.$t('Hide Filtering Report')
                    : _vm.$t('Show Filtering Report'),"width":"200","btnType":"grey","clickAction":_vm.fullReportClicked,"btnSize":"small","height":"25"}}),_c('Button',{staticClass:"my-1",attrs:{"text":_vm.$t('Download Filtering Report'),"width":"200","btnType":"grey","height":"25","btnSize":"small","clickAction":_vm.downloadReportClicked}})],1):_vm._e()])]:_vm._e(),(_vm.isShowFullReport)?_c('div',{staticClass:"wrapper-2 ma-2",staticStyle:{"max-height":"600px","overflow":"auto","direction":"ltr"},domProps:{"innerHTML":_vm._s(_vm.fullReport)}}):_vm._e()],2)]),_c('v-divider',{staticClass:"my-1"}),_c('div',{staticClass:"d-flex pa-2 justify-end fill-width"},[_c('Button',{staticClass:"mx-2",attrs:{"clickAction":_vm.closeShownSession,"text":_vm.$t('Close'),"btnType":"grey","width":"100"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }