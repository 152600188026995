<template>
  <div class="" style="height: calc(100%); padding: 64px 0px 4px 4px">
    <div
      :class="computedGridClass"
      class="mt-2"
      style="height: calc(100% - 16px); width: calc(100% - 8px)"
    >
      <div
        v-for="(profile, k, i) in shownProfile.order"
        v-if="isShowGrid(i)"
        :key="`${k}${i}`"
        class="grid-item wrapper-2"
        :class="[shownProfile.mode === '1x6' && 'mh-400']"
      >
        <component
          :is="profile"
          :analyticsData="analyticsData"
          :idx="i + 1"
          :expandClicked="expandClicked"
        />
      </div>
    </div>

    <!--DIALOG FOR EXPAND ITEM-->
    <ExpandItemDialog
      v-if="expandedItem"
      :expandedItem="expandedItem"
      :analyticsData="analyticsData"
      :closeClicked="() => (expandedItem = null)"
      :searchDatesString="searchDatesString"
    />
  </div>
</template>

<script>
import Alerts from './Children/Alerts/Alerts.vue'
import FilteringSummary from './Children/FilteringSummary/FilteringSummary.vue'
import Routes from './Children/Routes/Routes.vue'
import Transference from './Children/Transference/Transference.vue'
import Users from './Children/Users/Users.vue'
import FileTypes from './Children/FileTypes/FileTypes.vue'
import ExpandItemDialog from './Children/ExpandItemDialog/ExpandItemDialog.vue'
import Activity from './Children/Activity/Activity.vue'
import Storage from './Children/Storage/Storage.vue'

export default {
  name: 'AnalyticsCards',
  data() {
    return {
      expandedItem: null,
    }
  },
  props: {
    shownProfile: Object,
    analyticsData: Object,
    searchDatesString: String,
  },
  computed: {
    computedGridClass() {
      return `grid-${this.shownProfile.mode}`
    },
  },
  methods: {
    isShowGrid(idx) {
      if (idx === 0) return true
      const { mode } = this.shownProfile

      const conditions = [
        [1, ['1x1']],
        [2, ['1x1', '2x1']],
        [3, ['1x1', '2x1', '3x1']],
        [4, ['1x1', '2x1', '3x1', '2x2']],
        [5, ['1x1', '2x1', '3x1', '2x2']],
      ]

      const invalidModes = conditions[idx - 1][1]

      return !invalidModes.includes(mode)
    },

    expandClicked(name) {
      this.expandedItem = name
    },
  },

  components: {
    Alerts,
    FilteringSummary,
    FileTypes,
    Routes,
    Transference,
    Users,
    ExpandItemDialog,
    Activity,
    Storage,
  },
}
</script>
