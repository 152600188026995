export const themeSettingsKeys = [
  'backgroundInterface',
  'headerTextInterface',
  'headerTextValue',
  'logoInterface',
  'nameInterface',
  'suffix',
  'themeColor',
]

export const emailDefinitionsSettingsKeys = [
  'emailProtocol',
  'displaySenderName',
]

export const msGraphEmailSettingsKeys = [
  'clientId',
  'tenantId',
  'clientSecret',
  'senderEmail',
]

export const smtpEmailSettingsKeys = [
  'server',
  'senderEmail',
  'port',
  'login',
  'password',
  'encrypt',
]

export const SMSSettingsKeys = [
  'accountSid',
  'apiKey',
  'authToken',
  'profileId',
  'sender',
  'smsVendorEnum',
]
