<template>
  <!-- THE ORGANIZATION INFORMATION IF THE SUPERADMIN GET HERE FROM ALL THE USERS -->
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Account</h4>
    <div class="wrapper-2 pa-2 pos-relative">
      <div class="d-flex mb-4">
        <v-select
          @change="$emit('changeAccountType', $event)"
          :items="accountTypeRoles"
          :value="accountType"
          :disabled="isFieldDisabled || actionType === 'edit'"
          label="Account Category"
          class="mr-4 mw-160"
          :class="[actionType === 'add' && 'required']"
          hide-details
        ></v-select>

        <!-- THIS IS ONLY SHOWN IF ADMIN CHOOSED MANAGMENT -->
        <PersonalManagement
          v-if="accountType === 'Management'"
          :profile="profile"
          :isFieldDisabled="isFieldDisabled"
        />

        <!-- THIS IS SHOWN WHEN THE SUPER ADMIN COME TO COMPONENT FROM ALL THE USERS -->
        <AccountOrganization
          v-if="isShowAccountOrganization"
          :profile="profile"
          :actionType="actionType"
          :isFieldDisabled="isFieldDisabled"
        />
      </div>

      <!--LICENSES -->
      <Licenses
        :accountType="accountType"
        :profile="profile"
        :freezedProfile="freezedProfile"
        :actionType="actionType"
      />

      <!--REPORT LEVEL-->
      <ReportSection
        v-if="isShowAddReport"
        :isFieldDisabled="isFieldDisabled"
        :profile="profile"
      />

      <!--LANGUAGE AND CASUAL SETTINGS -->
      <LanguageAndCasualSettings
        :actionType="actionType"
        :profile="profile"
        :isFieldDisabled="isFieldDisabled"
      />

      <OnlineHelp page="singleUser" section="account" title="Account" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

//Childrens
import PersonalManagement from './Children/PersonalManagement/PersonalManagement.vue'
import AccountOrganization from './Children/AccountOrganization/AccountOrganization.vue'
import Licenses from './Children/Licenses/Licenses.vue'
import LanguageAndCasualSettings from './Children/LanguageAndCasualSettings/LanguageAndCasualSettings.vue'
import ReportSection from './Children/ReportSection/ReportSection.vue'

export default {
  name: 'Personal',

  props: {
    actionType: String,
    isFromUsers: Boolean,
    accountType: String,
    profile: Object,
    freezedProfile: Object,
    isFieldDisabled: Boolean,
  },
  components: {
    PersonalManagement,
    AccountOrganization,
    Licenses,
    LanguageAndCasualSettings,
    OnlineHelp,
    ReportSection,
  },

  watch: {
    'profile.role': function (n, o) {
      // if (o) {
      //   this.profile.license = ''
      // }
      if (n === 'Individual' || n === 'SuperAdmin') {
        this.profile.license = 'Website'
      }
    },
  },
  computed: {
    ...mapGetters(['loggedUser']),
    isShowAddReport() {
      if (this.profile.role === 'SuperAdmin') return false
      else if (this.profile.role === 'Individual') return false
      else if (this.profile.license === 'API') return false
      return true
    },

    isShowAccountOrganization() {
      if (!this.isFromUsers) return false
      else if (this.accountType === 'Individual') false
      else if (this.accountType === 'SuperAdmin') false
      return true
    },
    accountTypeRoles() {
      const roles = [
        {
          text: 'Transfer',
          value: 'Transfer',
        },
        {
          text: 'Management',
          value: 'Management',
        },
      ]
      if (
        this.loggedUser.role === 'SuperAdmin' &&
        this.isFromUsers &&
        this.profile.organizationId === 0
      ) {
        roles.push({
          text: 'Individual',
          value: 'Individual',
        })
      }

      return roles
    },
  },
}
</script>
