<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeDialog"
    width="auto !important"
    class="wrapper-1 bg-white pos-relative"
  >
    <v-card class="wrapper-1">
      <v-card-title class="text-center bg-grey mb-2">
        SFTP Access Details
      </v-card-title>
      
      <v-card-text class="px-2 pb-1"> Protocol: SFTP </v-card-text>
      <v-card-text class="px-2 pb-1"> Host: {{ computedHost }} </v-card-text>
      <v-card-text class="px-2 pb-1"> Port: 22 </v-card-text>
      <v-card-text class="px-2 pb-1">
        Username: {{ userEmail + ',guests ' + groupName }}
      </v-card-text>
      <v-card-text class="px-2 pb-1">
        Password: Your dotEngines password
      </v-card-text>
      <div class="d-flex justify-space-between pa-2">
        <Button
          :clickAction="closeDialog"
          width="fit-content"
          btnType="grey"
          text="Cancel"
          class="mx-2"
        />
        <Button
          :clickAction="sendEmailClicked"
          width="fit-content"
          btnType="blue"
          class="mx-2"
          text="Send Email"
        />
      </div>
    </v-card>
    <InsertEmailDialogForCasual
      v-if="isShowInsertDialog"
      :closeDialog="() => (isShowInsertDialog = false)"
      :sftpUsername="userEmail + ',guests ' + groupName"
      :isTarget="false"
      :groupName="groupName"
    />
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import InsertEmailDialogForCasual from '@/components/BaseComponents/CasualCommonComponents/InsertEmailDialogForCasual.vue'

export default {
  name: 'SftpDetailsDialog',
  data() {
    return {
      dialog: true,
      isShowInsertDialog: false,
    }
  },
  props: {
    groupName: String,
    groupMembers: Array,
    userEmail: String,
    closeDialog: Function,
  },
  components: { Button, InsertEmailDialogForCasual },

  computed: {
    computedHost() {
      return window.location.host
    },
  },
  methods: {
    sendEmailClicked() {
      this.isShowInsertDialog = true
    },
  },
}
</script>

<style scoped>
.select-senders {
  padding: 0px 8px 7px 0px;
  display: flex;
  align-items: center;
}
</style>
