<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeDynamicFieldsDialog"
    :content-class="isDarkMode ? 'dark-mode' : ''"
    width="500"
  >
    <div
      class="wrapper-1 bg-white"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    >
      <!--TITLE-SUBJECT-->
      <v-card-title class="text-h5 bg-grey title-border" v-for="item of interfaceDynamicFields.filter(el => el.textToDisplay != 'Static Path')">
        <b>{{item.textToDisplay}}:</b>
        <span class="mx-2 word-break">{{
          item.value
        }}</span>
      </v-card-title>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SubjectMessageDialog',
  data() {
    return {
      dialog: true,
    }
  },
  props: {
    closeDynamicFieldsDialog: Function,
    interfaceDynamicFields: Array,
  },
  components: { Button },

  computed: {
    ...mapGetters(['isDarkMode', 'language']),
  },
}
</script>