<template>
  <div class="wrapper-1 my-2 pa-2 h-50 d-flex align-center fs-1-12r">
    <!--DIRETORY NAME-->
    <div class="d-flex align-center">
      <img src="@/assets/folder.svg" class="mx-2 h-1-5r" />
      <span class="font-weight-bold mx-2">
        {{
          shownDirectory.name === 'All files'
            ? $t('All files')
            : shownDirectory.name
        }}
      </span>
      <span v-if="shownDirectory?.serverName?.includes('Ⓢ')" class="fs-0-75r"
        >(UTC)</span
      >
    </div>
    <v-divider vertical class="mx-2"></v-divider>

    <!--TOTAL ITEMS-->
    <span>{{ $t('Items') }}: {{ computedItems }}</span>

    <div v-if="shownDirectory.name !== 'All files'" class="d-flex">
      <v-divider vertical class="mx-2"></v-divider>

      <!--SIZE-->
      <span>
        {{ $t('Size') }}:
        {{ shownDirectory.size | bytesToSize }}
      </span>
    </div>

    <!--SESSION INFORMATION-->
    <div v-if="shownDirectory.session && computedItems">
      <v-divider vertical class="mx-2"></v-divider>
      <Button
        :clickAction="() => (isShowSessionInfo = !isShowSessionInfo)"
        height="32px"
        width="120"
        :text="$t('Session Info')"
        btnType="grey"
      />

      <!--SESSION INFO DIALOG-->
      <SessionInfo
        v-if="isShowSessionInfo"
        :closeSessionInfo="() => (isShowSessionInfo = false)"
        :shownDirectory="shownDirectory"
      />
    </div>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Children
import SessionInfo from './Children/SessionInfo/SessionInfo.vue'

export default {
  name: 'DirectoryData',
  data() {
    return {
      isShowSessionInfo: false,
    }
  },
  props: {
    shownDirectory: Object,
  },
  filters: {
    bytesToSize,
  },
  components: { Button, SessionInfo },
  computed: {
    computedItems() {
      if (this.shownDirectory?.children)
        return this.shownDirectory.children.length
      else return '0'
    },
  },
}
</script>
