<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Login Block Options</h4>
    <div class="wrapper-2 pa-2 pos-relative">
      <span
        :class="[organization.status !== 0 && 'low-op-disabeld']"
        class="d-flex align-center mx-1 fs-1-12r"
        >After

        <v-text-field
          v-model.number="organization.loginDefinitions.numOfFailedLogins"
          style="max-width: 30px"
          class="mx-1 mb-1 centered-input"
          dense
          height="20px"
          hide-details
          :disabled="organization.status !== 0"
          type="number"
        >
        </v-text-field>
        failed login tries, the user will be blocked for

        <v-text-field
          v-model.number="organization.loginDefinitions.minutesToBlock"
          style="max-width: 30px"
          class="mx-1 mb-1 centered-input"
          :disabled="organization.status !== 0"
          dense
          height="20px"
          hide-details
          type="number"
        >
        </v-text-field>
        minutes.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginFailure',
  props: {
    organization: Object,
  },
}
</script>
