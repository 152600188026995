<template>
  <div :class="logsTableClasses">
    <Table
      :items-per-page="-1"
      :headers="logsHeaders"
      :items="logs"
      :options="{ sortBy: ['shownDate'] }"
      :hideDefaultFooter="true"
      :addToMarkedLinesArr="addToMarkedLinesArr"
      :markedLinesArr="markedLinesArr"
      :removeFromMarkedLinesArr="removeFromMarkedLinesArr"
      :setOpId="v => (opId = v)"
      :setFileGuid="v => (fileGuid = v)"
      type="logs table"
    />

    <p v-if="isShowNoLogsMessage" class="fs-1r no-logs-message">
      No logs where found.
    </p>

    <!--DIALOG FOR SESSION DATA-->
    <SessionDataDialog
      v-if="opId"
      :opId="opId"
      :closeSessionDialog="() => (opId = null)"
    />

    <!--DIALOG FOR FILE DATA-->
    <FileDataDialog
      v-if="fileGuid"
      :fileGuid="fileGuid"
      :closeFilesDialog="() => (fileGuid = null)"
    />
  </div>
</template>

<script>
import Table from '@/components/BaseComponents/Table/Table.vue'

import {
  generateLocalDate,
  generateUTCDateDavid,
  generateLocalDateFromUTC0David,
} from '@/utils'
import { mapGetters } from 'vuex'
import FileDataDialog from './Children/FileDataDialog/FileDataDialog.vue'
import SessionDataDialog from './Children/SessionDataDialog/SessionDataDialog.vue'

export default {
  name: 'LogsTable',
  data() {
    return {
      opId: null,
      fileGuid: null,
    }
  },
  props: {
    logs: Array,
    search: Object,
    logsHeaders: Array,
    logsSettings: Object,
    isShowAdvancedSettings: Boolean,
    addToMarkedLinesArr: Function,
    removeFromMarkedLinesArr: Function,
    markedLinesArr: Array,
    page: Number,
  },
  filters: {
    generateLocalDate,
    generateUTCDateDavid,
    generateLocalDateFromUTC0David,
  },
  components: { Table, SessionDataDialog, FileDataDialog },
  computed: {
    ...mapGetters(['loggedUser']),
    logsTableClasses() {
      if (this.loggedUser.role === 'SuperAdmin') return 'logs-table-super'
      return 'logs-table'
    },
    isShowNoLogsMessage() {
      if (this.logs.length) return false
      if (this.page === 0) return false
      return true
    },
  },
}
</script>

<style scoped src="./LogsTable.css"></style>
