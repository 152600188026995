export const routeHeaders = [
  {
    text: 'Name',
    value: 'name',
    width: '25%',
    class: 'bg-table-heading table-header-first',
  },
  {
    text: 'Status',
    value: 'status',
    align: 'center',
    width: '25%',
    class: 'bg-table-heading',
  },
  {
    text: 'S/N',
    value: 'fakeID',
    align: 'center',
    width: '25%',
    class: 'bg-table-heading',
  },

  {
    text: '',
    value: 'mdi-pencil-circle-outline',
    icon: 'mdi-pencil-circle-outline',
    align: 'center',
    sortable: false,
    width: '25%',
    class: 'bg-table-heading table-header-last',
  },
]

export const singleUserRoutes = [
  {
    text: 'Name',
    value: 'name',
    class: 'bg-table-heading table-header-first',
    width: '25%',
  },
  {
    text: 'Status',
    value: 'routeStatus',
    class: 'bg-table-heading ',
    width: '25%',
  },
  {
    text: 'Source/Target',
    value: 'sourceOrTarget',
    width: '25%',
    class: 'bg-table-heading ',
  },
  {
    text: 'S/N',
    value: 'id',
    class: 'bg-table-heading table-header-last',
    width: '25%',
  },
]
