<template>
  <div class="pa-2">
    <h4 class="settings-h4">Filtering Policy</h4>
    <div class="wrapper-2 d-flex pa-4 mb-4">
      <input
        style="display: none"
        type="file"
        ref="fileInput"
        dense
        accept=".polx"
        @change="e => importPolicy(e)"
      />
      <Button
        width="fit-content"
        class="mr-4"
        text="Import Filtering Policy"
        :clickAction="clickFileInput"
        :disabled="organization.status !== 0"
      />
      <div class="d-flex align-center fs-1-12r">
        <h4 class="mr-2">Host policies:</h4>

        <span v-for="policy in policies" class="mr-2"
          >&#9679; {{ policy }}</span
        >
        <span v-if="!policies.length">(No Policies)</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Button from '@/components/BaseComponents/Button/Button.vue'
import { addPolicy } from '@/actions/policies'

export default {
  name: 'AddPolicy',
  props: { organization: Object },
  components: { Button },
  computed: {
    ...mapGetters(['policies']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    ...mapActions(['GET_POLICIES']),
    clickFileInput() {
      this.$refs.fileInput.click()
    },
    async importPolicy(e) {
      try {
        this.SET_PROCESSING(true)
        if (!e.target.files[0].name.includes('.polx')) {
          throw new Error("Only files with '.polx' extension are allowed.")
        }
        const res = await addPolicy(
          this.organization.organizationId,
          e.target.files[0]
        )
        if (res.status === 200) {
          this.SET_NOTIFICATION({
            type: 'success',
            text: 'Policy file was uploaded successfully.',
          })
        } else {
          throw new Error(res.data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.SET_PROCESSING(false)
        await this.GET_POLICIES(this.organization.organizationId)
      }
    },
  },
}
</script>
