var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-1 uploading-details ma-2 bg-white",class:[
    _vm.isShowUploadingDetails
      ? 'uploading-details-open'
      : 'uploading-details-close',

    _vm.isShowUploadingDetails && _vm.session.filesData.length && 'wrapper-hard',
  ]},[_c('UploadingDetailsHeader',{attrs:{"isShowUploadingDetails":_vm.isShowUploadingDetails,"toggleIsShowUpDetails":v => (_vm.isShowUploadingDetails = !_vm.isShowUploadingDetails),"session":_vm.session}}),_c('Transition',{attrs:{"name":"hide"}},[_c('v-tabs',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowUploadingDetails),expression:"isShowUploadingDetails"}],staticClass:"px-2 pb-2",model:{value:(_vm.activeTab.tab),callback:function ($$v) {_vm.$set(_vm.activeTab, "tab", $$v)},expression:"activeTab.tab"}},[(_vm.isShowTabs)?_c('div',{staticClass:"mx-auto d-flex"},[_c('v-tab',{attrs:{"href":"#regular"}},[_vm._v(_vm._s(_vm.$t('Registered')))]),_c('v-tab',{attrs:{"href":"#casual"}},[_vm._v(_vm._s(_vm.$t('Guest')))])],1):_vm._e(),_c('v-tab-item',{staticClass:"my-2 pa-2 mb-6",attrs:{"value":"regular"}},[_c('RegularSend',{attrs:{"session":_vm.session,"userActiveSourceRoutes":_vm.userActiveSourceRoutes,"restartSessionClicked":_vm.restartSessionClicked,"lightSessionRestart":_vm.lightSessionRestart}})],1),_c('v-tab-item',{staticClass:"mt-2 mb-6",attrs:{"value":"casual"}},[_c('CasualSend',{attrs:{"session":_vm.session}})],1)],1)],1),(_vm.isShowStartButton)?_c('div',{staticClass:"start-restart-button",class:[_vm.language === 'he' && 'start-restart-button-rtl'],on:{"click":_vm.startFilteringButtonClicked}},[_c('Button',{attrs:{"text":_vm.$t('Start'),"clickAction":_vm.startFilteringButtonClicked,"disabled":!!_vm.filteringErrMessage,"width":"150"}})],1):_vm._e(),(_vm.isShowRestartButton)?_c('Button',{staticClass:"start-restart-button",attrs:{"clickAction":_vm.restartSessionClicked,"text":_vm.$t('Additional Upload'),"icon":"mdi-cached","width":"180"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }