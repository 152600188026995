export const routeHeaders = vue => [
  {
    text: vue.$t('S/N'),
    value: 'id',
    class: `bg-table-heading ${
      vue.language === 'he' ? 'table-header-last' : 'table-header-first'
    }`,
    width: '15%',
  },
  {
    text: vue.$t('Routes'),
    value: 'name',
    class: 'bg-table-heading ',
    width: '25%',
  },
  {
    text: vue.$t('Senders'),
    value: 'sources',
    class: 'bg-table-heading ',
    width: '30%',
    sortable: false,
  },
  {
    text: vue.$t('Recipients'),
    value: 'targets',
    class: `bg-table-heading ${
      vue.language === 'he' ? 'table-header-first' : 'table-header-last'
    }`,
    width: '30%',
    sortable: false,
  },
]
