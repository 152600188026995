<template>
  <div class="table-row fs-1r" role="row" tabindex="0">
    <!--NAME ICON AND PATH-->
    <div class="name-icon-path-cell" :class="[!file.file.path && 'pt-3']">
      <!--ICON-->
      <div class="fill-width d-flex align-center">
        <b :class="file.file.icon" class="mx-2"></b>

        <!--NAME-->
        <div class="big-text-ellipsis mw-90pr">
          {{ file.file.name }}
        </div>
      </div>

      <!--PATH-->
      <div class="label big-text-ellipsis f-12 mx-2 mw-90pr fs-1r">
        {{ file.file.path }}
      </div>
    </div>

    <!--SIZE-->
    <div class="size-cell d-flex align-center justify-end px-3">
      <span> {{ file.file.numbSize | bytesToSize }}</span>
    </div>

    <!--LAST MODIFIED-->
    <div class="last-modi-cell text-center d-flex align-center">
      <span> {{ file.file.lastModifiedDate }}</span>
    </div>

    <!--DELETE FROM THE TABLE-->
    <div class="ma-auto">
      <v-icon
        v-if="!isInMiddleOfFiltering"
        @click="deleteItemClicked"
        @mouseover="hoverOnDelete = true"
        @mouseleave="hoverOnDelete = false"
        :color="hoverOnDelete ? 'red' : 'grey'"
        aria-label="Delete file"
        >mdi-delete</v-icon
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { bytesToSize } from '@/utils'

//Function to remove one file from the table
import { deleteItem } from '@/components/WebsiteInterface/uploadingFunctions'

export default {
  name: 'TableRow',
  data() {
    return {
      hoverOnDelete: false,
    }
  },
  props: { file: Object, session: Object },

  filters: {
    bytesToSize,
  },

  computed: {
    ...mapGetters(['isInMiddleOfFiltering', 'accessibility']),
  },
  methods: {
    deleteItemClicked() {
      deleteItem(this.file, this)
    },
  },
}
</script>

<style scoped src="./TableRow.css"></style>

<style src="./TableRowUnScoped.css"></style>
