<template>
  <tr
    @click="editAction(item)"
    class="pointer ma-4"
    :class="[item.status > 0 && 'low-op-disabeld']"
  >
    <td class="text-center">
      <img
        class="organization-logo mt-2"
        :src="`${selectedHost}/api/images/${item.organizationId}/logo`"
        alt="organization-logo"
      />
    </td>
    <td>
      {{ item.name }}
    </td>
    <td>
      {{ item.status | convertstatus }}
    </td>
    <td>{{ item.fakeID }}</td>
    <td>
      <v-btn
        x-small
        fab
        outlined
        color="#5B5B7A"
        @click.stop="goToSingleOrganization(item)"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { selectedHost } from '@/config'

//Component files
import { convertstatus } from './TableRowItem.js'

export default {
  name: 'TableRowItem',
  data() {
    return {
      selectedHost: selectedHost,
    }
  },
  props: {
    item: Object,
    editAction: Function,
  },
  filters: {
    convertstatus,
  },
  methods: {
    goToSingleOrganization(organization) {
      this.$router.push(`hosts/${organization.organizationId}`)
    },
  },
}
</script>

<style scoped src="./TableRowItem.css"></style>
