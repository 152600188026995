<template>
  <div class="ma-2 d-flex justify-space-between align-center">
    <!--ICON-->
    <v-icon class="mx-1" :color="alertColor">mdi-alert</v-icon>

    <!--MESAGE-->
    <div class="alert px-2 py-2 mx-1">
      <span class="fs-1-12r">
        {{ alert.message }}
      </span>

      <span class="align-self-end min-w-95">
        {{ alert.time | generateLocalDateFromUTC0NoMS }}
      </span>
    </div>

    <!--CHECKBOX-->
    <v-simple-checkbox
      :value="alert.isRead"
      hide-details
      class="ma-0 pa-0"
    ></v-simple-checkbox>
  </div>
</template>

<script>
import { generateLocalDateFromUTC0NoMS } from '@/utils'

export default {
  name: 'Alert',
  props: {
    alert: Object,
  },

  filters: { generateLocalDateFromUTC0NoMS },
  computed: {
    alertColor() {
      switch (this.alert.severity) {
        case 1:
          return 'red'
        case 2:
          return 'orange'
        case 3:
          return 'green'
        default:
          return 'green'
      }
    },
  },
}
</script>

<style scoped>
.alert {
  background-color: #a2d5ff;
  border-radius: 20px;
  min-height: 22px;
  font-weight: 400;
  color: #000000 !important;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.min-w-95 {
  min-width: 95px;
}
</style>
