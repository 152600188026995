<template>
  <div v-if="!isAgreedToCookies" class="cookies-accept">
    <v-alert
      @input="input"
      dismissible
      close-icon="mdi-check"
      class="ma-0"
      border="top"
      color="primary"
      dense
      dark
      width="400px"
    >
      <p class="ma-0">
        This website is using cookies. By continuing to browse the site, you are
        agreeing to our use of cookies.
      </p>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'CookiesAccept',
  data() {
    return {
      isAgreedToCookies: null,
    }
  },
  methods: {
    input(e) {
      localStorage.setItem('dot-engines-cookies-accept', true)
    },
  },
  created() {
    this.isAgreedToCookies = localStorage.getItem('dot-engines-cookies-accept')
  },
}
</script>

<style scoped src="./CookiesAccept.css"></style>
