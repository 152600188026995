<template>
  <div>
    <!--TITLE-->
    <v-stepper-step :complete="step > 2" step="2" class="pa-2" tabindex="0">
      {{ $t('Files Selection') }}
    </v-stepper-step>

    <v-stepper-content step="2" class="pa-0 ma-0">
      <div class="wrapper-1 ma-2 pa-2">
        <!--BUTTONS TO ADD FILES-->
        <FileDirButtons :session="session" :lightSessionRestart="() => null" />

        <v-divider class="my-2" />

        <!--ALL FILES TABLE-->
        <FilesTable
          v-if="session.filesData.length"
          :session="session"
          :showMoreDetailsCicked="file => (shownFile = file)"
        />

        <!--SHOW SPESIFIC FILE-->
        <MoreFileDetails
          v-if="shownFile"
          :shownFile="shownFile"
          :closeFileDetails="() => (shownFile = null)"
          :removeFileFromArray="removeFileFromArray"
        />
        <div class="d-flex justify-space-between align-center" tabindex="0">
          <div class="fs-1r">
            <!--FILES ADDED-->
            <span class="mx-2"
              >{{ $t('Total Files') }}: {{ session.filesData.length }}</span
            >
            <br
              v-if="
                $vuetify.breakpoint.width < 390 || accessibility.fontSize === 3
              "
            />
            <!--TOTAL SIZE-->
            <span class="mx-2"
              >{{ $t('Total Size') }}: {{ session.totalSize | bytesToSize }}</span
            >
          </div>
          <!--CONTINUE-->
          <div @click="continueButtonClicked">
            <Button
              :text="$t('Continue')"
              :clickAction="continueButtonClicked"
              :disabled="!session.filesData.length"
            />
          </div>
        </div>
      </div>
    </v-stepper-content>
  </div>
</template>

<script>
import { alertDialog, bytesToSize } from '@/utils'

//Functions to delete item from files list
import { deleteItem } from '@/components/WebsiteInterface/uploadingFunctions'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import FileDirButtons from '@/components/WebsiteInterface/CommonComponents/FileDirButtons/FileDirButtons.vue'

//Childrens
import FilesTable from './Childrens/FilesTable/FilesTable.vue'
import MoreFileDetails from './Childrens/MoreFileDetails/MoreFileDetails.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SelectFiles',
  data() {
    return {
      shownFile: null,
    }
  },
  props: {
    step: Number,
    session: Object,
    startMobileFiltering: Function,
  },

  filters: {
    bytesToSize,
  },

  components: { FileDirButtons, FilesTable, MoreFileDetails, Button },

  computed: {
    ...mapGetters(['accessibility']),
  },
  methods: {
    removeFileFromArray(item) {
      //this will remove a file from the files array
      deleteItem(item, this)
      this.shownFile = null
    },

    continueButtonClicked() {
      //check if there are files and if not show the message
      if (!this.session.filesData.length)
        return alertDialog(this, 'Files for filtering are missing')

      this.startMobileFiltering()
    },
  },
}
</script>
