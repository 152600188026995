<template>
  <v-dialog
    v-model="dialog"
    :content-class="isDarkMode ? 'dark-mode' : ''"
    width="380px"
    persistent
  >
    <v-card class="wrapper-1">
      <!--TITLE-->
      <v-card-title class="text-center bg-grey pa-2">
        <span class="word-break">{{
          $t('Enter password for encrypted files')
        }}</span>
      </v-card-title>
      <v-card-text>
        <!--LOOPING ON THE PASSWORDS-->
        <v-text-field
          v-for="(password, i) in localPasswords"
          v-model="password.value"
          @input="changePasswords(i, password.value)"
          autocomplete="new-password"
          :type="password.show ? 'text' : 'password'"
          :key="i"
          color="primary"
          hide-details
        >
          <v-icon slot="prepend" color="primary"> mdi-key-variant </v-icon>

          <template v-slot:append>
            <v-icon
              @mousedown="password.show = true"
              @mouseup="password.show = false"
              class="pointer"
              >mdi-eye{{ password.show ? '-off' : '' }}</v-icon
            >
          </template>
        </v-text-field>
      </v-card-text>

      <v-divider />
      <!--BUTTONS-->
      <div class="d-flex justify-space-evenly pa-2">
        <!--CANCEL BUTTON-->
        <Button
          :clickAction="closePasswordDialog"
          text="Cancel"
          btnType="grey"
        />

        <!--ADD PASSWORDS BUTTON-->
        <Button
          :text="$t('Add Passwords')"
          width="140"
          :clickAction="addPasswordsToObj"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UploadingPasswordDialog',
  data() {
    return {
      localPasswords: [{ value: '', show: false }],
      dialog: true,
    }
  },
  props: {
    session: Object,
    lightSessionRestart: Function,
    closePasswordDialog: Function,
  },
  components: { Button },
  computed: {
    ...mapGetters(['isDarkMode']),
  },
  methods: {
    changePasswords(index, password) {
      if (this.session.operationId) this.lightSessionRestart()
      //when the user change the passwords it changes the local passwords array
      if (index === this.localPasswords.length - 1)
        this.localPasswords.push({ value: '', show: false })
      if (index === this.localPasswords.length - 2 && password === '')
        this.localPasswords.pop()
    },
    addPasswordsToObj() {
      //when the user click save then this function save the passwords aray as string, each password is in each line
      let passwords = ''
      this.localPasswords.forEach(element => {
        if (element.value !== '') passwords += element.value + '\n'
      })
      this.session.passwords = passwords
      this.closePasswordDialog()
    },
  },
  mounted() {
    //build the local passwords obj
    this.localPasswords = this.session.passwords
      .split('\n')
      .map(el => ({ value: el, show: false }))
  },
}
</script>
