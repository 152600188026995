import { api } from '@/config'


export const policiesTableHeaders = [
    {
      text: 'Policy name',
      class: 'table-header-first bg-table-heading',
    },
    {
      text: '',
      class: ' bg-table-heading',
      sortable: false,
    },
    {
      text: '',
      class: ' bg-table-heading',
      sortable: false,
    },
    {
      text: '',
      class: 'table-header-last bg-table-heading',
      sortable: false,
    },
  ]

  export async function deletePolicy(orgId = 0, policyName) {
    const res = await api.delete(
      `organizations/${orgId}/deletepolicy?policy=${policyName}`
    )
    if (res.status !== 200) {
      throw new Error("Can't delete")
    }
    return res
  }