<template>
  <div class="pa-2">
    <h4 class="settings-h4">SMS Gateway</h4>
    <div class="wrapper-2 pa-4 mb-4 pos-relative">
      <div class="d-flex align-center">
        <!--ENABLE / DISABLE-->
        <v-tooltip right :open-on-hover="status === 0">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-simple-checkbox
                v-model="isSettingsEnabled"
                style="display: inline"
                class="mr-2"
                :disabled="status !== 0 && !isGlobalOrganization"
              ></v-simple-checkbox>
            </span>
          </template>
          <span>{{ isSettingsEnabled ? 'Disable' : 'Enable' }} updating</span>
        </v-tooltip>

        <!--SELECT SHOWN NAME AND SMS VENDOR-->
        <SMSSenderVendor
          :isGlobalOrganization="isGlobalOrganization"
          :smsSettings="smsSettings"
          :isSettingsEnabled="isSettingsEnabled"
          :actionType="actionType"
        />
      </div>

      <!-- IF THE THE VENDOR IS TELYNX -->
      <SMSTelnyx
        v-if="isShowTelnyx"
        :smsSettings="smsSettings"
        :isSettingsEnabled="isSettingsEnabled"
      />

      <!-- IF THE THE VENDOR IS TWILO OR THIS IS A GLOBAL ORGANIZATION -->
      <SMSTwilio
        v-if="isShowTwilio"
        :smsSettings="smsSettings"
        :isSettingsEnabled="isSettingsEnabled"
      />

      <!--IF THE SUPER ADMIN WANTS TO SEE HOST SMS VENDOR-->
      <span v-if="isShowSuperAdminError">
        Super Admin cannot see or edit the host's SMS settings.
      </span>
      <OnlineHelp
        :page="isGlobalOrganization ? 'globalSettings' : 'settings'"
        section="SMSSettings"
        title="SMS Vendor"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

//Childrens
import SMSSenderVendor from './Childrens/SMSSenderVendor/SMSSenderVendor.vue'
import SMSTelnyx from './Childrens/SMSTelnyx/SMSTelnyx.vue'
import SMSTwilio from './Childrens/SMSTwilio/SMSTwilio.vue'

export default {
  name: 'SMSSettings',
  data() {
    return {
      showPassword: true,
      isSettingsEnabled: false,
      message: '',
      testSms: '',
    }
  },
  props: {
    smsSettings: Object,
    isGlobalOrganization: Boolean,
    status: Number,
    actionType: String,
  },
  components: { SMSSenderVendor, SMSTelnyx, SMSTwilio, OnlineHelp },
  watch: {
    'smsSettings.smsDefinitions.smsVendorEnum': {
      handler: function (n, o) {
        if (n !== undefined && o !== undefined) {
          this.smsSettings.smsDefinitions.accountSid = null
          this.smsSettings.smsDefinitions.apiKey = null
          this.smsSettings.smsDefinitions.authToken = null
          this.smsSettings.smsDefinitions.profileId = null
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUser']),
    isShowTelnyx() {
      //if super admin watch regular host settings
      if (!this.isGlobalOrganization && this.loggedUser.role === 'SuperAdmin')
        return false

      if (this.smsSettings.smsDefinitions.smsVendorEnum === 2) return true

      return false
    },
    isShowTwilio() {
      //if super admin watch regular host settings
      if (!this.isGlobalOrganization && this.loggedUser.role === 'SuperAdmin')
        return false

      if (this.smsSettings.smsDefinitions.smsVendorEnum === 1) return true
      return false
      
      
      if (
        this.smsSettings.smsDefinitions.smsVendorEnum === 1 &&
        this.loggedUser.role !== 'SuperAdmin'
      )
        return true
      else if (
        this.isGlobalOrganization &&
        this.loggedUser.role === 'SuperAdmin'
      )
        return true

      return false
    },
    isShowSuperAdminError() {
      if (
        !this.isGlobalOrganization &&
        this.loggedUser.role === 'SuperAdmin' &&
        this.smsSettings.smsDefinitions.smsVendorEnum !== 0
      )
        return true
      return false
    },
  },
}
</script>
