<template>
  <div class="body fill-height">
    <h1>404</h1>
    <p>Oops! Something is wrong.</p>
    <a class="button" href="/">
      <i class="icon-home"></i> Go back to home page.
    </a>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style scoped src="./NotFound.css"></style>
