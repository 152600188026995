export const adminAlerts = [
  {
    description: 'Host settings changed',
    model: 'hostSettingsChanged',
  },
  {
    description: 'A new registered account was created',
    model: 'newRegisteredUserCreated',
  },

  // {
  //   description: 'A new casual user is created',
  //   model: 'newCasualUserCreated',
  // },

  {
    description: 'A new route is added',
    model: 'newRouteAdded',
  },

  {
    description: 'Route was activated/blocked/deleted',
    model: 'routeDefinitionsChanged',
  },

  {
    description: 'The host: &hostName& was deleted',
    model: 'hostDeleted',
  },

  {
    description: 'A registered account definitions were changed',
    model: 'registeredDefinitionsChanged',
  },
]
