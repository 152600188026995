import { selectedHost } from "@/config"

export const resetPassword = async data => {
    try {
      const res = await fetch(selectedHost + '/api/auth/SendResetPasswordMail', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
        },
      })
      if (res.ok) {
        console.log('success: ', res)
      } else {
        console.log('failure: ', res)
      }
      return res
    } catch (error) {
      console.log(error)
      return error
    }
  }