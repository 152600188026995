<template>
  <div
    class="advanced-settings-target pos-relative mx-1 mt-1"
    :class="[
      isAdvancedSettingsOpen && 'advanced-settings-target-active',
      isTargetSettingsError && 'advanced-settings-target-active-error',
      isDarkMode && 'darkmode-advanced-settings-target',
    ]"
  >
    <!--TITLE THAT LOOKS LIKE INPUT -->
    <span
      @click.stop="
        $emit('set-is-advanced-settings-open', !isAdvancedSettingsOpen)
      "
      class="fs-1-25r advanced-title ml-1"
      style="width: 200px"
      >Target Settings
      <i
        :class="[
          'v-icon notranslate mdi mdi-menu-down',
          isAdvancedSettingsOpen && 'primary--text rotate',
        ]"
      ></i>
    </span>

    <transition tag="div" name="hierarchy">
      <!--WRAPPER FOR THE DROPDOWN-->
      <div
        v-if="isAdvancedSettingsOpen"
        @click.stop
        class="wrapper-2 px-2 pb-2 folder-hierarchy"
      >
        <div class="my-2">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
              v-on="on"
                @click.stop="$emit('set-is-advanced-settings-open', false)"
                class="close-button pointer"
                >mdi-close-circle-outline
              </v-icon>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>
        <!-- DIRECTORY STRUCTURE -->
        <DirectoryStructure :route="route" :isEditDisabled="isEditDisabled" />

        <v-divider class="my-1" />

        <!--AWAITING FILES -->
        <AwaitingFiles
          :isEditDisabled="isEditDisabled"
          :fileLifeTime="fileLifeTime"
          :isDeleteAlertTimesError="isDeleteAlertTimesError"
          :route="route"
        />

        <!--ALERT BEFORE DELETE-->
        <AlertBeforeDelete
          v-if="route.deleteFilesAtByMs < 9999999999999"
          :alertBeforeDeleteTime="alertBeforeDeleteTime"
          :isEditDisabled="isEditDisabled"
          :isDeleteAlertTimesError="isDeleteAlertTimesError"
          :route="route"
        />

        <v-divider />

        <!--DUPLICATE FILE NAMES -->
        <DuplicateFileNames :route="route" :isEditDisabled="isEditDisabled" />

        <!--ONLINE HELP-->
        <OnlineHelp
          page="routes"
          section="advancedSettings"
          title="Target Settings"
        />
      </div>
    </transition>
  </div>
</template>

<script>
//Base compoentns
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

//Childrens
import DirectoryStructure from './Childrens/DirectoryStructure/DirectoryStructure.vue'
import AwaitingFiles from './Childrens/AwaitingFiles/AwaitingFiles.vue'
import AlertBeforeDelete from './Childrens/AlertBeforeDelete/AlertBeforeDelete.vue'
import DuplicateFileNames from './Childrens/DuplicateFileNames/DuplicateFileNames.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AdvancedSettings',
  props: {
    isEditDisabled: Boolean,
    isAdvancedSettingsOpen: Boolean,
    isTargetSettingsError: Boolean,
    route: Object,
    fileLifeTime: Object,
    alertBeforeDeleteTime: Object,
    isDeleteAlertTimesError: Boolean,
  },
  emits: ['set-is-advanced-settings-open'],
  components: {
    DirectoryStructure,
    AwaitingFiles,
    OnlineHelp,
    AlertBeforeDelete,
    DuplicateFileNames,
  },

  // watch: {
  //   'route.foldersHierarchy.isPath': {
  //     handler: function (n) {
  //       if (!n) {
  //         this.route.foldersHierarchy.path = ''
  //       }
  //     },
  //   },
  // },

  computed: {
    ...mapGetters(['isDarkMode']),
  },
}
</script>

<style scoped lang="scss" src="./AdvancedSettings.scss"></style>
