<template>
  <div class="buttons d-flex justify-end">
    <div
      @mouseover="showMessage = true"
      @mouseleave="showMessage = false"
      class="d-flex"
    >
      <transition name="slide-fade">
        <span class="mr-8 red--text message fs-1r" v-if="showMessage">{{
          message
        }}</span>
      </transition>
      <div class="mr-4">
        <Button
          :disabled="isSaveButtonDisabled"
          :clickAction="changeOrganizationStatus"
          :loading="loading"
          btnType="blue"
          :text="actionType === 'edit' ? 'Save' : 'Create'"
        />
      </div>
    </div>

    <div>
      <Button
        btnType="grey"
        text="Cancel"
        :clickAction="back"
        width="100px"
        class="mr-2"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
export default {
  name: 'SaveCancelButtons',
  data() {
    return {
      showMessage: false,
    }
  },
  components: { Button },
  emits: ['changeTab'],
  props: {
    loading: Boolean,
    isSaveButtonDisabled: Boolean,
    changeOrganizationStatus: Function,
    organization: Object,
    message: String,
    actionType: String,
  },
  methods: {
    back() {
      if (this.isSaveButtonDisabled) this.$emit('changeTab', 'tab-registered')
      if (this.actionType === 'edit') {
        this.$router.push(
          `/management/hosts/${this.organization.organizationId}/registered/users`
        )
      } else {
        this.$router.push('/management/hosts')
      }
    },
  },
}
</script>

