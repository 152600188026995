<template>
  <div
    :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    tabindex="0"
    autofocus
  >
    <div class="d-flex align-center title-border bg-grey pa-2">
      <v-icon>mdi-account-circle</v-icon>
      <h3 class="font-weight-light mx-2 fs-1-25r">
        {{ $t('New password') }}
      </h3>
    </div>
    <v-divider></v-divider>

    <v-card-text class="pa-2" v-if="!isRestoreRequestSent">
      <span class="fs-1r"
        >{{ $t('Type your email address to create new password') }}:</span
      >
      <v-text-field
        :rules="userNameRules"
        v-model="emailToReset"
        hide-details
        class="my-2"
        outlined
        dense
        :label="$t('Email')"
        type="text"
        placeholder=" "
        @keypress.enter.prevent="resetPW"
      ></v-text-field>
      <div>
        <v-checkbox
          v-model="notRobot"
          :label="$t(`I'm not a robot`)"
          class="ma-0 pa-0"
          hide-details
        ></v-checkbox>

        <div class="d-flex justify-end">
          <Button
            :clickAction="resetPW"
            :loading="loading"
            :disabled="!isValidEmail || !notRobot"
            class="mx-1"
            width="80"
            :text="$t('Send')"
          />
          <Button
            :clickAction="() => $emit('set-forgot-password', false)"
            class="mx-1"
            btnType="grey"
            :text="$t('Cancel')"
            width="80"
          />
        </div>
      </div>
    </v-card-text>

    <!--IF THE REQUEST SENT-->
    <v-card-text v-else class="pa-2 fs-1-12r" tabindex="0">
      <span
        >{{
          $t('A reset password email was sent to the inserted email address')
        }}
        <b>{{ emailToReset }}</b>
        {{ $t('only if it belongs to a known and valid user') }}.</span
      >
      <v-divider class="my-2"></v-divider>
      <div class="fill-width d-flex justify-end">
        <Button
          small
          :loading="loading"
          :disabled="!isValidEmail || !notRobot"
          :text="$t('Continue')"
          :clickAction="() => $emit('set-forgot-password', false)"
        />
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { validateEmail } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Files
import { resetPassword } from './ResetPassword'

export default {
  name: 'ResetPassword',
  data() {
    return {
      isRestoreRequestSent: false,
      userNameRules: [v => !!v.trim() || this.$t('Email is required')],
      emailToReset: '',
      notRobot: false,
      loading: false,
    }
  },
  props: { language: String, userName: String },
  emits: ['set-forgot-password'],
  components: { Button },
  computed: {
    isValidEmail() {
      return validateEmail(this.emailToReset)
    },
  },
  methods: {
    async resetPW() {
      try {
        this.loading = true
        await resetPassword(this.emailToReset)
        this.isRestoreRequestSent = true
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    if (this.userName) this.emailToReset = this.userName
  },
}
</script>
