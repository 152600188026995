<template>
  <div>
    <span class="f-14 font-weight-regular label">
      Test {{ isBackupSettings ? 'Backup' : '' }} Mail Server
    </span>
    <div class="wrapper-1 pa-4 d-flex align-center">
      <!--EMAIL TO TEST -->
      <v-text-field
        v-model="testEmail"
        :rules="emailRules"
        :disabled="!isSettingsEnabled"
        class="mr-2 mw-250"
        dense
        label="Send To"
        validate-on-blur
      >
      </v-text-field>

      <!--MESSAGE TO WRITE IN EMAIL-->
      <v-text-field
        v-model="testMessage"
        :disabled="!isSettingsEnabled"
        class="mr-2 mw-250"
        dense
        label="Message"
      >
      </v-text-field>

      <!--RUN TEST-->
      <v-btn
        @click="runTest"
        :loading="isLoadingTest"
        :disabled="isTestBtnDisabled || !isSettingsEnabled"
        color="info"
        small
        >Test</v-btn
      >
    </div>
  </div>
</template>

<script>
import { api } from '@/config'
import { validateEmail } from '@/utils'
import { mapMutations } from 'vuex'

export default {
  name: 'TestEmail',
  data() {
    return {
      testEmail: '',
      testMessage: '',
      isLoadingTest: false,
    }
  },

  props: {
    organization: Object,
    isSettingsEnabled: Boolean,
    isBackupSettings: Boolean,
    setIsCheckSmtpChange: Function,
    setIsCheckBackupSmtpChange: Function,
  },

  computed: {
    isTestBtnDisabled() {
      return !this.testMessage || !validateEmail(this.testEmail)
    },
    emailRules() {
      return [
        v => !!v || 'Email is required',
        v => validateEmail(v) || 'Email must be valid',
      ]
    },
  },

  methods: {
    ...mapMutations(['SET_NOTIFICATION']),
    async runTest() {
      if (this.isBackupSettings) await this.runBackupTest()
      else await this.runMainTest()
    },

    async runBackupTest() {
      try {
        this.isLoadingTest = true
        const body = {
          message: this.testMessage,
          email: this.testEmail,
        }

        //if the backup selcted method is SMTP
        if (this.organization.backupEmailDefinitions.emailProtocol === 'SMTP') {
          const { server, senderEmail, port, password, encrypt } =
            this.organization.backupEmailDefinitions.smtpDefinitions

          body.smtpDefinitions = {
            server,
            port,
            password,
            encrypt,
            senderEmail,
          }

          const res = await api.post('organizations/testsmtp', body)

          if (res.status !== 200)
            throw new Error(decodeURIComponent(res.headers['reason-phrase']))
        }

        //if the backup selected method is GRAPH
        else {
          const { clientId, tenantId, clientSecret, senderEmail } =
            this.organization.backupEmailDefinitions.msGraphDefinitions

          body.msGraphDefinitions = {
            clientId,
            tenantId,
            clientSecret,
            senderEmail,
          }

          const res = await api.post('organizations/testmsgraph', body)

          if (res.status !== 200)
            throw new Error(decodeURIComponent(res.headers['reason-phrase']))
        }

        //if everything is okay
        this.setIsCheckBackupSmtpChange(true)

        this.SET_NOTIFICATION({
          type: 'success',
          text: 'Success, check your email',
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoadingTest = false
      }
    },
    async runMainTest() {
      try {
        this.isLoadingTest = true

        const body = {
          message: this.testMessage,
          email: this.testEmail,
        }

        //if the selcted method is SMTP
        if (this.organization.emailDefinitions.emailProtocol === 'SMTP') {
          const { server, senderEmail, port, password, encrypt } =
            this.organization.emailDefinitions.smtpDefinitions

          body.smtpDefinitions = {
            server,
            port,
            password,
            encrypt,
            senderEmail,
          }

          const res = await api.post('organizations/testsmtp', body)

          if (res.status !== 200)
            throw new Error(decodeURIComponent(res.headers['reason-phrase']))
        }

        //if the selected method is GRAPH
        else if (
          this.organization.emailDefinitions.emailProtocol === 'Microsoft Graph'
        ) {
          const { clientId, tenantId, clientSecret, senderEmail } =
            this.organization.emailDefinitions.msGraphDefinitions

          body.msGraphDefinitions = {
            clientId,
            tenantId,
            clientSecret,
            senderEmail,
          }

          const res = await api.post('organizations/testmsgraph', body)

          if (res.status !== 200)
            throw new Error(decodeURIComponent(res.headers['reason-phrase']))
        }

        //if everything is okay
        this.setIsCheckSmtpChange(true)

        this.SET_NOTIFICATION({
          type: 'success',
          text: 'Success, check your email',
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoadingTest = false
      }
    },
  },
}
</script>
