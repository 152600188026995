<template>
  <div class="go-to-files-wrapper">
    <div class="wrapper-1 go-to-files bg-white mt-8 mx-8 w-24r">
      <div
        aria-label="Login to get to your files"
        tabindex="0"
        class="d-flex align-center title-border bg-grey pa-2"
      >
        <v-icon>mdi-account-circle</v-icon>

        <h3
          class="font-weight-light mx-2 fs-1-5r"
          aria-label="Login to get to your files"
          tabindex="0"
        >
          {{ $t('Login to get to your files') }}
        </h3>
      </div>

      <v-divider></v-divider>

      <!--EMAIL PASSWORD INPUTS-->
      <v-card-text class="pa-0 px-2">
        <div class="d-flex align-center">
          <span v-if="isWeb" class="mx-1 w-6r fs-1-25r"
            >{{ $t('Email') }}:
          </span>
          <v-text-field
            v-model="userName"
            :rules="userNameRules"
            dense
            hide-details
            outlined
            type="text"
            :label="isWeb ? '' : $t('Email')"
            class="my-2 fs-1-25r"
            id="login-email"
            @keypress.enter="enterPressed"
            :disabled="isConfirmationNeeded"
            aria-label="Email"
          ></v-text-field>
        </div>

        <div class="d-flex align-center">
          <span v-if="isWeb" class="mx-1 w-6r fs-1-25r"
            >{{ $t('Password') }}:
          </span>
          <v-text-field
            :rules="passwordRules"
            dense
            :name="Math.random()"
            outlined
            :label="isWeb ? '' : $t('Password')"
            class="my-2 fs-1-25r"
            :type="showPassword ? 'text' : 'password'"
            id="login-password"
            v-model.trim="password"
            @keypress.enter="enterPressed"
            :disabled="isConfirmationNeeded"
            hide-details
            aria-label="Password"
          >
            <!--WEBSITE-->
            <template
              v-if="$vuetify.breakpoint.width >= 769 && isPassLengthWasZero"
              v-slot:append
            >
              <v-icon
                @mousedown="showPassword = true"
                @mouseup="showPassword = false"
                class="pointer"
                >mdi-eye{{ showPassword ? '-off' : '' }}</v-icon
              >
            </template>
            <!--MOBILE-->
            <template v-else v-slot:append>
              <v-icon
                v-if="isPassLengthWasZero"
                @click="showPassword = !showPassword"
                class="pointer"
                >mdi-eye{{ showPassword ? '-off' : '' }}</v-icon
              >
            </template>
          </v-text-field>
        </div>
      </v-card-text>
      <!--ASK IF USER FORGOT PASSWORD-->
      <div
        v-if="!isConfirmationNeeded"
        class="d-flex justify-space-between align-center pa-2"
      >
        <button
          @click="forgotPasswordClicked"
          class="forgot-password ma-0"
          aria-label="forgot password?"
        >
          {{ $t('Forgot Password?') }}
        </button>
        <v-spacer></v-spacer>
        <Button
          :clickAction="login"
          :disabled="isLoginButtonDisabled"
          :text="$t('Continue')"
          width="100"
        ></Button>
      </div>

      <!--IF 2FA IS NEEDED-->
      <ConfirmedLogin
        v-else
        @set-sms-confirm-code="v => (smsConfirmCode = v)"
        :login="login"
        :clear="clear"
        :confirmLogin="confirmLogin"
        :timerTime="timerTime"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Button from '@/components/BaseComponents/Button/Button.vue'
import { validateEmail } from '@/utils'
import ConfirmedLogin from '../ConfirmedLogin/ConfirmedLogin.vue'
export default {
  name: 'GoToFilesLogin',
  data() {
    return {
      userName: '',
      password: '',
      userNameRules: [v => !!v.trim() || this.$t('Email is required')],
      passwordRules: [v => !!v || this.$t('Password is required')],
      showPassword: false,
      timerTime: 0,
      smsConfirmCode: '',
      loading: false,
      path: '',
      isPassLengthWasZero: false,
    }
  },

  watch: {
    password(n, o) {
      if (this.isPassLengthWasZero) return
      if (n.length > 2 && o.length === 0) this.isPassLengthWasZero = false
      else if (n.length === 1 && o.length === 0) this.isPassLengthWasZero = true
    },
  },
  computed: {
    ...mapGetters(['isConfirmationNeeded']),
    isLoginButtonDisabled() {
      if (!validateEmail(this.userName)) return true
      else if (!this.password) return true
      return false
    },
    isWeb() {
      if (this.$vuetify.breakpoint.width > 769) return true
      return false
    },
  },
  methods: {
    ...mapMutations(['SET_ISCONFIRMATIONNEEDED']),
    ...mapActions(['CONFIRM_LOGIN']),
    forgotPasswordClicked() {
      this.$router.push({
        path: '/',
        query: { email: this.userName, isforgotpassword: true },
      })
    },
    login() {
      this.$store
        .dispatch('LOGIN', {
          username: this.userName,
          password: this.password,
          logToFiles: this.path,
        })
        .then(() => {
          if (this.isConfirmationNeeded) {
            this.timerTime = 30
            this.timer = setInterval(() => {
              --this.timerTime
            }, 1000)
          }
        })
    },
    clear() {
      this.stopTimer()
      this.SET_ISCONFIRMATIONNEEDED(false)
      this.timer = null
      this.timerTime = 0
      this.loading = false
      this.smsConfirmCode = ''
      this.showPassword = false
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    async confirmLogin() {
      try {
        this.loading = true
        const data = {
          value: this.userName,
          code: this.smsConfirmCode,
          logToFiles: this.path,
        }
        await this.CONFIRM_LOGIN(data)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
  components: { Button, ConfirmedLogin },

  mounted() {
    //weird but this is only work with the timeout
    setTimeout(() => {
      const { email, path } = this.$route.query
      if (email) {
        this.userName = email
        this.path = path
      }
      window.history.replaceState({}, '', location.pathname)
    }, 25)

  },
}
</script>

<style scoped src="./GoToFilesLogin.css"></style>
