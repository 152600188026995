<template>
  <div class="d-flex flex-column fill-height">
    <div v-if="idx" class="d-flex justify-space-between">
      <!--TITLE-->
      <h2>
        {{ idx }}. Accounts Usage
        <span class="caption">{{ parsedAxisTitle }}</span>
      </h2>

      <!--EXPAND-->
      <v-icon @click="expandClicked('Users')">mdi-arrow-expand</v-icon>
    </div>

    <!-- <v-radio-group v-model="chartShown" hide-details row class="pa-0 ma-0">
      <v-radio hide-details label="Column chart" value="column"></v-radio>
      <v-radio hide-details label="Bubble chart" value="bubble"></v-radio>
    </v-radio-group> -->

    <div style="width: 100%; height: calc(100% - 24px)">
      <div v-if="chartShown === 'column'" class="fill-height">
        <v-radio-group
          v-model="barChartShown"
          hide-details
          row
          class="py-0 my-1 mx-2"
        >
          <v-radio hide-details label="Volumes" value="volume"></v-radio>
          <v-radio hide-details label="Files Counts" value="files"></v-radio>
          <v-radio
            hide-details
            label="Sessions counts"
            value="sessions"
          ></v-radio>
        </v-radio-group>

        <!--ON BIG SCREEN SHOW THE AXIS TITLES-->
        <span v-if="!idx" class="fs-1-12r my-1 mx-2 label">{{
          parsedAxisTitle
        }}</span>

        <Bar
          style="height: calc(100% - 30px)"
          :chart-options="barOptions"
          :chart-data="computedBarData"
        />
      </div>

      <!-- <Bubble
        v-else
        :chart-data="data"
        :chart-options="options"
        class="fill-height"
      /> -->
    </div>
  </div>
</template>

<script>
import { bytesToSize, megabytesToSize } from '@/utils'
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  PointElement,
  LinearScale,
  Title,
  BarElement,
  CategoryScale,
} from 'chart.js'
import { Bubble, Bar } from 'vue-chartjs'
import { mapGetters } from 'vuex'

ChartJS.register(
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Title,
  BarElement,
  CategoryScale
)

export default {
  name: 'Users',

  data() {
    return {
      data: {
        datasets: [],
      },
      barData: {
        datasets: [],
      },
      chartShown: 'column',
      barChartShown: 'volume',
      volumesBarData: [],
      filesBarData: [],
      sessionsBarData: [],
    }
  },

  components: {
    Bubble,
    Bar,
  },

  props: {
    analyticsData: Object,
    idx: Number,
    expandClicked: Function,
  },

  computed: {
    ...mapGetters(['isDarkMode']),
    parsedAxisTitle() {
      if (this.barChartShown === 'volume')
        return '(Types / Volumes [MB])'
      if (this.barChartShown === 'files') return '(Types / Files counts)'
      if (this.barChartShown === 'sessions') return '(Types / Sessions counts)'
    },
    computedBarData() {
      const obj = {
        ...this.barData,
        datasets: [
          {
            backgroundColor: [
              '#a2d5ff',
              '#a2d5ff',
              '#a2d5ff',
              '#a2d5ff',
              '#a2d5ff',
              '#a2d5ff',
            ],
          },
        ],
      }

      if (this.barChartShown === 'volume')
        obj.datasets[0].data = this.volumesBarData
      else if (this.barChartShown === 'files')
        obj.datasets[0].data = this.filesBarData
      else if (this.barChartShown === 'sessions')
        obj.datasets[0].data = this.sessionsBarData

      return obj
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const { dataset, label, dataIndex } = context
                const { x, y, r, email } = dataset.data[dataIndex]
                const arr = []
                arr.push(`${label} > ${email}`)
                arr.push(`Files count: ${x}`)
                arr.push(`Sessions count: ${y}`)
                arr.push(`Sent: ${bytesToSize(r * 1024 * 1024)}`)
                return arr
              },
            },
          },
        },

        scales: {
          y: {
            title: { display: true, text: 'Sessions' },
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
          x: {
            title: { display: true, text: 'Files count' },
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
        },
      }
    },
    barOptions() {
      const obj = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: { callbacks: { label: null } },
        },

        scales: {
          y: {
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
          x: {
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
        },
      }

      if (this.barChartShown === 'volume')
        obj.plugins.tooltip.callbacks.label = function (context) {
          const foundObj = context.dataset.data[+context.dataIndex]
          return `Total size: ${megabytesToSize(foundObj)}`
        }
      else if (this.barChartShown === 'files')
        obj.plugins.tooltip.callbacks.label = function (context) {
          const foundObj = context.dataset.data[+context.dataIndex]
          return `Total files: ${foundObj}`
        }
      else if (this.barChartShown === 'sessions')
        obj.plugins.tooltip.callbacks.label = function (context) {
          const foundObj = context.dataset.data[+context.dataIndex]
          return `Total sessions: ${foundObj}`
        }

      return obj
    },
  },

  created() {
    const users = {
      API: [],
      User: [],
      Casual: [],
      Admin: [],
      Kiosk: [],
      SFTP: [],
      SuperAdmin: [],
    }

    const totalVolumes = {
      API: 0,
      User: 0,
      Casual: 0,
      Admin: 0,
      Kiosk: 0,
      SFTP: 0,
      SuperAdmin: 0,
    }

    const totalSessions = {
      API: 0,
      User: 0,
      Casual: 0,
      Admin: 0,
      Kiosk: 0,
      SFTP: 0,
      SuperAdmin: 0,
    }

    const totalFiles = {
      API: 0,
      User: 0,
      Casual: 0,
      Admin: 0,
      Kiosk: 0,
      SFTP: 0,
      SuperAdmin: 0,
    }

    this.analyticsData.users.forEach(user => {
      users[user.role].push({
        x: user.filesCount,
        y: user.sessionsCount,
        r: user.volume / 1024 / 1024,
        email: user.email,
      })
      totalVolumes[user.role] += user.volume / 1024 / 1024
      totalSessions[user.role] += user.sessionsCount
      totalFiles[user.role] += user.filesCount
    })

    this.data.datasets = [
      {
        label: 'Regular Users',
        backgroundColor: '#2289dd',
        data: users.User,
      },
      {
        label: 'Casual Users',
        backgroundColor: '#2289dd',
        data: users.Casual,
      },
      {
        label: 'Host Admin',
        backgroundColor: '#2289dd',
        data: users.Admin,
      },
      {
        label: 'SFTP',
        backgroundColor: '#2289dd',
        data: users.SFTP,
      },
      {
        label: 'Kiosk',
        backgroundColor: '#2289dd',
        data: users.Kiosk,
      },
      {
        label: 'API',
        backgroundColor: '#2289dd',
        data: users.API,
      },
    ]

    this.barData.labels = [
      'Regular Users',
      'Casual Users',
      'Host Admin',
      'SFTP',
      'Kiosk',
      'API',
    ]
    this.volumesBarData = [
      totalVolumes.User,
      totalVolumes.Casual,
      totalVolumes.Admin,
      totalVolumes.SFTP,
      totalVolumes.Kiosk,
      totalVolumes.API,
    ]

    this.filesBarData = [
      totalFiles.User,
      totalFiles.Casual,
      totalFiles.Admin,
      totalFiles.SFTP,
      totalFiles.Kiosk,
      totalFiles.API,
    ]

    this.sessionsBarData = [
      totalSessions.User,
      totalSessions.Casual,
      totalSessions.Admin,
      totalSessions.SFTP,
      totalSessions.Kiosk,
      totalSessions.API,
    ]
  },
}
</script>
