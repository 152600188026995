<template>
  <div
    class="wrapper-1 mx-2 report-wrapper bg-white"
    :class="[
      !loggedUser.isAllowReport && 'report-wrapper-no-html',
      type === 'tablet' && 'report-wrapper-tablet',
    ]"
  >
    <!--THE TITLE-->
    <v-card-title
      class="text-h5 bg-grey pa-3 title-border font-weight-medium"
      tabindex="0"
    >
      Transference Report
    </v-card-title>
    <v-divider />

    <!--HTML REPORT-->
    <div v-if="loggedUser.isAllowReport" class="html-report-wrapper">
      <h3 class="mx-2 fs-1-25r" tabindex="0">Filtering Report</h3>
      <div
        class="wrapper-2 html-report pa-2 ma-2 fs-1r"
        v-html="statusResults.session.htmlReport.summary"
        tabindex="0"
      ></div>
    </div>

    <!--STATISTICS AND SUMMARY-->
    <div class="static-summary-wrapper">
      <h3 class="mx-2 fs-1-25r" tabindex="0">
        Uploading details
        {{ loggedUser.isAllowReport ? 'and statistics' : '' }}
      </h3>

      <div class="wrapper-2 mx-2 d-flex">
        <!--SUMMARY-->
        <div v-if="loggedUser.isAllowReport" class="d-flex">
          <Summary :statusResults="statusResults" tabindex="0" />
          <v-divider vertical />
        </div>

        <!--DETAILS-->
        <UploadDetails :sessionRes="statusResults.session" tabindex="0" />

        <!--BUTTONS TO RESTART AND DOWNLOAD REPORT-->
      </div>
      <SessionActions
        :sessionRes="statusResults.session"
        connectionType="tablet"
        :restartSessionClicked="restartSessionClicked"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import SessionActions from '@/components/WebsiteInterface/CommonComponents/SessionActions/SessionActions.vue'
import Summary from '@/components/BaseComponents/Summary/Summary.vue'

//Childrens
import UploadDetails from './Childrens/UploadDetails/UploadDetails.vue'

export default {
  name: 'Report',
  props: {
    statusResults: Object,
    restartSessionClicked: Function,
    type: String,
  },
  components: { Summary, UploadDetails, SessionActions },
  computed: {
    ...mapGetters(['loggedUser']),
  },
}
</script>

<style scoped src="./Report.css"></style>
