<template>
  <div class="casual-download-header bg-grey">
    <!--TITLE-->
    <h1>{{ `${$t('Download Files Securely From')} ${themeSettings.name} ${$t('by')} ${registeredName}` }}</h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  props: {
    registeredName: String,
  },
  computed: {
    ...mapGetters(['themeSettings']),
  },

}
</script>

<style lang="scss" scoped>
.casual-download-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 8px;
  border-radius: 10px 10px 0 0;
  h1 {
    font-size: 1.25rem;
  }
}
</style>
