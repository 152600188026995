<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Routes</h4>
    <div class="wrapper-2 pa-4 pos-relative">
      <div class="d-flex">
        <Button
          :clickAction="() => (isShowUserRoutes = !isShowUserRoutes)"
          width="200"
          text="Show Account Routes"
        />
        <v-select
          v-model="profile.defaultRoute"
          :items="computedUserRoutes"
          :disabled="isFieldDisabled"
          item-text="label"
          item-value="value"
          label="Default Route"
          class="profile-select my-0 mx-4 pa-0"
          style="max-width: 240px"
          hide-details
          clearable
        >
        </v-select>
      </div>

      <!--SHOW USER ORG ROUTES-->
      <OrgRoutes
        v-if="isShowUserRoutes"
        @openSingleRoute="openSingleRoute"
        :isUserRoutes="true"
        :userId="currentUser.userId"
        class="mt-2"
      />

      <!--SHOW USER ROUTES DIALOG-->
      <UserRouteDialog
        v-if="isShowSingleRoute"
        :closeUserRoutesDialog="v => (isShowSingleRoute = false)"
        :routeId="routeId"
      />

      <OnlineHelp
        page="singleUser"
        section="userRoutes"
        title="Account Routes"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'
import OrgRoutes from '@/components/SingleOrganization/Routes/OrgRoutes/OrgRoutes.vue'

//Chidren
import UserRouteDialog from './Children/UserRouteDialog/UserRouteDialog.vue'

export default {
  name: 'UserRoutes',
  data() {
    return {
      isShowUserRoutes: false,
      isShowSingleRoute: false,
      routeId: null,
    }
  },
  props: { profile: Object, isFieldDisabled: Boolean },
  components: { Button, OnlineHelp, UserRouteDialog, OrgRoutes },

  computed: {
    ...mapGetters(['userRoutes', 'currentUser']),
    computedUserRoutes() {
      const userRoutes = this.userRoutes
      const filteredRoutes = []
      userRoutes.forEach(el => {
        if (
          el.status === 0 &&
          el.sourceEndPoints.some(
            soEndPoint => soEndPoint.userEmail === this.profile.email
          ) &&
          !filteredRoutes.includes(el.name)
        )
          filteredRoutes.push(el.name)
      })
      return filteredRoutes
    },
  },
  methods: {
    openSingleRoute(routeId) {
      this.routeId = routeId
      this.isShowSingleRoute = true
    },
  },
}
</script>
