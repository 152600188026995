var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advanced-settings-target pos-relative mx-1 mt-1",class:[
    _vm.isAdvancedSettingsOpen && 'advanced-settings-target-active',
    _vm.isTargetSettingsError && 'advanced-settings-target-active-error',
    _vm.isDarkMode && 'darkmode-advanced-settings-target',
  ]},[_c('span',{staticClass:"fs-1-25r advanced-title ml-1",staticStyle:{"width":"200px"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('set-is-advanced-settings-open', !_vm.isAdvancedSettingsOpen)}}},[_vm._v("Target Settings "),_c('i',{class:[
        'v-icon notranslate mdi mdi-menu-down',
        _vm.isAdvancedSettingsOpen && 'primary--text rotate',
      ]})]),_c('transition',{attrs:{"tag":"div","name":"hierarchy"}},[(_vm.isAdvancedSettingsOpen)?_c('div',{staticClass:"wrapper-2 px-2 pb-2 folder-hierarchy",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"my-2"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"close-button pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('set-is-advanced-settings-open', false)}}},on),[_vm._v("mdi-close-circle-outline ")])]}}],null,false,2651673611)},[_c('span',[_vm._v("Close")])])],1),_c('DirectoryStructure',{attrs:{"route":_vm.route,"isEditDisabled":_vm.isEditDisabled}}),_c('v-divider',{staticClass:"my-1"}),_c('AwaitingFiles',{attrs:{"isEditDisabled":_vm.isEditDisabled,"fileLifeTime":_vm.fileLifeTime,"isDeleteAlertTimesError":_vm.isDeleteAlertTimesError,"route":_vm.route}}),(_vm.route.deleteFilesAtByMs < 9999999999999)?_c('AlertBeforeDelete',{attrs:{"alertBeforeDeleteTime":_vm.alertBeforeDeleteTime,"isEditDisabled":_vm.isEditDisabled,"isDeleteAlertTimesError":_vm.isDeleteAlertTimesError,"route":_vm.route}}):_vm._e(),_c('v-divider'),_c('DuplicateFileNames',{attrs:{"route":_vm.route,"isEditDisabled":_vm.isEditDisabled}}),_c('OnlineHelp',{attrs:{"page":"routes","section":"advancedSettings","title":"Target Settings"}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }