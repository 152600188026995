<template>
  <header class="header" tabindex="0" ref="header">
    <HeaderLogoText />
    <v-spacer></v-spacer>

    <!--LANGUAGE-->
    <Languages />

    <!--ACCESSIBILITY-->
    <Accessibility v-if="isShowAccessibility" />

    <!--DARK / NOT DARK MODE-->
    <DarkMode />

    <v-tooltip bottom v-if="!isLoggedIn">
      <template v-slot:activator="{ on }">
        <v-btn
          @click="isShowAbout = true"
          aria-label="About dot engines"
          v-on="on"
          outlined
          depressed
          color="primary"
          class="small-btn bg-white mx-1"
        >
          <v-icon color="primary">mdi-information-outline</v-icon>
        </v-btn>
      </template>
      <span> {{ $t('About') }}</span>
    </v-tooltip>

    <div class="d-flex">
      <HeaderMenu v-if="isLoggedIn" @is-show-about="v => (isShowAbout = v)" />
    </div>

    <!--ABOUT DIALOG-->
    <About v-if="isShowAbout" @toggle-is-show-about="isShowAbout = false" />
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import { selectedHost } from '@/config'

//Base components
import About from '@/components/BaseComponents/About/About.vue'

//Childrens
import HeaderMenu from './Childrens/HeaderMenu/HeaderMenu.vue'
import HeaderLogoText from './Childrens/HeaderLogoText/HeaderLogoText.vue'
import Accessibility from './Childrens/Accessibility/Accessibility.vue'
import DarkMode from './Childrens/DarkMode/DarkMode.vue'
import Languages from './Childrens/Language/Language.vue'

export default {
  name: 'Header',
  data() {
    return {
      window,
      isProfile: false,
      isNewAccount: false,
      selectedHost,
      isShowAbout: false,
    }
  },
  components: {
    About,
    HeaderMenu,
    HeaderLogoText,
    Accessibility,
    DarkMode,
    Languages,
  },
  watch: {
    //some bandage
    '$route.path': {
      handler: function (n) {
        this.$refs.header.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    isShowAccessibility() {
      if (this.$route.path.includes('management')) return false
      return true
    },
  },
}
</script>

<style scoped src="./Header.css"></style>
