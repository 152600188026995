<template>
  <div
    class="file-row"
    :style="`margin: 0px ${(level + 0) * 16}px;`"
    tabindex="0"
    :aria-label="file.name"
  >
    <!--FIRST LINE - ICONS AND NAME-->
    <div class="file-name-wrapper">
      <!--ICON TO OPEN/CLOSE MORE INFO-->
      <input type="checkbox" :id="file.historyId" class="expand" />
      <label :class="[!isFileHaveMoreInfo && 'op-0']" :for="file.historyId" />

      <!--THE LINE OF FILE INFORMATION-->
      <div class="file-info">
        <div
          :class="file.status && file.status.toLowerCase()"
          class="status-dir-foramt"
        />
        <span class="file-name">{{ file.name }}</span>
        <sup class="history-id">{{ file.historyId }}</sup>
        <div>{{ file.text }}</div>
      </div>

      <!--RECURSIVE!!! LOOP ON EACH ITEM INFO-->
      <FileRow
        v-for="item of file.moreInfo"
        :file="item"
        :key="`${file.historyId}${item.historyId}`"
        :level="level ? level + 1 : 1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileRow',

  data() {
    return {
      isShowMoreData: false,
    }
  },
  props: {
    file: Object,
    level: Number,
  },
  computed: {
    isFileHaveMoreInfo() {
      if (!this.file.moreInfo?.length) return false
      return true
    },
  },
}
</script>
<style scoped src="../../DirFilesTableReportDialog.css"></style>
