<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Registered Accounts Notifications By Email</h4>
    <div class="wrapper-2 pa-2 pos-relative">
      <!--NOTIFICATIONS FOR HOST -->
      <div
        class="d-flex my-1 mx-3"
        v-for="(item, index) in regAccountAlerts"
        :key="index"
      >
        <v-checkbox
          v-model="notificationsSet[item.model]"
          :label="item.description"
          :disabled="status !== 0"
          class="pa-0 ma-0"
          hide-details
        ></v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { regAccountAlerts } from './RegisteredUserAccountSettings'

export default {
  name: 'RegisteredUserAccountSettings',
  data() {
    return {
      regAccountAlerts,
    }
  },
  props: {
    notificationsSet: Object,
    status: Number,
  },
}
</script>
