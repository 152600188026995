<template>
  <div class="d-flex">
    <!--SELECT SMS VENDOR ONLY IN HOST-->
    <v-select
      v-model="smsSettings.smsDefinitions.smsVendorEnum"
      :disabled="!isSettingsEnabled"
      :items="computedSmsSettings"
      label="SMS Vendor"
      placeholder=" "
      class="mx-4"
      style="max-width: 160px"
    >
    </v-select>
    <!--SELECT SMS SENDER NAME -->
    <v-text-field
      v-if="smsSettings.smsDefinitions.smsVendorEnum !== 10"
      v-model="smsSettings.smsDefinitions.sender"
      :disabled="!isSettingsEnabled"
      label="Sender Name to Display"
      placeholder=" "
      :class="[actionType === 'add' && 'required-field']"
      :counter="11"
      class="mx-4"
      style="width: 200px"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'SMSSenderVendor',
  props: {
    isGlobalOrganization: Boolean,
    smsSettings: Object,
    isSettingsEnabled: Boolean,
    actionType: String,
  },

  watch: {
    'smsSettings.name': {
      handler: function (n) {
        if (
          this.actionType === 'add' &&
          n !== undefined &&
          /^[A-Za-z\s\-]*$/.test(n)
        ) {
          this.smsSettings.smsDefinitions.sender = n
        }
      },
      deep: true,
    },
  },

  computed: {
    computedSmsSettings() {
      if (this.isGlobalOrganization)
        return [
          { value: 10, text: 'None' },
          { value: 1, text: 'Twilio' },
          { value: 2, text: 'Telnyx' },
        ]

      //host admin
      const arr = [
        { value: 1, text: 'Twilio (Custom)' },
        { value: 2, text: 'Telnyx (Custom)' },
        { value: 10, text: 'None' },
      ]
      //if the global org have default value for sms
      if (this.smsSettings.isGlobalSms) {
        return [{ value: 0, text: 'Default' }, ...arr]
      }
      //if the global org dosent have default value for sms
      return arr
    },
  },
}
</script>
