export const progressItems = [
  {
    name: 'uploading',
    text: 'Uploading',
    value: 'dataSent',
  },

  {
    name: 'analyze',
    text: 'Analyzing',
    value: 'analyzedPercentage',
  },

  {
    name: 'synthesize',
    text: 'Synthesizing',
    value: 'synthesizedPercentage',
  },
]
