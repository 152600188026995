<template>
  <div class="d-flex align-center flex-wrap">
    <v-select
      v-if="profile.role !== 'SuperAdmin'"
      v-model="profile.language"
      :items="langs"
      :disabled="isFieldDisabled || !profile.license === 'Website'"
      item-text="label"
      item-value="value"
      label="Website & Email Language"
      class="profile-select my-2 mx-0"
      :class="[actionType === 'add' && 'required']"
      style="max-width: 230px"
      hide-details
      dense
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'LanguageAndCasualSettings',
  data() {
    return {
      langs: [
        { label: 'English', value: 'en' },
        { label: 'Hebrew', value: 'he' },
        // { label: 'Chinese', value: 'zh' },
      ],
    }
  },
  props: {
    profile: Object,
    actionType: String,
    isFieldDisabled: Boolean,
  },
}
</script>
