<template>
  <div class="my-4">
    <h4 class="settings-h4">Awaiting Files</h4>
    <div class="wrapper-1 pa-2">
      <FileLifeTime :kioskSettings="kioskSettings" />
      <AlertBeforeDelete :kioskSettings="kioskSettings" />
    </div>
  </div>
</template>

<script>
import AlertBeforeDelete from './Children/AlertBeforeDelete/AlertBeforeDelete.vue'
import FileLifeTime from './Children/FileLifeTime/FileLifeTime.vue'

export default {
  name: 'FileSettings',

  props: {
    kioskSettings: Object,
  },

  components: {
    FileLifeTime,
    AlertBeforeDelete,
  },
}
</script>
