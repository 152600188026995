<template>
  <div
    class="terms-of-use pa-2 mx-auto fs-1-12r"
    lang="en-IL"
    style="word-wrap:break-word"
  >
    <div class="WordSection1">
      <p
        class="MsoNormal"
        dir="ltr"
        style="text-align:left;direction:ltr;unicode-bidi:
embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        align="center"
        dir="ltr"
        style="text-align:center;direction:
ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR" style="">YAZAMTECH LTD. TERMS OF SERVICE</span></b>
      </p>

      <p
        class="MsoNormal"
        align="center"
        dir="ltr"
        style="text-align:center;direction:
ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style="">Effective as of December 1, 2021</span>
      </p>

      <p class="MsoNormal" style="margin-bottom:7.5pt">
        <span style="">End User License Agreement</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >Please read this legally binding End User License Agreement (“<b
            >License Agreement</b
          >”) between </span
        ><b><span dir="LTR" style="">YazamTech Ltd. (“</span></b
        ><span dir="LTR" style="">Yazamtech<b>”) </b></span
        ><span dir="LTR"
          >and Licensee carefully. By selecting the accept option, breaking the
          seal on the software package, or installing, downloading, copying or
          otherwise using the Products, Licensee acknowledges that Licensee has
          read, understands, and agrees to be bound by the terms and conditions
          of this License Agreement, including without limitation the terms and
          conditions of documents and policies that are incorporated herein by
          reference.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >If Licensee does not agree with the terms and conditions of this
          License Agreement, Licensee is not authorized to install or otherwise
          use the Products for any purpose whatsoever.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >Where a reseller, service provider, consultant, contractor or other
          party downloads, installs, manages or otherwise uses the Products on
          Licensee’s behalf, such party will be deemed to be Licensee’s agent
          and (i) such party will be deemed to have accepted all of the terms
          and conditions of this License Agreement, and (ii) to the extent
          permitted by applicable law, Licensee shall be solely responsible for
          the acts or omissions of such party in relation to its management or
          use of the Products on Licensee’s behalf.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""
          >Yazamtech continues to own the copy of the Software and the physical
          media contained in the Product and any other copies that the End User
          is authorized to make pursuant to this Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •DEFINITIONS</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Affiliates</b>” means, with respect to each party, entities that
          control, are controlled by, or are under common control with such
          party. For the purposes of this definition, “control” means the
          beneficial ownership of more than fifty percent (50%) of the voting
          power or equity in an entity.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Computer</b>”&nbsp;means any device or computing environment that
          benefits from the Licensed Product (including without limitation,
          workstations, physical servers, virtual servers, personal computers,
          laptops, netbooks, tablets, smartphones, and environments connected to
          an email server, an internet proxy or a gateway device, or a
          database). The Licensed Product does not have to be physically
          installed on the computer environment to provide benefit, nor is there
          a requirement for the computing hardware to be owned by the Licensee.
          The term “Computer” as defined herein includes, without limitation,
          non-persistent deployments, electronic devices that are capable of
          retrieving data, and virtual machines.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Documentation</b>”&nbsp;means the formal Product documentation
          (whether electronic or printed) published by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> for each Product.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Fee</b>”&nbsp;means the fee payable for the Product and/or
          Maintenance subscription.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Hardware</b>”&nbsp;means a hardware product, together with any
          related components provided by </span
        ><span dir="LTR" style="">Yazamtech</span><span dir="LTR">. </span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Licensed Products</b>”&nbsp;means all or each (as the context so
          allows) of those software program(s) accompanying or provided for use
          under the terms of this License Agreement, listed on the Order and/or
          that are installed on the Hardware provided to Licensee and/or
          installed on Licensee’s Computer in support for the Hardware, together
          with the Documentation and any of the Upgrades and Updates to those
          programs, but excluding any third party software as described in
          Section ‎9.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Licensee</b>”&nbsp;means the person or entity that has been
          granted license rights under this License Agreement, and
          “<b>Licensee’s</b>” means belonging to, pertaining to or engaged by
          Licensee, whether on a temporary basis or otherwise.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Maintenance</b>”&nbsp;means collectively Upgrades and/or Updates
          (where applicable to the Product) and standard technical support as
          further described in Section ‎4.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Order</b>”&nbsp;means the order confirmation or license
          certificate issued by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          that details the Product(s) licensed by Licensee and corresponding
          Product Term, License Entitlement and license credentials, and which
          forms part of this License Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Outsourced Provider</b>”&nbsp;means a third party to whom
          Licensee or Licensee’s Affiliates have outsourced their information
          technology functions.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Partner</b>”&nbsp;means a reseller, distributor or other
          independent third party from which Licensee validly obtains </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> Products.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Product</b>”&nbsp;means the Licensed Product, media, Hardware
          and/or Software, as applicable.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Sanctions and Export Control Laws</b>”&nbsp;means any law,
          regulation, statute, prohibition, or similar measure applicable to the
          Products and/or to either party relating to the adoption, application,
          implementation and enforcement of economic sanctions, export controls,
          trade embargoes or any other restrictive measures, including, but not
          limited to, those administered and enforced by the European Union, the
          United Kingdom, the United States, or any other jurisdiction, each of
          which shall be considered applicable to the Products.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Server</b>”&nbsp;means a Computer upon which the Licensed Product
          is installed and from which other Computers receive or retrieve data.
          If the data is solely generated by the Licensed Product, then the
          Computer is not considered a Server.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Taxes</b>”&nbsp;means all transaction taxes, including foreign
          withholding taxes, and local, state, provincial, federal or foreign
          taxes, levies, duties or similar governmental assessments of any
          nature, including value-added taxes, excise, use, goods and services
          taxes, consumption taxes.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Update</b>”&nbsp;means an update to the library of rules and/or
          identities and/or other updates to the detection data or software
          (excluding Upgrades) made available to Licensee by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          at its sole discretion from time to time automatically or otherwise,
          but excluding any updates marketed and licensed by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> for a separate Fee.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>Upgrade</b>”&nbsp;means any enhancement or improvement to the
          functionality of the Product, Product version or Product feature made
          available to Licensee by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          at its sole discretion from time to time automatically or otherwise,
          but excluding any software and/or upgrades marketed and licensed by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> for a separate Fee.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >“<b>User</b>”&nbsp;means an employee, consultant or other individual
          who benefits from the Product licensed to Licensee.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b
          ><span dir="LTR">
            •INTELLECTUAL PROPERTY RIGHTS AND OWNERSHIP</span
          ></b
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.3.4pt;margin-bottom:
6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >The Products, including without limitation all know-how, concepts,
          logic and specifications, are proprietary to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          and its licensors and are protected throughout the world by copyright
          and other intellectual property rights. Licensee hereby agrees not to
          remove any product identification or notices of proprietary
          restrictions. Further, Licensee hereby acknowledges and agrees that
          the right, title and interest in the Products and in any modifications
          made by Licensee to the Products, as provided for below in this
          License Agreement, are the property of, and are retained by, </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          and its licensors. No license, right or interest in </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          logos or trademarks is granted to Licensee under this License
          Agreement.&nbsp;Licensed Products are licensed, not sold or
          given.&nbsp;Except as expressly stated in this License Agreement, no
          license or right is granted directly or by implication, inducement,
          estoppel, or otherwise. Licensee undertakes not to violate or infringe
          the Intellectual Property rights or the privacy or publicity rights of
          any natural person or entity nor use Services in a way intended to
          hinder the mission of the cybersecurity vendor industry to protect
          customers from Malware infection.
        </span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •RIGHTS AND RESTRICTIONS</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Term</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •This License Agreement is effective from the moment of acceptance as
          described in the first paragraph of this License Agreement and shall
          remain in force until the earlier of (i) expiry of the Product Term of
          all items licensed hereunder, or (ii) termination of this License
          Agreement in accordance with the terms and conditions herein.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Unless this License Agreement is terminated earlier, (i) the Product
          Term for fixed term subscription-based Licensed Products, Maintenance
          packages and support packages shall commence on the start date and end
          on the expiry date noted on the Order, and (ii) the Product Term for
          Products that are billed on an auto-renewing basis shall commence on
          the date of purchase and continue for the duration of the authorized
          payments (subject to any minimum subscription term that may be noted
          on the Order) (each the “<b>Product Term</b>”).</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •In consideration of the payment of the Fee by Licensee and receipt of
          the corresponding payment by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >, hereby grants to Licensee a non-exclusive, non-sublicensable, and
          non-transferable (except as otherwise provided in this License
          Agreement) right to use the Products for their respective Product Term
          subject to the terms and conditions contained within this License
          Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •License Entitlement and Usage. The Products are licensed by User,
          Computer, Server or other applicable units. The Order specifies the
          number of applicable units that the Licensee has licensed for each
          Product (the “<b>License Entitlement</b>”). The Licensee’s actual
          usage and/or installations, including without limitation any usage or
          installations for failover purposes, may not exceed the License
          Entitlement at any time or under any circumstances. If Licensee wishes
          to increase its actual usage, Licensee must first purchase the
          corresponding additional License Entitlement.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Rights.&nbsp;Licensee is permitted to:</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Use the Products solely for Licensee’s and its Affiliates’ own
          internal information security purposes;</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Make a reasonable number of copies of the Licensed Products or any
          part thereof for backup or recovery purposes provided that Licensee
          reproduces Yazamtech ’s proprietary notices on any such backup copy of
          the Licensed Products. Such restriction shall not prevent Licensee
          from backing up or archiving Licensee’s data.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Restrictions.&nbsp;Licensee is not permitted to:</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Modify or translate the Products (i) except as necessary to configure
          the Licensed Products using the menus, filters, options and tools
          provided for such purposes and contained in the Product, and (ii) in
          relation to the Documentation, except as necessary to produce and
          adapt manuals and/or other documentation for Licensee’s internal
          business purposes;</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Reverse engineer, disassemble (including without limitation, removing
          the covering plates that bar access to the Hardware ports and/or
          accessing internal components of the Hardware) or decompile the
          Products or any portion thereof or otherwise attempt to derive or
          determine the source code or the logic therein, or create derivative
          works based on the Products, or authorize any third party to do any of
          the foregoing, except to the extent that such restriction is
          prohibited by applicable law;</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Except to the extent expressly stated otherwise in this License
          Agreement, use Products for which Licensee has not paid and Yazamtech
          has not received the applicable Fees;</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Sub-license, rent, sell, lease, distribute, transfer, transmit,
          embed, provide access to, or otherwise use the Products for the
          benefit of third parties as part of a service bureau or
          managed-service arrangement, cloud services offering, bundled product
          or otherwise, except as expressly provided under this License
          Agreement and/or unless Licensee enters into a separate agreement with
          Yazamtech for such purposes;</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Use the Products other than in the course of business and for
          Licensee’s own internal information security purposes unless and to
          the extent that such Products have been expressly licensed for
          employee personal use according to Section ‎14.3;</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Transfer Licensed Products to any third party without the prior
          written consent of Yazamtech; and/or</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Use the Products in or in association with safety critical
          applications where the failure of the Products to perform can
          reasonably be expected to result in a physical injury, or in loss of
          property, or loss of life. Any such use is entirely at Licensee’s own
          risk, and Licensee agrees to hold Yazamtech harmless from and hereby
          releases Yazamtech from all claims or losses relating to such
          unauthorized use.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •ITAR&nbsp;International Traffic in Arms Regulations (“ITAR”) controls
          the dissemination of technical data related to weapons, weapon
          systems, and other defense articles contained on the U.S. Munitions
          List. Licensee agrees not to use or allow a third party under its
          control to submit content that is subject to ITAR maintained by the
          U.S. Department of State (i.e. ITAR-controlled technical data), or
          otherwise cause Yazamtech to provide a Defense Service as defined in
          ITAR.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Permitted Third Party Usage. Licensee may permit its Affiliates and
          Outsourced Providers to use the Products provided that: (i) Licensee
          shall provide prior written notice to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >, (ii) the Affiliates and Outsourced Providers shall only use and/or
          operate the Products, in accordance with the license rights granted
          herein, on behalf of Licensee and its Affiliates, (iii) the actual
          usage of the Products by Licensee, Licensee’s Affiliates and
          Outsourced Providers in aggregate shall not exceed the License
          Entitlement purchased by the Licensee, (iv) Licensee shall ensure that
          its Affiliates and the Outsourced Providers are aware of and comply
          with the terms and conditions of this License Agreement; and, (v)
          Licensee shall be responsible for, and hold </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          harmless from, the acts and omissions of its Affiliates and Outsourced
          Providers relating to use of the Products.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee acknowledges and agrees that it has not based its purchasing
          decision on the future availability of any new products and/or
          additional features, components or versions of the Products, nor on
          any oral or written comments made by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> regarding future functionality or features.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee is solely responsible for its compliance with the applicable
          terms and conditions of any third-party agreements, including without
          limitation payment of any third-party fees, for hardware, software,
          connectivity and any other third-party products and services.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee should not conduct any activity purposed to overload, harm
          or imped the function the services or the Product. Nor shall resell,
          redistribute, rent, sell, or do unauthorized modify, reroute or
          attempt to carry out these activities.
        </span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •MAINTENANCE AND SUPPORT</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Unless otherwise stated in the Order, Maintenance is included for the
          duration of the Product Term.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Maintenance includes standard/base level remote technical support
          provided from [   ] AM to [    ] PM ([   ]) Israel Standard Time,
          Sunday through Thursday, excluding holidays. </span
        ><span dir="LTR" style=""
          >Upon Yazamtech’s receipt of a support request, Yazamtech will use
          commercially reasonable efforts to answer questions and provide
          standard error corrections to known problems.</span
        ><span dir="LTR"> Unless otherwise authorized by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          in writing, technical support is provided for the latest version of
          the relevant Product. </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          may, but is not obliged to, continue supporting old or discontinued
          Product versions.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style="">
          •Support does not include training sessions on the features and
          functionality of the Products or training in computer skills
          considered prerequisite to an individual’s ability to use personal
          computers, the Internet/World Wide Web and online software.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""> •Yazamtech</span
        ><span dir="LTR">
          reserves the right in its discretion to limit the number of Users who
          may contact </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> technical support.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style="">
          •Yazamtech retains the right to block, or otherwise prevent delivery,
          of any file, email, or other communication to or from the
          Services.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Custom or sample code, files or scripts (“<b>Fixes</b>”) provided by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          as part of the provision of technical support that do not form part of
          its standard commercial offering may only be used (i) in conjunction
          with the Product for which such Fixes were developed, and (ii) during
          the relevant Product Term.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b
          ><span dir="LTR">
            •WARRANTIES FOR LICENSED PRODUCTS; INDEMNITY</span
          ></b
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""> •Yazamtech</span
        ><span dir="LTR">
          warrants to Licensee only that for a period of ninety (90) days from
          the date of purchase (the &quot;<b
            >Licensed Products Warranty Period</b
          >&quot;): (i) the Licensed Products will perform substantially in
          accordance with the Documentation provided that they are operated in
          accordance with the Documentation on the designated operating
          system(s); and (ii) the Documentation will adequately describe the
          operation of the Licensed Products in all material respects.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •If Licensee notifies </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          in writing of a breach of warranty under Section ‎5.1&nbsp;during the
          Licensed Products Warranty Period, </span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR"
          >’s entire liability and Licensee’s sole remedy shall be at </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >’s option: (i) to correct, repair or replace the Licensed Product
          (limited to one replacement) and/or Documentation within a reasonable
          time, or (ii) to terminate the License Agreement as to the affected
          Licensed Product, and the corresponding License Entitlement, and
          authorize a&nbsp;pro rata refund of the Fee following return of
          the&nbsp;relevant Licensed Product (and all copies thereof)
          accompanied by proof of purchase. Any replacement Licensed Products
          shall be warranted for the remainder of the original Licensed Products
          Warranty Period.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •The warranty in&nbsp;Section ‎5.1&nbsp;shall not apply if (i) the
          Licensed Product has not been used in accordance with the terms and
          conditions of this License Agreement and the Documentation, (ii) the
          issue has been caused by failure of the Licensee to apply Updates,
          Upgrades or any other action or instruction recommended by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >, (iii) the issue has been caused by the act or omission of, or by
          any materials supplied by, the Licensee or any third party, or (iv)
          the issue results from any cause outside of </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">’s reasonable control.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Subject to Sections ‎5.5&nbsp;through ‎5.7,&nbsp;</span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">
          shall (a) defend, indemnify, and hold Licensee harmless from any third
          party claim, action, suit or proceeding alleging that Licensee’s use
          or possession of the Product in accordance with the terms and
          conditions of this License Agreement infringes such third party’s
          patent, trademark or copyright (“<b>Claim</b>”); and (b) reimburse
          Licensee’s reasonable attorney’s fees and costs actually incurred and
          any damages finally awarded or agreed to by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> in a monetary settlement.&nbsp;</span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">
          shall have sole control of the defense of the Claim and all associated
          settlement negotiations.&nbsp;</span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          may require Licensee to join and co-operate with the defense and/or
          settlement of the Claim at </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">’s cost.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •If a Claim is made or appears likely to be made, </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >, in its sole discretion, may: (i) procure a license so that
          Licensee’s use and possession of the Licensed Product in accordance
          with the terms and conditions of this License Agreement does not
          infringe any third party patents, trademarks or copyrights; (ii)
          modify or replace the Product with a functionally equivalent Product
          so that it no longer infringes the third party’s patents, trademarks
          or copyrights; or (iii) terminate the license to use the Product upon
          notice to Licensee and provide a pro rata refund of Fees paid for such
          Product that relates to the period after the date of termination in
          the case of subscription term Products.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Exclusions.&nbsp;</span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          shall have no liability or responsibility to indemnify, defend, and
          hold Licensee harmless under Section ‎5.4</span
        ><span dir="LTR" style=""> </span
        ><span dir="LTR">if: (i) Licensee fails to notify </span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">
          in writing within ten (10) days of Licensee being notified of any such
          Claim, (ii) Licensee does not, at the written request of </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >, promptly cease to use or possess the Product that is the subject of
          the Claim, (iii) Licensee, without </span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">
          prior written consent, acknowledges the validity of the Claim or takes
          any action that might impair the ability of </span
        ><span dir="LTR" style="">Yazamtech </span
        ><span dir="LTR"
          >to contest the Claim, (iv) the infringement arises due to
          modification of the Product by anyone other than </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >, use of the Product other than in accordance with the Documentation,
          or use of the Product with any hardware, software or other component
          not provided by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >, and the infringement would not have arisen without such use or
          modification, or (v) the Claim is raised based on use or possession in
          a country that is not a party to the World Intellectual Property
          Organization (WIPO) treaties on patents, trademarks and
          copyrights.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Sections ‎5.4, ‎5.5 and ‎5.6&nbsp;set out licensee’s sole and
          exclusive remedy and&nbsp;</span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >’s sole obligation and liability in the event that the products
          infringe or are alleged to infringe the patents, trademarks,
          copyrights or other intellectual property rights of any third party.
          licensee will in any event mitigate licensee’s losses as far as
          possible.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •DISCLAIMER OF WARRANTIES</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Except for the express warranties set forth in sections 5, </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          and its third-party licensors and suppliers and the contributors of
          certain included software make no warranties, conditions, undertakings
          or representations of any kind, either express or implied, statutory
          or otherwise, in relation to the product or any third-party software,
          including without limitation any implied warranties or conditions of
          merchantability, satisfactory quality, uninterrupted use, fitness for
          a particular purpose, non-infringement or arising from course of
          dealing, usage or trade.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Without limitation to the foregoing,</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR">
          does not warrant or represent that (i) the product will meet
          licensee’s requirements, (ii) the operation of the product will be
          error free or uninterrupted, (iii) defects in the product will be
          corrected, (iv) the products will detect, correctly identify and/or
          disinfect all threats, applications (whether malicious or otherwise)
          or other components, (v) licensee is entitled to block any third party
          applications, or (vi) that licensee is entitled to encrypt or decrypt
          any third party information.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee further acknowledges and agrees that licensee shall be
          solely responsible for proper back-up of all its data and that
          licensee shall take appropriate measures to protect such data. </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          and its third-party licensors assume no liability or responsibility
          whatsoever if data is lost or corrupted.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •LIMITATION OF LIABILITY</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee uses the product at licensee’s own risk. to the maximum
          extent permitted by applicable law, in no event shall </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          or any of its third-party licensors and suppliers or the contributors
          of included software be liable to licensee for, or to those claiming
          through licensee for, any indirect, consequential, incidental or
          special damage or loss of any kind including, but not limited to, loss
          of profits, loss of contracts, business interruptions, cost of
          substitute goods or services, loss of or corruption of data however
          caused and whether arising under contract or tort (including without
          limitation negligence), and including without limitation any loss or
          damage related to any third party software even if </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          has been advised of the possibility of such damages. the limitations
          in this section shall apply notwithstanding the failure of the
          essential purpose of any remedy.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •If any limitation, exclusion, disclaimer or other provision contained
          in this license agreement is held to be invalid for any reason by a
          court of competent jurisdiction and </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          becomes liable thereby for loss or damage that may lawfully be
          limited, such liability whether in contract, tort (including without
          limitation negligence) or otherwise, will not exceed the lesser of (i)
          the fee paid by licensee and (ii)</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR">'s list price for the product.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •In no event shall </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >’s aggregate liability to licensee arising out of or in connection
          with this license agreement, from all causes of action and theories of
          liability (including without limitation negligence), exceed the lesser
          of (i) the fee paid by licensee and (ii) </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">’s list price for the product.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""> •Yazamtech</span
        ><span dir="LTR">
          does not limit or exclude its liability for (i) death or personal
          injury caused by negligence, (ii) fraudulent misrepresentation, or
          (iii) any other liability to the extent that such liability cannot be
          excluded or limited by applicable law.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •PRODUCT CHANGES</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Subject to Sections 4 and ‎10, and in </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >’s sole discretion, Licensee may receive software Updates, and may be
          required to install or allow installation of software Updates as a
          condition of continued use of Licensed Products. Licensee acknowledges
          and agrees that</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR">
          may vary, Update or discontinue Products, Product versions, Product
          features, Product support, Product Maintenance, and support for third
          party products (including without limitation operating systems and
          platforms) from time to time for reasons including but not limited to
          changes in demand or enhancing security and technology. Licensee
          consents to receive Updates or Upgrades to the Licensed Products
          automatically through the Internet without obtaining further consent
          each time. </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          is not responsible if an Update or Upgrade affects how a Licensed
          Product works if this is caused by Licensee’s own equipment or device
          not supporting the Update or Upgrade.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""> •Yazamtech</span
        ><span dir="LTR">
          will provide a reasonable amount of advance notice of any planned
          Product discontinuation of a core Product feature, Licensee’s license
          to a Product, Product Maintenance, Product support, or support for
          third party products (a “<b>Discontinuation</b>”) to Licensee,
          including without limitation by emailing the date(s) of each planned
          Discontinuation.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Yazamtech may make unscheduled deployments of changes, releases,
          improvements, or enhancements to the Cloud Services any time. During
          such deployments, Licensee acknowledge certain features and
          functionality of the Cloud Services may be unavailable and outages may
          occur.</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR">
          recommends Licensees always use the latest version of a Product and/or
          third-party product.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Unless otherwise required by applicable law, </span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">
          will not provide a refund of Fees paid for a Product or service that
          is subject to a Discontinuation. </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          may, at its sole discretion, substitute a Product, service, or
          management platform subject to a Discontinuation with a Product,
          service, or management platform with substantially equivalent
          functionality.
        </span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •THIRD PARTY SOFTWARE</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >The Products may operate or interface with software or other
          technology that is licensed to</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR">
          from third parties. Licensee agrees that (a) it will use such third
          party software in accordance with this License Agreement, (b) no third
          party licensor makes any warranties, conditions, undertakings or
          representations of any kind, either express or implied, to Licensee
          concerning such third party software or the Products themselves, (c)
          no third party licensor will have any obligation or liability to
          Licensee as a result of this License Agreement or Licensee's use of
          such third party software, (d) the third party licensor is a
          beneficiary of this License Agreement and accordingly may enforce the
          terms and conditions herein to the extent necessary to protect its
          rights in relation to the third party software, and (e) such third
          party software may be licensed under license terms that grant Licensee
          additional rights or contain additional restrictions in relation to
          such materials, beyond those set forth in this License Agreement, and
          such additional license rights and restrictions are described or
          linked to in the applicable Documentation, the relevant </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          webpage, or within the Product itself. For the avoidance of any doubt,
          such additional rights and/or restrictions apply to the third-party
          software on a standalone basis; nothing in such third-party licenses
          shall affect Licensee’s use of the Licensed Products in accordance
          with the terms and conditions of this License Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      ></p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      ></p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:left;direction:ltr;unicode-bidi:
embed"
      >
        <b
          ><span dir="LTR">
            •EXPORT CONTROL,&nbsp;ANTI-BRIBERY AND&nbsp;COMPLIANCE WITH
            APPLICABLE LAWS</span
          ></b
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee is solely responsible for ensuring that the Products are
          used, accessed, disclosed and/or transported only in accordance with
          Sanctions and Export Control Laws.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee certifies that Licensee or Users, or any party that owns or
          controls or is owned or controlled by Licensee or Users, are not (i)
          ordinarily resident in, located in, or organized under the laws of any
          country or region subject to economic or financial sanctions or trade
          embargoes imposed, administered, or enforced by the European Union,
          the United Kingdom, or the United States; (ii) an individual or entity
          on the Consolidated List of Persons, Groups, and Entities Subject to
          European Union Financial Sanctions; the U.S. Department of the
          Treasury's List of Specially Designated Nationals and Blocked Persons
          or Foreign Sanctions Evaders List; the U.S. Department of Commerce's
          Denied Persons List or Entity List; or any other sanctions or
          restricted persons lists maintained by the European Union, the United
          Kingdom, or the United States; or (iii) otherwise the target or
          subject of any Sanctions and Export Control Laws. Licensee further
          certifies that it will not, directly or indirectly, export, re-export,
          transfer, or otherwise make available (a) the Products, or (b) any
          data, information, software programs and/or materials resulting from
          the Products (or direct product thereof) to any country, region, or
          person described in this Section or in violation of, or for purposes
          prohibited by, Sanctions and Export Control Laws, including for
          proliferation-related end uses.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Licensee agrees that </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          shall have no obligation to provide any Updates, Upgrades or services
          related to the Products where </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          believes the provision of such Updates, Upgrades or services could
          violate Sanctions and Export Control Laws.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Each party warrants that in entering into this License Agreement
          neither the party nor any of its officers, employees, agents,
          representatives, contractors, intermediaries or any other person or
          entity acting on its behalf has taken or will take any action,
          directly or indirectly, that contravenes (i) the United Kingdom
          Bribery Act 2010, or (ii) the United States Foreign Corrupt Practices
          Act 1977, or (iii) any other applicable anti-bribery laws or
          regulations anywhere in the world.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee warrants that its use and possession of the Products is and
          will continue to be in accordance with all other applicable laws and
          regulations. In particular but without limitation, Licensee
          acknowledges and agrees that it may be necessary under applicable law
          for Licensee to inform and/or obtain consent from individuals before
          it intercepts, accesses, monitors, logs, stores, transfers, exports,
          blocks access to, and/or deletes their communications. Licensee is
          solely responsible for compliance with such laws.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Any breach or suspected breach of this section ‎10 by licensee shall
          be a material breach incapable of remedy and entitles </span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">
          to terminate this license agreement immediately with or without notice
          to licensee. In addition, Licensee agrees (to the extent permitted by
          applicable law, and without waiver of Licensee’s constitutional,
          statutory, or other immunities, if any) to indemnify and hold </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          harmless from and against any claim, proceeding, loss, liability, cost
          or damage suffered or incurred by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          resulting from or related to Licensee’s violation of this Section
          10.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •TERMINATION</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •This License Agreement and Licensee’s rights under it will terminate
          immediately if: (i) Licensee fails to pay the Fee to </span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">
          or the Partner (as applicable) in accordance with the agreed payment
          terms; or (ii) </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          does not receive payment from the relevant Partner for the Products
          and packages provided to Licensee, or (iii) Licensee breaches any of
          the terms and conditions of this License Agreement; or (iv) if
          Licensee becomes insolvent.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee may terminate the license for the applicable Licensed
          Product(s) at any time by uninstalling and/or returning or destroying
          (if so requested by</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR"
          >) the relevant Product and Documentation and all copies
          thereof.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Within one (1) month after the date of termination of this License
          Agreement or the applicable Product Term, upon </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">’s request, Licensee will supply </span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">
          with written certification of the destruction of all partial and
          complete copies of the applicable Licensed Product and Documentation.
          In the case of encryption Products, Licensee shall decrypt all
          encrypted drives and data prior to uninstalling and destroying the
          Product.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee’s right to use and access the Products will automatically
          terminate on expiry of the applicable Product Term or this License
          Agreement (whichever is the earlier) unless and until Licensee renews
          Licensee’s license for the Products.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Termination for cause: Either party may terminate this License
          Agreement for cause upon thirty (30) days' written notice to the other
          party of a material breach of the Agreement if such breach remains
          uncured after the expiration of such period.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •CONFIDENTIALITY AND&nbsp;DATA PROTECTION</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""> •Yazamtec h</span
        ><span dir="LTR"
          >and the Licensee may receive or have access to confidential
          information under or in relation to this License Agreement that is
          secret and valuable to the other party and its licensors.&nbsp;A
          recipient is not entitled to use, communicate or disclose the other
          party’s confidential information to a third party without the
          disclosing party’s prior, written consent. The recipient will use the
          same degree of care that it uses to protect the confidentiality of its
          own confidential information of like kind (but not less than
          reasonable care).</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •The Licensed Products (including any Updates or Upgrades) may (if
          applicable): (i) cause Licensee’s device to automatically communicate
          with </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >’s servers to deliver the functionality described in the product
          description or through new features as they are introduced, and to
          record usage metrics; (ii) affect preferences or data stored on
          Licensee’s device; and (iii) collect personal information as set out
          in our&nbsp;Privacy Notice (the “<b>Privacy Notice</b>”). Licensee
          acknowledges and agrees that </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          may directly and remotely communicate with the Products in order to
          provide Maintenance and technical support, and to collect the
          following types of information: (i) Products, Product versions,
          Product features and operating systems being used by Licensee, (ii)
          processing times taken by the Product, (iii) Licensee’s customer
          identification code and company name, and (iv) IP and/or MAC address
          and/or ID of the machine that returns the above listed information.
          Certain Products may require the collection of additional information
          as detailed in the Privacy Notice.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •The information collected under Section ‎12.2</span
        ><span
          dir="LTR"
          style="font-family:
Times"
        >
        </span
        ><span dir="LTR"
          >may be used for the purposes of (i) providing the Products and
          performing this License Agreement, (ii) verifying Licensee’s
          compliance with the License Entitlement, (iii) evaluating and
          improving the performance of the Products, (iv) preparing statistical
          analysis (such as malware infection rates and the usage of Products),
          (v) planning development roadmaps and product lifecycle strategies,
          (vi) issuing alerts and notices to Licensee about incidents and
          product lifecycle changes that affect the Products being used by
          Licensee.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""> •Yazamtech</span
        ><span dir="LTR">
          may also require identification information for the Licensee,
          including, but not limited to, Licensee’s contact details and (where
          applicable) payment information for the purposes of (i) providing
          technical support, (ii) billing, (iii) verifying Licensee’s
          credentials and License Entitlement, (iv) issuing license expiry and
          renewal notices, (v) carrying out compliance checks for export and
          sanction control purposes, and (vi) providing account management.
          Licensee agrees to provide complete and accurate identification
          information to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> promptly upon </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">’s request.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •If the Licensee elects to send malware samples or any other materials
          to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          for review, the Licensee shall remove any regulated personally
          identifiable information, health information, and payment card data
          prior to submission.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •GENERAL</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Any Partner from whom Licensee may have purchased the Product is not
          appointed by </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          as its servant or agent. No such person has any authority, either
          express or implied, to enter into any contract or provide any
          representation, warranty or guarantee to Licensee or any third party
          or to translate or modify this License Agreement in any way on behalf
          of </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> or otherwise to bind </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> in any way whatsoever.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Licensee has no obligation to provide </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          with ideas, suggestions, concepts, or proposals relating to</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR"
          >’s products or business (“<b>Feedback</b>”). However, if Licensee
          provides Feedback to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">, Licensee grants</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR"
          >a non-exclusive, perpetual, irrevocable, worldwide, sub-licensable,
          transferable, royalty-free right and license to store, make, use,
          sell, market, have made, offer to sell, import, reproduce, publicly
          display, transmit, distribute, modify, publicly perform, and otherwise
          exploit such Feedback, in whole or in part, for any purpose, including
          combining the Feedback with other materials and/or products and making
          derivative works of or alterations to the Feedback in any manner or
          format whatsoever, without any reference, obligation, or remuneration
          to Licensee.&nbsp;All Feedback shall be deemed non-confidential to
          Licensee.&nbsp;Licensee shall not provide to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          any Feedback it has reason to believe is or may be subject to the
          intellectual property claims or rights of a third party.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •(i) Self-Audits. To help manage Licensee’s use of the Products and
          Licensee’s compliance with this License Agreement, Licensee agrees to
          perform a self-audit upon ten (10) working days’ prior written notice
          from </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >, calculating the number of Users, Computers, Servers or other
          applicable units benefiting from the Products. If Licensee’s
          self-audit reveals that Licensee’s actual usage exceeds the License
          Entitlement, Licensee shall procure the additional licenses required
          from </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          or its preferred Partner. (ii) Formal Audits. If Licensee does not
          perform a self-audit upon request from </span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">, or if </span><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          has reason to doubt the results of such self-audit, upon prior written
          notice to Licensee, Licensee shall permit</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR">
          or an independent certified accountant appointed by</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR">
          to access Licensee’s premises and inspect Licensee’s books of account
          and records at any time during normal business hours for the purpose
          of inspecting, auditing, verifying or monitoring the manner and
          performance of Licensee’s obligations under this License Agreement,
          including without limitation the payment of all applicable license
          fees. Any such audit shall minimize the disruption to Licensee’s
          business operations.&nbsp;</span
        ><span
          dir="LTR"
          style="font-family:
Times"
          >Yazamtech</span
        ><span dir="LTR">
          shall not be able to exercise this right more than once in each
          calendar year. If an audit reveals that Licensee has underpaid fees to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">, Licensee shall be invoiced for and shall pay to</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR">
          or the Partner (as applicable) within thirty (30) days of the date of
          invoice an amount equal to the shortfall between the fees due and
          those paid by Licensee. If the amount of the underpayment exceeds five
          percent (5%) of the fees due or the audit reveals a violation of any
          license restrictions pursuant to this License Agreement then, without
          prejudice to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >’s other rights and remedies, Licensee shall also pay </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">’s reasonable costs of conducting the audit.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""> •Yazamtech</span
        ><span dir="LTR">
          may in its sole discretion assign, novate, subcontract or otherwise
          transfer any of its rights or obligations hereunder.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""> •Yazamtech</span
        ><span dir="LTR">
          may amend the terms and conditions of this License Agreement and/or
          any documents and policies referenced herein at any time by notice to
          Licensee, including without limitation by posting revised terms and
          conditions on its website and/or the location of such document or
          policy. Such amended terms and conditions shall be binding upon
          Licensee with effect from the date of such change. For the avoidance
          of doubt, such amended terms and conditions shall supersede any prior
          version of the License Agreement that may have been embedded in or
          packaged with the Product itself.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Failure by either party to enforce any particular term or condition
          of this License Agreement shall not be construed as a waiver of any of
          its rights under it.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •The illegality, invalidity or unenforceability of any part of this
          License Agreement will not affect the legality, validity or
          enforceability of the remainder.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •If Licensee and </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          have signed a separate written agreement covering the licensing and
          use of the Products, the terms and conditions of such signed agreement
          shall take precedence over any conflicting terms and conditions of
          this License Agreement. Otherwise this License Agreement, the Order
          and the documents and policies referenced herein constitute the entire
          agreement between the parties relating to the licensing and use of the
          Products and supersede any other oral or written communications,
          agreements or representations with respect to the Products, except for
          any oral or written communications, agreements or representations made
          fraudulently.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •If there are any inconsistencies between the English language version
          of this License Agreement and any translated version, the English
          language version shall prevail.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Subject to Section 9(d), a person who is not a party to this License
          Agreement has no right to enforce any term or condition of this
          License Agreement, and the parties to this License Agreement do not
          intend that any third-party rights are created by this License
          Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Governing Law. This License Agreement, the relationship between
          Licensee and</span
        ><span dir="LTR" style=""> Yazamtech</span
        ><span dir="LTR"
          >, and any dispute or claim arising out of or in connection with it,
          including without limitation non-contractual disputes or claims, shall
          be governed by and construed in accordance with the laws of the State
          of Israel notwithstanding its conflicts of law principles.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Jurisdiction. The courts located in Tel Aviv, Israel shall have
          exclusive jurisdiction to determine any dispute or claim that may
          arise out of, under, or in connection with this License
          Agreement.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •The parties agree to resolve any and all disputes, disagreements or
          differences between the parties relating to this Agreement as follow:
          The parties hereto agree to first attempt to settle any and all
          disputes, disagreements or differences between the parties relating to
          their business relationship with each other through a mediation
          (“<b>Mediation</b>”). In the event that the Mediation does not achieve
          a resolution of all issues which are governed by this Agreement, then
          the parties will proceed to arbitration (“<b>Arbitration</b>”)
          pursuant to the process. Any party to the Mediation can select a
          mediator who is registered as a mediator with ADR Chambers in Israel
          (also the Arbitrator hereafter). The first such proposed mediator
          shall be selected to conduct the Mediation. The Mediation shall take
          place within thirty (30) days of the delivery of the Notice to
          Mediate. If Mediation is unsuccessful, any and all remaining disputes,
          disagreements or differences between the parties relating to their
          business relationship with each other, including any dispute,
          disagreement or difference relating to the validity, enforceability or
          applicability of this agreement to arbitrate, shall be submitted to
          final and binding Arbitration. No appeal lies to any court on any
          question of fact, law or mixed fact and law. The Arbitration shall be
          commenced by one party delivering to the other party a Notice to
          Arbitrate which shall set out a brief description of the dispute,
          disagreement or difference to be arbitrated and a summary of the
          relief claimed.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Class Action Waiver. The parties agree that the arbitration shall be
          conducted in the party’s respective individual capacities only and not
          as a class action or other representative action, and the parties
          expressly waive their right to file a class action or seek relief on a
          class basis.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Nothing in Section ‎13.1&nbsp;shall limit the&nbsp;right of Yazamtech
          to initiate proceedings against Licensee in any court of competent
          jurisdiction where deemed necessary by Yazamtech to (i) protect its
          intellectual property rights, (ii) protect its confidential
          information, and/or (iii) recover overdue payments.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Any notices required to be given to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          or any questions concerning this License Agreement should be addressed
          to </span
        ><span dir="LTR" style="">info@Yazamtech.com</span
        ><span dir="LTR">.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •The following Sections shall survive any termination or expiration of
          this License Agreement:&nbsp;‎2,&nbsp;‎6,&nbsp;‎7,
          8,&nbsp;‎10,&nbsp;‎11.3,&nbsp;‎12.1,&nbsp;‎13, 14.1.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Force Majeure. The failure of </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          to comply with any provision of this License Agreement due to an act
          of God, hurricane, war, fire, riot, earthquake, pandemic, terrorism,
          and act of public enemy, actions of governmental authorities
          (excepting compliance with applicable codes and regulations) or other
          force majeure event will not be considered a breach of c.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Relationship. The parties enter into this License Agreement as
          independent contracting parties. Neither party will have, or hold
          itself out as having, any right or authority to incur any obligation
          on behalf of the other party. These Terms will not be construed to
          create an association, joint venture, or partnership between the
          parties or to impose any partnership liability upon any party.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <b><span dir="LTR"> •ADDITIONAL TERMS AND CONDITIONS.</span></b>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-bottom:6.0pt;text-align:justify;
text-justify:inter-ideograph;direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"
          >The first part of this License Agreement includes general terms and
          conditions applicable to all Products. The additional terms and
          conditions in this Section ‎14</span
        ><span dir="LTR" style=""> </span
        ><span dir="LTR"
          >below apply only to the Products referenced in each section.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.36.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      ></p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Payment</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Invoices may provide for interest to be paid on any sums not remitted
          by the due date.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Yazamtech reserves the right to change its Fee from time to time and
          at any time. If the Licensee does not agree to the new Fee, Licensee
          should stop using the Services and the Product.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •All Fee do not include Taxes. Yazamtech may calculate Taxes payable
          by Licensee based on the billing information provided.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •All Fees paid or payable are non-refundable to the maximum extent
          permitted by law.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee pays its Fee according to the payment terms in an Order.
          Failure to pay charges or Fee may result in the suspension or
          termination of Services.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR" style=""> •Yazamtech</span
        ><span dir="LTR">
          Cloud Products&nbsp;(“<b>Cloud Products</b>”).&nbsp;This Section only
          applies to </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> Cloud Products:</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •The Licensee shall not store or transmit any content through the
          Cloud Products that (i) is unlawful, pornographic, obscene, indecent,
          harassing, racially or ethnically offensive, harmful, threatening,
          discriminatory or defamatory, (ii) facilitates or promotes illegal
          activity, (iii) infringes any third-party intellectual property
          rights, or (iv) is otherwise inappropriate (“<b>Prohibited</b>
          <b>Content</b>”).</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •The Licensee acknowledges that Yazamtech has no control over any
          content stored or transmitted by Licensee, does not monitor such
          content and accordingly acts as a mere conduit. Yazamtech reserves the
          right to remove content from the Cloud Products immediately without
          prior notice where it reasonably suspects that such content is
          Prohibited Content. The Licensee shall (to the extent permitted by
          applicable law, and without waiver of Licensee’s constitutional,
          statutory, or other immunities, if any) indemnify and hold Yazamtech
          harmless from and against all damages, losses and expenses arising as
          a result of any third-party action or claim relating to Licensee’s
          content. Licensee is solely responsible for all activity occurring
          under Licensee’s Product, Cloud Product and support accounts,
          including the rights and privileges Licensee grants to Users and any
          activity undertaken or decision made by Users.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •The Product or Cloud Product may enable access to or link third
          parties’ websites, platforms, content, products, services or
          information (“<b>Third Party Services</b>”). Third Party Services are
          not part of the Product or Cloud Product, and Yazamtech does not
          control and is not responsible for the Third-Party Services. Licensee
          is solely responsible for: (a) obtaining and complying with any terms
          of access and use of the Third-Party Services, including any separate
          fees or charges imposed by the provider of the Third-Party Services;
          and (b) configuring the Third-Party Services appropriately. Yazamtech
          disclaims all responsibility and liability arising from or related to
          Licensee’s access or use of the Third-Party Services, including any
          impact on the Product capabilities as a result of the Licensee’s use
          of, or reliance upon, the Third-Party Services.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •The Cloud Products are not designed for the storage of regulated
          health or payment card data and Licensee may only store or transmit
          such information through Cloud Products if it has entered a separate
          written agreement with Yazamtech expressly permitting such
          purpose.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Prior to termination or expiry of the Product Term, Licensee must (i)
          remove all Product settings from its Servers and Computers, and (ii)
          remove all of its custom settings, software and data from the
          Yazamtech network.&nbsp;For certain Products, Yazamtech may download
          and return the data upon request and for a reasonable fee to be agreed
          in writing in advance. Subject to applicable laws, reserves the right
          to delete data that has not been removed after such termination or
          expiry date.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee acknowledges and agrees that the product may require the
          complete erasure of the hard disk of the target computer during
          installation, including without limitation the operating system
          resident thereon. by installing the aforementioned product, licensee
          expressly agrees that it shall ensure that the computer on which such
          product is to be installed does not contain any valuable data, the
          loss of which would cause damage to licensee, and Yazamtech expressly
          disclaims any liability for losses of any kind related to licensee’s
          failure to do so.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Employee Personal Use.</span>
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Employee personal use of Products is not permitted without the prior
          express written consent of Yazamtech. If Yazamtech agrees to permit
          personal use of any Product, such use is subject to the terms of this
          License Agreement, and must not cause Licensee’s total usage,
          including such personal use does not exceed the License
          Entitlement.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.108.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">
          •Licensee shall ensure that its employees are aware of and comply with
          the terms and conditions of this License Agreement, and, to the extent
          permitted by applicable law, Licensee shall be responsible for the
          acts and omissions of its employees relating to use of the
          Products.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.72.0pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR"> •Other important terms: (i) </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          may transfer its rights and obligations under this License Agreement
          to another organization, and </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          will always notify Licensee in writing if this happens, but this will
          not affect Licensee’s rights or </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >'s obligations under this License Agreement; (ii) Licensee may only
          transfer Licensee’s rights and obligations under this License
          Agreement to another person if </span
        ><span dir="LTR" style="">Yazamtech </span
        ><span dir="LTR"
          >agrees in writing; (iii) the contract formed by this License
          Agreement is between Licensee and </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"
          >. No other person shall have any rights to enforce any of its terms;
          (iv) each of the paragraphs of this License Agreement operates
          separately. If any court or relevant authority decides that any of
          them are unlawful, the remaining paragraphs will remain in full force
          and effect; (v) if </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          fails to insist that Licensee perform any of Licensee’s obligations
          under this License Agreement, or if </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          does not enforce its rights against Licensee, or if </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> delays in doing so, that will not mean that </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          has waived its rights against Licensee and will not mean that Licensee
          does not have to comply with those obligations. If </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR"> does waive a default by Licensee, </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          will only do so in writing, and that will not mean that </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          will automatically waive any later default by Licensee; (vi) </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          may amend the terms and conditions of this License Agreement and/or
          any documents and policies referenced herein at any time by notice to
          Licensee. </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          will use reasonable efforts to bring any such changes Licensee’s
          attention, including without limitation via in-Product informational
          notices. Such amended terms and conditions shall be binding upon
          Licensee within fifteen (15) calendar days, unless Licensee objects to
          such amended terms and conditions by terminating Licensee’s license in
          accordance with Section ‎11.2&nbsp;or by notifying </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          in writing and entering into a subsequent written agreement between </span
        ><span dir="LTR" style="">Yazamtech</span
        ><span dir="LTR">
          and Licensee. Failure to terminate Licensee’s license within such
          period shall mean that Licensee expressly and unreservedly accepts all
          the amendments contained in the notice, which shall take effect
          immediately upon expiry of said fifteen (15) calendar day period. For
          the avoidance of doubt, such amended terms and conditions shall
          supersede any prior version of the License Agreement that may have
          been embedded in or packaged with the Product itself.</span
        >
      </p>

      <p
        class="MsoNormal"
        dir="ltr"
        style="margin-top:0cm;margin-right: 0.37.15pt;
margin-bottom:6.0pt;margin-left: 0.0cm;text-align:justify;text-justify:inter-ideograph;
direction:ltr;unicode-bidi:embed"
      >
        <span dir="LTR">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-top:0cm;margin-right: 0.0cm;margin-bottom:6.0pt;
margin-left: 0.36.0pt;text-align:justify;text-justify:inter-ideograph"
      >
        <span style=";color:#333333"> •ELECTRONIC COMMUNICATIONS</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph"
      >
        <span style=""
          >By accepting this License Agreement with Yazamtech or purchasing or
          signing up for Services, Licensee understand Yazamtech may send
          Licensee (including via email) information regarding the Services,
          such as: (a) notices about Licensee's use of the Services, including
          use violations; (b) updates to the Services and new features,
          functionalities, services, or products; (c) promotional information
          regarding Yazamtech or third-party products and services.
          <span style="color:#333333">If </span>Licensee<span
            style="color:#333333"
          >
            don't consent to receive notices (other than promotional
            information) electronically, stop using the Services.</span
          ></span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfUse',
}
</script>

<style src="./TermsOfUse.css"></style>
