<template>
  <v-tabs-items v-model="tab" touchless class="fill-height tablet-tabs-wrapper">
    <!--UPLOAD-->
    <Upload :userActiveSourceRoutes="userActiveSourceRoutes" />

    <!--DOWNLOAD-->
    <DownloadTablet
      :userCameFromEmailPath="userCameFromEmailPath"
      :setTab="setTab"
      :tab="tab"
    />
  </v-tabs-items>
</template>

<script>
//Children
import Upload from './Childrens/Upload/Upload.vue'
import DownloadTablet from './Childrens/DownloadTablet/DownloadTablet.vue'

export default {
  name: 'WebsiteInterfaceTablet',
  props: {
    tab: String,
    userActiveSourceRoutes: Array,
    userCameFromEmailPath: String,
    setTab: Function,
  },
  components: { Upload, DownloadTablet },
}
</script>

<style scoped src="./WebsiteInterfaceTablet.css"></style>
