<template>
  <div>
    <div
      class="ma-2 fs-1-12r d-flex justify-space-between"
      :class="[!isBigWindow && 'fs-1-25r']"
    >
      <span>(Time / RAM Percentage Used)</span>

      <span>{{ computedRamStr }}</span>
    </div>
    <LineChartGenerator
      v-if="isBigWindow"
      :chart-options="computedChartOptions"
      :chart-data="chartData"
      style="height: calc(100% - 40px)"
    />
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import 'chartjs-adapter-luxon'
import { mapGetters } from 'vuex'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js'
import ChartStreaming from 'chartjs-plugin-streaming'
import { bytesToSize } from '@/utils'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  ChartStreaming
)

export default {
  name: 'DataOfRam',
  data() {
    return {
      chartData: {
        labels: ['ss', 'rr'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [12, 14],
          },
        ],
      },
      chartOptions: {
        plugins: {
          legend: { display: false },
          streaming: {
            duration: 60000,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {},
      },
    }
  },

  components: {
    LineChartGenerator,
  },

  props: {
    computerData: Object,
    isBigWindow: Boolean,
  },
  computed: {
    ...mapGetters(['isDarkMode']),

    computedRamStr() {
      const { UsedRam, TotalRam } = this.computerData

      return `${bytesToSize(UsedRam)} / ${bytesToSize(TotalRam)}`
    },
    computedChartOptions() {
      const { UsedRam, TotalRam } = this.computerData
      const ramPer = (UsedRam / TotalRam) * 100
      const realTime = {
        onRefresh: function (chart) {
          chart.data.datasets.forEach(function (dataset) {
            dataset.data.push({
              x: Date.now(),
              y: ramPer,
            })
          })
        },
      }

      return {
        ...this.chartOptions,
        elements: {
          line: {
            tension: 0.2,
            borderColor: this.isDarkMode ? '#b5b5b5' : '#969696',
          },
        },
        scales: {
          x: {
            type: 'realtime',
            // Change options only for THIS AXIS
            realtime: {
              ...realTime,
              duration: 20000,
            },
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
          y: {
            grid: {
              color: this.isDarkMode ? '#e3e3e360' : '#e3e3e3',
            },
          },
        },
      }
    },
  },
}
</script>
