<template>
  <div class="wrapper-1 pa-2 d-flex">
    <!--DATE FORMATS-->
    <div class="wrapper-1 pa-2 mx-1 w-100">
      <span class="label f-14">Time format:</span>
      <v-radio-group
        v-model="customSearch.dateFormat"
        class="pa-0 ma-0"
        hide-details
      >
        <v-radio value="Local" label="Local" class="pa-0 ma-0 my-1"></v-radio>
        <v-radio value="UTC" label="UTC" class="pa-0 ma-0 my-1"></v-radio>
      </v-radio-group>
    </div>

    <StartEndDateSelect class="mx-1" :customSearch="customSearch" column />
  </div>
</template>

<script>
import StartEndDateSelect from '@/components/BaseComponents/StartEndDateSelect/StartEndDateSelect.vue'
import {
  formatDateStringToDayjs,
  getUTCMSNow,
  getUTCdateFromLocalDate,
} from '@/utils'
import dayjs from 'dayjs'

export default {
  name: 'DatesSelector',
  data() {
    return {
      customSearch: {
        startDate: null,
        endDate: null,
        dateFormat: 'UTC',
      },
    }
  },
  props: { profile: Object },
  components: { StartEndDateSelect },

  watch: {
    'customSearch.dateFormat': {
      handler: function (n) {
        if (n) this.profile.dateFormat = n
      },
      deep: true,
    },
    'customSearch.startDate': {
      handler: function (n) {
        //save it in utc and then if needed change it to local when user asks
        //if select is local
        if (this.customSearch.dateFormat === 'Local') {
          const str = formatDateStringToDayjs(n)
          return (this.profile.startDate = getUTCdateFromLocalDate(str))
        }

        const str = formatDateStringToDayjs(this.customSearch.startDate)
        const date = new Date(str)
        this.profile.startDate = date.getTime()
      },
    },

    'customSearch.endDate': {
      handler: function (n) {
        //save it in utc and then if needed change it to local when user asks
        //if select is local
        if (this.customSearch.dateFormat === 'Local') {
          const str = formatDateStringToDayjs(n)
          return (this.profile.endDate = getUTCdateFromLocalDate(str))
        }

        const str = formatDateStringToDayjs(this.customSearch.endDate)
        const date = new Date(str)
        this.profile.endDate = date.getTime()
      },
    },
  },

  created() {
    if (this.profile.endDate === null) {
      this.customSearch.endDate = dayjs().format('YYYY-MM-DD HH-mm')
      return
    }

    //make the custom values as format in UTC
    if (this.profile.dateFormat === 'UTC') {
      this.customSearch.startDate = dayjs(this.profile.startDate).format(
        'YYYY-MM-DD HH-mm'
      )
      this.customSearch.endDate = dayjs(this.profile.endDate).format(
        'YYYY-MM-DD HH-mm'
      )
    }

    //make the custom values as format in LOCAL
    else {
      const startOffset = getUTCMSNow(this.profile.startDate)
      const endOffset = getUTCMSNow(this.profile.endDate)

      this.customSearch.startDate =
        dayjs(startOffset).format('YYYY-MM-DD HH-mm')

      this.customSearch.endDate = dayjs(endOffset).format('YYYY-MM-DD HH-mm')
    }

    this.customSearch.dateFormat = this.profile.dateFormat
  },
}
</script>
