<template>
  <div>
    <!--SHOW WHAT YOU ARE SEARCHING FOR-->
    <span v-if="dirSearch" class="f-14 label">
      Searching for: <b>{{ dirSearch }}</b>
      <v-icon @click="deleteSearch" class="mx-1"
        >mdi-close-circle-outline</v-icon
      ></span
    >

    <!--ICON TO OPEN DIALOG-->
    <v-icon
      @click="isShowSearchBar = !isShowSearchBar"
      class="mx-2"
      x-large
      tabindex="0"
      aria-label="Search in directory"
      >mdi-magnify</v-icon
    >

    <!--DIALOG TO SEARCH-->
    <v-dialog
      v-model="isShowSearchBar"
      :content-class="isDarkMode ? 'dark-mode' : ''"
      class="wrapper-1 bg-white"
    >
      <v-card class="wrapper-1">
        <!--TITLE-->
        <v-card-title class="text-center bg-grey mb-2"> Search </v-card-title>

        <!--ENTER TEXT TO SEARCH-->
        <v-card-text class="px-2 pb-1">
          <v-text-field
            v-model="localDirSearch"
            clearable
            outlined
            hide-details
            autofocus
          />
        </v-card-text>

        <v-divider class="my-2" />

        <!--BUTTONS-->
        <div class="d-flex justify-space-between pa-2">
          <!--CANCEL BUTTON-->
          <Button
            :clickAction="() => (isShowSearchBar = false)"
            width="fit-content"
            btnType="grey"
            text="Cancel"
            class="mx-2"
          />

          <!--SEARCH BUTTON-->
          <Button
            :clickAction="searchInTable"
            width="fit-content"
            btnType="blue"
            class="mx-2"
            text="Search"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FolderSearch',
  data() {
    return {
      localDirSearch: '',
      isShowSearchBar: false,
    }
  },
  props: {
    setDirSearch: Function,
    dirSearch: String,
    shownDirectory: Object,
  },
  components: {
    Button,
  },

  watch: {
    shownDirectory: {
      handler: function (n) {
        this.deleteSearch()
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(['isDarkMode']),
  },
  methods: {
    searchInTable() {
      //after the user clicked search show the results and then close the window
      this.setDirSearch(this.localDirSearch)
      this.isShowSearchBar = false
    },
    deleteSearch() {
      this.setDirSearch(null)
      this.localDirSearch = ''
    },
  },
}
</script>
