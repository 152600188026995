<template>
  <div class="heading-panel-filters">
    <!-- SELECT TO FILTER ROLES IN USERS LIST -->
    <v-select
      v-if="filtersToShow.includes('roles')"
      v-model="filter.roleFilter"
      @change="updateFilter('role', filter.roleFilter)"
      @click:clear="
        $nextTick(() => {
          filter.roleFilter = 'All'
        })
      "
      dense
      :clearable="filter.roleFilter !== 'All'"
      :items="itemsForRolesFilter"
      class="filter-item"
      label="Account Type"
      hide-details
    >
      <template slot="selection" slot-scope="data">
        <img
          class="mr-2"
          v-if="data.item.value === 'SuperAdmin'"
          src="@/assets/super-admin.svg"
          alt=""
        />
        <img
          class="mr-2"
          v-else-if="data.item.value === 'Admin'"
          src="@/assets/org-admin.svg"
          alt=""
        />
        <span v-else-if="data.item.value === 'All'"></span>
        <img class="mr-2" v-else src="@/assets/user.svg" alt="" />
        <span>{{ data.item.text }}</span>
      </template>
      <template slot="item" slot-scope="data">
        <img
          class="mr-2"
          v-if="data.item.value === 'SuperAdmin'"
          src="@/assets/super-admin.svg"
          alt=""
        />
        <img
          class="mr-2"
          v-else-if="data.item.value === 'Admin'"
          src="@/assets/org-admin.svg"
          alt=""
        />
        <span
          v-else-if="
            !data.item.value ||
            data.item.value.includes('divider') ||
            data.item.value === 'All'
          "
        ></span>
        <img class="mr-2" v-else src="@/assets/user.svg" alt="" />
        <span class="ml-2">{{ data.item.text }}</span>
      </template>
    </v-select>

    <!-- SELECT FOR FILTER BY USER STATUS IN USERS LIST-->
    <v-select
      v-if="filtersToShow.includes('status')"
      v-model="filter.status"
      :clearable="filter.status !== 'All'"
      :items="['Active', 'Blocked', 'All']"
      @change="updateFilter('status', filter.status)"
      @click:clear="
        $nextTick(() => {
          filter.status = 'All'
        })
      "
      dense
      class="filter-item"
      hide-details
      label="Status"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'HeadingPanelFilters',
  props: {
    filter: Object,
    updateFilter: { type: Function, default: () => {} },
    filtersToShow: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'Profile',
    },
  },

  computed: {
    itemsForRolesFilter() {
      const items = [
        {
          text: 'All',
          value: 'All',
        },
        {
          text: 'Host Admin',
          value: 'Admin',
        },
        {
          text: 'Transfer',
          value: 'User',
        },
      ]
      if (!this.$route.params.organizationId) {
        items.push({
          text: 'Individual',
          value: 'Individual',
        })
        items.push({
          text: 'Super Admin',
          value: 'SuperAdmin',
        })
      }
      return items
    },
  },
}
</script>

<style scoped src="./HeadingPanelFilters.css"></style>
