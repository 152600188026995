<template>
  <div
    class="wrapper-2 bg-white rounded d-flex justify-space-around align-center "
  >
    <h2 class="pa-2">
      You account is blocked, please contact your Administrator
    </h2>
    <Button :clickAction="() => $router.go(-1)" text="Return" />
  </div>
</template>

<script>
//Base Components
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'Block',
  components: { Button },
}
</script>
