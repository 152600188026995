<template>
  <div class="mx-auto" :id="isSuperSmallScreen && 'tabs-super-small-screen'">
    <!-- THE HEADER NOT FOR INDIVIDUAL-->
    <div v-show="loggedUser.role !== 'Individual'" class="mx-auto">
      <v-tabs
        v-model="localTab"
        :class="[(!isShowUploadingTab || !isShowDownloadingTab) && 'op-0']"
        height="40px"
        class="wrapper-1 pa-1 bg-white"
        active-class="black-border"
        hide-slider
      >
        <!--UPLOADING-->
        <v-tab v-if="isShowUploadingTab" href="#uploading">
          <div class="fs-1-25r" :class="[!isSuperSmallScreen && 'px-3']">
            {{ $t('Uploading') }}
          </div>
        </v-tab>

        <!--DOWNLOADING-->
        <v-tab v-if="isShowDownloadingTab" href="#downloading">
          <div :class="[!isSuperSmallScreen && 'px-3']" class="fs-1-25r">
            {{ computedDownloadingTitle }}
          </div>
        </v-tab>
      </v-tabs>
    </div>

    <!--INDIVIDUAL SEE THE GAP-->
    <div v-show="loggedUser.role === 'Individual'" class="d-none"></div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DownUpTabs',
  data() {
    return {
      localTab: null,
      isShowDialog: true,
    }
  },
  props: {
    tab: String,
    setTab: Function,
    isShowUploading: Boolean,
    isShowDownloading: Boolean,
  },
  watch: {
    localTab(n) {
      this.setTab(n)
    },
    tab(n) {
      this.localTab = n
    },
  },
  computed: {
    ...mapGetters(['loggedUser', 'accessibility', 'isDarkMode', 'language']),
    computedDownloadingTitle() {
      if (this.loggedUser.license === 'SFTP') return 'SFTP Viewer'
      return this.$t('Downloading')
    },
    isSuperSmallScreen() {
      if (this.$vuetify.breakpoint.width > 380) return false
      return true
    },
    isShowUploadingTab() {
      if (this.isShowUploading) return true
      if (
        this.loggedUser.isAllowCasuals &&
        this.loggedUser.license === 'Website'
      )
        return true
      return false
    },
    isShowDownloadingTab() {
      if (this.isShowDownloading) return true
      if (
        this.loggedUser.isAllowCasuals &&
        this.loggedUser.license === 'Website'
      )
        return true
      return false
    },
  },
  methods: {
    ...mapActions(['LOG_OUT']),
  },
  created() {
    this.localTab = this.tab
  },
  components: { Button },
}
</script>

<style>
#tabs-super-small-screen .v-tab {
  padding: 8px !important;
}
</style>
