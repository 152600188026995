<template>
  <div class="mx-2 upload-details fs-1r">
    <!--POLICY IF THERE IS-->
    <div v-if="sessionRes.policy"><b>Policy: </b> {{ sessionRes.policy }}</div>

    <!--ROUTE IF THERE IS-->
    <div v-if="sessionRes.route && sessionRes.route.name">
      <b>Route: </b> {{ sessionRes.route.name }}
    </div>

    <!--REGULAR ACCOUNTS IF THERE IS-->
    <div v-if="sessionRes.selectedUsers.length" class="big-text-ellipsis">
      <b>Registered accounts: </b>
      {{ sessionRes.selectedUsers | getSelectedUsers }}
    </div>

    <!--REGULAR ACCOUNTS IF THERE IS-->
    <div v-if="sessionRes.selectedCasualUsers.length" class="big-text-ellipsis">
      <b>Guest Users: </b>
      {{ sessionRes.selectedCasualUsers | getSelectedUsers }}
    </div>

    <!--SUBJECT IF THERE IS-->
    <div v-if="sessionRes.subject">
      <b>Subject: </b>
      {{ sessionRes.subject }}
    </div>

    <!--MESSAGE IF THERE IS-->
    <div v-if="sessionRes.message" class="big-text-ellipsis">
      <b>Message: </b>
      {{ sessionRes.message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadDetails',
  props: {
    sessionRes: Object,
  },
  filters: {
    getSelectedUsers(v) {
      return v.join(', ')
    },
  },
}
</script>

<style scoped src="./UploadDetails.css"></style>
