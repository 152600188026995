<template>
  <div
    class="wrapper-1 fill-width table-wrapper"
    :class="[type === 'tablet' && 'tablet-table-wrapper', isDrag && 'drag-files']"
  >
    <!--TABLE HEADER-->
    <TableHeader :sortByChanged="sortByChanged" :session="session" />

    <!--CURRENT FILE UPLOADED-->
    <CurUploadedFileRow
      v-if="curFileUploaded"
      :curFileUploaded="curFileUploaded"
    />

    <!--TABLE ROWS-->
    <div class="bg-white table-rows-wrapper">
      <!--LOOPING ON TABLE ROWS-->
      <TableRow
        v-for="file of computedFileList"
        v-show="
          curFileUploaded ? file.file.name !== curFileUploaded.file.name : true
        "
        :session="session"
        :file="file"
      />
    </div>
  </div>
</template>

<script>
//Childrens
import CurUploadedFileRow from './Children/CurUploadedFileRow/CurUploadedFileRow.vue'
import TableHeader from './Children/TableHeader/TableHeader.vue'
import TableRow from './Children/TableRow/TableRow.vue'

export default {
  name: 'UploadFilesTable',
  data() {
    return {
      computedFileList: [],
    }
  },
  props: {
    session: Object,
    curFileUploaded: Object,
    type: String,
    isDrag: Boolean,
  },
  components: { TableHeader, TableRow, CurUploadedFileRow },
  methods: {
    sortByChanged(sortBy, sortType) {
      const filesData = [...this.session.filesData]
      if (sortBy === 'name') {
        const newFileList = filesData.sort((a, b) => {
          if (a.file.name.toLowerCase() < b.file.name.toLowerCase()) return -1
          else if (a.file.name.toLowerCase() > b.file.name.toLowerCase())
            return 1
          else return 0
        })
        if (sortType === 'inc') return (this.computedFileList = newFileList)
        return (this.computedFileList = newFileList.reverse())
      }

      //if the sort is by SIZE
      if (sortBy === 'size') {
        const newFileList = filesData.sort((a, b) => {
          if (a.file.numbSize < b.file.numbSize) return -1
          else if (a.file.numbSize > b.file.numbSize) return 1
          else return 0
        })

        if (sortType === 'inc') return (this.computedFileList = newFileList)
        return (this.computedFileList = newFileList.reverse())
      }

      //if the sort is by LAST MODIFIED
      if (sortBy === 'lastModifiedDate') {
        const newFileList = filesData.sort((a, b) => {
          if (a.file.lastModifiedDate < b.file.lastModifiedDate) return -1
          else if (a.file.lastModifiedDate > b.file.lastModifiedDate) return 1
          else return 0
        })
        if (sortType === 'inc') return (this.computedFileList = newFileList)
        return (this.computedFileList = newFileList.reverse())
      }
    },
  },

  created() {
    this.sortByChanged('lastModifiedDate', 'dec')
  },
}
</script>

<style scoped src="./UploadFilesTable.css"></style>
