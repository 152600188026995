import {api} from '@/config';

export const getEmptyOrganization = async () => {
  const res = await api.get('organizations/emptyorganization')
  if (res.status !== 200) {
    throw { res }
  }
res.data.status = 0
  return res.data
}
