<template>
  <div class="users-list-wrapper" :class="generateWrapperClasses">
    <!--HEAD PANNEL-->
    <HeadingPanel
      :updateFilter="updateFilter"
      @open-modal="newUserButtonClicked"
      type="account"
      :updateSearch="updateSearch"
      :filtersToShow="['roles', 'status']"
    />

    <Table
      :search="filter.search"
      :headers="headers"
      :options="{ sortBy: ['fakeID'] }"
      :items="computedUsers"
      :rowClickAction="goToEditUserPage"
      :itemsPerPage="-1"
      type="users-list"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { convertID, convertrole, convertImage, alertDialog } from '@/utils'

//Base components
import HeadingPanel from '@/components/BaseComponents/HeadingPanel/HeadingPanel.vue'
import Table from '@/components/BaseComponents/Table/Table.vue'
import superAdminLogo from '@/assets/super-admin.svg'
import hostAdminLogo from '@/assets/org-admin.svg'
import userLogo from '@/assets/user.svg'
//File
import { usersListHeaders } from './UsersList'

export default {
  name: 'UsersList',
  data() {
    return {
      isSearchMatch: true,
      isFromOrganizations: false,
      filter: {
        status: 'All',
        roleFilter: 'All',
        search: '',
      },
    }
  },
  components: {
    HeadingPanel,
    Table,
  },
  filters: {
    convertrole,
    convertImage,
    convertUserStatus(s) {
      switch (s) {
        case 0:
          return 'Active'
        case 1:
          return 'Blocked'
        case 2:
          return 'Archived'
        default:
          return '-'
      }
    },
  },
  computed: {
    ...mapGetters([
      'users',
      'loggedUser',
      'organizations',
      'currentOrganization',
    ]),
    generateWrapperClasses() {
      const classes = []
      if (
        this.loggedUser.role === 'SuperAdmin' &&
        !this.$route.path.includes('hosts')
      )
        classes.push('users-list-wrapper-super-all')
      return classes
    },
    headers() {
      const headers = usersListHeaders.map(el => el)
      if (this.isFromOrganizations) {
        const index = headers.findIndex(p => p.text === 'Host')
        headers.splice(index, 1)
      }
      return headers
    },
    computedUsers() {
      let users = this.users.map(el => {
        const fakeID = convertID(el.userId, 'A')
        const status = el.status === 0 ? 'Active' : 'Blocked'
        const role = convertrole(el.role)
        const isGuests = el.isAllowCasuals ? '✓' : '✕'
        let license = el.role === 'SuperAdmin'? '' : el.license

        let host = el.organizationId
        if (this.loggedUser.role === 'SuperAdmin') {
          host = this.organizations.find(
            org => org.organizationId === el.organizationId
          )?.name
        }
        return { ...el, fakeID, status, role, license, isGuests, organizationId: host }
      })

      if (this.filter.status === 'Active') {
        users = users.filter(el => el.status === 'Active')
      } else if (this.filter.status === 'Blocked') {
        users = users.filter(el => el.status === 'Blocked')
      } else if (this.filter.status === 'All') {
        users = users.filter(el => el.status !== 2)
      }

      //
      if (this.filter.roleFilter === 'SuperAdmin') {
        users = users.filter(el => el.role === 'Super Admin')
      } else if (this.filter.roleFilter === 'Admin') {
        users = users.filter(el => el.role === 'Host Admin')
      } else if (this.filter.roleFilter === 'User') {
        users = users.filter(
          el => el.role === 'Transfer' || el.role === 'Transfer'
        )
      } else if (this.filter.roleFilter === 'Application') {
        users = users.filter(el => el.role === 'Application')
      } else if (this.filter.roleFilter === 'Individual') {
        users = users.filter(el => el.role === 'Individual')
      }
      let sortedUsersWithSuperAdminsInTheEnd = users.sort((a, b) => {
        return a.role === 'SuperAdmin' ? 1 : -1
      })
      return sortedUsersWithSuperAdminsInTheEnd
    },
  },

  methods: {
    ...mapActions(['GET_USERS', 'GET_ORGANIZATIONS']),
    ...mapMutations(['SET_PROCESSING']),
    getUserLogo(item) {
      if (item.role === 'SuperAdmin') return superAdminLogo
      else if (item.role === 'Admin') return hostAdminLogo
      else if (item.role === 'Individual') return individualLogo
      else return userLogo
    },

    getOrgName(item) {
      return item.organizationId !== 0
        ? this.organizations.find(
            el => el.organizationId === item.organizationId
          )?.name
        : '-'
    },
    getFiltered(e) {
      if (!e.length) this.isSearchMatch = false
      else this.isSearchMatch = true
    },
    updateSearch(value) {
      this.filter.search = value
    },
    updateFilter(t, value) {
      let type = t
      if (!value) type = 'reset'
      switch (type) {
        case 'reset':
          this.filter.status = 'All'
          this.filter.roleFilter = 'All'
          break
        case 'status':
          this.filter.status = value
          break
        case 'role':
          this.filter.roleFilter = value
          break
        default:
          console.log('something is wrong')
          break
      }
    },
    async newUserButtonClicked() {
      //check if the user came from organizations and want to add a user to spesific org
      if (this.isFromOrganizations) {
        //if the organization don't hve more licenses show a message
        const isHostDontHaveMoreLicenses =
          this.currentOrganization.allowedLicenses.every(lic => {
            if (lic.type === 'Casual') return true
            return lic.numOfAllowed <= lic.numOfInUse
          })

        if (isHostDontHaveMoreLicenses) {
          const t = this.loggedUser.role === 'SuperAdmin' ? 'this' : 'your'

          alertDialog(
            this,
            `Please be informed that ${t} host currently does not have any additional licenses available. As a result, the only type of account that can be created at this time is a "Host Admin" account without licenses`
          )
        }

        this.$router.push(
          `/management/hosts/${this.currentOrganization.organizationId}/registered/users/new`
        )
      }

      //if the user not came from organization and want to add a user
      else this.$router.push(`/management/accounts/new`)
    },
    goToEditUserPage(user) {
      this.$router.push(
        `/management/${
          this.isFromOrganizations
            ? `hosts/${this.currentOrganization.organizationId}/registered/users`
            : 'accounts'
        }/${user.userId}`
      )
    },
  },
  async created() {
    if (this.loggedUser.role === 'SuperAdmin') await this.GET_ORGANIZATIONS()
    if (this.$route.path.includes('hosts')) {
      this.isFromOrganizations = true
    } else {
      await this.GET_USERS()
    }

    this.$emit('changeTab', 'tab-registered')
    this.$emit('tabClicked', 'users')
  },
}
</script>

<style scoped src="./UsersList.css"></style>
