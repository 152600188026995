<template>
  <div class="home">
    <LoginForm />
    <!-- <CookiesAccept /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CookiesAccept from '@/components/Home/CookiesAccept/CookiesAccept.vue'
import LoginForm from '@/components/Home/Logins/LoginForm/LoginForm.vue'

export default {
  name: 'Home',
  components: {
    CookiesAccept,
    LoginForm,
  },
  computed: {
    ...mapGetters(['loggedUser']),
  },
  created() {
    //check if this is needed
    if (this.loggedUser.role) {
      if (
        this.loggedUser.role === 'User' ||
        this.loggedUser.role === 'GuestUser'
      ) {
        this.$router.push('/website-interface')
      } else if (this.loggedUser.role === 'Admin') {
        this.$router.push(`/management/hosts/${this.loggedUser.organizationId}`)
      } else if (this.loggedUser.role === 'SuperAdmin') {
        this.$router.push('/management')
      }
    }
  },
}
</script>

<style scoped src="./Home.css"></style>
