<template>
  <div class="heading-panel-wrapper d-flex justify-end">
    <transition name="slide-fade">
      <div v-if="isSettingsOpen" class="wrapper-2 heading-panel pa-3">
        <v-text-field
          v-model="filter.search"
          autofocus
          class="ml-auto mr-4"
          dense
          :style="{ maxWidth: '150px' }"
          prepend-inner-icon="mdi-magnify"
          clearable
          @input="updateSearch"
          hide-details
          label="Search"
        >
        </v-text-field>
        <HeadingPanelFilters
          :type="type"
          :updateFilter="updateFilter"
          :filtersToShow="filtersToShow"
          :filter="filter"
        />
      </div>
    </transition>
    <div class="d-flex align-center">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            @click.prevent="isSettingsOpen = !isSettingsOpen"
            small
            outlined
            v-on="on"
            class="mx-2 white--text"
            fab
            color="primary"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>{{ isSettingsOpen ? 'Close' : 'Open' }} search settings</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            @click.prevent="$emit('open-modal')"
            small
            v-on="on"
            outlined
            class="mx-2 white--text"
            fab
            color="primary"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Create new {{ type }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
//Childrens
import HeadingPanelFilters from './Childern/HeadingPanelFilters/HeadingPanelFilters.vue'

export default {
  name: 'HeadingPanel',
  data() {
    return {
      filter: {
        status: 'All',
        roleFilter: 'All',
        search: '',
      },
      isSettingsOpen: false,
    }
  },
  watch: {
    filter: {
      handler: function (n) {
        if (!n) return

        this.updateRoute()
      },
      deep: true,
    },
    isSettingsOpen(n) {
      if (n === false) {
        this.filter = {
          status: 'All',
          roleFilter: 'All',
          search: '',
        }
        window.history.replaceState({}, '', location.pathname)

        this.updateFilter('reset')
        this.updateSearch('')
      }
    },
  },
  props: {
    updateSearch: {
      type: Function,
    },
    filtersToShow: {
      type: Array,
      default: () => [],
    },
    updateFilter: {
      type: Function,
    },
    type: {
      type: String,
      default: 'Account',
    },
  },
  components: {
    HeadingPanelFilters,
  },

  methods: {
    updateRoute() {
      if (
        JSON.stringify(this.filter) ===
        '{"status":"All","roleFilter":"All","search":""}'
      )
        return window.history.replaceState({}, '', location.pathname)

      let query = ''

      if (this.filter.search) query = `&search=${this.filter.search}`

      if (this.filter.roleFilter)
        query = query + `&rolefilter=${this.filter.roleFilter}`

      if (this.filter.status) query = query + `&status=${this.filter.status}`

      if (query[0] === '&') query = query.replace('&', '?')

      if (this.$route.fullPath !== this.$route.path + query)
        this.$router.push(query)
    },
  },

  created() {
    if (this.$route.query.search) {
      this.isSettingsOpen = true
      this.filter.search = this.$route.query.search
      this.updateSearch(this.$route.query.search)
    }

    if (this.$route.query.rolefilter) {
      this.isSettingsOpen = true
      this.filter.roleFilter = this.$route.query.rolefilter
      this.updateFilter('role', this.$route.query.rolefilter)
    }

    if (this.$route.query.status) {
      this.isSettingsOpen = true
      this.filter.status = this.$route.query.status
      this.updateFilter('status', this.$route.query.status)
    }
  },
}
</script>

<style scoped src="./HeadingPanel.css"></style>
