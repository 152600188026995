<template>
  <div v-if="shownDirectory" class="fill-height">
    <!--TOP LINE - SEARCH AND BACK-->
    <DirectoryHeader
      :changeShownDirClicked="changeShownDirClicked"
      :setDirSearch="v => (dirSearch = v)"
      :dirSearch="dirSearch"
      :shownDirectory="shownDirectory"
    />
    <!--TABLE OF DIR-FILES-->
    <DirectoryDownloadTable
      v-if="shownDirectory"
      :shownDirectory="shownDirectory"
      :changeShownDirClicked="changeShownDirClicked"
      :dirSearch="dirSearch"
      :selectedDirFiles="selectedDirFiles"
      :setSelectedDirFiles="v => (selectedDirFiles = v)"
      type="mobile"
    />
    <!--ACTIONS BUTTONS-->
    <SelectedDirFilesActions
      :shownDirectory="shownDirectory"
      :selectedDirFiles="selectedDirFiles"
      :showReportClicked="showReportClicked"
      :deleteFilesClicked="deleteFilesClicked"
      :downloadFilesClicked="downloadFilesClicked"
    />

    <!--INSERT PASSWORD DIALOG-->
    <InsertPassword
      v-if="isShowPasswordScreen"
      :setIsShowPasswordScreen="v => (isShowPasswordScreen = v)"
      :downloadFilesClicked="downloadFilesClicked"
    />

    <!--REPORT DIALOG FOR DIRECTORIES AND FILES-->
    <DirFilesTableReportDialog
      v-if="isShowReportDialog"
      :closeReportDialog="closeReportDialog"
      :report="report"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import InsertPassword from '@/components/WebsiteInterface/CommonComponents/InsertPassword/InsertPassword.vue'
import DirectoryDownloadTable from '@/components/WebsiteInterface/CommonComponents/DirectoryDownloadTable/DirectoryDownloadTable.vue'
import DirFilesTableReportDialog from '@/components/WebsiteInterface/CommonComponents/DirFilesTableReportDialog/DirFilesTableReportDialog.vue'

//Children
import DirectoryHeader from './Children/DirectoryHeader/DirectoryHeader.vue'
import SelectedDirFilesActions from './Children/SelectedDirFilesActions/SelectedDirFilesActions.vue'

//Functions to download and manipulate the table
import {
  //get directoreis
  getStartFileDirPoint,
  getSingleDirFromPathArr,
  getOneDirBack,
  getAllFiles,
  //get actual data
  getFilteringReport,
  deleteDirFiles,
  downloadDirFiles,
} from '@/components/WebsiteInterface/downloadingFunctions'

export default {
  name: 'DownloadMobile',
  data() {
    return {
      shownDirectory: null,
      dirSearch: null,
      selectedDirFiles: [],
      isShowPasswordScreen: false,
      report: {
        files: [],
        directories: [],
      },
      isShowReportDialog: false,
    }
  },
  props: {
    userCameFromEmailPath: String,
    setTab: Function,
    tab: String,
  },
  components: {
    DirectoryHeader,
    SelectedDirFilesActions,
    InsertPassword,
    DirectoryDownloadTable,
    DirFilesTableReportDialog,
  },
  watch: {
    shownDirectory(n) {
      this.selectedDirFiles = []
    },

    async tab(n) {
      if (n === 'downloading') {
        await getStartFileDirPoint(this, 'mobile')
      }
    },
  },
  computed: {
    ...mapGetters(['usrPass', 'loggedUser']),
  },

  methods: {
    changeShownDirClicked(dirPath, type) {
      //if the user clicked the directory from the table row
      if (type === 'tableRow') getSingleDirFromPathArr(this, dirPath)
      //if the user clicked the back on directory button
      else if (type === 'backButton') getOneDirBack(this)
      //if the user asked for all files
      else if (type === 'allFiles') getAllFiles(this)
    },

    closeReportDialog() {
      this.isShowReportDialog = false
      this.report = {
        files: [],
        directories: [],
      }
    },

    showReportClicked() {
      getFilteringReport(this)
    },

    deleteFilesClicked() {
      deleteDirFiles(this)
    },
    downloadFilesClicked() {
      //if the user refreshsed and he dosen't hav epassord and also he is not casual
      if (!this.usrPass && this.loggedUser.role !== 'Casual')
        return (this.isShowPasswordScreen = true)

      //if the user have password
      this.isShowPasswordScreen = false
      downloadDirFiles(this)
    },
  },

  async created() {
    await getStartFileDirPoint(this, 'mobile')
  },
}
</script>
