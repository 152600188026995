<template>
  <div class="org-routes">
    <HeadingPanel
      v-if="!isUserRoutes"
      @open-modal="openRouteModal(null, 'add')"
      type="route"
      :filtersToShow="['status']"
      :updateFilter="updateFilter"
      :updateSearch="updateSearch"
      :isFromUsers="false"
    />

    <!--ROUTES TABLE-->
    <Table
      v-if="!isUserRoutes"
      :items="computedRoutesList"
      :headers="computedRouteHeaders"
      :options="{ sortBy: ['fakeID'] }"
      :rowClickAction="openRouteModal"
      :itemsPerPage="-1"
      type="org routes"
    />

    <v-data-table
      v-if="isUserRoutes"
      fixed-header
      :footer-props="{
        'items-per-page-options': [-1, 5, 10, 25, 50],
      }"
      :items-per-page="-1"
      :items="computedRoutesList"
      :headers="computedRouteHeaders"
      :options="{ sortBy: ['id'] }"
      must-sort
      class="wrapper-1 routes-table ma-1"
      :class="[isUserRoutes && 'my-2']"
      @current-items="getFiltered"
       noDataText="No routes defined"
    >
      <template v-if="computedRoutesList.length" v-slot:body="{ items }">
        <tbody>
          <tr
            @click="openRouteModal(item, 'edit')"
            v-for="item in items"
            :key="item.email"
            class="pointer"
            :class="[item.status !== 0 && 'semi-opacity']"
          >
            <!--NAME OF THE ROUTE-->
            <td>
              <span
                :class="{
                  'semi-opacity': item.routeStatus === 1,
                  'semi-opacity-archived': item.routeStatus === 2,
                }"
              >
                {{ item.name }}
              </span>
            </td>

            <!-- ROUTE STATUS -->
            <td>
              <span
                :class="{
                  'semi-opacity': item.routeStatus === 1,
                  'semi-opacity-archived': item.routeStatus === 2,
                }"
              >
                {{ item.status | convertRouteStatus }}
              </span>
            </td>

            <!--IF THIS IS THE USER ROUTES SHOW SOURCE OR TARGET-->
            <td v-if="isUserRoutes">
              <span
                :class="{
                  'semi-opacity': item.routeStatus === 1,
                  'semi-opacity-archived': item.routeStatus === 2,
                }"
              >
                {{ item.sourceOrTarget }}
              </span>
            </td>

            <!-- ID OF THE ROUTE-->
            <td
              :class="{
                'semi-opacity': item.routeStatus === 1,
                'semi-opacity-archived': item.routeStatus === 2,
              }"
            >
              {{ item.fakeID || item.routeId }}
            </td>

            <!--EDIT IF THIS IS ALL THE USER ROUTES-->
            <td v-if="!isUserRoutes">
              <v-btn
                x-small
                fab
                outlined
                color="#5B5B7A"
                @click.stop="openRouteModal(item, 'edit')"
              >
                <v-icon dark>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="!isSearchMatch">
            <td align="center" colspan="8">No routes to display</td>
          </tr>
        </tbody>
      </template>
      <template v-else v-slot:no-data>
        <p style="color: black">
          {{ loading ? 'Loading...' : 'No routes to display' }}
        </p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { alertDialog, convertID } from '@/utils'
import { api } from '@/config'
import HeadingPanel from '@/components/BaseComponents/HeadingPanel/HeadingPanel.vue'

//Component files
import { routeHeaders, singleUserRoutes } from './OrgRoutes'
import Table from '@/components/BaseComponents/Table/Table.vue'

export default {
  name: 'OrgRoutes',
  data() {
    return {
      isSearchMatch: true,
      search: '',
      loading: false,
      statusFilter: 'All but archived',
      sourceInterface: 'All',
      targetInterface: 'All',
      userRoutes: [],
    }
  },
  props: {
    isUserRoutes: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  components: {
    HeadingPanel,
    Table,
  },
  filters: {
    parseName(v) {
      if (v.firstName || v.lastName) {
        return `${v.firstName || ''} ${v.lastName || ''}`
      }
      return v.email || 'id:' + v.userId
    },
    convertRouteStatus(s) {
      switch (s) {
        case 0:
          return 'Active'
        case 1:
          return 'Blocked'
        case 2:
          return 'Archived'
        default:
          return '-'
      }
    },
  },
  computed: {
    ...mapGetters([
      'currentOrganization',
      'routesList',
      'policies',
      'loggedUser',
    ]),
    computedRouteHeaders() {
      if (!this.isUserRoutes) return routeHeaders
      return singleUserRoutes
    },
    computedRoutesList() {
      let routes
      if (this.isUserRoutes) {
        routes = this.userRoutes.length
          ? this.userRoutes.map(r => ({
              ...r,
              fakeID: convertID(r.routeId, 'R'),
              sourceOrTarget: this.convertSourceOrTarget(r),
            }))
          : []
      } else {
        routes = Array.isArray(this.routesList)
          ? this.routesList.map(r => ({
              ...r,
              status: r.status === 0 ? 'Active' : 'Blocked',
              fakeID: convertID(r.routeId, 'R'),
            }))
          : []
      }

      if (this.statusFilter === 'Active') {
        routes = routes.filter(el => el.status === 'Active')
      } else if (this.statusFilter === 'Blocked') {
        routes = routes.filter(el => el.status === 'Blocked')
      } else if (this.statusFilter === 'All but archived') {
        routes = routes.filter(el => el.status !== 2)
      }

      if (this.search) {
        routes = routes.filter(el => {
          if (
            el.name.toLowerCase().includes(this.search.toLowerCase()) ||
            el.targetEndPoints.some(el =>
              el.user.email.toLowerCase().includes(this.search.toLowerCase())
            )
          ) {
            return el
          }
        })
      }

      return routes
    },
  },
  methods: {
    ...mapActions(['GET_ROUTES', 'REMOVE_ROUTE', 'GET_POLICIES']),
    ...mapMutations(['SET_PROCESSING']),
    convertSourceOrTarget(route) {
      if (
        route.sourceEndPoints &&
        route.targetEndPoints &&
        route.sourceEndPoints?.some(el => el.userId === this.userId) &&
        route.targetEndPoints?.some(el => el.userId === this.userId)
      ) {
        return 'Source & Target'
      }
      if (
        route.sourceEndPoints &&
        route.sourceEndPoints?.some(el => el.userId === this.userId)
      ) {
        return 'Source'
      }
      if (
        route.targetEndPoints &&
        route.targetEndPoints?.some(el => el.userId === this.userId)
      ) {
        return 'Target'
      }
      return 'undefined'
    },
    getFiltered(e) {
      if (!e.length) this.isSearchMatch = false
      else this.isSearchMatch = true
    },
    updateSearch(value) {
      this.search = value
    },
    updateFilter(t, value) {
      let type = t
      if (!value) type = 'reset'
      switch (type) {
        case 'reset':
          this.statusFilter = 'All but archived'
          this.organizationFilter = 'All'
          this.roleFilter = 'All'
          break
        case 'status':
          this.statusFilter = value
          break
        default:
          console.log('something is wrong')
          break
      }
    },
    openRouteModal(route, type = 'edit') {
      if (this.userId) {
        this.$emit('openSingleRoute', route.routeId)
      } else {
        if (type === 'add') {
          if (!this.policies.length) {
            return alertDialog(
              this,
              `No routes can be created because this host doesn't have any policies.<br>In order to create a route there must be at least one policy`
            )
          }
          this.$router.push(
            `/management/hosts/${this.currentOrganization.organizationId}/registered/routes/new`
          )
        } else {
          this.$router.push(
            `/management/hosts/${this.currentOrganization.organizationId}/registered/routes/${route.routeId}?orgId=${this.currentOrganization.organizationId}`
          )
        }
      }
    },
  },
  async mounted() {
    try {
      this.SET_PROCESSING(true)
      this.loading = true
      if (this.isUserRoutes && this.userId) {
        try {
          const res = await api.get(`/Routes?userId=${this.userId}`)
          
          this.userRoutes = res.data
        } catch (error) {
          console.log(error)
        }
      } else {
        this.$emit('tabClicked', 'routes')
        await this.GET_ROUTES({ id: this.currentOrganization.organizationId })
        await this.GET_POLICIES(this.currentOrganization.organizationId)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
      this.SET_PROCESSING(false)
    }
  },
}
</script>

<style scoped src="./OrgRoutes.css"></style>
