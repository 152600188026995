<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeFileDetails"
    :content-class="isDarkMode ? 'dark-mode' : ''"
    :max-width="500"
  >
    <div class="wrapper-1 bg-white"  :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']">
      <v-card-title class="text-h5 bg-grey title-border" tabindex="0">
        <b>{{ $t('File Details') }}</b>
      </v-card-title>

      <div class="d-flex flex-column pa-2 fs-1r" tabindex="0">
        <!--NAME-->
        <span class="word-break"><b>{{ $t('Name') }}: </b>{{ shownFile.file.name }}</span>

        <!--SIZE-->
        <span><b>{{ $t('Size') }}: </b> {{ shownFile.file.size | bytesToSize }}</span>

        <!--PATH-->
        <span v-if="shownFile.file.path"
          ><b>{{ $t('Path') }}: </b>{{ shownFile.file.path }}</span
        >

        <!--TYPE-->
        <span
          ><b>{{$t('File type')}}: </b> <b :class="shownFile.file.icon"></b
          >{{ shownFile.file.name | getFileType }}</span
        >

        <!--LAST MODIFIED-->
        <span><b>{{ $t('Last Modified') }}: </b>{{ shownFile.file.lastModifiedDate }}</span>
      </div>

      <!--REMOVE FROM LIST -->
      <div class="d-flex justify-end px-3 pb-2">
        <Button
          :text="$t('Delete')"
          width="110"
          btnType="grey"
          icon="mdi-delete"
          :clickAction="() => removeFileFromArray(shownFile)"
        />
      </div>

      <v-divider></v-divider>
      <div class="d-flex justify-end pa-2">
        <Button
          :text="$t('Close')"
          btnType="grey"
          width="70"
          :clickAction="closeFileDetails"
        ></Button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { bytesToSize } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MoreFileDetails',
  data() {
    return {
      dialog: true,
    }
  },
  components: { Button },

  props: {
    shownFile: Object,
    closeFileDetails: Function,
    removeFileFromArray: Function,
  },

  filters: {
    bytesToSize,
    getFileType(f) {
      return f.split('.').pop()
    },
  },

  computed: {
    ...mapGetters(['isDarkMode', 'language']),
  },
}
</script>
