<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeSessionDialog"
    width="600"
    class="wrapper-1 bg-white"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card class="wrapper-1">
      <v-card-title class="bg-grey fs-1-5r">
        <span class="ma-auto"> More About Session</span>
      </v-card-title>

      <v-card-text
        v-if="sessData"
        style="max-height: 65vh"
        class="pa-2 overflow-auto fs-1-12r"
      >
        <!--SESSION DRY DATA-->
        <div
          v-for="item of sessionDataBuider"
          :key="item.label"
          class="my-1 mx-2 d-flex"
        >
          <b class="min-w-125">{{ item.label }}:</b>
          {{
            item.type
              ? getDataByType(item.type, sessData[item.model])
              : sessData[item.model]
          }}
        </div>

        <v-divider class="my-2"></v-divider>
        <!--SUMMARY-->
        <div>
          <span class="fs-1-25r font-weight-bold">Summary:</span>
          <Summary :statusResults="sessData.summary" />
        </div>

        <v-divider class="my-2"></v-divider>
        <!--SESSION LIFE TIME-->
        <span class="fs-1-25r font-weight-bold">Lifetime (UTC):</span>
        <!--DATA ABOUT FILE LIFETIME-->
        <div
          v-for="item of lifeTimeBuilder"
          :key="item.label"
          class="my-1 mx-2 d-flex"
        >
          <b class="w-175">{{ item.label }}:</b>
          {{ generateUTCDateDavid(sessData[item.model], true) }}
        </div>

        <!--RECIPIENTS-->
        <v-divider class="my-2"></v-divider>
        <span class="fs-1-25r mb-2 font-weight-bold">Recipients:</span>
        <ul>
          <li v-for="email of sessData.targetEmails" :key="email">
            {{ email }}
          </li>
        </ul>
      </v-card-text>

      <div v-else class="text-center ma-3 fs-1-12r">Session was not found.</div>

      <!--ADD-->
      <div class="d-flex justify-end pa-2">
        <Button text="Close" :clickAction="closeSessionDialog" btnType="grey" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { api } from '@/config'
import { bytesToSize, generateUTCDateDavid, parseTime } from '@/utils'
import Summary from '@/components/BaseComponents/Summary/Summary.vue'
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'SessionDataDialog',
  data() {
    return {
      dialog: true,
      sessData: null,
      generateUTCDateDavid,
      sessionDataBuider: [
        { label: 'Host', model: 'organizationName' },
        { label: 'Policy', model: 'policy' },
        { label: 'Sender', model: 'senderEmail' },
        { label: 'License', model: 'license' },
        { label: 'Route', model: 'routeName' },
        { label: 'Operation ID', model: 'operationId' },
        { label: 'Original size', model: 'filesOriginalSize', type: 'size' },
        { label: 'Filtered size', model: 'filesCurrentSize', type: 'size' },
        { label: 'Duration', model: 'durationMilliseconds', type: 'duration' },
        { label: 'Subject', model: 'Message' },
      ],
      lifeTimeBuilder: [
        { label: 'Session started', model: 'dateCreated' },
        { label: 'Email sent', model: 'dateAwaitingFilesMailSent' },
        { label: 'Deletion', model: 'filesDeletionDate' },
      ],
    }
  },
  props: {
    closeSessionDialog: Function,
    opId: String,
  },
  computed: {
    ...mapGetters(['isDarkMode']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    getDataByType(type, data) {
      if (type === 'size') return bytesToSize(data)
      else if (type === 'duration') return parseTime(data || 0)
    },
  },
  async created() {
    try {
      this.SET_PROCESSING(true)
      const res = await api.get(`operations/${this.opId}`)
      if (res.status !== 200) throw Error
      this.sessData = res.data
    } catch (e) {
      console.log(e)
    } finally {
      this.SET_PROCESSING(false)
    }
  },
  components: { Summary, Button },
}
</script>
