<template>
  <div  :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']" class="wrapper-1 pa-2 ma-2 fs-1-12r" tabindex="0">
    <!--ID-->
    <div><b>ID:</b> {{ route.id }}</div>

    <!--NAME-->
    <div><b>{{ $t('Name') }}:</b> {{ route.name }}</div>

    <!--SENDRES-->
    <div>
      <b>{{ $t('Senders') }}: </b>
      <div class="mx-2" v-html="route.sources"></div>
    </div>

    <!--RECIPIENTS-->
    <div>
      <b>{{ $t('Recipients') }}: </b>
      <div class="mx-2" v-html="route.targets"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex'
export default {
  name: 'RouteCard',
  props: {
    route: Object,
  },
  computed: {
    ...mapGetters(['language'])
  }
}
</script>
