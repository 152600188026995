<template>
  <div>
    <div aria-hidden class="d-flex align-center title-border bg-grey pa-2">
      <v-icon>mdi-account-circle</v-icon>

      <h3 class="font-weight-light mx-2 fs-1-5r">
        {{ $t('Login') }}
      </h3>

      <v-spacer></v-spacer>

      <v-tooltip v-if="isShowIndvReg" top>
        <template v-slot:activator="{ on }">
          <v-icon
            class="mx-1"
            text
            v-on="on"
            aria-label="Individual registration"
            @click="$router.push('/register')"
          >
            mdi-account-plus
          </v-icon>
        </template>
        <span>{{ $t('Individual registration') }}</span>
      </v-tooltip>
    </div>

    <v-divider></v-divider>

    <!--PASSWORD AND USERNAE INPUTS-->
    <v-card-text class="pa-0 px-2">
      <div class="d-flex align-center">
        <span v-if="isWeb" class="mx-1 w-6r fs-1-25r">{{ $t('Email') }}: </span>
        <v-text-field
          v-model="userName"
          :rules="userNameRules"
          aria-label="email"
          dense
          hide-details
          outlined
          type="text"
          :label="isWeb ? '' : $t('Email')"
          class="my-2 fs-1-25r"
          id="login-email"
          @keypress.enter="enterPressed"
          :disabled="isConfirmationNeeded"
        ></v-text-field>
      </div>

      <div class="d-flex align-center">
        <span v-if="isWeb" class="mx-1 w-6r fs-1-25r"
          >{{ $t('Password') }}:
        </span>
        <v-text-field
          :rules="passwordRules"
          dense
          :name="Math.random()"
          aria-label="password"
          outlined
          :label="isWeb ? '' : $t('Password')"
          class="my-2 fs-1-25r"
          :type="showPassword ? 'text' : 'password'"
          id="login-password"
          v-model.trim="password"
          @keypress.enter="enterPressed"
          :disabled="isConfirmationNeeded"
          hide-details
        >
          <!--WEBSITE-->
          <template
            v-if="$vuetify.breakpoint.width >= 769 && isPassLengthWasZero"
            v-slot:append
          >
            <v-icon
              @mousedown="showPassword = true"
              @mouseup="showPassword = false"
              class="pointer"
              >mdi-eye{{ showPassword ? '-off' : '' }}</v-icon
            >
          </template>
          <!--MOBILE-->
          <template v-else v-slot:append>
            <v-icon
              v-if="isPassLengthWasZero"
              @click="showPassword = !showPassword"
              class="pointer"
              >mdi-eye{{ showPassword ? '-off' : '' }}</v-icon
            >
          </template>
        </v-text-field>
      </div>
    </v-card-text>

    <!--ASK IF USER FORGOT PASSWORD-->
    <div
      v-if="!isConfirmationNeeded"
      class="d-flex justify-space-between align-center pa-2"
    >
      <button
        @click="$emit('set-forgot-password', true)"
        class="forgot-password ma-0"
        aria-label="forgot password?"
      >
        {{ $t('Forgot Password?') }}
      </button>
      <v-spacer></v-spacer>
      <Button
        :clickAction="login"
        :disabled="isLoginButtonDisabled"
        :text="$t('Login')"
        width="80"
      ></Button>
    </div>

    <!--IF 2FA IS NEEDED-->
    <ConfirmedLogin
      v-else
      @set-sms-confirm-code="v => (smsConfirmCode = v)"
      :login="login"
      :clear="clear"
      :confirmLogin="confirmLogin"
      :timerTime="timerTime"
      :loading="loading"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { validateEmail } from '@/utils'

//Base Components
import Button from '@/components/BaseComponents/Button/Button.vue'

import ConfirmedLogin from '../../../ConfirmedLogin/ConfirmedLogin.vue'

export default {
  name: 'Login',
  data() {
    return {
      userNameRules: [v => !!v.trim() || this.$t('Email is required')],
      passwordRules: [v => !!v || this.$t('Password is required')],
      showPassword: false,
      userName: '',
      password: '',
      loading: false,
      smsConfirmCode: '',
      timer: null,
      timerTime: 0,
      isPassLengthWasZero: false,
    }
  },
  emits: ['set-forgot-password', 'set-user-name'],
  components: { Button, ConfirmedLogin },
  watch: {
    timerTime() {
      if (this.timerTime < 1) {
        this.stopTimer()
      }
    },
    userName(n) {
      //this is to send to forgot password the email
      this.$emit('set-user-name', n)
    },
    password(n, o) {
      if (this.isPassLengthWasZero) return
      if (n.length > 2 && o.length === 0) this.isPassLengthWasZero = false
      else if (n.length === 1 && o.length === 0) this.isPassLengthWasZero = true
    },
  },
  computed: {
    ...mapGetters(['isConfirmationNeeded']),
    isLoginButtonDisabled() {
      if (!validateEmail(this.userName)) return true
      else if (this.password.length < 6) return true
      return false
    },

    isWeb() {
      if (this.$vuetify.breakpoint.width > 769) return true
      return false
    },

    isShowIndvReg() {
      return false
      if (window.location.hostname === 'dotengines.com') return false
      return true
    },
  },
  methods: {
    ...mapMutations(['SET_ISCONFIRMATIONNEEDED']),
    ...mapActions(['CONFIRM_LOGIN']),
    login() {
      this.$store
        .dispatch('LOGIN', {
          username: this.userName,
          password: this.password,
        })
        .then(() => {
          if (this.isConfirmationNeeded) {
            this.timerTime = 30
            this.timer = setInterval(() => {
              --this.timerTime
            }, 1000)
          }
        })
    },

    async confirmLogin() {
      try {
        this.loading = true
        const data = {
          value: this.userName,
          code: this.smsConfirmCode,
        }
        await this.CONFIRM_LOGIN(data)
      } catch (error) {
      } finally {
        this.loading = false
      }
    },

    enterPressed() {
      if (validateEmail(this.userName) && this.password.length > 5) this.login()
    },
    clear() {
      this.stopTimer()
      this.SET_ISCONFIRMATIONNEEDED(false)
      this.timer = null
      this.timerTime = 0
      this.loading = false
      this.smsConfirmCode = ''
      this.showPassword = false
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
  },
}
</script>
