<template>
  <v-dialog
  persistent
    v-model="dialog"
    width="auto !important"
    class="wrapper-1 bg-white pos-relative"
     :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
  >
    <v-card class="wrapper-1">
      <v-card-title class="text-center bg-grey mb-2">
        {{$t('Enter Verification Code')}}
      </v-card-title>
      <v-card-text class="pa-0 px-2">
        <div class="d-flex align-center">
          <span class="fs-1-12r">{{`${$t("We've sent a verification code to")}: ${email}. ${$t('Please enter it here')}:`}}</span>
          <v-text-field
            v-model="code"
            dense
            hide-details
            outlined
            type="text"
            :label="$t('Code')"
            class="ma-2 fs-1-25r"
            @keypress.enter="enterPressed"
          ></v-text-field>
        </div>
      </v-card-text>
      <div class="d-flex justify-space-between pa-2">
          <Button
            :clickAction="closeDialog"
            width="fit-content"
            btnType="grey"
            :text="$t('Cancel')"
            class="mx-2"
          />
          <Button
            :clickAction="() => (verifyCode(code))"
            width="fit-content"
            btnType="blue"
            class="mx-2"
            :text="$t('Verify Code')"
            :disabled="code.length === 0"
          />
        </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue';

export default {
  name: 'EnterCodeDialog',
  data() {
    return {
      dialog: true,
      code: ''
    }
  },
  props: {
    email: String,
    closeDialog: Function,
    verifyCode: Function,
  },
  components: {Button}
}
</script>
