<template>
  <div class="d-flex">
    <!--AUTH TOKEN-->
    <v-text-field
      v-model="smsSettings.smsDefinitions.authToken"
      :disabled="!isSettingsEnabled"
      label="Authenticate Token"
      placeholder=" "
      class="mx-4"
      type="text"
    >
    </v-text-field>
    <!--ACCOUNT SID-->
    <v-text-field
      v-model="smsSettings.smsDefinitions.accountSid"
      :disabled="!isSettingsEnabled"
      label="Account SID"
      placeholder=" "
      class="mx-4"
      type="text"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'SMSTwilio',
  props: { smsSettings: Object, isSettingsEnabled: Boolean },
}
</script>
