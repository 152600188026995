<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Guest Users</h4>
    <div class="wrapper-2 px-2 py-2 pos-relative">
      <div class="d-flex align-center">
        <div
          @mouseover="showGuestsMessage = true"
          @mouseleave="showGuestsMessage = false"
        >
          <v-checkbox
            v-model="profile.isAllowCasuals"
            :disabled="
              isFieldDisabled ||
              (!freezedProfile.isAllowCasuals &&
                casualLicense.numOfInUse + 1 > casualLicense.numOfAllowed)
            "
            label="License to interact with guests"
            class="mb-2"
            hide-details
            dense
          />
        </div>
        <span
          v-if="
            showGuestsMessage &&
            !freezedProfile.isAllowCasuals &&
            casualLicense.numOfInUse + 1 > casualLicense.numOfAllowed
          "
          class="red--text ml-4"
          >Not enough licenses</span
        >
      </div>
      <!-- <span class="fs-1r"
        >Usage:
        {{
          freezedProfile.isAllowCasuals !== profile.isAllowCasuals
            ? casualLicense.numOfInUse + 1
            : casualLicense.numOfInUse
        }}
        / {{ casualLicense.numOfAllowed }}</span
      > -->
      <Button
        text="SFTP Access Details for Downloading"
        btnType="grey"
        class="mx-2"
        :clickAction="() => sftpDetailsDownloadingClicked()"
      />
      <OnlineHelp page="singleUser" section="casual" title="Guest Users" />
      <CasualGroups
        v-if="
          profile.license === 'SFTP' &&
          profile.isAllowCasuals &&
          profile.casualUsers.length > 0
        "
        :user="profile"
        :parent="'admin'"
        @setGroup="setGroup"
      />
      <!-- different for each group -->
      <SftpDetailsDialogForCasualRecipients
        v-if="isShowSftpDetailsDialogForCasualRecipients"
        :closeDialog="() => (isShowSftpDetailsDialogForCasualRecipients = false)"
        :isTarget="isTarget"
        :userEmail="profile.email"
        :groupName="group.name"
        :groupMembers="group.casualUsers"
      />
      <!-- once for all guests, not related to groups -->
      <SftpDetailsDialogForCasualSenders
        v-if="isShowSftpDetailsDialogForCasualSenders"
        :closeDialog="() => (isShowSftpDetailsDialogForCasualSenders = false)"
        :userEmail="profile.email"
        :casualUsers="profile.casualUsers"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import CasualGroups from '@/components/Profile/GuestsSFTP/CasualGroups.vue'
import SftpDetailsDialogForCasualRecipients from './Children/SftpDetailsDialogForCasual/SftpDetailsDialogForCasualRecipients.vue'
import SftpDetailsDialogForCasualSenders from './Children/SftpDetailsDialogForCasual/SftpDetailsDialogForCasualSenders.vue'
import { mapGetters, mapMutations } from 'vuex'

//Components files
import { regularUserCasualUsersTableHeaders } from './CasualUsers'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'CasualUsers',
  data() {
    return {
      regularUserCasualUsersTableHeaders,
      isNumOfAllowedError: false,
      isCasualUsersTableOpen: false,
      isShowSftpDetailsDialogForCasualRecipients: false,
      isShowSftpDetailsDialogForCasualSenders: false,
      isTarget: false,
      group: null,
      showGuestsMessage: false,
    }
  },
  props: {
    profile: Object,
    isFieldDisabled: Boolean,
    casualLicense: Object,
    freezedProfile: Object,
  },
  computed: {
    ...mapGetters(['currentOrganization']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    setGroup(group) {
      this.group = group
      this.isShowSftpDetailsDialogForCasualRecipients = true
    },
    sftpDetailsDownloadingClicked() {
      this.isShowSftpDetailsDialogForCasualSenders = true
      this.isTarget = true
    },
  },
  components: {
    Button,
    OnlineHelp,
    CasualGroups,
    SftpDetailsDialogForCasualRecipients,
    SftpDetailsDialogForCasualSenders,
  },
}
</script>
