<template>
  <!--THIS IS THE WRAPPER FOR LOGIN AND RESET PASSWORD-->
  <div
    v-if="isShowLogin"
    :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    class="wrapper-2 login-form mt-8 mx-8 w-24r"
  >
    <Login
      v-if="!forgotPassword"
      @set-forgot-password="v => (forgotPassword = v)"
      @set-user-name="v => (userName = v)"
    />
    <ResetPassword
      v-if="forgotPassword"
      :language="language"
      :userName="userName"
      @set-forgot-password="v => (forgotPassword = v)"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Children
import ResetPassword from './Childern/ResetPassword/ResetPassword.vue'
import Login from './Childern/Login/Login.vue'
import router from '@/router';

export default {
  name: 'LoginForm',
  data() {
    return {
      isFromProfile: false,
      isSecondStep: false,
      loading: false,
      password: '',
      forgotPassword: false,
      userName: '',
    }
  },
  components: { Button, ResetPassword, Login },
  computed: {
    ...mapGetters(['isShowLogin', 'language']),
  },
  methods: {
    ...mapMutations(['SET_IS_SHOW_LOGIN', 'SET_SERVER_ERROR_NOTIFICATION']),
  },
  async created() {
    const user = sessionStorage.getItem('user')
    if (!user) router.push('/sign')
    //weird but this is only work with the timeout
    setTimeout(() => {
      const { isforgotpassword, email } = this.$route.query
      if (isforgotpassword) this.forgotPassword = true
      if (email) this.userName = email
      window.history.replaceState({}, '', location.pathname)
    }, 25)
    this.SET_IS_SHOW_LOGIN(true)
  },
  beforeDestroy() {
    if (this.timer) {
      this.stopTimer()
    }
  },
}
</script>

<style scoped src="./LoginForm.css"></style>
