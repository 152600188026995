<template>
  <div class="mx-4">
    <!--TEXT AND RECOMMENDED SIZE-->
    <span
      class="small-title"
      :class="[currentOrganization.status !== 0 && 'low-op-disabeld']"
      >Kiosk Logo
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small>mdi-information-outline</v-icon>
        </template>
        <span> Recommended: square .png file</span>
      </v-tooltip>
    </span>

    <!--SHOWN IMAGE-->
    <v-img
      ref="logoImage"
      :src="imageUrlWithTimestamp"
      alt="Header logo"
      class="shown-logo"
      contain
    />
    <!--SELECT LOGO-->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-hover v-slot="{ hover }">
          <v-icon
            :color="hover ? 'green' : 'grey'"
            v-on="on"
            class="my-2 mr-2"
            @click="logoFileInputClicked"
            :disabled="currentOrganization.status !== 0"
            >mdi-upload</v-icon
          >
        </v-hover>
      </template>
      <span>Select logo</span>
    </v-tooltip>

    <!--DELETE LOGO-->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-hover v-slot="{ hover }">
          <v-icon
            :color="hover ? 'red' : 'grey'"
            v-on="on"
            class="my-2"
            @click="deleteLogo"
            :disabled="currentOrganization.status !== 0"
            >mdi-delete</v-icon
          >
        </v-hover>
      </template>
      <span>Delete logo</span>
    </v-tooltip>

    <!--THE INPUT TO ADD LOGO-->
    <input
      style="display: none"
      type="file"
      ref="avatar"
      dense
      accept="image/png, image/jpeg, image/bmp"
      @change="e => updateLogoPreview(e)"
    />
  </div>
</template>

<script>
import { api, selectedHost } from '@/config'
import { alertDialog, confirmDialog } from '@/utils'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Logo',
  data() {
    return {
      logoUrl: null,
      readerResult: null,
    }
  },

  props: {
    images: Object,
  },
  computed: {
    ...mapGetters(['currentOrganization']),
    imageUrlWithTimestamp() {
      if (this.readerResult) return this.readerResult

      if (this.images.logo === 'delete')
        return `${selectedHost}/api/images/0/kiosklogo?${Date.now()}`

      return `${selectedHost}/api/images/${
        this.currentOrganization.organizationId
      }/kiosklogo?${Date.now()}`
    },
  },

  methods: {
    ...mapMutations(['SET_NOTIFICATION']),

    logoFileInputClicked() {
      this.$refs.avatar.value = null
      this.$refs.avatar.click()
    },

    async updateLogoPreview(e) {
      const logo = e.target.files[0]
      if (!logo.type.includes('image/' || logo.type.includes('svg+xml'))) {
        return alertDialog(this, 'File type is not supported for logo')
      }
      const reader = new FileReader()
      reader.readAsDataURL(logo)

      this.images.logo = logo

      //i dont know why but the timeout is needed to show the logo
      setTimeout(() => {
        this.readerResult = reader.result
      }, 100)
    },

    deleteLogo() {
      this.images.logo = 'delete'
    },
  },
}
</script>

<style scoped src="./Logo.css"></style>
