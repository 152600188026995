<template>
  <v-tab-item value="about">
    <div class="pa-2" style="height: 60vh; overflow: auto">
      <div class="d-flex">
        <img
          :height="isMobile ? '30px' : '60px'"
          :src="yazamLogo"
          aria-label="YazamTech Logo"
          alt="YazamTech Logo"
        />
        <v-spacer></v-spacer>
        <img
          :height="isMobile ? '30px' : '60px'"
          :src="dotEnginesLogo"
          alt="dotEngines Logo"
          aria-label="dotEngines Logo"
        />
      </div>
      <h2 class="text-center fs-1-5r">dotEngines by YazamTech</h2>
      <div class="about-p mx-auto fs-1-12r">
        <p class="text-justify">
          YazamTech is an innovator of a comprehensive cyber security range of
          next-generation Content Disarm & Reconstruction (CDR) Solutions to
          prevent infected file streams from entering the network, either by
          blocking the infected file streams, or by actively disarming their
          content and reconstructing them securely prior to entering the
          network. YazamTech’s proprietary products Manage & Control the risks
          of Advanced Threats, both known and unknown, from entering the network
          with minimal effect on the File Stream Activity. Rapid CDR processing
          and scalability ensures Business Continuity and an Optimal User
          Experience.
        </p>

        <p class="text-justify">
          YazamTech as the next generation CDR vendor specializes in developing
          and manufacturing unique data security systems, providing reliable
          response to a wide range of security threats. YazamTech develops
          complementary systems that provide a solution to data related attacks
          as well as to sensitive information leak threats, offering security
          systems that minimize the risks in necessary data transferring to and
          from secured environments. YazamTech dynamically expands its spectrum
          of technological cyber security solutions as the number and variety of
          threats evolve. YazamTech’s solutions are fully flexible, and can be
          customized, taking into consideration the organization’s needs.
        </p>
      </div>
    </div>
  </v-tab-item>
</template>

<script>
import { selectedHost } from '@/config'
import yazamLogo from '@/assets/yazam-logo.png'

export default {
  name: 'AboutSection',
  data() {
    return {
      yazamLogo,
      dotEnginesLogo: `${selectedHost}/api/images/0/logo`,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 420
    },
  },
}
</script>
