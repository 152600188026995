<template>
  <div class="mb-2 files-table fs-1r">
    <!--TITLE-->
    <div class="d-flex font-weight-bold" tabindex="0" role="columnheader">
      <span class="mr-2 w-75pr">{{ $t('Name') }}</span>
      <span>{{ $t('Size') }}</span>
    </div>

    <!--FILES LIST-->
    <div
      v-for="file in session.filesData"
      @click="showMoreDetailsCicked(file)"
      class="d-flex my-2 bottom-border"
      tabindex="0"
      role="row"
    >
      <!--FILE ICON-->
      <b :class="file.file.icon" class="mx-1 w-5pr"></b>

      <!--FILE NAME-->
      <div class="mr-2 file-name">
        {{ file.file.name }}
      </div>

      <!--FILE SIZE-->
      <span class="text-right pr-2 w-60">
        {{ file.file.numbSize | bytesToSize }}</span
      >

      <!--CHEVRON TO SHOW IF MORE DETAILS IS OPEN-->
      <v-icon class="w-20 mr-3" @click="showMoreDetailsCicked(file)" aria-label="More information"
        >mdi-dots-horizontal-circle-outline</v-icon
      >
    </div>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils'

export default {
  name: 'FilesTable',
  props: {
    session: Object,
    showMoreDetailsCicked: Function,
  },
  filters: {
    bytesToSize,
  },
}
</script>

<style scoped src="./FilesTable.css"></style>
