import { api, selectedHost } from '@/config'

export default {
  state: {
    themeSettings: {
      language: 'en',
      idleCountDown: 1000 * 3,
      idleCountMessage: 60,
    },
  },
  actions: {
    async GET_THEME({ commit }, payload) {
      const id = payload?.id
      const suffix = payload?.suffix
      let query = null
      if (payload?.suffix) query = `/organizations/theme/0?suffix=${suffix}`
      else if (payload?.id) query = `/organizations/theme/${id}`
      else query = '/organizations/theme/0'

      const res = await api.get(query)
      if (res.status !== 200) {
        commit('SET_NOTIFICATION', {
          type: 'error',
          text: `Can't fetch theme`,
        })
        return
      }
      //bandage - fix
      if (!res.data.theme)
        res.data.theme = { idleCountDown: 10, idleCountDownMessage: 1 }
      if (
        res.data.theme.idleCountDown === 0 ||
        res.data.theme.idleCountDown === null
      ) {
        res.data.theme.idleCountDown = 10
      }
      if (
        res.data.theme.idleCountDownMessage === 0 ||
        res.data.theme.idleCountDownMessage === null
      ) {
        res.data.theme.idleCountDownMessage = 1
      }
      if (id) {
        res.data.backgroundUrl = `${selectedHost}/api/images/${id}/background`
        res.data.logoUrl = `${selectedHost}/api/images/${id}/logo`
      } else if (suffix) {
        res.data.backgroundUrl = `${selectedHost}/api/images/${res.data.organizationId}/background`
        res.data.logoUrl = `${selectedHost}/api/images/${res.data.organizationId}/logo`
      } else {
        res.data.backgroundUrl = `${selectedHost}/api/images/0/background`
        res.data.logoUrl = `${selectedHost}/api/images/0/logo`
      }
      if (res.data.language === null) res.data.language = 'en'
      commit('SET_THEME', res.data)
      return res
    },
  },
  mutations: {
    SET_THEME(state, payload) {
      state.themeSettings = payload
    },
    SET_LANGUAGE(state, payload) {
      state.themeSettings.language = payload
    },
  },
  getters: {
    themeSettings: state => state.themeSettings,
    accentThemeColor: state => state.themeSettings.themeColor,
    language: state => state.themeSettings.language,
    idleCountDown: state => state.themeSettings.idleCountDown,
    idleCountDownMessage: state => state.themeSettings.idleCountDownMessage,
  },
}
