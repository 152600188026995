<template>
  <div class="d-flex">
    <!--INPUT FOR SMS PROFILE ID-->
    <v-text-field
      v-model="smsSettings.smsDefinitions.profileId"
      :disabled="!isSettingsEnabled"
      class="mx-4"
      maxLength="5"
      label="Profile ID"
    >
    </v-text-field>

    <!--INPUT FOR SMS API KEY-->
    <v-text-field
      v-model="smsSettings.smsDefinitions.apiKey"
      :disabled="!isSettingsEnabled"
      class="mx-4"
      label="API Key"
      type="text"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'SMSTelnyx',
  props: {
    smsSettings: Object,
    isSettingsEnabled: Boolean,
  },
}
</script>
